import {IonSkeletonText} from "@ionic/react";
import {useEffect, useState} from "react";
import {getVendorProductsPure, getVendorPure} from "../../Api/api.vendor";
import PrivatePage from "../../Components/Page/PrivatePage";
import ProductList from "../Product/ProductList";
import {VendorType} from "./VendorStore";
import {Helmet} from 'react-helmet';
import AppHeader from "../../Components/Header/AppHeader";
import VendorHero from "./Components/VendorHero";
import {useLocation} from "react-router-dom";
import ProductClass from "../Product/ProductClass";
import {UserStore} from "../User/UserStore";
import {useStoreState} from "pullstate";
import {clearActiveCollection} from "../Collection/CollectionStore";

const VendorCollectionPage = ({...props}: any) => {
  const vendorId = props.match.params.vendorId;

  const hubs = useStoreState(UserStore, s => s.hubs);

  const [thisVendor, setThisVendor] = useState<VendorType | null>(null);
  const [products, setProducts] = useState<ProductClass[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const location = useLocation();

  useEffect(() => {
    clearActiveCollection();
  }, [vendorId]);


  useEffect(() => {
    let ignore = false;
    setCurrentPage(1);
    setProducts(null);
    getVendorPure(vendorId).then((vendor) => {
      if (vendor && !ignore) {
        setThisVendor(vendor);
      }
    });

    return () => {
      ignore = true
    };
  }, [vendorId]);

  useEffect(() => {
    setCurrentPage(1);
    setProducts(null);
  }, [hubs]);


  useEffect(() => {
    let ignore = false;
    getVendorProductsPure(vendorId, currentPage).then((res) => {
      if (!ignore) {
        setTotalPage(Math.ceil(res.totalResults / res.rowsPerPage));
        setProducts(res.products);
      }
    });

    return () => {
      ignore = true;
    };
  }, [vendorId, currentPage, hubs]);

  return (
    <PrivatePage>
      <Helmet>
        <title>Browse {thisVendor?.name ?? 'Vendor'} Details</title>
        <link rel="canonical"
              href={`https://shop.marketwagon.com${location.pathname !== null ? location.pathname : ''}${location.search !== null ? location.search : ''}`}/>
      </Helmet>

      <AppHeader
        specialTab={thisVendor?.name}
      />

      <VendorHero
        isLoading={thisVendor}
        compact={props.compact}
        backgroundImage={thisVendor?.heroImage}
        avatar={thisVendor?.avatar}
        name={thisVendor?.name}
        children={props.children}
        description={thisVendor?.description}
      />

      {!thisVendor &&
        <div
          className='grid max-w-screen-xl grid-cols-1 gap-4 px-4 py-4 mx-auto product-list-grid md:grid-cols-2 lg:grid-cols-3 '>
          <IonSkeletonText
            animated
            className='w-full h-80 rounded-lg'
          />

          <IonSkeletonText
            animated
            className='w-full h-80 rounded-lg'
          />

          <IonSkeletonText
            animated
            className='w-full h-80 rounded-lg'
          />

          <IonSkeletonText
            animated
            className='w-full h-80 rounded-lg'
          />

          <IonSkeletonText
            animated
            className='w-full h-80 rounded-lg'
          />

          <IonSkeletonText
            animated
            className='w-full h-80 rounded-lg'
          />
        </div>
      }

      <ProductList
        products={products}
        currentPage={currentPage}
        link={`/collections/vendor/${thisVendor?.id}?page={page}`}
        totalPages={totalPage}
        onPageChange={(page: any) => setCurrentPage(page)}
        isLoading={!products}
      />
    </PrivatePage>
  )
}

export default VendorCollectionPage;
