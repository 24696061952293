import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useStoreState} from 'pullstate';
import {UserStore} from '../../Domains/User/UserStore';
import ShareButton from '../ShareButton/ShareButton';

const ShareModal = ({...props}: { onDismiss: Function }) => {
  const userState = useStoreState(UserStore);
  const inviteUrl = `${window.location.origin}/shop?referredByCustomerId=${userState.id}?share=social`;
  return (
    <div className='bg-white border-transparent h-full overflow-hidden sm:h-auto sm:rounded-lg'>
      <IonHeader className='sticky top-0 z-50'>
        <IonToolbar className='rounded-lg'>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>
          </IonButtons>
          <IonTitle className='flex-grow flex-shrink-0 w-full whitespace-nowrap'>
            Share your Favorite Local Foods
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className='px-8 py-4'>
        <p className='pb-2'>
          You can show your support for local food by inviting your friends
          to shop local with you. Earn a $20 referral bonus when a friend
          places their first Market Wagon order.
        </p>
        <div className='flex flex-shrink-0 flex-wrap items-center justify-center'>
          <ShareButton
            className='text-white bg-green-600 rounded-md'
            title='Shop with me on Market Wagon'
            url={inviteUrl}
            label='Share'
          />
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
