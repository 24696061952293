import {createSelector} from 'reselect';
import {Store} from 'pullstate';

import {
  getAuth,
  serverLogout,
} from '../../Api/api.auth';
import {
  getProfile,
  loginWithEmail,
  parseAccountPayload,
  registerAccount,
} from '../../Api/api.account';

import {GET, PUT} from '../../Api/Api';
import {getFriends} from '../../Api/api.friends';
import {getFeed} from '../../Api/api.feed';
import {getOrders} from '../../Api/api.orders';
import {AccountPayload} from './types';

const starterState: AccountPayload = {
  hubs: [],
  avatar: undefined,
  firstName: undefined,
  handle: undefined,
  lastName: undefined,
  deliveryAddress: undefined,
};

// Merge localStorage with Starter State
const baseUserStore: AccountPayload = {
  ...starterState,
};

/**
 * Main User Store
 * This is used for more complicated state management
 * than just useState
 */
export const UserStore = new Store(baseUserStore);

// Get State function
const getState = (state: any): AccountPayload => state;

/**
 * User Login
 * @param email
 * @param password
 */
export const userLogin = async (
  email: string,
  password: string
): Promise<void> => {
  try {
    const playerId = UserStore.getRawState().playerId || '';
    const os = UserStore.getRawState().os || '';

    const account: AccountPayload = await loginWithEmail(
      email,
      password,
      playerId,
      os
    ).catch((e) => {
      throw new Error(e);
    });

    // ts-ignore is invoked because we can't add types to the window event, and the _learnq property comes from Klaviyo at run time
    // @ts-ignore
    const _learnq: any = window._learnq || [];

    _learnq.push(['identify', {
      '$email': account.email,
      '$first_name': account.firstName,
      '$last_name': account.lastName
    }]);

    setUserAuth(account).then();
  } catch (e: any) {
    throw new Error(`${e.message}`);
  }
};

export const userRegister = async (
  firstName: string | null,
  lastName: string | null,
  email: string,
  password: string | null,
  zipCode: string | null,
  referredByCustomerId: string | null = null
) => {
  try {
    const playerId = UserStore.getRawState().playerId || '';
    const os = UserStore.getRawState().os || '';

    const account: AccountPayload = await registerAccount(
      firstName,
      lastName,
      email,
      password,
      zipCode,
      playerId,
      os,
      referredByCustomerId
    );

    if (account) {
      UserStore.update(() => account);
    }
  } catch (e: any) {
    throw new Error(e.message || e);
  }
};

/**
 * Load the Users Profile from the API
 * Update the store
 */
export const loadUserProfile = async (profileData: Object = {}) => {
  try {
    let hubData = await GET('/auth/bridge');
    hubData = hubData.payload;
    let profile: any;
    let piecedTogetherProfile: any;

    if (('hubs' in profileData || 'hubs' in hubData)) {
      profile = await getProfile();
    } else {
      profile = profileData;
    }

    piecedTogetherProfile = {
      ...profile,
      ...hubData,
    };

    profile = piecedTogetherProfile;
    profile = parseAccountPayload(profile);

    setUserAuth(profile).then();

    return profile;
  } catch (e) {
  }
};

/**
 * Set the User Hub by lookup props
 * @param props
 */
export const setUserHub = async (props: any) => {
  try {
    UserStore.update((s) => {
      s.hubId = props.id
    });

    const auth = parseAccountPayload(UserStore.getRawState());

    setUserAuth(auth).then();
  } catch (e) {
  }
};

/**
 * Check to see if we know who this user is
 */
export const isUserKnown = createSelector(getState, (state) => {
  return state.hubs?.length;
});


/**
 *
 * @returns true if the user has a non-negative ID, and the 'isGuest' property is not true; false in all other cases
 */
export const isLoggedIn = () => {
  const userState = UserStore.getRawState();
  let whatToReturn = false;

  // Neither isGuest nor ID
  if (!userState.hasOwnProperty('isGuest') && !userState.hasOwnProperty('id')) {
    whatToReturn = false;
  }

  if (userState.hasOwnProperty('isGuest') && userState.hasOwnProperty('id')) {
    if (String(userState.id).includes('-') && userState.isGuest) {
      whatToReturn = true;
    } else {
      whatToReturn = true;
    }
  }

  return whatToReturn;
}

/**
 * Log a user out
 */
export const logout = async () => {
  await serverLogout();

  // Force the whole app to refresh
  setTimeout(() => {
    window.location.href = '/';
  }, 500);
};

export const switchHub = async (id: string) => {
  const res = await PUT(`/hubs/${id}/switch`);

  if (res.success) {
    await loadUserProfile();
    await getFeed(res.payload[0].id);
    await getOrders();

    return true;
  } else {
    throw new Error('Error changing delivery dates.');
  }
};

export const hasFriends = async () => {
  const call = await getFriends();
  const userHasFriends = !!call.length;
  const numberOfFriends = call.length;

  return {
    'hasFriends': userHasFriends,
    'numberOfFriends': numberOfFriends
  };
}

export const setUserAuth = async (auth: any) => {
  let isGuest = auth.isGuest;

  // If a user comes through the site and doesn't trigger the bridge, guest bridge, lat/lng, or hub id logins, they use the normal login.
  // If they just enter a zipcode, that endpoint does not return a user id, just the hubs.
  // So let's make a call to auth and get the customer's id from there.
  let guestId: string | null = null;

  if (auth.id === undefined) {
    await getAuth().then((res: any) => {
      guestId = res.customerId
    });
  }

  if (auth.id && String(auth.id).includes('-')) {
    isGuest = true;
  }

  UserStore.update((s) => {
    s.avatar = auth?.avatar;
    s.customerId = auth?.customerId;
    s.dataSource = auth?.dataSource;
    s.deliveryAddress = auth?.deliveryAddress;
    s.email = auth?.email;
    s.firstName = auth?.firstName;
    s.handle = auth?.handle;
    s.hasPassword = auth?.hasPassword;
    s.hubs = auth?.hubs ?? [];
    s.hubId = auth?.hubId;
    s.id = auth?.id !== undefined ? auth?.id : guestId;
    s.image = auth?.image;
    s.isGlutenFree = auth?.isGlutenFree;
    s.isGuest = isGuest;
    s.isVegan = auth?.isVegan;
    s.isWagonPassActive = auth?.isWagonPassActive;
    s.lastName = auth?.lastName;
    s.lastOrderDate = auth?.lastOrderDate;
    s.paymentInfo = auth?.paymentInfo;
    s.paymentType = auth?.paymentType;
    s.subscribedAllMessages = auth?.subscribedAllMessages;
    s.zipCode = auth?.zipCode;
    s.os = auth?.os;
    s.playerId = auth?.playerId;
    s.utmSource = auth?.utmSource;
    s.utmCampaign = auth?.utmCampaign;
  });

  return UserStore.getRawState();
}

/**
 * Sets a simple property on the UserStore.
 * @param key UserStore key to be set
 * @param value UserStore value to be set
 */
export const setUserStoreProperty = (key: any, value: any) => {
  UserStore.update((s: any) => {
    s[key] = value;
  });
}
