import {IonIcon} from '@ionic/react';
import {arrowForwardOutline} from 'ionicons/icons';
import {VendorItemType} from '../types';

export const VendorCard = ({...props}: VendorItemType) => {
  return (
    <div className="flex w-full bg-white shadow-lg rounded-lg h-40 md:max-w-22.5rem">
      <div className="flex justify-center w-1/2 rounded-tl-lg rounded-bl-lg overflow-hidden"
           style={{"backgroundColor": "rgb(249 249 249)"}}>
        <div className="max-w-7.813rem self-center overflow-hidden">
          <img
            className="w-auto"
            src={props.vendor.avatar}
            alt={props.vendor.name}
          />
        </div>
      </div>

      <div
        className={`px-2 w-1/2 flex flex-col justify-center ${props.vendor.name.includes('.com') ? ' break-all' : 'break-word'}`}>
        <h2 className="font-semibold text-lg leading-4 my-1">{props.vendor.name}</h2>
        <p className="font-medium text-xs text-gray-600">
          {props.vendor?.city}, {props.vendor.state}
        </p>

        <button
          className="flex text-green-600 hover:text-green-500 mt-5 font-bold text-sm"
          title="Shop"
          onClick={props.onClick}
        >
          Shop Now
          <IonIcon className='ml-2 self-center' icon={arrowForwardOutline}/>
        </button>
      </div>
    </div>
  );
};
