import React from 'react';
import ReactDOM from 'react-dom';
import {getAuth, getBridgeData, handleBridgeData, handleGuestBridge} from './Api/api.auth';
import './index.css';
import App from './App';
import {getProfile, loginGuest} from './Api/api.account';
import {CoordinatesType} from './Domains/Onboard/types';

/**
 * App init function
 * App init gathers the following data before we init React
 * - Auth Data
 * - Bridge Data
 * - and if we have a customer id, their profile data
 * Once gathered, we determine what "onboarding" process they should take - Guest Bridge, User Bridge, or login.
 */
const appInit = async () => {
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  let authData: any = {};
  let bridgeData: any = {};
  let profileData: any = {};
  let userData: any = {}; // userData will hold all the data we gather about the user based on their auth/bridge/profile
  let mountWithError: string = '';

  // Get and process auth data into our userData array
  await getAuth().then((res: any) => {
    authData = res;
    userData = {...userData, ...authData};
  });

  // Gather and process bridge data
  await getBridgeData().then((res: any) => {
    bridgeData = res;
    userData = {...userData, ...bridgeData};
  });

  // If we have a user id who isn't a guest, let's grab their profile data as well.
  if (bridgeData.id && !bridgeData.id.includes('-')) {
    await getProfile().then((res: any) => {
      profileData = res;
      userData = {...userData, ...profileData};
    })
  }

  // Process and add all of our query params into UserState.
  // Might make sense to move this to URL Param redirectors...
  urlQuery.forEach((value: any, key: any) => {
    userData[key] = value;
  });


  let utmSource = userData.utmSource;
  if (!utmSource) {
    utmSource = userData.utm_source;
  }

  let utmCampaign = userData.utmCampaign;
  if (!utmCampaign) {
    utmCampaign = userData.utm_campaign;
  }

  // If we have a user ID and that ID includes '-', this is a guest user
  if (userData.id && String(userData.id).includes('-')) {
    handleGuestBridge(userData).then();

    // If we have an id, and '-' is not present, this is a normal user
  } else if (userData.id && !String(userData.id).includes('-')) {
    handleBridgeData(userData).then();
  } else if ((userData.hubId || userData.wagonId) && !userData.id) {
    // If we have a hubId query Param, but not user id, this is a guest we should onboard
    await loginGuest(null, null, userData.hubId || userData.wagonId, utmSource, utmCampaign).catch(() => {
      mountWithError = 'Sorry, we do not currently support your location.'
    });

    // If we do not have a hub id query param, no user id, but we do have a lat/lng, let's onboard them with that knowledge.
  } else if (!userData.hubId && !userData.id && (userData.lat && userData.lng)) {
    const latLng: CoordinatesType = {
      latitude: parseFloat(userData.lat),
      longitude: parseFloat(userData.lng),
    };

    await loginGuest(null, latLng, null, utmSource, utmCampaign).catch(() => {
      mountWithError = 'Sorry, we do not currently support your location.'
    });
  }
  // In the above if/else if block, if the user data doesn't meet any of those criteria, we can assume they are not a user or guest and can show them the onboarding modal on mount.

  // Hide init loader
  document.querySelector('.InitLoader')?.classList.add('opacity-0');

  // Remove it from the page
  setTimeout(() => {
    document.querySelector('.InitLoader')?.remove();
  }, 1250);

  // Mount React once the above completes. We are also passing "mountWithError" in the event something goes wrong.
  ReactDOM.render(
    <React.StrictMode>
      <App mountWithError={mountWithError}/>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

appInit().then();
