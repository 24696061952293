import {IonSkeletonText, useIonRouter} from "@ionic/react";
import {useListBundles} from "../bundle.actions";
import {LoadingState, useAppStore} from "../../store";
import BundleModel from "../bundle.model";

const BundlesSection = () => {
  const router = useIonRouter();
  const [bundles, bundlesLoadingState] = useListBundles();
  const update = useAppStore(s => s.update);

  const getBundles = () => bundles ?? [];
  const handleBundleClick = (bundle: BundleModel) => {
    update({activeBundle: bundle})
    router.push(`/bundles/${bundle.slug}`);
  }

  return (
    <div>
      <section
        className="bg-white mt-6 mx-4 px-4 rounded-sm shadow-lg py-4 md:flex md:flex-col md:gap-x-5 md:px-8 2xl:flex-row 2xl:justify-center 2xl:max-w-78rem 2xl:mx-auto 2xl:gap-x-24">
        <h3 className="font-semibold text-sm text-black text-center md:self-center md:mb-2 2xl:text-left 2xl:w-44">
          <span className="hidden text-mw-orange font-medium md:block">Check out our Bundles! <br/></span>
          Pre-selected baskets of the finest Local Food
        </h3>

        <div
          className="flex justify-between items-start mt-3 w-full mx-auto md:mx-0 md:max-w-none md:mt-0 md:items-center md:justify-center md:gap-x-10">
          {bundlesLoadingState === LoadingState.LOADED && getBundles() && getBundles().map((bundle, index) => {
            return (
              <button
                key={index}
                title={`View ${bundle.title}`}
                onClick={() => {
                  handleBundleClick(bundle);
                }}
                className='hover:shadow-lg rounded-2.5xl bg-white w-full max-w-5.375rem gap-x-2 md:flex md:w-44 md:items-center md:max-w-none'
              >
                <img
                  alt={bundle.description}
                  className='w-14 h-14 sm:w-5.375rem sm:h-4.563rem rounded-2.5xl object-cover mb-2 md:mb-0 md:max-w-4.438rem mx-auto'
                  src={bundle.image}
                />

                <div>
                  <h3
                    className='leading-4 font-medium text-sm text-center text-black md:text-left md:text-base md:leading-tight'
                  >
                    {bundle.title}
                  </h3>
                </div>
              </button>
            );
          })}

          {bundlesLoadingState === LoadingState.LOADING &&
            <>
              <div>
                <IonSkeletonText
                  animated
                  className='rounded-2.5xl mb-2'
                  style={{width: '86px', height: '83px'}}
                />

                <IonSkeletonText
                  animated
                  style={{width: '86px', height: '16px'}}
                />
              </div>

              <div>
                <IonSkeletonText
                  animated
                  className='rounded-2.5xl mb-2'
                  style={{width: '86px', height: '83px'}}
                />

                <IonSkeletonText
                  animated
                  style={{width: '86px', height: '16px'}}
                />
              </div>

              <div>
                <IonSkeletonText
                  animated
                  className='rounded-2.5xl mb-2'
                  style={{width: '86px', height: '83px'}}
                />

                <IonSkeletonText
                  animated
                  style={{width: '86px', height: '16px'}}
                />
              </div>
            </>
          }
        </div>
      </section>
    </div>
  );
}

export default BundlesSection;
