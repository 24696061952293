import {useEffect, useState} from 'react';
import {addToCart} from '../../Cart/CartStore';
import {isPlatform} from '@ionic/core';
import {useIonLoading, useIonModal, useIonToast} from '@ionic/react';
import {CompactButtonProps} from '../types';
import BeyondLocalBadge from '../../../Components/Badges/BeyondLocalBadge';
import dayjs from 'dayjs';
import GeneralStoreBadge from '../../../Components/Badges/GeneralStoreBadge';
import {ProductModal} from '../ProductModal';
import {loadProduct} from '../ProductStore';

const ProductItemCompact = ({...props}: CompactButtonProps) => {
  const [basePrice, setBasePrice] = useState('0.00');
  const [presentToast, dismissToast] = useIonToast();
  const [showLoading, dismissLoading] = useIonLoading();
  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });

  const localAddToCart = async () => {
    if (props.product && props.product.id) {
      showLoading('Adding to your cart...').then();
      await addToCart({
        productId: props.product.id,
        qty: 1,
        variantId: props.product.variants[0]?.id,
      }).then();
      dismissLoading().then();

      const buttons = isPlatform('mobile')
        ? [
          {
            text: 'View Cart',
            handler() {
              presentProductModal();
              dismissToast();
            },
          },
        ]
        : [];
      presentToast({
        keyboardClose: true,
        position: 'top',
        duration: 1700,
        cssClass: 'mw-toast',
        animated: true,
        buttons: buttons,
        message: `Added ${props.product.title} to Cart `,
      }).then();
    }
  };

  useEffect(() => {
    if (props.product.displayPrice) {
      setBasePrice(`${props.product.displayPrice}`);
    }
  }, [props.product.displayPrice]);

  if (props.product) {
    return (
      <div className='relative'>
        {props.product && props.product.badge === 2 && !props.product.isPreOrder &&
          <BeyondLocalBadge classes='absolute z-20 h-auto right-10'/>
        }

        {props.product && props.product.badge === 3 && !props.product.isPreOrder &&
          <GeneralStoreBadge classes='absolute z-20 h-auto right-10'/>
        }

        {props.product && props.product.badge === 4 && !props.product.isPreOrder &&
          <div className='absolute z-20 right-10 shadow-xl bg-black h-auto p-2'>
            <p className='text-gray-100 font-semibold text-sm leading-tight tracking-tight'>
              Seeds <br/> <span className='text-gray-400'>and</span> <br/> Starts
            </p>
          </div>
        }

        {props.product && props.product.isPreOrder &&
          <div className='absolute z-20 h-auto right-1 top-7'>
            <img
              src='/images/badges/pre-order.png'
              alt={`Preorder ${props.product?.title} - Arrives: ${dayjs(props.product?.variants[0]?.preOrderDate.split('.000Z')[0]).format('MM/DD/YYYY')}`}
              style={{width: '37px'}}
            />
          </div>
        }

        <div
          className='relative w-32 p-1 text-left flex-fixed product-item-small focus:outline-none focus:ring-2'
          onClick={() => {
            loadProduct(props.product).then();
            presentProductModal();
          }}
        >

          <figure
            aria-label={`${props.product.title} image`}
            className='relative h-20 mb-1 overflow-hidden bg-center bg-cover rounded-md shadow-sm product-image'
            style={{
              backgroundImage: `url("${
                props.product.images ? props.product.images[0] : ''
              }")`,
            }}
          >
            <div
              className='absolute top-0 right-0 px-1 py-1 text-xs font-bold leading-none text-white bg-black rounded-md price'>
              ${basePrice}
            </div>
          </figure>
          {/* <!-- Title -->  */}
          <h3 className='flex-grow h-8 mb-1 text-xs leading-tight text-green-600 line-clamp-2'>
            {props.product.title}
          </h3>
          {!props.hideAddToCard && (
            <div className='flex items-center justify-between'>
              <button
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  localAddToCart().then();
                }}
                className='w-full px-1 py-1 mt-1 text-xs font-semibold text-green-100 bg-green-600 rounded-lg shadow-sm'
              >
                Add to Cart
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ProductItemCompact;
