import CollectionsGrid from './CollectionGrid';
import {Helmet} from 'react-helmet';
import PrivatePage from '../../Components/Page/PrivatePage';
import AppHeader from '../../Components/Header/AppHeader';
import {useEffect} from 'react';
import {clearActiveCollection} from './CollectionStore';
import {useIonRouter} from "@ionic/react";

const CollectionsPage = () => {
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  const vendorId = urlQuery.get('vendor');
  const router = useIonRouter();
  useEffect(() => {
    if (vendorId) {
      let url = `/collections/vendor/${vendorId}`;
      if (urlQuery.toString() !== '') {
        url += `?${urlQuery.toString()}`;
      }
      router.push(url);
    }

    // DO NOT ADD ROUTER TO THIS DEPENDENCY.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery, vendorId]);
  useEffect(() => {
    return () => {
      clearActiveCollection();
    }
  }, []);

  return (
    <PrivatePage>
      <Helmet>
        <title>Browse the Market Wagon Collections | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com/collections`}/>
      </Helmet>

      <AppHeader/>

      <main className='p-4'>
        <CollectionsGrid/>
      </main>
    </PrivatePage>
  );
};

export default CollectionsPage;
