import PrivatePage from '../../Components/Page/PrivatePage';
import SearchView from './SearchView';
import {Helmet} from 'react-helmet';

const SearchPage = () => {
  return (
    <PrivatePage>
      <Helmet>
        <title>Search | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/search"/>
      </Helmet>

      <SearchView/>
    </PrivatePage>
  );
};
export default SearchPage;
