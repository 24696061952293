import {Store} from 'pullstate';
import {getCollections} from '../../Api/api.collections';
import {CollectionType} from './types';

export type CollectionStoreStateType = {
  collections: Array<CollectionType>;
  activeCollection: any;
  activeChild: any;
};

const starterState: CollectionStoreStateType = {
  collections: [],
  activeCollection: null,
  activeChild: null,
};

export const CollectionStore = new Store(starterState);

export const clearActiveCollection = () => {
  CollectionStore.update((s) => {
    s.activeCollection = null;
    s.activeChild = null;
  });
};

export const setActiveCollection = async (collection?: CollectionType) => {
  CollectionStore.update((s: any) => {
    s.activeCollection = collection;
    s.activeChild = null;
  });
};

export const setActiveChild = async (collection: any) => {
  CollectionStore.update((s: any) => {
    s.activeChild = collection;
  });
};

export async function loadCollections(): Promise<void> {
  try {
    let collections = await getCollections();

    if (collections) {
      CollectionStore.update((s: any) => {
        s.collections = collections;
      });
    }
  } catch (e) {
    console.error(`Error loading collections`, e);
  }
}
