import {IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonSkeletonText} from "@ionic/react";
import {useStoreState} from "pullstate";
import SearchBar from "../../Domains/Search/SearchBar";
import {UserStore} from "../../Domains/User/UserStore";
import ShareButton from "../ShareButton/ShareButton";

interface GeneralHeaderProps {
  title: string | null,
  showSearch?: boolean,
  backHref?: string,
  slug?: string | null,
}

const GeneralHeader = ({...props}: GeneralHeaderProps) => {
  const userHubs = useStoreState(UserStore, s => s.hubs);

  return (
    <IonHeader className='sticky top-0 z-50'>
      <IonToolbar>
        <IonButtons slot='start'>
          {props?.backHref &&
            <IonBackButton defaultHref={props.backHref}/>
          }
        </IonButtons>

        <IonTitle>
          {props?.title &&
            <span className='max-w-25rem w-full block overflow-x-hidden mx-auto text-ellipsis'
                  style={{textOverflow: 'ellipsis'}}>
                {props?.title}
              </span>
          }

          {props?.title === null &&
            <IonSkeletonText className='mx-auto' animated style={{height: '20px', width: '150px'}}/>
          }
        </IonTitle>

        <IonButtons slot='end'
                    className={`${(props.showSearch && props.slug) && (userHubs && userHubs[0]) ? 'mr-7' : ''}`}>
          {props.slug &&
            <ShareButton
              iconClass='text-xl'
              url={`https://marketwagon.com/blogs/${props?.slug}`}
              title={`Read: ${props?.title}`}
            />
          }
        </IonButtons>
      </IonToolbar>

      {(props?.showSearch) && (userHubs && userHubs[0]) &&
        <SearchBar />
      }
    </IonHeader>
  );
}

export default GeneralHeader;
