import {useEffect, useState} from "react";
import IFrameModal from "./IframeModal"
import {useIonModal} from "@ionic/react";
import {IFrameControllerProps} from "./types";

const IFrameModalController = ({...props}: IFrameControllerProps) => {
  // Prepare the State
  const [isOpen, setIsOpen] = useState(false);
  // Prepare the Modal
  const [present, dismiss] = useIonModal(IFrameModal, {
    componentProps: {
      url: props.url,
      title: props.title,
      hideHeader: props.hideHeader ?? false,
      showCheckout: props.showCheckout,
      router: props.router,
      showLoading: props.showLoading ?? false,
    },

    onDismiss: () => {
      if (props.router) {
        props.router.push('/shop');
      }
      dismiss();
    },
    handleCheckoutComplete: (message: any) => {
      dismiss();
      props.router.push(`/order-complete/${message}`);
    },

  });

  // Listen for changes in props.open
  useEffect(() => {
    if (props.open && !isOpen) {
      present({
        onDidDismiss: () => {
          if (props.onDismiss) props.onDismiss();
          setIsOpen(false);
          if (props.showCheckout) {
            props.showCheckout(false);
          }
        }
      });

      setIsOpen(true)
    } else if (!props.open && isOpen) {
      dismiss();
      setIsOpen(false);
      if (props.showCheckout) {
        props.showCheckout(false);
      }
    }
  }, [props.open, props.showCheckout, isOpen, setIsOpen, present, props, dismiss])


  // Return Nothing
  return (
    <></>
  );
};
export default IFrameModalController;
