import {loadProduct} from "../Domains/Product/ProductStore";

/**
 * Dispatches and broadcasts a Custom Event named modalEvent to open Modals across the data
 * @param name - String; name of the custom event to be fired.
 * @param data - Array of data that a modal might require.
 */
export default function OpenModal(name: string, data: any = null) {
  if (!name) {
    console.error('Dispatch Event requires an event name');
    return false;
  }

  if (name === 'product') {
    loadProduct(data).then();
  }

  const event = new CustomEvent('modalEvent', {
    detail: {
      name: name,
      data: data,
    }
  });

  window.dispatchEvent(event);
  return true;
}
