import Product from '../ProductClass';
import ProductItemCompact from './ProductCompact';
import ProductListItem from './ProductListItem';
import {ProductItemType} from '../types';

const ProductItem = ({children, className, ...props}: ProductItemType) => {
  const type: 'compact'  | 'item' = props.type || 'item';
  const product: Product =
    props.product instanceof Product
      ? props.product
      : new Product(props.product || {});

  if (type === 'compact') {
    return <ProductItemCompact {...props} product={product}/>;
  } else {
    return <ProductListItem {...props} product={product}/>;
  }
};

export default ProductItem;
