import {useState} from 'react';
import Login from './Login';
import Register from './Register';
import Welcome from './Welcome';

type ViewTypes = 'login' | 'register' | 'welcome' ;
const OnBoard = ({...props}: { onDismiss: Function }) => {

  const [view, setView] = useState<ViewTypes>("welcome");

  return (
    <div className="h-full md:h-37.5rem">
      {view === 'welcome' &&
        <Welcome onDismiss={props.onDismiss} changeView={(v: ViewTypes) => {
          setView(v)
        }}/>}

      {view === 'login' &&
        <Login onDismiss={props.onDismiss} changeView={(v: ViewTypes) => {
          setView(v)
        }}/>}

      {view === 'register' &&
        <Register onDismiss={props.onDismiss} changeView={(v: ViewTypes) => {
          setView(v)
        }}/>}
    </div>
  );
};

export default OnBoard;
