import {
  useIonAlert,
} from '@ionic/react';
import {useEffect, useState} from 'react';
import FAQQuestions from './FAQQuestions';
import {UserStore} from '../User/UserStore';
import {submitGeneralInquiry} from '../../Api/api.problems';
import {useStoreState} from 'pullstate';
import PublicPage from '../../Components/Page/PublicPage';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';

const FAQPage = () => {
  const userState = useStoreState(UserStore);
  const [selectedQuestionId, setSelectedQuestionId] = useState<Number | null>(
    null
  );
  const [visibleQuestions, setVisibleQuestions] = useState(FAQQuestions);
  const [showContactButton, setShowContactButton] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [formErrors, setFormErrors] = useState<Array<Object>>();
  const [presentAlert] = useIonAlert();

  const [firstName, setFirstName] = useState(
    userState.firstName ? userState.firstName : ''
  );
  const [lastName, setLastName] = useState(
    userState.lastName ? userState.lastName : ''
  );
  const [emailAddress, setEmailAddress] = useState(
    userState.email ? userState.email : ''
  );
  const [zipCode, setZipCode] = useState(
    userState.zipCode ? userState.zipCode : ''
  );
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const submitContactUs = async () => {

    try {
      //@ts-ignore
      const captchaToken: any = await window.grecaptcha.execute(
        `${process.env.REACT_APP_RECAPTCH_KEY}`,
        {action: 'submit'}
      );

      if (validateForm()) {
        await submitGeneralInquiry(
          firstName,
          lastName,
          emailAddress,
          zipCode,
          phoneNumber,
          message,
          captchaToken
        ).then((e) => {
          if (e === 200 || e === 400) {
            presentAlert(
              'Message successfully sent! Our customer support team will be in contact shortly.'
            );
          } else {
            presentAlert('Unable to send message at this time.');
          }
          resetForm();
        });
      }

    } catch (e: any) {
      console.error(e);
      presentAlert('Unable to send message at this time.').then();
    }
  };

  const resetForm = () => {
    setEmailAddress('');
    setFirstName('');
    setLastName('');
    setZipCode('');
    setPhoneNumber('');
    setMessage('');
  };

  const toggleAnswerVisibility = (id: number) => {
    if (id === selectedQuestionId) {
      setSelectedQuestionId(null);
    } else {
      setSelectedQuestionId(id);
    }
  };

  const filterVisibleQuestions = (search: string) => {
    const sanitizedSearch = search.toLowerCase().trim();
    const newFilteredQuestions = FAQQuestions.filter(
      (question) =>
        !!question.phrases.find((phrase) => sanitizedSearch.includes(phrase)) ||
        question.question.includes(sanitizedSearch)
    );
    setVisibleQuestions(newFilteredQuestions);
  };

  useEffect(() => {
    if (firstName && lastName && emailAddress && zipCode && message) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [emailAddress, firstName, lastName, message, phoneNumber, zipCode]);

  const validateForm = () => {
    setFormErrors([]);
    const errors = [];

    if (emailAddress.length < 5 || !emailAddress.includes('@')) {
      errors.push({field: 'email', message: 'Valid email is required'});
    }

    if (firstName.length < 1) {
      errors.push({
        field: 'firstName',
        message: 'Your first name is required',
      });
    }

    if (lastName.length < 1) {
      errors.push({
        field: 'lastName',
        message: 'Your last name is required',
      });
    }

    if (
      zipCode.length !== 5 &&
      !/^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode)
    ) {
      errors.push({field: 'zipCode', message: 'Valid zip code is required'});
    }

    if (
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
        phoneNumber
      ) &&
      phoneNumber !== ''
    ) {
      errors.push({field: 'Phone Number', message: 'Phone Number not valid'});
    }

    setFormErrors(errors);

    return errors.length <= 0;
  };

  return (
    <PublicPage>
      <Helmet>
        <title>FAQ | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/faq"/>
      </Helmet>

      <GeneralHeader title='Contact Us' backHref='/shop'/>

      <section>
        <div className='py-4 bg-gray-100'>
          <div className='max-w-7xl mx-auto py-6 px-4 sm:py-12 sm:px-6 lg:px-8'>
            {showContactForm && (
              <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
                <div>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-medium text-warm-gray-900'
                  >
                    First Name
                  </label>

                  <div className='mt-1'>
                    <input
                      type='text'
                      name='first-name'
                      onChange={(event) => setFirstName(event.target.value)}
                      value={firstName}
                      autoComplete='given-name'
                      className='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md'
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='last-name'
                    className='block text-sm font-medium text-warm-gray-900'
                  >
                    Last Name
                  </label>

                  <div className='mt-1'>
                    <input
                      onChange={(event) => setLastName(event.target.value)}
                      type='text'
                      name='last-name'
                      autoComplete='family-name'
                      value={lastName}
                      className='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md'
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-warm-gray-900'
                  >
                    Email
                  </label>

                  <div className='mt-1'>
                    <input
                      onChange={(event) =>
                        setEmailAddress(event.target.value)
                      }
                      name='email'
                      type='email'
                      autoComplete='email'
                      value={emailAddress}
                      className='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md'
                    />
                  </div>
                </div>

                <div>
                  <div className='flex justify-between'>
                    <label
                      htmlFor='phone'
                      className='block text-sm font-medium text-warm-gray-900'
                    >
                      Phone
                    </label>

                    <span className='text-sm text-warm-gray-500'>
                      Optional
                    </span>
                  </div>

                  <div className='mt-1'>
                    <input
                      type='text'
                      name='phone'
                      id='phone'
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      autoComplete='tel'
                      value={phoneNumber}
                      className='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md'
                      aria-describedby='phone-optional'
                    />
                  </div>
                </div>

                <div className='sm:col-span-2'>
                  <label
                    htmlFor='zip-code'
                    className='block text-sm font-medium text-warm-gray-900'
                  >
                    Zip Code
                  </label>

                  <div className='mt-1'>
                    <input
                      onChange={(event) => setZipCode(event.target.value)}
                      type='text'
                      name='zip-code'
                      id='zip-code'
                      value={zipCode}
                      className='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md'
                    />
                  </div>
                </div>

                <div className='sm:col-span-2'>
                  <div className='flex justify-between'>
                    <label
                      htmlFor='message'
                      className='block text-sm font-medium text-warm-gray-900'
                    >
                      Message
                    </label>

                    <span
                      id='message-max'
                      className='text-sm text-warm-gray-500'
                    >
                      Max. 500 characters
                    </span>
                  </div>

                  <div className='mt-1'>
                    <textarea
                      id='message'
                      name='message'
                      onChange={(event) => setMessage(event.target.value)}
                      value={message}
                      maxLength={500}
                      rows={4}
                      className='py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-warm-gray-300 rounded-md'
                      aria-describedby='message-max'
                    />
                  </div>
                </div>

                <div className='sm:col-span-2 sm:flex sm:justify-end'>
                  {formErrors && formErrors.length > 0 ? (
                    <ul className='flex flex-wrap items-center justify-start mt-6 rounded-lg errors'>
                      {formErrors.map((error: any, index: number) => {
                        if (error.field !== 'none') {
                          return (
                            <li
                              className='p-2 m-0 mb-1 mr-1 text-xs text-yellow-700 bg-yellow-100 rounded-md'
                              key={index}
                            >
                              {error?.message}
                            </li>
                          );
                        }

                        return true;
                      })}
                    </ul>
                  ) : null}

                  <div className='reCaptcha'>

                  </div>

                  <button
                    type='submit'
                    disabled={isDisabled}
                    className={`mt-2 w-full inline-flex items-center justify-center px-6 py-3 border rounded-md shadow-sm text-base font-medium bg-green-600 hover:bg-green-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto ${
                      isDisabled ? `opacity-60 cursor-not-allowed` : ``
                    }`}
                    onClick={submitContactUs}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}

            {!showContactForm && (
              <div className='max-w-3xl mx-auto divide-y-2 divide-gray-200'>
                <div>
                  <label
                    htmlFor='comment'
                    className='text-center text-2xl font-bold text-gray-900'
                  >
                    You might find the answer to your question in our FAQ:
                  </label>

                  <div className='mt-4'>
                    <textarea
                      rows={4}
                      name='comment'
                      id='comment'
                      value={message}
                      className='shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                      placeholder='Start typing your question here...'
                      onChange={(event) => {
                        setMessage(event.target.value);
                        filterVisibleQuestions(event.target.value);
                        setShowContactButton(event.target.value.length > 3);
                      }}
                    />
                  </div>

                  {(showContactButton || visibleQuestions.length < 2) && (
                    <div className='flex justify-center mt-4'>
                      <button
                        type='button'
                        className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={() => setShowContactForm(true)}
                      >
                        The FAQ did not help
                      </button>
                    </div>
                  )}
                </div>

                <div className='mt-6 space-y-6 divide-y divide-gray-200'>
                  {visibleQuestions.map((question) => (
                    <div className='pt-6' key={question.id}>
                      <div className='text-lg'>
                        <button
                          onClick={() => toggleAnswerVisibility(question.id)}
                          type='button'
                          className='text-left w-full flex justify-between items-start text-gray-400'
                          aria-controls='faq-0'
                          aria-expanded='false'
                        >
                          <span
                            className='font-medium text-gray-900'
                            dangerouslySetInnerHTML={{
                              __html: question.question,
                            }}
                          />

                          <span className='ml-6 h-7 flex items-center'>
                            <svg
                              className={
                                'h-6 w-6 transform ' +
                                (question.id === selectedQuestionId
                                  ? '-rotate-180'
                                  : 'rotate-0')
                              }
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='2'
                              stroke='currentColor'
                              aria-hidden='true'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19 9l-7 7-7-7'
                              />
                            </svg>
                          </span>
                        </button>
                      </div>

                      <p
                        className={`mt-2 md:pr-12 pr-0
                          ${
                          question.id === selectedQuestionId
                            ? 'visible'
                            : 'hidden'
                        }`}
                      >
                        <span className='text-base text-gray-500'>
                          {typeof question.answer == 'function' ? <question.answer/> : question.answer}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </PublicPage>
  );
};

export default FAQPage;
