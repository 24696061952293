import {useEffect, useRef, useState} from "react";
import VendorModel from "../../NewDomains/Vendor/vendor.model";
import {searchAutoComplete, searchVendors} from "../../Api/api.search";
import UseDebounce from "../../Helpers/Hooks/UseDebounce";
import MWAvatar from "../../Components/MWAvatar/MWAvatar";
import Spinner from "../../Components/Spinner";
import {useIonRouter} from "@ionic/react";
import {useAppStore} from "../../NewDomains/store";
import UseOutsideClick from "../../Helpers/Hooks/UseOutsideClick";

type Item = {
  id?: string,
  title?: string,
  slug?: string,
  type?: string,
  brandName?: string,
};

const SearchBar = () => {
  const router = useIonRouter();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [showSearchBar, setShowSearchBar] = useState(false);

  const searchMode = useAppStore((s) => s.searchMode);
  const term = useAppStore((s) => s.searchQuery);
  const update = useAppStore((s) => s.update);
  const [vendorResults, setVendorResults] = useState<VendorModel[] | null>(null);
  const [itemResults, setItemResults] = useState<Item[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchTerm = UseDebounce(term, 300);

  const handleOutsideClick = () => {
    setShowSearchBar(false);
  };

  const outsideClickRef = UseOutsideClick(handleOutsideClick);

  const doShowSearchBar = () => {
    setShowSearchBar(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const changeSearchTerm = (value: string) => {
    update({searchQuery: value});
  };

  const reset = () => {
    setShowSearchBar(false);
    setItemResults(null);
    setVendorResults(null)
  }

  const navigateToVendor = (id: string) => {
    reset();
    const url = `/collections/vendor/${id}`;
    router.push(url);
  };
  const navigateToItem = (item: Item) => {
    reset();
    const url = (item.type === 'collection' ? '/collections/' : '/products/') + (item.slug ?? item.id);
    router.push(url);
  };

  const submit = (e: any) => {
    e.preventDefault();
    if (searchMode === 'vendors' && vendorResults && vendorResults.length > 0) {
      navigateToVendor(vendorResults[0].id);
    } else if (searchMode === 'items') {
      if (itemResults && itemResults[0].type === 'collection') {
        navigateToItem(itemResults[0]);
      } else {
        reset();
        router.push(`/search/?q=${term}`);
      }
    }
  };

  useEffect(() => {
    let ignore = false;
    setVendorResults(null);
    setItemResults(null);
    if (searchTerm.length > 2) {
      setIsLoading(true);
      if (searchMode === 'vendors') {
        searchVendors(searchTerm).then((vendors) => {
          if (!ignore) {
            setIsLoading(false);
            setVendorResults(vendors);
          }
        });
      } else {
        searchAutoComplete(searchTerm).then((items) => {
          if (!ignore) {
            setIsLoading(false);
            setItemResults(items);
          }
        });
      }
    }

    return () => {
      ignore = true;
    };
  }, [searchTerm, searchMode]);

  return (
    <div
      ref={outsideClickRef}
    >
      <div
        className={`${showSearchBar ? 'hidden' : 'block'} absolute z-10 right-0 top-0 w-12 md:w-auto flex items-center`}
        style={{height: '61px'}}>
        <button
          className='text-green-600 hover:bg-green-600 hover:text-white p-1 rounded-md md:flex md:mr-4 md:pr-2'
          onClick={doShowSearchBar}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
               stroke="currentColor"
               className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
          </svg>
          <span className='sr-only md:not-sr-only'>Search</span>
        </button>
      </div>
      <div
        className={`${showSearchBar ? 'block' : 'hidden'} absolute z-10 right-0 top-0 w-full max-w-md bg-gray-50 max-h-96 overflow-y-scroll
          sm:rounded-bl-md shadow
          `}>
        <form className='
          flex items-center px-2 sticky top-0 bg-gray-50 z-20'
              style={{height: '61px'}}
              onSubmit={submit}

        >
          <div className="flex items-center">
            <label htmlFor="searchMode" className="sr-only">
              Search Mode
            </label>
            <select
              id="searchMode"
              value={searchMode}
              name="searchmode"
              onChange={e => update({searchMode: e.target.value})}
              autoComplete="searchmode"
              className="h-8 pl-1 sm:text-sm text-xs rounded-l-md bg-gray-100 text-gray-600 ring-1 ring-inset ring-gray-300 outline-none focus:ring-green-600 focus:ring-2"
            >
              <option value='items'>Search Items</option>
              <option value='vendors'>Search Vendors</option>
            </select>
          </div>
          <input
            ref={inputRef}
            type="text"
            name="searchterm"
            id="searchTerm"
            value={term}
            autoComplete={'off'}
            autoCorrect={'off'}
            onChange={e => changeSearchTerm(e.target.value)}
            className="block w-full text-gray-900 h-8 ring-1 ring-inset ring-gray-300 outline-none pl-1 focus:ring-green-600 focus:ring-2"
          />
          <button
            type="submit"
            className="h-8 px-1 rounded-r-md bg-green-600 text-white ring-1 ring-inset ring-green-600"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor"
                 className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
            </svg>
            <span className='sr-only'>Search</span>
          </button>
          <button
            type="button"
            className='ml-2'
            onClick={reset}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="h-4 w-4 text-green-600">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
            </svg>
            <span className='sr-only'>Close</span>
          </button>
        </form>
        {term.length > 2 && isLoading && <div className='flex items-center pb-6'><Spinner/></div>}
        {searchTerm.length > 0 && searchTerm.length < 3 &&
          <div className='text-xs text-red-500 pb-2 text-center w-full'>Please provide at least 3 characters</div>}
        {term.length > 2 && !isLoading && vendorResults && (
          <div className='flex justify-center items-center w-full overflow-y-scroll'>
            {vendorResults.length > 0 && (
              <ul className='divide-y divide-gray-300 w-full'>
                {vendorResults.map(
                  vendor => (<li key={`vendor-${vendor.id}`}
                                 aria-label={vendor.name}
                                 className='p-1 w-full'>
                    <button
                      type='button'
                      onClick={() => navigateToVendor(vendor.id)}
                      className='flex justify-between items-center hover:bg-gray-200 w-full rounded-md'>
                      <div className='flex min-w-0 gap-x-4'>
                        {vendor.avatar &&
                          (<MWAvatar
                              image={vendor.avatar}
                              label={vendor.name}
                              hideLabel={true}
                            />
                          )
                        }
                        {!vendor.avatar && <div className='p-1'>
                          <div className='h-14 w-14 rounded-full bg-gray-100'></div>
                        </div>}
                        <main className='min-w-0 flex flex-col justify-center capitalize'>
                          <div className='text-left font-bold line-clamp-1'>{vendor.name}</div>
                          <div className='text-xs text-left text-gray-500 line-clamp-1'>
                            {vendor?.city}, {vendor.state}
                          </div>
                        </main>
                      </div>
                      <div
                        className='flex flex-shrink-0 items-center gap-x-4'
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="h-6 w-6 flex-none text-gray-400">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                        </svg>
                      </div>
                    </button>
                  </li>)
                )}
              </ul>
            )}
            {!isLoading && term.length > 2 && vendorResults.length === 0 && (
              <span className='text-gray-700 p-2'>
                No results found
              </span>
            )}
          </div>
        )}
        {
          term.length > 2 && !isLoading && itemResults && (
            <div className='flex justify-center items-center w-full overflow-y-scroll'>
              {itemResults.length > 0 && (
                <ul className='divide-y divide-gray-300 w-full'>
                  {itemResults.map(
                    item => (<li key={`${item.type}-${item.id}`}
                                 aria-label={item.title}
                                 className='p-1 h-14 w-full'>
                      <button
                        type='button'
                        onClick={() => navigateToItem(item)}
                        className='flex h-full justify-between items-center hover:bg-gray-200 w-full rounded-md'>
                        <div className='flex min-w-0 gap-x-4'>
                          {item.type === 'collection' && (
                            <main className='flex items-center flex-row space-x-2 ml-4'>
                              <div className='text-left font-bold text-green-600 line-clamp-1'>All {item.title}</div>
                            </main>
                          )}
                          {item.type === 'product' && (
                            <main className='min-w-0 flex flex-col justify-center capitalize ml-4'>
                              <div className='text-sm text-left font-bold line-clamp-1'>{item.title}</div>
                              <div className='text-xs text-left text-gray-500 line-clamp-1'>
                                {item.brandName}
                              </div>
                            </main>
                          )}
                        </div>
                        <div
                          className='flex flex-shrink-0 items-center gap-x-4'
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                               stroke="currentColor" className="h-6 w-6 flex-none text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                          </svg>
                        </div>
                      </button>
                    </li>)
                  )}
                </ul>
              )}
              {!isLoading && term.length > 2 && itemResults.length === 0 && (
                <span className='text-gray-700 p-2'>
                  No results found
                </span>
              )}
            </div>
          )
        }
      </div>
    </div>
  )
};

export default SearchBar;
