export default class VendorModel {
  id: string;
  name: string;
  city?: string;
  state?: string;
  avatar?: string;
  isFollowed: boolean = false;

  constructor(input: any) {
    this.id = input.id;
    this.name = input.name;
    this.city = input.city;
    this.state = input.state;
    this.avatar = input.avatar;
    this.isFollowed = !!input.isFollowed;
  }
}
