import {
  IonContent,
  IonPage,
  useIonAlert,
} from '@ionic/react';
import useEventListener from '@use-it/event-listener';
import {useEffect, useState} from 'react';
import {loginGuest} from '../../Api/api.account';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import UseBranding from '../../Helpers/Hooks/UseBranding';
import {getVendor} from "../../Api/api.vendor";
import Vendor from "../Vendor/VendorClass";
import MWButton from "../../Components/MWButton/MWButton";
import {userRegister} from "../User/UserStore";

type ViewType = 'zipCode' | 'email';
const VendorRewardsLanding = ({...props}: {
  changeView: Function;
  onDismiss: Function;
}) => {
  const [view, setView] = useState<ViewType>("zipCode");
  const [localZipCode, setLocalZipcode]: any = useState(null);
  const [localEmail, setLocalEmail]: any = useState(null);
  const [showMessage] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [vendor, setVendor] = useState<Vendor | undefined>(undefined);

  const utmSource = urlQuery.get('utm_source');
  const utmCampaign = urlQuery.get('utm_campaign');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const guestRegister = async () => {
    setLoading(true);

    if (localZipCode && localZipCode.length === 5) {
      try {
        const res = await loginGuest(localZipCode, null, null, utmSource, utmCampaign);
        if (!res) {
          showMessage('Sorry, we do not currently support your location.').then();
        } else {
          setView('email');
        }
      } catch (e) {
        console.error('error setting user', e);
        showMessage('Sorry, we do not currently support your location.').then();
      }
    }
    setLoading(false);
  };

  const registerIfProvided = async () => {
    if (!localEmail || localEmail === '') {
      props.onDismiss();
    } else {
      try {
        setLoading(true);
        await userRegister(null, null, localEmail, null, localZipCode);
        props.onDismiss();
        setLoading(false);
      } catch (e) {
        const error: any = e;
        console.error('error setting user', error);
        showMessage(error.message).then();
      }
      setLoading(false);
    }

  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && localZipCode) {
      if (view === 'zipCode') {
        guestRegister().then();
      } else {

      }
    }
  };

  useEffect(() => {
    if (!vendor) {
      getVendor(params.trk, true).then((res) => setVendor(res));
    }
  }, [vendor, params.trk]);

  useEventListener('keydown', handleKeyPress);

  return (
    <IonPage className='h-full'>
      <IonContent>
        <main className='bg-white py-4 px-4'>
          <div className='flex flex-col items-center justify-center bg-white'>
            <img
              className='max-w-none'
              src={UseBranding()}
              alt='Market Wagon Logo'
              width='140px'
              height='140px'
            />
            <h1 className='text-base font-bold text-center leading-tight mt-5 md:text-2xl'>
              Welcome to Market Wagon,<br/>
              the online farmer's market that delivers.
            </h1>

            {(view === 'zipCode' &&
              <>
                <p className='text-gray-800 text-center px-6 md:px-10 mt-8 text-sm md:text-base'>
                  Please enter your zip code and we will show you the best local products
                  from {vendor?.name ?? 'your favorite vendor'} and others.
                </p>
                <div
                  className='mt-4 w-full max-w-sm'>
                  <input
                    tabIndex={3}
                    type='tel'
                    placeholder='Zip Code'
                    style={{fontSize: '18px'}}
                    className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg login-form'
                    required
                    value={localZipCode || ''}
                    onChange={(evt: any) => {
                      setLocalZipcode(evt.target.value);
                    }}
                  />

                </div>
                <div className='mt-8 w-full max-w-sm'>
                  <MWButton
                    title='Next'
                    className='justify-center w-full text-white bg-green-600 rounded-lg h-11'
                    onClick={() => {
                      guestRegister().then();
                    }}
                    disabled={(localZipCode || '').length !== 5 || loading}
                  >
                    {!loading ? (
                      <div>Next</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </MWButton>
                </div>
              </>
            )}
            {(view === 'email' &&
              <>
                <p className='text-gray-800 text-center px-6 md:px-10 mt-8 text-sm md:text-base'>
                  Please enter your email address to keep up to date with {vendor?.name ?? 'your favorite vendor'} and
                  Market Wagon.
                </p>
                <div
                  className='mt-4 w-full max-w-sm'>
                  <input
                    tabIndex={3}
                    type='tel'
                    placeholder='Email address (optional)'
                    style={{fontSize: '18px'}}
                    className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg login-form'
                    value={localEmail || ''}
                    onChange={(evt: any) => {
                      setLocalEmail(evt.target.value);
                    }}
                  />

                </div>
                <div className='mt-8 w-full max-w-sm'>
                  <MWButton
                    title='Shop Market Wagon'
                    className='justify-center w-full text-white bg-green-600 rounded-lg h-11'
                    onClick={() => {
                      registerIfProvided().then();
                    }}
                  >
                    {!loading ? (
                      <div>Shop Market Wagon</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </MWButton>
                </div>
              </>
            )}

          </div>
        </main>
        <div className='flex justify-center px-4 mt-10 space-x-3 text-xs flex-fixed legal'>
          <button
            className='underline'
            title='View Terms of Service'
            onClick={() => {
              setShowTermsModal(true);
            }}
          >
            Terms of Service
          </button>

          <button
            className='underline'
            title='View Privacy Policy'
            onClick={() => {
              setShowPrivacyModal(true);
            }}
          >
            Privacy Policy
          </button>
        </div>
      </IonContent>

      <IFrameModalController
        onDismiss={() => {
          setShowTermsModal(false)
        }}
        title="Terms &amp; Conditions"
        url="https://marketwagon.com/pages/conditions-of-use"
        open={showTermsModal}
      />

      <IFrameModalController
        onDismiss={() => {
          setShowPrivacyModal(false)
        }}
        title="Privacy"
        url="https://marketwagon.com/privacy.php"
        open={showPrivacyModal}
      />
    </IonPage>
  );
};
export default VendorRewardsLanding;
