import {UserStore} from '../User/UserStore';
import {IonIcon} from "@ionic/react";
import {arrowForwardOutline} from 'ionicons/icons';
import {useEffect} from "react";
import OpenModal from '../../Helpers/OpenModal';

const CheckoutButton = ({...props}) => {
  const userState = UserStore.useState((s) => s);

  useEffect(() => {
    if (props.onClick) {
      window.gtag("event", "begin_checkout", {
        "items": [],
        "coupon": ""
      });
    }
  }, [props.onClick]);

  return (
    <>
      <div className='flex items-center justify-center rounded-md flex-shrink-0 bg-gray-200'>
        <div
          className='flex px-4 py-2 text-base font-bold'
        >
          {props.subtotal}
        </div>
        <button
          disabled={props.disabled}
          type='button'
          className={`${props.disabled ? 'text-black bg-gray-200 cursor-not-allowed px-4 py-2 rounded-md shadow-sm' :
            'flex border border-transparent text-lg rounded-md shadow-sm text-gray-100 bg-mw-orange hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 divide-yellow-400 px-4 py-2'
          }`}

          onClick={props.onClick}
        >
                    <span className='pl-2 font-bold flex items-center'>
                        Checkout
                        <IonIcon className='ml-2' icon={arrowForwardOutline}/>
                    </span>
        </button>
      </div>

      {!userState.hubs?.length && (
        <button
          className='w-full px-2 py-2 text-sm leading-tight text-center text-gray-500 rounded-full'
          onClick={() => OpenModal('delivery')}
        >
          Set your{' '}
          <span className='underline'>delivery address to checkout</span>
        </button>
      )}
    </>
  );
};

export default CheckoutButton;
