import {useStoreState} from "pullstate";
import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router";
import ContentFooter from "../../Domains/Layout/ContentFooter";
import DesktopAside from "../../Domains/Layout/DesktopAside";
import TabBar from "../../Domains/Layout/TabBar";
import {UserStore} from "../../Domains/User/UserStore";
import {ProfileType} from "../../Domains/User/types";
import {useIonModal} from "@ionic/react";
import OnBoard from "../../Domains/Onboard/Onboard";
import {CollectionStore} from "../../Domains/Collection/CollectionStore";
import VendorRewardsLanding from "../../Domains/Onboard/VendorRewardsLanding";
import CustomerReferralLanding from "../../Domains/Onboard/CustomerReferralLanding";
import Email from "../../Domains/Onboard/Email";
import {createCookie} from "../../Helpers/CreateCookie";
import Cookies from "js-cookie";

const PrivatePage = ({children}: any) => {
  const location: any = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const userState: ProfileType = useStoreState(UserStore);
  const [hasMounted, setHasMounted] = useState(false);
  const [isVendorRewards, setIsVendorRewards] = useState(false);
  const [isCustomerReferral, setIsCustomerReferral] = useState(false);
  const showEmailModal = !Cookies.get('doNotShowEmailModal') ?? true;
  const scrollingContainer = useRef<any>(null);
  const activeCollection = useStoreState(CollectionStore, s => s.activeCollection);
  const activeChildCollection = useStoreState(CollectionStore, s => s.activeCollection);
  const [presentEmailModal, dismissEmailModal] = useIonModal(Email, {
    onDismiss: () => {
      dismissEmailModal();
    }
  });
  const [presentOnboardModal, dismissOnboardModal] = useIonModal(OnBoard, {
    onDismiss: () => {
      dismissOnboardModal();
    }
  });
  const [presentVendorRewardsLanding, dismissVendorRewardsLanding] = useIonModal(VendorRewardsLanding, {
    onDismiss: () => {
      dismissVendorRewardsLanding();
    }
  });
  const [presentCustomerReferralLanding, dismissCustomerReferralLanding] = useIonModal(CustomerReferralLanding, {
    onDismiss: () => {
      dismissCustomerReferralLanding();
    }
  });

  useEffect(() => {
    if (params.trk) {
      setIsVendorRewards(true);
    }
  }, [params]);

  useEffect(() => {
    if (params.referredByCustomerId) {
      setIsCustomerReferral(true);
    }
  }, [params]);

  useEffect(() => {
    if (location.key) window.scrollTo(0, 0);
  }, [location.key]);

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
    }
  }, [hasMounted]);

  useEffect(() => {
    if (hasMounted && !isVendorRewards && !isCustomerReferral && userState.hubs && !userState.hubs.length) {
      presentOnboardModal();
    }
  }, [hasMounted, presentOnboardModal, userState.hubs, isVendorRewards, isCustomerReferral]);

  useEffect(() => {
    if (hasMounted && !isVendorRewards && !isCustomerReferral && userState.hubs && userState.hubs.length > 0 && userState.isGuest && showEmailModal) {
      createCookie('doNotShowEmailModal', true, 30 * 24 * 60 * 60 * 1000);
      presentEmailModal();
    }
  }, [hasMounted, presentEmailModal, userState.hubs, userState.isGuest, isVendorRewards, isCustomerReferral, showEmailModal]);

  useEffect(() => {
    if (hasMounted && isVendorRewards && userState.hubs && !userState.hubs.length) {
      presentVendorRewardsLanding();
    }
  }, [hasMounted, presentVendorRewardsLanding, userState.hubs, isVendorRewards]);

  useEffect(() => {
    if (hasMounted && isCustomerReferral && userState.hubs && !userState.hubs.length) {
      presentCustomerReferralLanding();
    }
  }, [hasMounted, presentCustomerReferralLanding, userState.hubs, isCustomerReferral]);

  // Scroll the scrolling container on active collection/child collection change
  useEffect(() => {
    if (activeCollection !== null) {
      scrollingContainer.current.scrollTo(0, 0);
    }
  }, [activeChildCollection, activeCollection]);

  useEffect(() => {
    scrollingContainer.current.scrollTo(0, 0);
  }, [location]);

  return (
    <div className='lg:mr-80'>
      <DesktopAside/>

      <section className="app-main h-full overflow-y-scroll" ref={scrollingContainer}>
        <div className="min-h-inner-page-mobile bg-gray-100 pb-10">
          {children}
        </div>

        <ContentFooter className='hidden lg:block'/>
      </section>

      <TabBar className='lg:hidden'/>
    </div>
  );
}

export default PrivatePage;
