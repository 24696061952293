import {IonAvatar, IonSkeletonText} from '@ionic/react';
import {useEffect, useState} from 'react';
import initials from '../../Helpers/Initials';
import strToColor from '../../Helpers/StrToColor';
import {MWAvatarType} from './types';

const MWAvatar = ({...props}: MWAvatarType) => {
  const getSize = () => {
    switch (props.size) {
      case 'xs':
        return 'h-6 w-6 ';
      case 'sm':
        return 'h-10 w-10';
      case 'md':
        return 'h-14 w-14';
      case 'lg':
        return 'h-20 w-20';
      default:
        return 'h-14 w-14';
    }
  };
  const [color, setColor] = useState('#007A3E');

  useEffect(() => {
    if (props.label) {
      // Dynamically generate a color for this label
      const dynoColor = strToColor(props.label || '#007A3E');
      setColor(dynoColor);
    }
  }, [props.label]);

  return (
    <figure
      aria-label={`${props.label} avatar`}
      className={` inline-flex rounded-lg p-1 flex-grow-0 flex-shrink-0 overflow-hidden focus:ring-2 flex-col
      focus:scale-95 transition-all transform duration-200 ease-in-out items-center justify-start group mw-avatar ${
        props.className || ''
      }`}
    >
      {props.image &&
        <IonAvatar
          slot='start'
          style={{
            backgroundImage: `url("${props.image}")`,
            backgroundColor: color,
          }}
          className={`
            flex ${getSize()} flex-grow-0 flex-shrink-0 bg-cover bg-center items-center shadow-md justify-center
            text-white group group-focus:ring-2 hover:ring-2
            ${props.avatarClassName || ''}
            ${props.active ? 'ring-4 ring-green-400' : ''}
          `}
        >
          {!props.image && (
            <span
              className={`
                font-bold uppercase
                ${props.size === 'sm' ? 'text-lg' : props.size === 'xs' ? 'text-xs' : 'text-2xl'}
              `}
            >
              {initials(props.label)}
            </span>
          )}
        </IonAvatar>
      }

      {props.image === undefined &&
        <IonSkeletonText
          className={`
            rounded-full flex flex-grow-0 flex-shrink-0 z-10 relative
          `}
          style={{width: '80px', height: '80px'}}
          animated
        />
      }

      {props.label && !props.hideLabel && (
        <label
          className={`
            mt-2 text-xs leading-tight text-gray-600 line-clamp-1
            ${props.active ? 'font-bold' : ''}
          `}
          aria-label={props.label}
        >
          {props.label}
        </label>
      )}
    </figure>
  );
};

export default MWAvatar;
