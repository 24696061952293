import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import FavoriteButton from '../../Components/FavoriteButton/FavoriteButton';
import ShareButton from '../../Components/ShareButton/ShareButton';
import {VendorHeaderToolbarProps} from './types';

const VendorHeaderToolbar = ({...props}: VendorHeaderToolbarProps) => {

  const closeClick = () => {
    if (props.onDismiss) props.onDismiss();
  };

  return (
    <IonHeader className='sticky top-0 z-50'>
      <IonToolbar>
        <IonButtons slot='start'>
          {props.isModal && (
            <IonButton onClick={closeClick}>
              <IonIcon icon={close}/>
            </IonButton>
          )}
          {!props.isModal && <IonBackButton defaultHref='/'/>}
        </IonButtons>
        <IonTitle>
          {props.vendor?.name ? props.vendor.name : 'Loading...'}
        </IonTitle>
        <IonButtons slot='end' className='pr-3 space-x-3'>
          <FavoriteButton
            iconClass='text-2xl text-gray-400'
            vendorId={props.vendor.id}
            liked={props.vendor.isFollowed}
          />
          <ShareButton
            iconClass='text-xl'
            title={`Checkout ${props.vendor?.name} on Market Wagon`}
            url={`https://www.marketwagon.com/vendors?id=${props.vendor?.id}&share=social`}
          />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default VendorHeaderToolbar;
