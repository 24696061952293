import FavoritesList from './FavoritesList';
import UseIsGuestError from '../../Helpers/Hooks/UseIsGuestError';
import PrivatePage from '../../Components/Page/PrivatePage';
import UseWindowSize from '../../Helpers/Hooks/UseWindowSize';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';

const FavoritesPage = () => {
  // Fire on page load to determine if the user is a guest or not
  // & Redirect if needed.
  UseIsGuestError();
  const windowSize = UseWindowSize();

  return (
    <PrivatePage>
      <Helmet>
        <title>Favorites | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/favorites"/>
      </Helmet>

      <GeneralHeader title='Favorites' backHref='/shop'/>

      <FavoritesList
        buttonType={windowSize.width && windowSize.width >= 480 ? 'tiny' : 'icon'}
      />
    </PrivatePage>
  );
};
export default FavoritesPage;
