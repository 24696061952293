import { CollectionType, CollectionSubType } from '../Domains/Collection/types';
import Product, { ProductType } from '../Domains/Product/ProductClass';
import { GET } from './Api'

export const getCollections = async (): Promise<Array<CollectionType>> => {
  try {
    const call = await GET('/collections');
    return call.payload.map((collection: CollectionType) => collection);
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getCollectionProducts = async (
  collection: CollectionType | CollectionSubType,
  perPage: number,
  currentPage: number
): Promise<[Array<Product>, number]> => {
  const call = await GET(
    `/collections/${collection.id}/products`,
    currentPage,
    perPage
  );
  const products = call.payload.map(
    (product: ProductType) => new Product(product)
  );

  return [products, call.totalRows ?? 0];
};
