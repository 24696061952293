import {useIonActionSheet, useIonToast} from '@ionic/react';
import useShare from './UseShare';
import ShareIcon from '../ShareIcon/ShareIcon';
import {closeCircleOutline, clipboard} from 'ionicons/icons';
import Button from '../MWButton/MWButton';
import {ShareButtonType} from './types';

const ShareButton = ({...props}: ShareButtonType) => {
  const {destinations, platformShare, canPlatformShare} = useShare();
  const [manualPresent] = useIonActionSheet();
  const [showToast] = useIonToast();

  const manualShare = (url: string, title?: string) => {
    const buttons = destinations.map((destination) => {
      return {
        text: destination.title,
        icon: destination.icon,
        handler() {
          window.open(destination.generate(url, title), '_blank');
        },
      };
    });

    buttons.push({
      text: 'Copy Link',
      icon: clipboard,
      handler() {
        navigator.clipboard.writeText(props.url).then();
        showToast(`Link Copied`, 1500).then();
      },
    });

    buttons.push({
      text: 'Cancel',
      icon: closeCircleOutline,
      handler() {
      },
    });

    manualPresent({
      header: `Share`,
      buttons: buttons,
      subHeader: `${title || ''} ${url}`,
    }).then();
  };

  return (
    <Button
      title='Share this'
      className={`${props.className || ''}`}
      onClick={() => {
        if (canPlatformShare()) {
          platformShare(props.url, props.title).then();
        } else {
          manualShare(props.url, props.title);
        }
      }}
    >
      <ShareIcon className={props.iconClass || ''}/>
      {props.label && <span>{props.label}</span>}
    </Button>
  );
};

export default ShareButton;
