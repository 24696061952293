import {PostCardType} from '../Domains/Post/types';
import Product from '../Domains/Product/ProductClass';
import Vendor from '../Domains/Vendor/VendorClass';
import {GET} from './Api';

export const getFeed = async (hubId: string): Promise<Array<PostCardType>> => {
  // Using a hubId to cache the feed
  // so if a hubid changes, the cache
  // is invalidated
  const FEED_KEY = `feed-${hubId}`;

  let items: Array<PostCardType>;

  // Get the feed from Storage
  const feedFromStorage: Array<PostCardType> = JSON.parse(
    sessionStorage.getItem(FEED_KEY) || '[]'
  );

  // If it exists in session storage use it
  if (feedFromStorage.length > 0) {
    items = feedFromStorage;
  } else {
    // Doesn't exist - lets call the real API
    const call = await GET('/feed');
    items = call.payload;
    sessionStorage.setItem(FEED_KEY, JSON.stringify(items));
  }

  // Format the Feed Items
  return items.map((feedPost: PostCardType) => {
    const post = feedPost;
    // adjust commentCount and likeCount case
    //@ts-ignore
    post.commentCount = feedPost.commentcount;
    //@ts-ignore
    post.commentCount = feedPost.likecount;

    // Process Product
    if (post.product) post.product = new Product(post.product);

    // Process Vendor
    if (post.vendor) post.vendor = new Vendor(post.vendor);

    return post;
  });
};
