import {IonIcon, useIonModal} from "@ionic/react";
import {informationCircle} from 'ionicons/icons';
import GeneralStoreModal from "../Modal/Modals/GeneralStoreModal";

const GeneralStoreBadge = (props: any) => {
  const [presentGeneralStoreModal, dismissGeneralStoreModal] = useIonModal(GeneralStoreModal, {
    onDismiss: () => {
      dismissGeneralStoreModal();
    }
  });

  return (
    <button
      className={`absolute z-20 h-auto shadow-xl ${props.classes}`}
      onClick={() => {
        presentGeneralStoreModal();
      }}
      style={{width: `${props.type !== 'compact' ? '67px' : '37px'}`}}
    >
      <img
        className='w-full h-full'
        src='/images/badges/general-store.png'
        alt='Click to learn about our General Store Collection'
      />

      <IonIcon
        className={`text-white absolute -bottom-0.438rem -left-0.438rem
        ${props.type !== 'compact' ? '-right-0.438rem' : 'left'}`}
        icon={informationCircle} size="medium"/>
    </button>
  )
}

export default GeneralStoreBadge;
