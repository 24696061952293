import {
  IonContent,
  IonButtons,
  IonButton,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonItemDivider,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';

import {arrowBack} from 'ionicons/icons';
import {useCallback, useEffect, useRef, useState} from 'react';
import {requestPasswordResetEmail} from '../../Api/api.auth';
import {userLogin} from '../User/UserStore';

const Login = ({...props}: { changeView: Function; onDismiss: Function }) => {
  const errorBase: string | undefined = undefined;
  const [error, setError] = useState(errorBase);
  const [loginForm, setLoginForm] = useState({email: '', password: ''});
  const [present] = useIonAlert();
  const [showLoading, dismissLoading] = useIonLoading();
  const emailInput: any = useRef();

  const doLogin = useCallback(async () => {
    showLoading('Logging in...', 600).then();

    try {
      await userLogin(loginForm.email, loginForm.password).catch((e) => {
        throw e;
      });
      props.onDismiss();
      dismissLoading().then();
    } catch (e: any) {
      setError(e.message || e);
      dismissLoading().then();
    }
  }, [dismissLoading, loginForm.email, loginForm.password, props, showLoading]);

  async function doPasswordReset() {
    if (!loginForm.email) {
      present('Please enter an email address.').then();
      return false;
    }

    requestPasswordResetEmail(loginForm.email).then();
    present('Check your email for instructions.').then();
    return;
  }

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      loginForm.email.length &&
      loginForm.password.length
    ) {
      doLogin().then();
    }
  }, [doLogin, loginForm.email.length, loginForm.password.length])
  useEffect(() => {
    const inputListener = emailInput.current;
    inputListener.addEventListener('keyup', (handleKeyPress));
    return () => {
      inputListener.removeEventListener('keyup', handleKeyPress);
    };

  }, [handleKeyPress]);

  return (
    <IonPage className='h-full'>
      <IonHeader className='sticky top-0'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              onClick={() => {
                props.changeView('welcome');
              }}
            >
              <IonIcon icon={arrowBack}/>
            </IonButton>
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className='pb-4'>
          <IonItemDivider className='mb-4 bg-green-100'>
            <p className='py-4 text-sm leading-tight text-green-600 '>
              Login to your MarketWagon account for faster checkouts. Don't have
              an account?{' '}
              <button
                className='underline'
                onClick={() => {
                  props.changeView('register');
                }}
              >
                Create one here
              </button>
            </p>
          </IonItemDivider>

          <section
            aria-label='Email and Password'
            className='max-w-md px-2 mx-auto'
          >
            <form
              className='mx-2 mt-5 mb-2 overflow-hidden border border-gray-200 rounded-lg shadow-lg bg-white-200 login-form'>

              <IonItem>
                <IonLabel position='fixed' className='text-gray-300'>
                  Email
                </IonLabel>
                <IonInput
  placeholder='Email Address'
  type='email'
  value={loginForm.email}
  ref={emailInput}
  required
  onIonChange={(evt: any) => {
    setError(undefined);
    const email = evt?.target?.value;
    setLoginForm({...loginForm, ...{email}});
  }}
  onIonInput={(evt: any) => {
    setError(undefined);
    const email = evt?.target?.value;
    setLoginForm({...loginForm, ...{email}});
  }}
  />
              </IonItem>
              <IonItem lines='none'>
                <IonLabel position='fixed' className='text-gray-300'>
                  Password
                </IonLabel>
                <IonInput
  placeholder='Password'
  type='password'
  value={loginForm.password}
  onIonChange={(evt: any) => {
    setError(undefined);
    const password = evt?.target?.value;
    setLoginForm({...loginForm, ...{password}});
  }}
  onIonInput={(evt: any) => {
    setError(undefined);
    const password = evt?.target?.value;
    setLoginForm({...loginForm, ...{password}});
  }}
  required
  />
              </IonItem>
            </form>

          </section>
        </IonList>

        <div className='max-w-md px-4 mx-auto'>
          {error && (
            <div className='p-2 mb-4 text-sm text-center text-red-800 bg-red-100 rounded-xl'>
              {error}
            </div>
          )}

          <IonButton
            onClick={() => doLogin()}
            expand='block'
            disabled={!loginForm.email.length || !loginForm.password.length}
          >
            Login to My Account
          </IonButton>

          <button
            className='w-full pt-3 text-sm text-gray-500 underline'
            onClick={() => doPasswordReset()}
          >
            Forgot Password?
          </button>

        </div>
      </IonContent>
    </IonPage>
  );
};
export default Login;
