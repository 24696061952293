import {
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonSpinner,
  IonBadge,
  useIonModal,
} from '@ionic/react';
import {useCallback, useEffect, useState} from 'react';
import PhotoSlides from '../../Components/PhotoSlides/PhotoSlides';
import ProductQuestions from './ProductQuestions';
import ProductComments from './ProductFriendComponent';
import ProductReviews from './ProductReviews';
import Product from './ProductClass';
import {
  getProductComments,
  getProductReviews,
  getProductQuestions,
} from '../../Api/api.products';
import {CommentType} from '../Comments/types';
import {setVendor} from '../Vendor/VendorStore';
import {hasFriends, UserStore} from '../User/UserStore';
import ProductBuyBarWithQuantityButton from './ProductItem/ProductBuyBarWithQtyBtn';
import {ProductQuestionType, ProductReviewType} from './types';
import BeyondLocalBadge from '../../Components/Badges/BeyondLocalBadge';
import dayjs from 'dayjs';
import GeneralStoreBadge from '../../Components/Badges/GeneralStoreBadge';
import VendorModal from '../Vendor/VendorModal';

type ProductDetailViewProps = {
  product: Product | undefined;
  modal?: boolean;
  loadingDetail?: boolean;
  router?: any;
  onDismiss?: any;
};

const ProductDetails = ({...props}: ProductDetailViewProps) => {
  const product = props.product;
  const userState = UserStore.useState((s) => s);
  const [activeTab, setActiveTab] = useState('reviews');
  const [questions, setQuestions] = useState<Array<ProductQuestionType>>([]);
  const [reviews, setReviews] = useState<Array<ProductReviewType>>([]);
  const [comments, setComments] = useState<Array<CommentType>>([]);
  const [userHasFriends, setUserHasFriends] = useState(false);
  const [numberOfFriends, setNumberFriends] = useState(0);
  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();
    },
    router: props.router,
  });

  const handleViewHubDetails = async (id: string) => {
    props.onDismiss();
    props.router.push(`/hubs/${id}`);
  };

  const presentVendor = () => {
    if (product && product.vendor) {
      props.onDismiss();
      setVendor(product.vendor);
      presentVendorModal();
    }
  };

  //  * The last lookup is stopping the following
  //  * useEffect from triggering multiple times when opening
  //  * mutliple (different) product modals
  //  *
  //  */
  let lastLookup = localStorage.getItem('product-lookup');

  // const getComments = () => {
  //   getProductComments(product?.id).then((res) => {
  //     setComments(res);
  //   });
  // }

  const getComments = useCallback(() => {
    getProductComments(product?.id).then((res) => {
      setComments(res);
    });
  }, [product?.id]);

  useEffect(() => {
    // Set a hash for this lookup
    let hash = `${activeTab}${product?.id}`;

    // If it doesn't match the hash - the lookup
    if (lastLookup !== hash) {
      // Save hash so next call doesn't get executed if its the same
      localStorage.setItem('product-lookup', hash);
    }
  }, [product?.id, lastLookup, activeTab]);

  useEffect(() => {
    if (activeTab === 'reviews' && product?.id) {
      getProductReviews(product?.id).then((res) => {
        setReviews(res);
      });
    }
  }, [activeTab, product?.id]);

  useEffect(() => {
    if (activeTab === 'comments' && product?.id) {
      if (!userState.isGuest) {
        getComments();

        hasFriends().then((res: any) => {
          setUserHasFriends(res.hasFriends);
          setNumberFriends(res.numberOfFriends);
        });
      } else {
        setComments([]);
      }
    }
  }, [
    activeTab,
    getComments,
    numberOfFriends,
    product?.id,
    userHasFriends,
    userState.isGuest,
  ]);

  useEffect(() => {
    if (activeTab === 'questions' && product?.id) {
      getProductQuestions(product?.id).then((res) => {
        setQuestions(res);
      });
    }
  }, [activeTab, product?.id]);

  return (
    <main className={`bg-white`}>
      {props.product && props.product.badge === 2 && !props.product.isPreOrder &&
        <BeyondLocalBadge classes='absolute z-20 h-auto right-10'/>
      }

      {props.product && props.product.badge === 3 && !props.product.isPreOrder &&
        <GeneralStoreBadge classes='absolute z-20 h-auto right-10'/>
      }

      {props.product && props.product.badge === 4 && !props.product.isPreOrder &&
        <div className='absolute z-20 right-10 shadow-xl bg-black h-auto p-2'>
          <p className='text-gray-100 font-semibold text-sm leading-tight tracking-tight'>
            Seeds <br/> <span className='text-gray-400'>and</span> <br/> Starts
          </p>
        </div>
      }

      {props.product && props.product.isPreOrder &&
        <div className='absolute z-20 h-auto right-10'>
          <img
            src='/images/badges/pre-order.png'
            alt={`Preorder ${props.product?.title} - Arrives: ${dayjs(props.product?.variants[0]?.preOrderDate.split('.000Z')[0]).format('MM/DD/YYYY')}`}
            width='67'
            height='51'
          />
        </div>
      }

      <div>
        {/* Product Photos */}
        {!!product && product.images?.length > 1 && (
          <PhotoSlides
            aria-label='Product Photos'
            slidesStyle={{height: '50vh'}}
            images={product.images}
          />
        )}
      </div>

      {!!product && product.images?.length === 1 && (
        <img
          src={product.images[0]}
          alt={product.title}
          className='w-full object-cover'
          style={{height: '333px'}}
        />
      )}

      {/* Title and Vendor  */}
      {!!product && (
        <section
          aria-label='Product Info'
          className='pt-2 pb-2 bg-white mw-container '
        >
          {/* Product Title  */}
          <h1 className='px-4 mb-2 text-2xl font-semibold leading-tight lg:text-3xl'>
            {product?.title}
          </h1>

          {/* Vendor */}
          <p className='px-4 text-sm text-gray-400 line-clamp-1'>
            From
            <button
              onClick={() => presentVendor()}
              className='ml-2 font-medium text-gray-600 text-green-500 bg-green-50 rounded-full px-2'
            >
              {product?.vendor?.name}
            </button>
          </p>
        </section>
      )}

      {/* Buy Bar */}
      {!!product && product.variants && product.variants.length > 0 && (
        <ProductBuyBarWithQuantityButton
          product={product}
          className='sticky top-0 z-40 px-4 bg-white mw-container'
        />
      )}

      {!!product &&
        product.variants.length === 0 &&
        product.availableMarkets &&
        product.availableMarkets.length > 0 && (
          <section
            aria-label='Product Details'
            className='px-4 py-4 mw-container'
          >
            <h2 className='text-red-500 font-bold text-xl'>
              This product is not available in your hub, but it is available in
              these hubs:
            </h2>

            <ul className='space-y-2 mt-2'>
              {product.availableMarkets?.map(({id, name}, index: number) => (
                <li key={id} className=''>
                  <span className='ml-2 font-medium text-gray-600 px-2'>
                    {name}
                  </span>

                  <button
                    className='ml-2 font-medium text-yellow-500 bg-yellow-50 hover:bg-yellow-100 rounded-full px-2'
                    key={index}
                    onClick={() => handleViewHubDetails(id)}
                  >
                    View &rarr;
                  </button>
                </li>
              ))}
            </ul>
          </section>
        )}

      {!product && (
        <div className='loading-mock-ui px-4 max-w-screen-md w-full mx-auto pt-5'>
          <IonSkeletonText animated className='h-12 w-11/12 mb-2'/>

          <IonSkeletonText animated className=' h-4 w-20 rounded-md mb-4'/>

          <div className='flex items-center justify-between mb-2'>
            <IonSkeletonText animated className=' h-4 w-20 rounded-md'/>
            <IonSkeletonText animated className=' h-4 w-20 rounded-md'/>
          </div>

          <div className='loading-bar-bar grid grid-cols-2 gap-3'>
            <IonSkeletonText animated className=' h-12 w-full rounded-md'/>
            <IonSkeletonText animated className=' h-12 w-full rounded-md'/>
          </div>
        </div>
      )}

      {/* Product Details  */}
      <section aria-label='Product Details' className='px-4 py-2 mw-container'>
        {props.loadingDetail ? <IonSpinner/> : ''}

        <div
          className='pb-4 mb-4 space-y-4 text-base leading-snug text-gray-600 border-b border-gray-200'
          dangerouslySetInnerHTML={{__html: product?.description || ''}}
        />

        {/* Product Specs */}
        <ul className='leading-tight specs'>
          {product && product.tags.length > 0 && (
            <li className='pb-1 mb-1'>
              <label className='text-xs text-gray-500 md:text-base'>Tags</label>
              <div className='mt-1 text-sm md:text-base'>
                {product.tags.split(',').map((tag, index) => {
                  return (
                    <IonBadge
                      className='mr-1 bg-gray-200'
                      color='light'
                      key={index}
                    >
                      {tag}
                    </IonBadge>
                  );
                })}
              </div>
            </li>
          )}
        </ul>
      </section>

      <IonToolbar className='shadow-lg'>
        <IonSegment value={activeTab}>
          <IonSegmentButton
            onClick={() => setActiveTab('questions')}
            value='questions'
          >
            Q&A
          </IonSegmentButton>
          <IonSegmentButton
            onClick={() => setActiveTab('reviews')}
            value='reviews'
          >
            Reviews
          </IonSegmentButton>
          <IonSegmentButton
            onClick={() => setActiveTab('comments')}
            value='comments'
          >
            Friends
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      {/* Answers */}
      {activeTab === 'questions' && (
        <ProductQuestions
          product={product}
          questions={questions}
          isGuest={userState.isGuest}
        />
      )}

      {/* Reviews  */}
      {activeTab === 'reviews' && (
        <ProductReviews
          product={product}
          setReviews={setReviews}
          reviews={reviews}
          isGuest={userState.isGuest}
        />
      )}

      {activeTab === 'comments' && (
        <ProductComments
          comments={comments}
          isGuest={userState.isGuest}
          hasFriends={userHasFriends}
          numberOfFriends={numberOfFriends}
          productId={product && product.id ? Number(product.id) : 0}
          getComments={getComments}
          router={props.router}
          onDismiss={props.onDismiss}
        />
      )}

      <section className='p-10' aria-hidden='true'></section>
    </main>
  );
};
export default ProductDetails;
