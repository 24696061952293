import Cookies from "js-cookie";
import {useStoreState} from "pullstate";
import {useEffect, useState} from "react";
import {UserStore} from "../../Domains/User/UserStore";

/**
 * Returns the proper branding images based on a server dictated cookie.
 * If the mw_data_source cookie is not undefined, run the value against the array of expected values to find a matching image
 * Return the image
 * @param context A "flag" to determine where the logo is being returned (the general-header component loads a different MW logo)
 * @returns
 */
export default function UseBranding(context: string = '') {
  const [hasMounted, setHasMounted] = useState(false);
  const userStore = useStoreState(UserStore);
  const [dataSource, setDataSource] = useState<string | null | undefined>(undefined);
  const expectedValues: string[] = [
    // 'kroger',
  ];
  const images = [
    '/images/marketwagon-logomark.svg',
    '/images/marketwagon-logotype.svg',
    '/images/kroger-logo.svg',
  ];
  const [branding, setBranding] = useState<string | null>(null);

  useEffect(() => {
    // Check if we have a cookie on existing mount
    if (!hasMounted) {
      let cookie = Cookies.get('mw_data_source');
      setDataSource(cookie);
      setHasMounted(true);
    }

    // If we have mounted and we have a context, handle that special case
    if (context === 'general-header') {
      setBranding(images[1]);
    }

    // If we have mounted but still don't have a data source, let's look in userStore
    if (hasMounted && dataSource === undefined) {
      setDataSource(userStore.dataSource);
    }

    // If our dataSource is anything but null, run it against our expectedValues array.
    // If it's value is found in that array, choose an image from images based on the value of the cookie.
    if (hasMounted && dataSource !== null && expectedValues.includes(String(dataSource))) {
      setBranding(images[images.findIndex(element => element.includes(String(dataSource)))]);
    }

    /**
     * Removing the next line will cause TypeScriptlint to flag "images" and "expectedValues" as a missing dependency.
     * They will never programmatically change, so we want to omit it from dependency tree.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, dataSource, userStore]);

  if (branding !== null) {
    return branding;
  } else {
    return images[0];
  }
}
