import {
  IonButton,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonText,
  // IonSelect,
  // IonSelectOption,
} from '@ionic/react'
// import { emit } from 'process'
import {useEffect, useState} from 'react'
import DeliveryAddress, {DeliveryAddressType} from './DeliveryAddress'


type AddressFormProps = {
  address?: DeliveryAddress,
  onSave?: Function
  onCancel?: Function;
}

const DeliveryAddressForm = ({...props}: AddressFormProps) => {
  const starterState: DeliveryAddressType = props.address || {}
  const [addressState, setAddressState]: any = useState(starterState)

  //error handling
  const [zipCodeError, setZipCodeError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);


  const onSave = () => {
    if (props.onSave) {
      props.onSave(addressState)
    }
  }
  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  useEffect(() => {
    if (addressState.address.length > 0) {
      setAddressError(false);
    } else {
      setAddressError(true);
    }
    if (addressState.city.length > 0) {
      setCityError(false);
    } else {
      setCityError(true);
    }


  }, [addressState])

  return (
    <div className="">

      <IonItemDivider color="transparent"></IonItemDivider>
      <IonItem>
        <IonLabel position="fixed" className="text-gray-300">
          Address
        </IonLabel>
        <IonInput
          value={addressState.address}
          placeholder="Street Address"
          type="text"
          required
          onInput={(evt: any) => {
            const addState = {...addressState}
            addState.address = evt.target.value
            setAddressState(addState)
          }}
        ></IonInput>
        {addressError &&
          <IonText className="text-red-600">
            Address is required
          </IonText>}
      </IonItem>
      <IonItem>
        <IonLabel position="fixed" className="text-gray-300">
          Address 2
        </IonLabel>
        <IonInput
          value={addressState.address2}
          placeholder="Apt, floor, suite, etc (optional)"
          type="text"
          required
          onInput={(evt: any) => {
            const addState = {...addressState}
            addState.address2 = evt.target.value
            setAddressState(addState)
          }}
        ></IonInput>
      </IonItem>
      <IonItem title='Address City Name'>
        <IonLabel position="fixed" className="text-gray-300">
          City
        </IonLabel>
        <IonInput
          value={addressState.city}
          placeholder="City"
          type="text"
          required
          onInput={(evt: any) => {
            const addState = {...addressState}
            addState.city = evt.target.value
            setAddressState(addState)
          }}
        ></IonInput>
        {cityError &&
          <IonText className="text-red-600">
            City is required
          </IonText>}
      </IonItem>
      <IonItem title="Address State or Region">
        <IonLabel position="fixed" className="text-gray-300">
          State
        </IonLabel>
        <select
          className="focus:ring-2 group group-focus:ring-2"
          value={addressState.state}
          placeholder="State"
          onInput={(evt: any) => {
            const addState = {...addressState}
            addState.state = evt.target.value
            setAddressState(addState)
          }}
        >
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </IonItem>
      <IonItem>
        <IonLabel position="fixed" className="text-gray-300">
          Zip Code
        </IonLabel>
        <IonInput
          placeholder="Zip Code"
          value={addressState.zipCode}
          onInput={(evt: any) => {
            if (evt.target.value.length === 5) {
              setZipCodeError(false);
              const addState = {...addressState}
              addState.zipCode = evt.target.value
              setAddressState(addState)
            } else {
              setZipCodeError(true);
            }

          }}
          type="text"
          pattern="[0-9]{5}"
          required
        ></IonInput>
        {zipCodeError &&
          <IonText className="text-red-600">
            Must be 5 digits
          </IonText>}
      </IonItem>


      <div className="p-4 flex items-center space-x-2 w-full">

        <IonButton

          fill="clear"
          color="dark"
          expand="block"
          className="w-full"
          onClick={() => onCancel()}
        >
          Cancel
        </IonButton>
        <IonButton
          className="w-full"
          disabled={
            !(
              addressState.zipCode &&
              addressState.address &&
              addressState.city &&
              addressState.state
            ) || addressError || cityError || zipCodeError
          }
          expand="block"
          fill='solid'
          onClick={() => onSave()}
        >
          Update
        </IonButton>
      </div>
    </div>
  )
}

export default DeliveryAddressForm
