import {Store} from 'pullstate';
import {getFavoriteProducts} from '../../Api/api.products';
import {getFavoriteVendors} from '../../Api/api.vendor';
import Product from '../Product/ProductClass';
import Vendor from '../Vendor/VendorClass';

interface FavoritesStoreType {
  products: Array<Product>;
  vendors: Array<Vendor>;
}

const starterState: FavoritesStoreType = {
  products: [],
  vendors: [],
};
export const FavoritesStore = new Store(starterState);

export const loadFavoriteVendors = async (): Promise<boolean> => {
  try {
    const vendors: Array<Vendor> = await getFavoriteVendors();
    FavoritesStore.update((s) => {
      s.vendors = vendors;
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const loadFavoriteProducts = async () => {
  try {
    const currentProductPage = 1;
    const productsPerPage = 40;
    const products: Array<Product> = await getFavoriteProducts(
      currentProductPage,
      productsPerPage
    );
    FavoritesStore.update((s) => {
      s.products = products;
    });
  } catch (e) {
    console.error(e);
  }
};
