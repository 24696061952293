export default class VariantModel {
  id: string;
  title: string;
  inventory?: number;
  price?: number;
  salePrice?: number;
  saleType?: string;
  saleAmount?: number;
  saleEndDate?: Date;
  preOrderDate?: Date;
  preOrderDispatchId?: string;

  constructor(input: any) {
    this.id = input.id;
    this.title = input.title;
    this.inventory = input.inventory;
    this.price = input.price;
    this.salePrice = input.salePrice;
    this.saleType = input.saleType;
    this.saleAmount = input.saleAmount;
    this.saleEndDate = input.saleEndDate ? new Date(input.saleEndDate) : undefined;
    this.preOrderDate = input.preOrderDate ? new Date(input.preOrderDate) : undefined;
    this.preOrderDispatchId = input.preOrderDispatchId;
  }
}
