import {Store} from 'pullstate';
import {getProduct} from '../../Api/api.products';
import Product, {ProductType} from './ProductClass';

const initialState: {
  isModal?: boolean;
  product?: Product;
  loading?: boolean;
} = {loading: true};
export const ProductStore = new Store(initialState);

/**
 * Load the Active Product by ID
 * TODO:// see why the product store causes a multiplier of reactions
 * for each modal that is opened... 1, reacts 1, 2 reacts 2, 3 reacts 6, 4 reacts 12
 * @param productItem
 * @param openModal
 */
export const loadProduct = async (
  productItem: ProductType | Product | any,
  openModal: boolean = false
) => {
  if (productItem) {
    unLoadProduct();

    // Load up the Product Item
    // so we have some content

    ProductStore.update((s) => {
      s.product = new Product(productItem);
      s.isModal = openModal;
      s.loading = true;
    });
    // Get the Full Product
    const fullProduct: Product | undefined | number = await getProduct(
      productItem.id
    );

    if (fullProduct === 404) {
      return false;
    }

    if (fullProduct) {
      ProductStore.update((s) => {
        s.product = fullProduct as Product;
        s.loading = false;
      });
    }

    return true;
  } else {
    // TODO: Show 404
    return false;
  }
};

const unLoadProduct = () => {
  ProductStore.update((s) => {
    s.product = undefined;
    s.isModal = false;
    s.loading = false;
  });
}
