import {GET} from "./Api";

/**
 * Returns a paginated list of published blog posts ordered by most recent.
 */
export const getBlogPosts = async (page: number, perPage: number, category: string | null = null) => {
    try {
        const results = await GET(`/blog-posts?category=${category}`, page, perPage);

      return {
          posts: results.payload,
          page: results.page,
          perPage: results.perPage,
          totalRows: results.totalRows
        };
    } catch (e) {
        console.error(e);
        return false;
    }
}

/**
 * Returns a single blog post and it's associated data.
 * @param idOrSlug A string or number representing the blog post to fetch
 */
export const getBlogPost = async (idOrSlug: any) => {
    try {
        const results = await GET(`/blog-posts/${idOrSlug}`);
        return results.payload;
    } catch (e) {
        console.error(e);
        return false;
    }
}
