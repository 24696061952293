import {
  IonIcon,
  IonSkeletonText,
  useIonRouter,
} from '@ionic/react';
import {useEffect, useRef, useState} from 'react';
import {
  checkmarkCircleOutline,
  informationCircleOutline,
} from 'ionicons/icons';
import dayjs from 'dayjs';
// @ts-ignore
import ConfettiGenerator from 'confetti-js';
import {getOrder, getSurveyType} from '../../Api/api.orders';
import MWButton from '../../Components/MWButton/MWButton';
import UseWindowSizeSize from '../../Helpers/Hooks/UseWindowSize';
import {CartStoreInit} from '../Cart/CartStore';
import {isLoggedIn, UserStore} from '../User/UserStore';
import OpenModal from '../../Helpers/OpenModal';
import PrivatePage from '../../Components/Page/PrivatePage';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';

const OrderCompletePage = ({match}: any) => {
  const canvasRef = useRef<any>();
  const {width, height} = UseWindowSizeSize();
  const userState = UserStore.useState((s) => s, []);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [order, setOrder] = useState<any | undefined>(undefined);
  const [addressString, setAddressString] = useState('');
  const [showInstructions, setShowInstructions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');

  const router = useIonRouter();

  useEffect(() => {
    setIsLoading(true);
    CartStoreInit().then();
    setOrderId(match.params.orderId);
  }, [match.params.orderId]);

  useEffect(() => {
    if (isLoggedIn()) {
      if (orderId && order === undefined) {
        getSurveyType(orderId).then((res) => {
          if (res.type === 'MarketingSource') {
            OpenModal('MarketingSourceModal', {orderId: orderId});
          }

          if (res.type === 'CSAT') {
            OpenModal('CSAT', {
              orderId: orderId,
              question: res.question.text,
              questionId: res.question.id,
            });
          }

          if (res.type === 'NPS') {
            OpenModal('NPS', {orderId: orderId});
          }

          if (res.type === 'Share' && userState.handle !== '') {
            OpenModal('Share', {orderId: orderId});
          }

          if (res.type === 'Subscribe') {
            OpenModal('Subscribe', {orderId: orderId});
          }
        });
      }
    }
  }, [order, orderId, userState.handle]);

  useEffect(() => {
    if (orderId && isLoggedIn()) {
      getOrder(orderId).then((res) => {
        setOrder(res);

        window.gtag('event', 'conversion', {
          send_to:
            process.env.REACT_APP_API_GOOGLE_ADS + '/byO6CNS5-rcDEMDYofwD',
          value: res.orderTotal,
          currency: 'USD',
          transaction_id: orderId,
        });

        window.gtag('event', 'purchase', {
          transaction_id: orderId,
          value: res.orderTotal,
          currency: 'USD',

          items: [],
        });
      })
    }
  }, [orderId]);

  useEffect(() => {
    if (order) {
      if (order.deliveryAddress?.address === undefined) {
        setAddressString(
          `${order.pickupAddress.address} ${order.pickupAddress.city}, ${order.pickupAddress.state} ${order.pickupAddress.zipCode}`
        );
      } else {
        setAddressString(
          `${order.deliveryAddress.address} ${order.deliveryAddress.city}, ${order.deliveryAddress.state} ${order.deliveryAddress.zipCode}`
        );
      }
      setIsLoading(false);
    }
  }, [order]);

  useEffect(() => {
    if (order && userState.hubs && userState.hubs[0]) {
      const dDate = userState.hubs[0].deliveryDay;
      setDeliveryDate(dayjs(dDate).format('dddd, MMM D'));
    }
  }, [order, userState.hubs]);

  useEffect(() => {
    const confettiSettings = {
      target: 'confettiCanvas',
      max: 10,
      props: [
        {type: 'svg', src: '/images/icons/cabbage.svg', size: 50},
        {type: 'svg', src: '/images/icons/carrot.svg', size: 50},
        {type: 'svg', src: '/images/icons/ham.svg', size: 50},
        {type: 'svg', src: '/images/icons/tomato.svg', size: 50},
      ],
      width: width,
      height: height,
      rotate: true,
    };

    const confetti = new ConfettiGenerator(confettiSettings);

    if (!isLoading) {
      confetti.render();
    }

    return () => confetti.clear();
  }, [height, isLoading, width]);

  return (
    <PrivatePage className='relative'>
      <Helmet>
        <title>Order Complete</title>
        <link rel="canonical" href={`https://shop.marketwagon.com/order-complete`}/>
      </Helmet>

      <GeneralHeader title='Order Complete'/>

      <div className='h-screen'>
        <section
          aria-label='Order Confirmation'
          className='w-full max-w-screen-sm p-4 mx-auto space-y-4 md:p-6'
        >
          <div className='flex items-center'>
            <IonIcon
              className='mr-2 text-2xl text-green-600'
              icon={checkmarkCircleOutline}
            />

            {isLoading && (
              <IonSkeletonText
                style={{height: '38px', width: '166px'}}
                className='rounded-lg'
                animated={true}
              />
            )}

            {!isLoading && (
              <h2 className='text-base font-semibold'>
                <span className='block text-lg font-bold leading-none'>
                  {!userState.isGuest &&
                    userState.firstName &&
                    `Thank you, ${userState.firstName}!`}

                  {(userState.isGuest ||
                      (!userState.isGuest && !userState.firstName)) &&
                    'Your order is confirmed!'}
                </span>

                {!userState.isGuest && (
                  <span className='block text-sm font-normal text-gray-600'>
                    Your order is confirmed
                  </span>
                )}
              </h2>
            )}
          </div>

          <section
            aria-label='Delivery Details'
            className='relative p-4 bg-white rounded-md shadow-sm'
          >
            {isLoading && (
              <IonSkeletonText
                style={{height: '166px', width: '100%'}}
                className='rounded-lg'
                animated={true}
              />
            )}

            {!isLoading && (
              <iframe
                width='100%'
                title='map'
                className='h-40 mb-4 overflow-hidden rounded-lg'
                id='gmap_canvas'
                src={`https://www.google.com/maps/embed/v1/place?key=${
                  process.env.REACT_APP_GOOGLE_KEY
                }&q=${encodeURIComponent(addressString)}`}
                scrolling='no'
              />
            )}

            <p className='mb-1 text-sm font-semibold text-gray-800 flex items-center'>
              Order&nbsp;
              <span>
                {isLoading && (
                  <IonSkeletonText
                    style={{height: '20px', width: '50px'}}
                    className='rounded-lg'
                    animated={true}
                  />
                )}
                {!isLoading && ` #${order?.id}`}
              </span>
            </p>

            <p className='text-xs text-gray-600 truncate'>
              {isLoading && (
                <IonSkeletonText
                  style={{height: '16px', width: '300px'}}
                  className='rounded-lg'
                  animated={true}
                />
              )}

              {!isLoading && addressString}
            </p>

            {order && order.deliveryNotes && (
              <p className='text-xs text-gray-500 truncate'>
                Note:
                <span>
                  {isLoading && (
                    <IonSkeletonText
                      style={{height: '20px', width: '50px'}}
                      className='rounded-lg'
                      animated={true}
                    />
                  )}

                  {!isLoading && order.deliveryNotes}
                </span>
              </p>
            )}

            <div className='flex items-center mt-2 space-x-2 rounded-sm'>
              <button
                className='w-5 h-5 mr-1 text-lg text-green-600 rounded-full'
                onClick={() => setShowInstructions(!showInstructions)}
              >
                <IonIcon icon={informationCircleOutline}/>
              </button>
              Arrives{' '}
              <strong>
                {isLoading && (
                  <IonSkeletonText
                    className='rounded-lg'
                    style={{height: '24px', width: '150px'}}
                    animated={true}
                  />
                )}{' '}
                {!isLoading && deliveryDate}
              </strong>
            </div>

            <div
              className={`pt-2 overflow-hidden ${
                showInstructions ? 'h-16.25rem' : 'h-0'
              }`}
              style={{transition: 'height 0.75s'}}
            >
              <p className='text-xs leading-snug text-gray-600'>
                Deliveries typically arrive early afternoon. You'll be notified
                when your order is in route. You do not have to be home, as we
                will leave your order in insulated tote(s) with ice packs.
              </p>
            </div>

            {order && (
              <img
                height='1'
                width='1'
                className='hidden'
                alt=''
                src={`https://ct.pinterest.com/v3/?tid=${process.env.REACT_APP_API_PINTEREST}&event=checkout&ed[value]=${order.orderTotal}&ed[order_quantity]=1&ed[currency]=USD&noscript=1`}
              />
            )}

            <div className='w-full flex justify-center mt-3'>
              <MWButton
                title={'Continue Shopping'}
                className='bg-green-600 hover:bg-green-500 text-white rounded-lg z-20'
                onClick={() => {
                  router.push('/shop');
                }}
              >
                Continue Shopping
              </MWButton>
            </div>
          </section>
        </section>
      </div>

      <canvas
        id='confettiCanvas'
        className={`fixed left-0 right-0 bottom-0 w-full h-full ${
          isLoading ? '-top-full' : 'top-0'
        }`}
        ref={canvasRef}
        style={{transition: 'top 1s ease-in-out'}}
      />
    </PrivatePage>
  );
};

export default OrderCompletePage;
