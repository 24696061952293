import {useCallback, useEffect, useRef, useState} from 'react';
import {IonButton} from '@ionic/react';
import Textarea from 'react-expanding-textarea';
import {CommentReplyProps} from './types';

const CommentReply = ({...props}: CommentReplyProps) => {
  const commentBase: any = undefined;
  const [comment, setComment] = useState(commentBase);

  const textareaRef: any = useRef(null);

  const handleChange = useCallback((e) => {
    setComment(e.target.value);
  }, []);

  const submitComment = () => {
    props.onPublish(comment);
    setComment('');
  };

  useEffect(() => {
    if (textareaRef.current) textareaRef.current.focus();
  }, []);

  return (
    <div
      aria-label='Compose a message'
      className='flex items-center bg-gray-100 rounded-xl'
    >
      <Textarea
        className='w-full px-3 py-2 bg-transparent textarea'
        value={comment}
        style={{minHeight: '50px', fontSize: '17px'}}
        id='my-textarea'
        maxLength={250}
        name='message'
        onChange={handleChange}
        placeholder={
          props.placeholder || 'Leave a comment for your friends to see'
        }
        ref={textareaRef}
      />
      {comment && (
        <IonButton
          fill='clear'
          title='Post Comment'
          className='font-bold'
          onClick={() => submitComment()}
        >
          {props.postButtonTitle || 'Post'}
        </IonButton>
      )}
    </div>
  );
};

export default CommentReply;
