import MWAvatar from '../MWAvatar/MWAvatar';
import {AvatarTitleSubType} from './types';

const AvatarTitleSub = ({...props}: AvatarTitleSubType) => {
  const image = props.image;
  const title = props.title;
  const subTitle = props.subtitle;
  const avatarLabel = props.avatarLabel;
  return (
    <div onClick={() => {
      if (props.onClick) props.onClick();
    }}
         className={`flex cursor-pointer flex-row items-center w-full space-x-2 avatar-title-sub`}
    >
      <MWAvatar
        label={avatarLabel}
        hideLabel={true}
        image={image}
        size='sm'
      />
      <div className='w-full details'>
        <div className='block p-0 m-0 text-xs leading-tight line-clamp-1'>
          {title && <span className='font-semibold'>{title}</span>}
        </div>
        {subTitle && (
          <div
            aria-label='How long ago'
            className='p-0 m-0 text-xs leading-tight text-gray-500'
          >
            {subTitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarTitleSub;
