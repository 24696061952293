import {
  InboxThreadDetailType,
  InboxThreadType,
  InboxHeaderType,
} from '../Domains/Inbox/types';
import { GET, POST } from './Api';

export const getInbox = async (
  currentPage: number,
  perPage: number
): Promise<Array<InboxThreadType>> => {
  const call = await GET('/inbox', currentPage, perPage);
  return call.payload;
};
export const getHeaders = async (
  currentPage: number,
  perPage: number
): Promise<InboxHeaderType> => {
  const call = await GET('/inbox', currentPage, perPage);
  return {
    'MW-CURRENT-PAGE': call.headers.get('MW-CURRENT-PAGE'),
    'MW-ROWS-PER-PAGE': call.headers.get('MW-ROWS-PER-PAGE'),
    'MW-TOTAL-RESULTS': call.headers.get('MW-TOTAL-RESULTS'),
  };
};

export const getInboxThread = async (
  id: any
): Promise<InboxThreadDetailType> => {
  const call = await GET(`/inbox/${id}`);
  return call.payload;
};

export const postInboxThread = async (
  id: number,
  message: string
): Promise<any> => {
  const call = await POST(`/inbox/${id}/reply`, {
    reply: message,
  });
  return call.payload;
};

export const markAsRead = async (id: number): Promise<any> => {
  const call = await POST(`/inbox/${id}/read`);
  return call.payload;
};
