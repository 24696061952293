import {
  IonItem,
  IonLabel,
} from '@ionic/react';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getCollections} from '../../Api/api.collections';
import {CollectionsGridProps, CollectionType} from './types';

const CollectionsGrid = ({...props}: CollectionsGridProps) => {
  const collectionsState: Array<CollectionType> = [];
  const [collections, setCollections] = useState(collectionsState);

  const view = props.type || 'grid';

  // Use Effect to initially get the collections
  useEffect(() => {
    getCollections().then((_collections: Array<CollectionType>) => {
      const showCollections = _collections.filter(function (e) {
        return e.showInCollections;
      });
      setCollections(showCollections);
    });
  }, []);

  if (view === 'grid') {
    return (
      <section
        aria-label='Our Collections'
        className={`${
          props.className || ''
        } grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4`}
      >
        {collections.map((collection: CollectionType, index: number) => {
          return (
            <Link
              to={`/collections/${collection.slug}`}
              title={`Shop ${collection.title}`}
              key={index}
              className='bg-cover overflow-hidden shadow-md bg-center h-48 rounded-md flex  items-end text-left justify-start'
              style={{backgroundImage: `url(${collection.image})`}}
              onClick={(evt) => {
                if (props.onSelect) {
                  props.onSelect(collection, evt);
                }
              }}
            >
              <div
                className='bg-gradient-to-b from-transparent to-black w-full bg-opacity-20 text-xl font-bold leading-tight text-white p-4'>
                {collection.title}
              </div>
            </Link>
          );
        })}
      </section>
    );
  } else {
    return (
      <section className='pb-20'>
        {collections.map((collection: CollectionType, index: number) => {
          return (
            <IonItem
              button
              key={index}
              onClick={() => {
                if (props.onSelect) {
                  props.onSelect(collection);
                }
              }}
            >
              <div
                slot='start'
                className='w-20 h-20 bg-center bg-cover rounded-md image'
                style={{backgroundImage: `url(${collection.image})`}}
              ></div>
              <IonLabel className='ion-text-wrap'>{collection.title}</IonLabel>
            </IonItem>
          );
        })}
      </section>
    );
  }
};

export default CollectionsGrid;
