import BannerModel from "./banner.model";
import {GET} from "../../Api/Api";

export class BannerApi {
  static async getBanners() {
    const call = await GET('/banners/');
    return call.payload.map((i: any) => new BannerModel(i));
  }
}


