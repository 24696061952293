import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import {useState} from 'react';
import PublicPage from '../../Components/Page/PublicPage';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';

const HelpPage = () => {
  const [showBecomeVendorModal, setShowBecomeVendorModal] = useState(false);

  const inquiryEmail = () => {
    const email = [
      'j',
      'o',
      'h',
      'n',
      '.',
      'l',
      'a',
      'u',
      'g',
      'h',
      'l',
      'i',
      'n',
      '@',
      'marketwagon',
      '.',
      'c',
      'o',
      'm',
    ].join('');
    const subject = ['Media', ' Inquiry'].join('');
    // This weirdness is intentional so to avoid spam from email scrappers
    window.location.href =
      // eslint-disable-next-line no-useless-concat
      'mai' + 'l' + 't' + 'o:' + email + '?' + 'subj' + 'ect' + '=' + subject;
  };

  return (
    <PublicPage>
      <Helmet>
        <title>Help | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/help"/>
      </Helmet>

      <GeneralHeader title='Help' backHref='/shop'/>

      <section>
        <div className='py-12 bg-gray-100'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='lg:text-center'>
              <p className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                Market Wagon Help
              </p>
              <p className='mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto'>
                How can we help you?
              </p>
            </div>
            <div className='mt-10'>
              <div className='space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10'>
                <div
                  className='relative rounded-lg shadow-md bg-white md:inline text-left px-4 pt-6 pb-2 cursor-pointer'>
                  <Link to={'/support'}>
                    <div>
                      <div
                        className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z'
                          />
                        </svg>
                      </div>
                      <p className='ml-16 text-lg leading-6 font-medium text-gray-900'>
                        I have a question about an order I placed
                      </p>
                    </div>
                    <p className='mt-2 ml-16 text-gray-500 text-sm md:text-base'>
                      Have a problem, question, or need help with an order or
                      a product you purchased? We're here to help...
                    </p>
                    <div className='w-full flex justify-end'>
                      <div className='font-semibold text-green-600 flex mr-2 mt-2'>
                        <span>Customer Support</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9 5l7 7-7 7'
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>

                <div
                  className='relative rounded-lg shadow-md bg-white md:inline text-left px-4 pt-6 pb-2 cursor-pointer'>
                  <Link to={'/faq'}>
                    <div>
                      <div
                        className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-5 w-5'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                        >
                          <path
                            fillRule='evenodd'
                            d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </div>
                      <p className='ml-16 text-lg leading-6 font-medium text-gray-900'>
                        I have a question about shopping on Market Wagon
                      </p>
                    </div>
                    <p className='mt-2 ml-16 text-gray-500 text-sm md:text-base'>
                      If you have questions about our services, the vendors,
                      or products you find on the website, ask away...
                    </p>
                    <div className='w-full flex justify-end'>
                      <div className='font-semibold text-green-600 flex mr-2 mt-2'>
                        <span>FAQs</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9 5l7 7-7 7'
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>

                <button
                  className='relative rounded-lg shadow-md bg-white md:inline text-left px-4 pt-6 pb-2 cursor-pointer'
                  onClick={() => setShowBecomeVendorModal(true)}
                >
                  <div>
                    <div
                      className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z'
                        />
                      </svg>
                    </div>
                    <p className='ml-16 text-lg leading-6 font-medium text-gray-900'>
                      I'm interested in selling on Market Wagon
                    </p>
                  </div>
                  <p className='mt-2 ml-16 text-gray-500 text-sm md:text-base'>
                    Are you a farmer or food artisan looking to grow your
                    business in your local market? We would love to hear from
                    you.
                  </p>
                  <div className='w-full flex justify-end'>
                    <div className='font-semibold text-green-600 flex mr-2 mt-2'>
                      <span>Become a Vendor</span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9 5l7 7-7 7'
                        />
                      </svg>
                    </div>
                  </div>
                </button>

                <button
                  className='relative rounded-lg shadow-md bg-white md:inline text-left px-4 pt-6 pb-2 cursor-pointer'
                  onClick={inquiryEmail}
                >
                  <div>
                    <div
                      className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z'
                        />
                      </svg>
                    </div>
                    <p className='ml-16 text-lg leading-6 font-medium text-gray-900'>
                      Media Inquiries
                    </p>
                  </div>
                  <p className='mt-2 ml-16 text-gray-500 text-sm md:text-base'>
                    To request an interview, comment, or appearance...
                  </p>
                  <div className='w-full flex justify-end'>
                    <div className='font-semibold text-green-600 flex mr-2 mt-2'>
                      <span>Media Inquiry</span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9 5l7 7-7 7'
                        />
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <IFrameModalController
          onDismiss={() => {
            setShowBecomeVendorModal(false);
          }}
          title='Become a Vendor'
          url='https://marketwagon.com/pages/apply-to-become-a-vendor.php'
          open={showBecomeVendorModal}
        />
      </section>
    </PublicPage>
  );
};

export default HelpPage;
