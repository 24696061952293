export default class CartBannerItem {
  id?: string;
  title?: string;
  body?: string;
  type?: string;

  constructor(starter: any) {
    this.id = starter.id;
    this.title = starter.title;
    this.body = starter.body;
    this.type = starter.type;
  }
}
