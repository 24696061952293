import {POST} from './Api';

export const submitGeneralInquiry = async (
  firstName: string | null,
  lastName: string | null,
  emailAddress: string | null,
  zipCode: string | null,
  phoneNumber: string | null,
  message: string | null,
  token: string | null
) => {
  const call = await POST(`/problems/contact-us`, {
    firstName,
    lastName,
    emailAddress,
    zipCode,
    message,
    phoneNumber,
    token,
  });

  return call.status;
};
