/**
 * Strips "Market Wagon" from the name of a hub.
 * If the needle isn't in the haystack, the original name is returned.
 *
 * @param name String; Name of hub to be formatted.
 * @returns String; Hub name without "Market Wagon"
 */
export default function formatHubName(name: string) {
  return name.includes('Market Wagon') ? name.split('Market Wagon')[1] : name;
}
