import {SearchResults} from '../Domains/Search/types';
import {GET} from './Api';

export const search = async (
  term: string,
  page: number = 1
): Promise<SearchResults> => {
  const call = await GET(`/search/auto-complete/${term}?page=${page}`);

  return {
    results: call.payload,
    currentPage: page,
    totalResults: call.totalRows || 0,
    rowsPerPage: call.perPage || 0,
  };
};

export const searchAutoComplete = async (term: string) => {
  const call = await GET(`/search/new/auto-complete/${term}`);
  return call.payload;
};


export const searchVendors = async (
  term: string,
) => {
  const call = await GET(`/search/vendors/${term}`);
  return call.payload;
};

export const termSearch = async (
  term: string,
  page: number,
  perPage: number
) => {
  const call = await GET(`/search/new/query/${term}`, page, perPage);

  return {
    results: call.payload,
  };
};

export const suggestionSearch = async (
  idOrSlug: any,
  page: number,
  perPage: number
) => {
  const call = await GET(`/products/${idOrSlug}/suggestions`, page, perPage);

  return {
    results: call.payload,
  };
};
export const nameSearch = async (
  term: string,
) => {
  try {
    const call = await GET(`/vendors/search/${term}`);
    return {
      results: call.payload,
    };
  } catch (error) {
    return error;
  }
};
