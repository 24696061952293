import {useCallback, useEffect, useState} from 'react';
import {UserStore} from '../User/UserStore';
import {
  clearActiveCollection,
  CollectionStore,
  setActiveChild,
  setActiveCollection,
} from '../Collection/CollectionStore';
import CollectionList from '../Collection/CollectionProductList';
import {useStoreState} from 'pullstate';
import Feed from '../Feed/Feed';
import BundlesSection from '../../NewDomains/Bundle/Components/BundlesSection';
import PrivatePage from '../../Components/Page/PrivatePage';
import {getProduct} from '../../Api/api.products';
import Product from '../Product/ProductClass';
import {useIonModal, useIonRouter} from '@ionic/react';
import {setVendor} from '../Vendor/VendorStore';
import Vendor from '../Vendor/VendorClass';
import {Helmet} from 'react-helmet';
import AppHeader from '../../Components/Header/AppHeader';
import {ProductModal} from '../Product/ProductModal';
import {loadProduct} from '../Product/ProductStore';
import VendorModal from '../Vendor/VendorModal';
import BannersSection from "../../NewDomains/Banner/Components/BannersSection";

const HomePage = ({match}: any) => {
  document.body.scrollTo(0, 0);
  const userState = useStoreState(UserStore);
  const collectionState = useStoreState(CollectionStore);
  const collectionPassed: string | undefined = match?.params?.collectionSlug?.toLowerCase();
  const slug: string = match.params.slug || null;
  const router = useIonRouter();
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  const productId = urlQuery.get('id');
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const vendorId = match.params.vendorId || null;
  const pathName = window.location.pathname || null;
  const searchParams = window.location.search || null;

  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();

      if (router.routeInfo.pathname.includes('/products/')) {
        router.push('/shop');
      }
    },
    router: router,
  });
  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();

      if (router.routeInfo.pathname.includes('/vendors/')) {
        router.push('/shop');
      }
    },
    router: router,
  });

  const awaitGetProduct = useCallback(async () => {
    if (hasMounted && ((slug !== null && slug !== 'me') || (productId !== null))) {
      let pId = productId;

      if (pId === null) {
        pId = slug;
      }

      await getProduct(pId).then((res: any) => {
        if (res !== 404) {
          res = res as Product;
          loadProduct(res);
          presentProductModal();
        } else {
          router.push('/404');
        }
      });
    }
    // DO NOT ADD ROUTER TO THIS DEPENCENCY.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted, slug, productId, presentProductModal]);

  const awaitGetVendor = useCallback(async () => {
    if (hasMounted && ((slug !== null && slug !== 'me') || (vendorId !== undefined))) {
      const vd = {
        id: vendorId
      };

      setVendor(vd as Vendor);
      presentVendorModal();
    }
    // DO NOT ADD ROUTER TO THIS DEPENCENCY.
  }, [hasMounted, slug, vendorId, presentVendorModal]);

  useEffect(() => {
    if (!hasMounted) {
      // Get Page Collection
      let collection = (collectionState.collections || []).find(
        (c) => c.slug === collectionPassed
      );

      if (collection) {
        setActiveCollection(collection).then();
      } else if (collectionPassed && !isNaN(parseInt(collectionPassed))) {
        const subCollectionId = parseInt(collectionPassed);

        collection = (collectionState.collections || []).find(
          (c) => c.children?.find((i) => i.id === subCollectionId) !== undefined
        );

        if (collection) {
          setActiveCollection(collection).then();
          const child = collection.children.find((c) => c.id === subCollectionId);
          setActiveChild(child).then();
        }
      }

      setHasMounted(true);
    }
  }, [collectionPassed, collectionState, hasMounted]);

  useEffect(() => {
    if (hasMounted) {
      // Get Page Collection
      let collection = (collectionState.collections || []).find(
        (c) => c.slug === collectionPassed
      );

      if (collection) {
        setActiveCollection(collection).then();
      } else if (collectionPassed && !isNaN(parseInt(collectionPassed))) {
        const subCollectionId = parseInt(collectionPassed);

        collection = (collectionState.collections || []).find(
          (c) => c.children?.find((i) => i.id === subCollectionId) !== undefined
        );

        if (collection) {
          setActiveCollection(collection).then();
          const child = collection.children.find((c) => c.id === subCollectionId);
          setActiveChild(child).then();
        }
      } else {
        clearActiveCollection();
      }

    }
  }, [collectionPassed, collectionState.collections, hasMounted]);

  useEffect(() => {
    if (hasMounted) {
      if (window.location.pathname.includes('vendors')) {
        awaitGetVendor().then();
      } else if (window.location.pathname.includes('products')) {
        awaitGetProduct().then();
      }
    }
  }, [awaitGetProduct, awaitGetVendor, hasMounted]);

  return (
    <PrivatePage>
      {!collectionState.activeCollection && !productId && router.routeInfo.pathname === '/shop' &&
        <Helmet>
          <title>Browse All Collections | Market Wagon</title>
          <link rel="canonical" href={`https://shop.marketwagon.com/shop`}/>
        </Helmet>
      }

      {(
          (collectionState.activeCollection) ||
          (productId) ||
          (router.routeInfo.pathname.includes('/subscription')) ||
          (router.routeInfo.pathname.includes('/profile')) ||
          (router.routeInfo.pathname.includes('/account'))
        ) &&
        <Helmet>
          <link rel="canonical"
                href={`https://shop.marketwagon.com${pathName !== null ? pathName : ''}${searchParams !== null ? searchParams : ''}`}/>
        </Helmet>
      }
      <AppHeader/>

      {userState && userState.hubs && userState.hubs.length > 0 && (
        <div className='min-h-screen'>
          {collectionState.activeCollection === null ?
            <>
              <BannersSection/>
              <BundlesSection/>
              <Feed/>
            </>
            :
            <CollectionList/>
          }
        </div>
      )}
    </PrivatePage>
  );
};
export default HomePage;
