import {IonIcon} from '@ionic/react';
import {closeCircle, star, starHalf, starOutline} from 'ionicons/icons';
import {RatingProps} from './types';

const MWRatingFull = ({...props}: RatingProps) => {
  const rating = props.rating || 0;
  const roundedRating = Math.round(rating / 0.5) * 0.5;
  const isHalfed = !Number.isSafeInteger(roundedRating);
  const endStar = Math.round(roundedRating);
  const hideValue = props.hideValue;
  const size = props.size || "sm";

  const starClick = (rating: number) => {
    if (props.onChange) {
      props.onChange(rating);
    }
  }

  return (
    <div
      className={`flex flex-row items-center rating-full size-${size} ${props.className || ''
      }`}
    >


      {/* Filled Stars */}
      {new Array(endStar).fill(0).map((count: any, i) => {
        if (isHalfed && i + 1 === endStar) {
          return (
            <button key={i} onClick={() => starClick(i + 1)} className="flex items-center">
              <IonIcon

                className={`${size !== 'sm' ? 'text-xl' : ''} text-yellow-600 star-${count}`}
                icon={starHalf}
              />
            </button>
          );
        } else {
          return <button key={i} onClick={() => starClick(i + 1)} className="flex items-center">
            <IonIcon className={` ${size !== 'sm' ? 'text-xl' : ''} text-yellow-600`} icon={star}/>
          </button>
        }
      })}

      {/* // Unfilled Stars */}
      {new Array(5 - endStar).fill(0).map((count: any, i) => {
        return (
          <button key={i} onClick={() => starClick(i + endStar + 1)} className="flex items-center">
            <IonIcon

              icon={starOutline}
              className={`text-gray-400 ${size !== 'sm' ? 'text-xl' : ''} star-${count}`}
            />
          </button>
        );
      })}

      {/* Show or Hide the Value */}
      {
        !hideValue && (
          <span
            aria-label='Product Rating'
            className='ml-1 text-xs text-gray-300'
          >
            {rating}
          </span>
        )
      }
      {/* Button to Clear Rating - if allowed  */}
      {props.onChange && rating > 0 && <button onClick={() => starClick(0)} className="flex ml-2 items-center">
        <IonIcon icon={closeCircle} className={`text-gray-400 ${size !== 'sm' ? 'text-xl' : ''} `}/>
      </button>}
    </div>
  );
};

export default MWRatingFull;
