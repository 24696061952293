import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import DeliveryButton from '../Delivery/DeliveryButton';
import {UserStore} from '../User/UserStore';
import CartItems from './CartItems';
import {CartStore, getCartSubTotal} from './CartStore';
import CheckoutButton from './CheckoutButton';
import CartItem from "./CartItemClass";

const CartModal = ({...props}) => {
  const subtotal = getCartSubTotal();
  const userState = UserStore.useState((s) => s, []);
  const [showCheckout, setShowCheckout] = useState(false);
  const cartState = CartStore.useState((s) => s);
  const [notWatchedItems, setNotWatchedItems] = useState(true);

  useEffect(() => {
    let checkForOnlyWatchedItems = cartState.items.filter((item: CartItem) => {
      return !item.watching;
    });

    if (checkForOnlyWatchedItems.length > 0) {
      setNotWatchedItems(false);
    } else {
      setNotWatchedItems(true);
    }

  }, [cartState, notWatchedItems, userState]);

  return (
    <IonPage className="h-full md:rounded-lg md:overflow-hidden md:h-37.5rem" aria-label='Shopping Cart Modal'
             aria-haspopup='true'>
      <IonHeader>
        <IonToolbar style={{'--border-color': 'transparent'}}>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close Shopping Cart Window'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>

          </IonButtons>

          <div className='flex items-center justify-center flex-grow flex-shrink w-full pr-10'>
            <DeliveryButton/>
          </div>

        </IonToolbar>
        <div
          className='sticky top-0 z-50 flex flex-col items-center justify-center px-4 py-2 bg-white shadow-md'
        >
          <CheckoutButton
            disabled={!userState.hubs?.length || notWatchedItems}
            subtotal={subtotal}
            onClick={() => {
              props.onDismiss();
              setShowCheckout(true)
            }}
          />
        </div>

      </IonHeader>

      <IonContent
        className='min-h-full mb-20 overflow-y-auto bg-green-500'
        style={{height: 'calc(100% - 130px)'}}
      >

        <CartItems showCheckout={false} dismiss={props.onDismiss} item={undefined}/>
      </IonContent>

      <IFrameModalController
        title={'Checkout'}
        url={`${process.env.REACT_APP_CHECKOUT_URL}`}
        open={showCheckout}
        hideHeader={true}
        showCheckout={(val: boolean) => setShowCheckout(val)}
        router={props.router}
        showLoading={true}
      />
    </IonPage>
  );
};

export default CartModal;
