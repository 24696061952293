import {GET, PUT} from "../../Api/Api";
import HubModel from "./hub.model";

export class HubAPI {
  static async getHubDetails(id: any) {
    const call = await GET(`/hubs/${id}/details`);
    return new HubModel(call.payload);
  }

  static async getHubs() {
    const call = await GET('/hubs');
    return call.payload.map((i: any) => new HubModel(i));
  }

  static async switchHub(id: string) {
    const res = await PUT(`/hubs/${id}/switch`);
    return res.success;
  };
}


