import {useEffect, useState} from 'react';
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonLoading,
  useIonModal,
} from '@ionic/react';
import {ellipse} from 'ionicons/icons';
import dayjs from 'dayjs';
import {InboxListProps, InboxThreadType} from './types';
import MWAvatar from '../../Components/MWAvatar/MWAvatar';
import {UseQuery} from '../../Helpers/Hooks/UseQuery';
import {
  InboxStore,
  loadInbox,
  loadThread,
  fetchHeaders,
} from './InboxStore';
import Pagination from '../../Components/Pagination/Pagination';
import MessageModal from './MessageModal';
import {isLoggedIn} from '../User/UserStore';

const InboxList = ({...props}: InboxListProps) => {
  let urlQuery = UseQuery();
  const inboxStore = InboxStore.useState((s) => s);
  const [showLoading, dismissLoading] = useIonLoading();
  const [paginationPage, setPaginationPage] = useState(1);
  const [isMount, setIsMount] = useState(true);
  const [presentInboxThreadModal, dismissInboxThreadModal] = useIonModal(MessageModal, {
    onDismiss: () => {
      dismissInboxThreadModal();
    }
  });

  useEffect(() => {
    if (inboxStore.loadingInbox || inboxStore.loadingThread) {
      showLoading('Loading...', 2000).then();
    } else {
      setTimeout(() => {
        dismissLoading().then();
      }, 500);
    }
  }, [
    inboxStore.loadingInbox,
    inboxStore.loadingThread,
    dismissLoading,
    showLoading,
  ]);

  /**
   * Use Effect to Monitor activeThread
   * If it is an active thread and no modal, show the modal
   */
  useEffect(() => {
    if (inboxStore.activeThread) {
      presentInboxThreadModal();
    } else {
      setIsMount(true);
    }
  }, [inboxStore.activeThread, presentInboxThreadModal]);

  /**
   * Use Effect the first time this component mounts
   * to load the users Inbox from the InboxStore
   */
  useEffect(() => {
    if (isLoggedIn()) {
      if (isMount) {
        setIsMount(false);
        const page: any = urlQuery.get('page') || 1;
        const startingPage = parseInt(page);
        setPaginationPage(startingPage);
      }

      const perPage = 5;
      loadInbox(paginationPage, perPage).then();
      fetchHeaders(paginationPage, perPage).then();
    }
  }, [urlQuery, isMount, setIsMount, setPaginationPage, paginationPage]);

  let totalPages = Math.ceil(
    inboxStore.headers['MW-TOTAL-RESULTS'] /
    inboxStore.headers['MW-ROWS-PER-PAGE']
  );

  return (
    <IonList className={`${props.className || ''} md:rounded-md`}>
      {inboxStore.threads?.length === 0 && (
        <div className='py-10 empty-block'>
          <h1>You have no new messages</h1>
        </div>
      )}
      {inboxStore.threads?.map((thread: InboxThreadType, index: number) => {
        return (
          <IonItem
            key={index}
            button
            onClick={() => {
              loadThread(thread.id).then();
            }}
          >
            <div slot='start'>
              <MWAvatar
                hideLabel={true}
                label={thread.vendor?.name || ''}
                image={thread.vendor?.avatar}
                size='sm'
              />
            </div>
            <IonLabel className='py-1 ion-text-wrap'>
              <div
                className={`flex text-base items-center p-0 m-0 space-x-4 leading-tight ${
                  !thread.isRead ? 'font-bold' : ''
                }`}
              >
                {thread.vendor?.name}
                {!thread.isRead && (
                  <IonIcon
                    icon={ellipse}
                    className='ml-1 text-sm text-blue-500'
                  />
                )}
              </div>
              <p className='text-xs leading-tight text-gray-500'>
                <span className='text-black'>
                  {dayjs(thread.lastMessage.created).fromNow()}
                </span>{' '}
                - {`${thread.lastMessage.message.substr(0, 100).replace(/<\/?[^>]+(>|$)/g, "")}...`} {/* https://stackoverflow.com/a/5002161 */}
              </p>
            </IonLabel>
          </IonItem>
        );
      })}

      {totalPages > 1 && (
        <Pagination
          current={paginationPage}
          totalPages={totalPages}
          link='/inbox?page={page}'
          onPageChange={(page) => {
            setPaginationPage(page);
          }}
        />
      )}
    </IonList>
  );
};
export default InboxList;
