import {useIonRouter} from '@ionic/react';
import Product from '../ProductClass';
import ProductTile from "../../../NewDomains/Product/Components/ProductTile";

const ProductCardWide = (props: any) => {
  const product: Product = props.product instanceof Product ? props.product : new Product(props.product || {});
  const router = useIonRouter();

  return (
    <ProductTile
      type={''}
      id={product.id}
      title={
        product.vendor?.name ||
        product.title
      }
      subtitle={(product.originCounty && product.originState) ? product.originCounty + ' County, ' + product.originState : ''}
      avatar={
        product.vendor?.avatar ||
        product?.images[0]
      }
      avatarLabel={product.vendor?.name}
      likeCount={0}
      commentCount={0}
      textImage={product.images[0]}
      text={product.title}
      product={product}
      vendor={product.vendor}
      customerliked={product.isFavorite}
      likecount={0}
      router={router}
    />
  );
};

export default ProductCardWide;
