import {IonButton, IonIcon} from '@ionic/react';
import {cartOutline} from 'ionicons/icons';

const EmptyCartMessage = ({...props}) => {
  return (
    <>
      <div className='mt-10 text-center bg-transparent '>
        <IonIcon
          className='not-italic text-gray-300'
          style={{lineHeight: '100%', fontSize: '100px'}}
          icon={cartOutline}
        />
        <div className='text-lg font-bold'>Your cart is Empty</div>
        <IonButton
          onClick={() => {
            if (props.dismiss) {
              props.dismiss();
            }
          }}
          className='text-sm md:hidden'
          fill='clear'
        >
          Continue Shopping
        </IonButton>
      </div>
    </>
  );
};

export default EmptyCartMessage;
