import {IonIcon, isPlatform} from '@ionic/react'
import {shareOutline, shareSocialOutline} from 'ionicons/icons'

const ShareIcon = ({...props}) => {
  if (isPlatform('ios')) {
    return (
      <IonIcon className={`${props.className || ''}`} icon={shareOutline}/>
    )
  } else {
    return (
      <IonIcon
        className={`${props.className || ''}`}
        icon={shareSocialOutline}
      />
    )
  }
}
export default ShareIcon
