import {useIonRouter} from "@ionic/react";
import {PaginationProps} from "./types";
import {useMemo} from "react";

const DOTS = '...';

export default function Pagination({...props}: PaginationProps) {
  const totalPages = props.totalPages;
  const currentPage = props.current;
  const router = useIonRouter();

  const paginationRange = usePagination({totalPages, current: currentPage});

  const handleChangePage = (page: number) => {
    if (props.current === page) {
      return;
    }

    const link: string = props.link.replace(/\{page}/gi, `${page}`);

    router.push(link);
    window.scrollTo(0, 0);

    if (props.onPageChange) {
      props.onPageChange(page);
    }
  };

  return (
    <nav
      aria-label='More Pages'
      className="max-w-screen-sm md:mx-auto mx-2 border border-gray-200 my-6 p-1 overflow-y-auto rounded-md"
    >
      <ul className='flex flex-nowrap items-center justify-around w-full'>
        {paginationRange.map((pageNumber, i) => {
            if (pageNumber === DOTS) {
              return <li key={`dots-${i}`}>&#8230;</li>;
            } else {
              return <PaginationItem key={`numbers-${i}`} number={pageNumber as number} isCurrent={currentPage === pageNumber}
                                     handlePageChange={handleChangePage}/>;
            }
          }
        )}
      </ul>
    </nav>
  );
}

const PaginationItem = ({
                          number, isCurrent, handlePageChange
                        }:
                          {
                            number: number, isCurrent: boolean, handlePageChange:
                              (page: number) => void
                          }) => {
  return (
    <li key={`page-${number}`}>
      <button
        className={`
              flex-grow flex-shrink text-center w-12 p-2 rounded-md
               border-2
               border-transparent
               hover:border-green-600
              ${isCurrent ? 'active bg-green-600 text-white' : ''}
            `}
        onClick={() => {
          handlePageChange(number)
        }}
      >
        {number}
      </button>
    </li>
  )
}

const range = (start: number, end: number) => {
  let length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({length}, (_, idx) => idx + start);
};
const usePagination = ({
                         totalPages,
                         current,
                       }: { totalPages: number, current: number }) => {
  const siblingCount = 1;
  return useMemo(() => {


    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      current + siblingCount,
      totalPages
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {

      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPages - rightItemCount + 1,
        totalPages
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  }, [totalPages, siblingCount, current]);
};

