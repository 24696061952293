import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useEffect} from 'react';
import {markAsRead, postInboxThread} from '../../Api/api.inbox';

import ChatBubble from '../../Components/ChatBubble/ChatBubble';
import CommentReply from '../../Components/CommentReply/CommentReply';
import {InboxMessageType} from './types';
import {closeThread, InboxStore, loadThread} from './InboxStore';
import {UserStore} from "../User/UserStore";
import {useStoreState} from 'pullstate';

const MessageModal = ({...props}) => {
  const inboxState = InboxStore.useState((s) => s);
  const userState = useStoreState(UserStore);

  const publish = async (comment: string, id: any) => {
    try {
      postInboxThread(id, comment).then();
      loadThread(id).then();
    } catch (e) {
      console.error(e);
    }
  };

  const handleThreadClose = () => {
    props.onDismiss();
    closeThread();
  };

  useEffect(() => {
    if (
      inboxState.activeThread?.thread.lastMessage.sender === 'U' &&
      !inboxState.activeThread.thread.isRead
    ) {
      markAsRead(inboxState.activeThread?.thread.id).then();
    }
  }, [inboxState.activeThread]);

  return (
    <IonPage className="h-full overflow-hidden md:rounded-lg md:h-37.5rem">
      <IonHeader className='shadow-md'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              slot='start'
              onClick={handleThreadClose}
            >
              <IonIcon icon={close} slot='icon-only'/>
            </IonButton>
          </IonButtons>

          <IonTitle>
            {!inboxState.activeThread
              ? 'Loading...'
              : `${inboxState.activeThread?.thread?.vendor?.name}`}
          </IonTitle>
        </IonToolbar>

        <div className='px-4 py-4 bg-white'>
          <CommentReply
            postButtonTitle={'Send'}
            placeholder={`Reply to ${inboxState.activeThread?.thread?.vendor?.name}`}
            onPublish={(comment: string) => {
              publish(comment, inboxState.activeThread?.thread?.id).then();
            }}
          />
        </div>
      </IonHeader>

      <IonContent className='w-full' style={{height: `calc(100% - 130px)`}}>
        {inboxState.activeThread !== undefined && (
          <div className='p-4 space-y-4 chat-window '>
            {inboxState.activeThread.messages.map(
              (message: InboxMessageType, index: number) => {
                return (
                  <ChatBubble
                    key={index}
                    direction={message.sender === 'C' ? 'from' : 'to'}
                    name={message.sender === 'C' ? `${userState.firstName} ${userState.lastName}` : `${inboxState.activeThread?.thread?.vendor?.name}`}
                    date={message.created}
                    message={message.message}
                  />
                );
              }
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
export default MessageModal;
