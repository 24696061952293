import {useEffect, useState} from 'react';
import {
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import Vendor from './VendorClass';
import {getVendors} from '../../Api/api.vendor';
import {setVendor} from './VendorStore';
import {VendorCard} from './VendorCard/VendorCard';
import {useLocation} from 'react-router-dom';
import {UserStore} from "../User/UserStore";
import PrivatePage from '../../Components/Page/PrivatePage';
import {useStoreState} from 'pullstate';
import {Helmet} from 'react-helmet';
import AppHeader from '../../Components/Header/AppHeader';
import VendorModal from './VendorModal';

const VendorsPage = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [sort, setSort] = useState('az-asc');
  const [vendors, setVendors] = useState([]);
  const search = useLocation().search;
  const userStore = useStoreState(UserStore);
  const router = useIonRouter();

  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();
    },
    router: router,
  });

  useEffect(() => {
    if (userStore.hubs !== undefined && userStore.hubs.length > 0) {
      if (!isMounted) {
        setIsMounted(true);

        const id: number | undefined = parseInt(
          `${new URLSearchParams(search).get('id')}`
        );

        if (id) {
          const vd = {
            id: id
          }

          setVendor(vd as Vendor);
          presentVendorModal();
        }
      }
    }
  }, [isMounted, presentVendorModal, search, userStore.hubs]);

  const openVendor = async (vendor: Vendor) => {
    setVendor(vendor);
    presentVendorModal();
  };

  useEffect(() => {
    if (userStore.hubs !== undefined && userStore.hubs.length > 0) {
      setLoaded(false);

      getVendors(sort).then((vendors: any) => {
        setLoaded(true);
        setVendors(vendors);
      });
    }
  }, [sort, userStore.hubs]);

  return (
    <PrivatePage>
      <Helmet>
        <title>View All Vendors | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com/vendors`}/>
      </Helmet>

      <AppHeader/>

      <main className='max-w-screen-xl p-4 mx-auto md:max-w-screen-md'>
        <div className='flex justify-between'>
          <h1 className='text-base w-40 leading-4 text-gray-900 font-bold self-center md:w-auto'>
            Browse Local Farms & Artisans
          </h1>

          <IonSelect
            value={sort}
            className='px-2 py-2.5 text-sm font-semibold text-green-600 border bg-white border-gray-600 rounded-md focus:ring-2 ring-green-600 h-11 line-clamp-1 border-opacity-30 w-32'
            interface='action-sheet'
            placeholder='Sort A-Z'
            onIonChange={(e) => setSort(e.detail.value || 'az-asc')}
            disabled={!loaded}
          >
            <IonSelectOption value='az-asc'>Sort A-Z</IonSelectOption>
            <IonSelectOption value='popular'>Sort Popular</IonSelectOption>
            <IonSelectOption value='desc'>Sort Z-A</IonSelectOption>
          </IonSelect>
        </div>

        <section className='mt-4 flex flex-col gap-y-3 md:flex-row md:flex-wrap md:justify-between'>
          {!loaded &&
            [1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <div
                  key={i}
                  className='flex w-full bg-white shadow-lg rounded-lg h-40 md:max-w-22.5rem'
                >
                  <IonSkeletonText
                    animated
                    class='w-1/2 h-full mt-0 rounded-tr-none rounded-br-none'
                  />

                  <div className='w-1/2 flex flex-col justify-center px-2'>
                    <IonSkeletonText
                      animated
                      class='w-full'
                      style={{height: '16px'}}
                    />
                    <IonSkeletonText
                      animated
                      class='w-full'
                      style={{height: '16px'}}
                    />

                    <IonSkeletonText
                      animated
                      class='w-full mt-5'
                      style={{height: '20px'}}
                    />
                  </div>
                </div>
              );
            })}

          {loaded &&
            vendors.map((vendor: Vendor, index: number) => {
              return (
                <VendorCard
                  vendor={vendor}
                  key={index}
                  onClick={() => {
                    openVendor(vendor).then();
                  }}
                  name={vendor.name} id={vendor.id}/>
              );
            })
          }
        </section>
      </main>
    </PrivatePage>
  );
};
export default VendorsPage;
