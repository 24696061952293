export interface DeliveryAddressType {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: number;
  instructions?: string;
}

export default class DeliveryAddress {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: number;
  instructions?: string;

  constructor(starter: any = {}) {
    this.address = starter.address;
    this.address2 = starter.address2;
    this.city = starter.city;
    this.state = starter.state;
    this.zipCode = starter.zipCode;
    this.instructions = starter.instructions;
  }
}
