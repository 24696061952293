import {create} from 'zustand';
import HubModel from "./Hub/hub.model";
import BannerModel from "./Banner/banner.model";
import BundleModel from "./Bundle/bundle.model";
// import { SubCollectionClass, CollectionClass } from './Collection/classes';
// import { ProductClass } from './Product/ProductClass';
// import Vendor from './Vendor/VendorClass';
// import { DeliveryAddressType } from './Delivery/DeliveryAddress';
// import { BundleClass } from './Bundle/classes';
// import { HubType } from './Hubs/types';
// import { FeedItemClass } from './Post/types';
// import { FriendsFeedType } from './Friends/types';
// import { CartItemClass } from './Cart/classes';
// import Order from './Orders/OrderClass';

export enum LoadingState {
  UNINITIALIZED = 0,
  LOADING = 1,
  LOADED = 2,
  FAILED = 3,
}

// export enum AuthState {
//   NOT_ONBOARDED = 0,
//   GUEST = 1,
//   LOGGED_IN = 2,
// }

export interface State {
  hubs?: Array<HubModel>;
  banners?: Array<BannerModel>;
  bannersLoadingState: LoadingState;
  bundles?: Array<BundleModel>;
  bundlesLoadingState: LoadingState;
  subscribedBundles?: Array<BundleModel>;
  subscribedBundlesLoadingState: LoadingState;

  searchQuery: string;
  searchMode: string;

  // View temp states
  activeBundle?: BundleModel;

  currentHub: () => HubModel | undefined;

  update: (newState: State | Partial<State>) => void,
}

export const useAppStore = create<State>((set, get) => ({
  hubs: undefined,

  banners: undefined,
  bannersLoadingState: LoadingState.UNINITIALIZED,
  bundles: undefined,
  bundlesLoadingState: LoadingState.UNINITIALIZED,

  activeBundle: undefined,

  subscribedBundles: undefined,
  subscribedBundlesLoadingState: LoadingState.UNINITIALIZED,

  searchMode: 'items',
  searchQuery: '',

// @ts-ignore
  currentHub: () => ((get()?.hubs?.length ?? 0) > 0 ? get().hubs[0] : undefined),
  update: (newState: State | Partial<State>) => {
    set((state) => ({...state, ...newState}));
  },
}));


// export interface AppState {
//   hubs: Array<Hub> | null;
//
//   hubId: number | null;
//   customerId: number | null;
//
//   cartItems: Array<CartItemClass> | null;
//   cartItemsLoadingState: LoadingState;
//
//   orders: Array<Order> | null;
//   ordersLoadingState: LoadingState;
//
//   collections: Array<CollectionClass> | null;
//   collectionsLoadingState: LoadingState;
//
//   subscriptionEnabled: boolean;
//   subscriptionState: number | null;
//   subscriptionStateLoadingState: LoadingState;
//
//   activeCollection: CollectionClass | null;
//   activeSubCollection: SubCollectionClass | null;
//   specialCollection: string | null;
//
//   favoriteProducts: Array<ProductClass> | null;
//   favoriteProductsLoadingState: LoadingState;
//
//   subscribedProducts: Array<CartItemClass> | null;
//   subscribedProductsLoadingState: LoadingState;
//
//   subscribedBundles: Array<BundleClass> | null;
//   subscribedBundlesLoadingState: LoadingState;
//
//   autoOrderTip: number | null;
//   autoOrderTipLoadingState: LoadingState;
//
//   favoriteVendors: Array<Vendor> | null;
//   favoriteVendorsLoadingState: LoadingState;
//
//   bundles: Array<BundleClass> | null;
//   bundlesLoadingState: LoadingState;
//
//   feedItems: Array<FeedItemClass> | null;
//   feedItemsLoadingState: LoadingState;
//
//
//   avatar: string | null;
//   dataSource: string | null;
//   deliveryAddress: DeliveryAddressType | null;
//   email: string | null;
//   firstName: string | null;
//   handle: string | null;
//   image: string | null;
//   isGlutenFree: boolean;
//   isVegan: boolean;
//   isWagonPassActive: boolean;
//   lastName: string | null;
//   lastOrderDate: string | null;
//   app: boolean;
//   os: string | null;
//   paymentInfo: string | null;
//   paymentType: string | null;
//   playerId: string | null;
//   trk: string | null;
//   zipCode: string | null;
//   utmSource: string | null;
//   utmCampaign: string | null;
//   profileLoadingState: LoadingState;
//   authLoadingState: LoadingState;
//
//   authState: () => AuthState;
//   currentHub: () => HubType | null;
// }

// const initialState = {
//   cartItems: null,
//   cartItemsLoadingState: LoadingState.UNINITIALIZED,
//   orders: null,
//   ordersLoadingState: LoadingState.UNINITIALIZED,
//   collections: null,
//   collectionsLoadingState: LoadingState.UNINITIALIZED,
//   subscriptionEnabled: false,
//   subscriptionState: null,
//   subscriptionStateLoadingState: LoadingState.UNINITIALIZED,
//   activeCollection: null,
//   activeSubCollection: null,
//   specialCollection: null,
//   favoriteProducts: null,
//   favoriteProductsLoadingState: LoadingState.UNINITIALIZED,
//   subscribedProducts: null,
//   subscribedProductsLoadingState: LoadingState.UNINITIALIZED,
//   subscribedBundles: null,
//   subscribedBundlesLoadingState: LoadingState.UNINITIALIZED,
//   autoOrderTip: null,
//   autoOrderTipLoadingState: LoadingState.UNINITIALIZED,
//   favoriteVendors: null,
//   favoriteVendorsLoadingState: LoadingState.UNINITIALIZED,
//   feedItems: null,
//   feedItemsLoadingState: LoadingState.UNINITIALIZED,
//   friendsFeedItems: null,
//   friendsFeedItemsLoadingState: LoadingState.UNINITIALIZED,
//   friendRequests: null,
//   friendRequestsLoadingState: LoadingState.UNINITIALIZED,
//   bundles: null,
//   bundlesLoadingState: LoadingState.UNINITIALIZED,
//   bundleLoadingState: LoadingState.UNINITIALIZED,
//   customerId: null,
//   hubId: null,
//   email: null,
//   firstName: null,
//   lastName: null,
//   handle: null,
//   avatar: null,
//   dataSource: null,
//   app: false,
//   os: null,
//   deliveryAddress: null,
//   image: null,
//   isGlutenFree: false,
//   isVegan: false,
//   isWagonPassActive: false,
//   lastOrderDate: null,
//   playerId: null,
//   zipCode: null,
//   utmSource: null,
//   utmCampaign: null,
//   hubs: null,
//   paymentInfo: null,
//   paymentType: null,
//   trk: null,
//   profileLoadingState: LoadingState.UNINITIALIZED,
//   authLoadingState: LoadingState.UNINITIALIZED,
//   shouldReset: false,
// };

// export const useAppStore = create<AppState>((set, get) => ({
//   ...initialState,
//   authState: () => {
//     if (get().customerId === null) {
//       return AuthState.NOT_ONBOARDED;
//     } else if (!get().customerId?.toString()?.startsWith('-')) {
//       return AuthState.LOGGED_IN;
//     } else {
//       return AuthState.GUEST;
//     }
//   },
//   // @ts-ignore
//   currentHub: () => (get().hubs !== null ? get().hubs[0] : null),
//   update: (newState: AppState | Partial<AppState>) => {
//     set(() => newState);
//   },
//   reset: (newValues?: AppState | Partial<AppState>) => {
//     set({ ...initialState, ...newValues });
//   },
// }));
