import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import useEventListener from '@use-it/event-listener';
import {useStoreState} from 'pullstate';
import {useEffect, useState} from 'react';
import {loginGuest} from '../../Api/api.account';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import UseBranding from '../../Helpers/Hooks/UseBranding';
import {UserStore} from '../User/UserStore';
import {GeolocationCoordinatesType, CoordinatesType} from './types';

const Welcome = ({
                   ...props
                 }: {
  changeView: Function;
  onDismiss: Function;
}) => {
  const [localZipCode, setLocalZipcode]: any = useState(null);
  const [showMessage] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const userState = useStoreState(UserStore);
  const [hasHub, setHasHub] = useState(false);
  const [isInApp, setIsInApp] = useState(false);
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  const utmSource = urlQuery.get('utm_source');
  const utmCampaign = urlQuery.get('utm_campaign');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const guestRegister = async (pos: GeolocationCoordinatesType = {}) => {
    setLoading(true);

    if (localZipCode && localZipCode.length === 5) {
      try {
        const res = await loginGuest(localZipCode, null, null, utmSource, utmCampaign);
        props.onDismiss();

        if (!res) {
          showMessage('Sorry, we do not currently support your location.').then();
        }
      } catch (e) {
        console.error('error setting user', e);
        showMessage('Sorry, we do not currently support your location.').then();
      }
    }

    if (pos?.coords) {
      const latLng: CoordinatesType = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
      };

      try {
        const res = await loginGuest(null, latLng, null, utmSource, utmCampaign);
        props.onDismiss();

        if (!res) {
          showMessage('Sorry, we do not currently support your location.').then();
        }
      } catch (e) {
        showMessage(
          'There was an issue getting your location. Please try again later.'
        ).then();
      }
    }

    setLoading(false);
  };

  const handleRegisterDisplay = () => {
    let shouldShow = false;

    if (hasHub) {
      shouldShow = true;
    }

    return shouldShow;
  };

  const handleGuestDisplay = () => {
    let shouldShow = false;

    if (!hasHub || !userState.isGuest) {
      shouldShow = true;
    }

    return shouldShow;
  };

  const handleBrowserLocationRequest = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((pos) => {
        guestRegister(pos).then();
      });
    } else {
      showMessage('Sorry, your browser does not support Geolocation.').then();
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && localZipCode) {
      guestRegister().then();
    }
  };

  useEffect(() => {
    if (userState && userState?.hubs && userState.hubs.length > 0) {
      setHasHub(true);
    }
  }, [userState]);

  useEventListener('keydown', handleKeyPress);

  useEffect(() => {
    const urlQuery = new URLSearchParams(new URL(window.location.href).search);
    setIsInApp(urlQuery.get('app') === 'true');
  }, [setIsInApp]);

  return (
    <IonPage className='h-full'>
      <IonHeader style={{'--border-width': 0}}>
        <IonToolbar style={{'--border-width': 0}}>
          <IonButtons slot='start'>
            {userState.isGuest && (
              <IonButton
                onClick={() => {
                  props.onDismiss();
                }}
              >
                Close
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>Welcome</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <main className='bg-white '>
          <div className='flex items-center justify-center px-6 pb-6 mt-6 space-x-3 bg-white'>
            <img
              className='max-w-none'
              src={UseBranding()}
              alt='Market Wagon Logo'
              width='105px'
              height='105px'
            />
            <div className='md:w-5/12'>
              <h1 className='text-lg font-extrabold text-center leading-tight mb-1 md:text-2xl'>
                Your Online Farmers Market that Delivers
              </h1>
              <p className='text-sm leading-snug text-center text-green-700'>
                Locally grown, no minimum orders. Buy what, and when you want.
              </p>
            </div>
          </div>

          <div className='bg-gray-100'>
            <div className='grid max-w-xs grid-cols-1 gap-2 px-4 py-6 mx-auto mt-4 mb-12'>
              {/* Create */}
              {handleRegisterDisplay() && (
                <>
                  <div
                    className='relative flex items-center justify-center max-w-xs mx-auto mb-2 text-center border-b border-gray-300 w-60'>
                    <span className='absolute px-4 text-sm text-gray-500 bg-gray-100 -top-3'>
                      New Customers
                    </span>
                  </div>

                  <IonButton
                    tabIndex={1}
                    className='shadow-lg focus:ring-2'
                    size='default'
                    onClick={() => {
                      props.changeView('register');
                    }}
                    expand='block'
                  >
                    Create Free Account
                  </IonButton>
                </>
              )}

              {/* Sign in */}
              <div
                className='relative flex items-center justify-center max-w-xs mx-auto mt-6 mb-2 text-center border-b border-gray-300 w-60'>
                <span className='absolute px-4 text-sm text-gray-500 bg-gray-100 -top-3'>
                  Existing Customers
                </span>
              </div>

              <IonButton
                tabIndex={2}
                className='text-white shadow-lg'
                onClick={() => {
                  props.changeView('login');
                }}
                style={{'--background': '#000'}}
              >
                Sign in
              </IonButton>

              {/* Guest */}
              {/* {(!userState.isGuest) || (hasHub) &&  */}
              {handleGuestDisplay() && (
                <>
                  <div className='mb-6'>
                    <div
                      className='relative flex items-center justify-center max-w-xs mx-auto mt-6 mb-3 text-center border-b border-gray-300 w-60'>
                      <span className='absolute px-4 text-sm text-gray-600 bg-gray-100 -top-3'>
                        or Shop as Guest
                      </span>
                    </div>
                    <div
                      className='flex flex-row items-center w-full max-w-xs p-1 pl-4 pr-1 mx-auto overflow-hidden bg-white border border-gray-200 shadow-lg rounded-xl bg-white-200 login-form'>
                      <input
                        tabIndex={3}
                        type='tel'
                        placeholder='Zip Code'
                        style={{fontSize: '18px'}}
                        className='flex-grow flex-shrink w-10 h-8 text-xl focus:outline-none group'
                        required
                        value={localZipCode || ''}
                        onChange={(evt: any) => {
                          setLocalZipcode(evt.target.value);
                        }}
                      />

                      <IonButton
                        tabIndex={4}
                        fill='clear'
                        className='h-8 font-semibold bg-gray-100 rounded-lg focus:ring-2 focus:ring-green-400'
                        onClick={() => {
                          guestRegister().then();
                        }}
                        disabled={(localZipCode || '').length !== 5 || loading}
                      >
                        {!loading ? (
                          <div>Browse...</div>
                        ) : (
                          <div>Loading...</div>
                        )}
                      </IonButton>
                    </div>
                    {!isInApp && (
                      <p className='pl-4 pt-1 text-xs text-gray-600'>
                        Or,
                        <button
                          className='ml-1 text-green-600 hover:text-green-500'
                          onClick={handleBrowserLocationRequest}
                        >
                          use current location
                        </button>
                        .
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
        <div className='flex justify-center px-4 mt-10 space-x-3 text-xs flex-fixed legal'>
          <button
            className='underline'
            title='View Terms of Service'
            onClick={() => {
              setShowTermsModal(true);
            }}
          >
            Terms of Service
          </button>

          <button
            className='underline'
            title='View Privacy Policy'
            onClick={() => {
              setShowPrivacyModal(true);
            }}
          >
            Privacy Policy
          </button>
        </div>
      </IonContent>

      <IFrameModalController
        onDismiss={() => {
          setShowTermsModal(false)
        }}
        title="Terms &amp; Conditions"
        url="https://marketwagon.com/pages/conditions-of-use"
        open={showTermsModal}
      />

      <IFrameModalController
        onDismiss={() => {
          setShowPrivacyModal(false)
        }}
        title="Privacy"
        url="https://marketwagon.com/privacy.php"
        open={showPrivacyModal}
      />
    </IonPage>
  );
};
export default Welcome;
