import APIError from './APIError';
import { v4 as uuidv4 } from 'uuid';

type APIResponse = {
  success: boolean;
  payload: any;
  message?: string;
  headers: any;
  status?: number;
  page?: number;
  perPage?: number;
  totalRows?: number;
};

export const BASE_API_URL: string =
  process.env.REACT_APP_MWAPI_URL_LIVE || 'http://localhost:8000/api';

export const GET = async (
  path: string,
  page?: number,
  perPage?: number,
  headers: any = {}
) => {
  if (page !== undefined && perPage !== undefined) {
    headers['MW-CURRENT-PAGE'] = page;
    headers['MW-ROWS-PER-PAGE'] = perPage;
  }
  return call(path, {
    method: 'GET',
    headers,
  });
};

export const POST = async (
  path: string,
  data: any = {},
  headers: any = {}
): Promise<APIResponse> => {
  return call(path, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
};

export const PUT = async (
  path: string,
  data: any = {},
  headers: any = {}
): Promise<APIResponse> => {
  return call(path, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  });
};

export const DELETE = async (
  path: string,
  data: any = {},
  headers: any = {}
): Promise<APIResponse> => {
  return call(path, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(data),
  });
};

const call = async (
  path: string,
  fetchOptions: any = {}
): Promise<APIResponse> => {
  regenerateAnalyticsCookieIfNeeded(path);
  fetchOptions.headers['content-type'] = 'application/json';
  fetchOptions.credentials = 'include';
  let res = undefined;
  let payload = undefined;
  try {
    res = await fetch(`${BASE_API_URL}${path}`, fetchOptions);
    payload = await res.json();
  } catch (e: any) {
    console.error(`API error: `, e.message || e);
    throw new APIError(e.message, undefined);
  }

  if (res.status >= 200 && res.status < 300) {
    let page = undefined;
    let perPage = undefined;
    let totalRows = undefined;

    if (res.headers.get('MW-CURRENT-PAGE') !== null) {
      // @ts-ignore
      page = parseInt(res.headers.get('MW-CURRENT-PAGE'));
      if (isNaN(page)) {
        page = undefined;
      }
    }
    if (res.headers.get('MW-ROWS-PER-PAGE') !== null) {
      // @ts-ignore
      perPage = parseInt(res.headers.get('MW-ROWS-PER-PAGE'));
      if (isNaN(perPage)) {
        perPage = undefined;
      }
    }
    if (res.headers.get('MW-TOTAL-RESULTS') !== null) {
      // @ts-ignore
      totalRows = parseInt(res.headers.get('MW-TOTAL-RESULTS'));
      if (isNaN(totalRows)) {
        totalRows = undefined;
      }
    }

    return {
      success: true,
      message: undefined,
      payload,
      headers: res.headers,
      status: res.status,
      page,
      perPage,
      totalRows,
    };
  } else {
    const message =
      payload instanceof Array
        ? payload[0]
        : `API responded with ${res.status}`;
    console.error(`API error ${fetchOptions.method} ${path}:`, message);
    throw new APIError(message, res.status);
  }
};

const getCookie = (name: string): string | null => {
  const cookie = ('; ' + document.cookie)
    .split(`; ${name}=`)
    .pop()
    ?.split(';')[0];
  if (!cookie || cookie === '') {
    return null;
  }
  return cookie;
};

const createAnalyticsCookie = (name: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 3 * 60 * 60 * 1000); // 3 hours
  const domain =
    process.env.REACT_APP_ENV === 'local' ? 'localhost' : 'marketwagon.com';
  document.cookie = `${name}=${uuidv4()};Expires=${d.toUTCString()};Domain=${domain};Path=/;Secure;SameSite=Lax`;
};

const deleteAnalyticsCookie = (name: string) => {
  const d = new Date(0);
  const domain =
    process.env.REACT_APP_ENV === 'local' ? 'localhost' : 'marketwagon.com';
  document.cookie = `${name}=DELETED;Expires=${d.toUTCString()};Domain=${domain};Path=/;Secure;SameSite=Lax`;
};

const regenerateAnalyticsCookieIfNeeded = (path: string) => {
  let name = 'mw_analytics_session_uuid';
  name = process.env.REACT_APP_ENV === 'staging' ? 'staging_' + name : name;
  if (!getCookie(name) && path !== '/auth/logout') {
    createAnalyticsCookie(name);
  } else if (getCookie(name) && path === '/auth/logout') {
    deleteAnalyticsCookie(name);
  }
};
