import {LoadingState, useAppStore} from '../store';
import {useEffect, useState} from "react";
import BundleModel from "./bundle.model";
import {BundleApi} from "./bundle.api";
import {useStoreState} from "pullstate";
import {UserStore} from "../../Domains/User/UserStore";

export const useListBundles = (): [BundleModel[] | undefined, LoadingState] => {
  const userState = useStoreState(UserStore);
  const bundles = useAppStore((s) => s.bundles);
  const loadingState = useAppStore((s) => s.bundlesLoadingState);
  const update = useAppStore((s) => s.update);

  useEffect(() => {
    update({bundlesLoadingState: LoadingState.UNINITIALIZED, bundles: undefined});
  }, [userState.hubs, update]);

  useEffect(() => {
    let ignore = false;

    async function startFetching() {
      try {
        update({bundlesLoadingState: LoadingState.LOADING});
        const newBundles = await BundleApi.getBundles();
        update({bundlesLoadingState: LoadingState.LOADED, bundles: newBundles});
      } catch (_e) {
        update({bundlesLoadingState: LoadingState.FAILED});
      }
    }

    if ((loadingState === LoadingState.UNINITIALIZED || loadingState === LoadingState.FAILED)
      && (userState.hubs?.length ?? 0) > 0 && !ignore) {
      startFetching().then();
    }

    return () => {
      ignore = true;
    };
  }, [userState.hubs, loadingState, update]);

  return [bundles, loadingState];
};

export const useGetBundle = (id: string): [BundleModel | undefined, LoadingState] => {
  const [bundle, setBundle] =
    useState<BundleModel | undefined>(undefined);
  const [loadingState, setLoadingState] =
    useState(LoadingState.UNINITIALIZED);

  useEffect(() => {
    let ignore = false;

    async function startFetching() {
      try {
        setLoadingState(LoadingState.LOADING);
        const bundle = await BundleApi.getBundle(id);
        setLoadingState(LoadingState.LOADED);
        if (!ignore) {
          setBundle(bundle);
        }
      } catch (_e) {
        setLoadingState(LoadingState.FAILED);
      }
    }

    startFetching().then();
    return () => {
      ignore = true;
    };
  }, [id]);

  return [bundle, loadingState];
};


export const subscribeToBundle = async (id: string, interval: number = 1) => {
  return await BundleApi.subscribeToBundle(id, interval);
}

export const unsubscribeFromBundle = async (id: string) => {
  return await BundleApi.unsubscribeFromBundle(id);
}

export const useListSubscribedBundles = (): [BundleModel[] | undefined, LoadingState] => {
  const userState = useStoreState(UserStore);
  const subscribedBundles = useAppStore((s) => s.subscribedBundles);
  const loadingState = useAppStore((s) => s.subscribedBundlesLoadingState);
  const update = useAppStore((s) => s.update);

  useEffect(() => {
    update({subscribedBundlesLoadingState: LoadingState.UNINITIALIZED, subscribedBundles: undefined});
  }, [userState.hubs, update]);

  useEffect(() => {
    let ignore = false;

    async function startFetching() {
      try {
        update({subscribedBundlesLoadingState: LoadingState.LOADING});
        const newBundles = await BundleApi.getSubscribedBundles();
        update({subscribedBundlesLoadingState: LoadingState.LOADED, subscribedBundles: newBundles});
      } catch (_e) {
        update({subscribedBundlesLoadingState: LoadingState.FAILED});
      }
    }

    if ((loadingState === LoadingState.UNINITIALIZED || loadingState === LoadingState.FAILED)
      && (userState.hubs?.length ?? 0) > 0 && !ignore) {
      startFetching().then();
    }

    return () => {
      ignore = true;
    };
  }, [userState.hubs, loadingState, update]);

  return [subscribedBundles, loadingState];
};
