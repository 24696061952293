import {IonSlide, IonSlides} from '@ionic/react';
import {PhotoSlidesProps} from './types';

const PhotoSlides = ({...props}: PhotoSlidesProps) => {
  return (
    <div
      aria-label={props['aria-label'] || 'Photo Slider'}
      className={`photo-slides ${props.className} bg-black`}
      style={{...(props.style || {})}}
    >
      <IonSlides
        pager={true}
        color='white'
        style={{
          '--bullet-background-active': '#FFF',
          ...(props.slidesStyle || {}),
        }}
      >
        {props.images.map((image: string, index: number) => {
          return (
            <IonSlide key={index} className={`photo photo-${index}`}>
              <img src={image} alt={`${index + 1}`}/>
            </IonSlide>
          );
        })}
      </IonSlides>
    </div>
  );
};

export default PhotoSlides;
