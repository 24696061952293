import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useState} from 'react';
import {submitSurvey} from '../../Api/api.orders';

const CSATModal = ({
                     ...props
                   }: {
  onDismiss: Function;
  orderId: number;
  question: string;
  questionId: number;
}) => {
  const [CSATScore, setCSATScore] = useState(0);
  const localSubmitSurvey = () => {
    submitSurvey(props.orderId, 'CSAT', CSATScore, props.questionId).then();
    props.onDismiss();
  };
  return (
    <div
      className='fade w-full h-full outline-none overflow-x-hidden overflow-y-auto sm:w-auto sm:h-auto sm:rounded-lg'>
      <div className='relative w-auto h-full pointer-events-none'>
        <div
          className='border-none shadow-lg relative flex flex-col w-full h-full pointer-events-auto
          bg-white bg-clip-padding rounded-md outline-none text-current'>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton
                  aria-label='Close'
                  id='close-button'
                  fill='clear'
                  slot='start'
                  onClick={() => props.onDismiss()}
                >
                  <IonIcon
                    aria-describedby='close-button'
                    icon={close}
                    slot='icon-only'
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className='w-full overflow-visible px-0'>
                Customer Satisfaction Survey
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <div className='modal-body relative pt-4 px-4'>
            <h2 className='text-2xl font-normal leading-normal mt-0 mb-8 text-center'>
              {props.question}
            </h2>

            <fieldset className='sm:flex sm:gap-x-4 sm:justify-center'>
              <div
                className='flex gap-x-2 mb-2 sm:mb-0 sm:flex-col sm:w-24 sm:items-start sm:justify-between sm:gap-y-4'>
                <label htmlFor='CSATstronglyDisagree'>Strongly Disagree</label>

                <input
                  type='radio'
                  name='CSATScore'
                  value={1}
                  id='CSATstronglyDisagree'
                  onChange={(e) => {
                    setCSATScore(parseInt(e.target.value));
                  }}
                />
              </div>

              <div
                className='flex gap-x-2 mb-2 sm:mb-0 sm:flex-col sm:w-24 sm:items-center sm:justify-between sm:gap-y-4'>
                <label htmlFor='CSATdisagree'>Disagree</label>

                <input
                  type='radio'
                  name='CSATScore'
                  value={2}
                  id='CSATdisagree'
                  onChange={(e) => {
                    setCSATScore(parseInt(e.target.value));
                  }}
                />
              </div>

              <div
                className='flex gap-x-2 mb-2 sm:mb-0 sm:flex-col sm:w-24 sm:items-center sm:justify-between sm:gap-y-4'>
                <label htmlFor='CSATneutral'>Neutral</label>

                <input
                  type='radio'
                  name='CSATScore'
                  value={3}
                  id='CSATneutral'
                  onChange={(e) => {
                    setCSATScore(parseInt(e.target.value));
                  }}
                />
              </div>

              <div
                className='flex gap-x-2 mb-2 sm:mb-0 sm:flex-col sm:w-24 sm:items-center sm:justify-between sm:gap-y-4'>
                <label htmlFor='CSATagree'>Agree</label>

                <input
                  type='radio'
                  name='CSATScore'
                  value={4}
                  id='CSATagree'
                  onChange={(e) => {
                    setCSATScore(parseInt(e.target.value));
                  }}
                />
              </div>

              <div className='flex gap-x-2 sm:flex-col sm:w-24 sm:items-start sm:justify-between sm:gap-y-4'>
                <label className='text-right' htmlFor='CSATstronglyAgree'>Strongly Agree</label>

                <input
                  type='radio'
                  name='CSATScore'
                  value={5}
                  id='CSATstronglyAgree'
                  onChange={(e) => {
                    setCSATScore(parseInt(e.target.value));
                  }}
                  className='self-end'
                />
              </div>
            </fieldset>
          </div>

          <div
            className='modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200
            rounded-b-md mt-4'>
            <button
              className='inline-block px-6 py-2.5 bg-green-600 text-white text-base rounded shadow-md
              hover:bg-green-700 hover:shadow-lg'
              type='button'
              disabled={CSATScore === 0}
              value='Submit'
              onClick={() => localSubmitSurvey()}
            >
              Submit
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CSATModal;
