import {LoadingState, useAppStore} from '../store';
import {useEffect} from "react";
import BannerModel from "./banner.model";
import {BannerApi} from "./banner.api";
import {useStoreState} from "pullstate";
import {UserStore} from "../../Domains/User/UserStore";

export const useListBanners = (): [BannerModel[] | undefined, LoadingState] => {
  const userState = useStoreState(UserStore);
  const banners = useAppStore((s) => s.banners);
  const loadingState = useAppStore((s) => s.bannersLoadingState);
  const update = useAppStore((s) => s.update);

  useEffect(() => {
    update({bannersLoadingState: LoadingState.UNINITIALIZED, banners: undefined});
  }, [userState.hubs, update]);

  useEffect(() => {
    let ignore = false;

    async function startFetching() {
      try {
        update({bannersLoadingState: LoadingState.LOADING});
        const newBanners = await BannerApi.getBanners();
        update({bannersLoadingState: LoadingState.LOADED, banners: newBanners});
      } catch (_e) {
        update({bannersLoadingState: LoadingState.FAILED});
      }
    }

    if ((loadingState === LoadingState.UNINITIALIZED || loadingState === LoadingState.FAILED)
      && (userState.hubs?.length ?? 0) > 0 && !ignore) {
      startFetching().then();
    }

    return () => {
      ignore = true;
    };
  }, [userState.hubs, loadingState, update]);

  return [banners, loadingState];
};
