import dayjs from 'dayjs';
import { CommentType, ProductCommentsProps } from '../Comments/types';
import Textarea from 'react-expanding-textarea';
import { IonButton } from '@ionic/react';
import { useState } from 'react';
import { writeProductComment } from '../../Api/api.products';

const ProductComments = ({ ...props }: ProductCommentsProps) => {
  const [comment, setComment] = useState('');

  const submitComment = () => {
    writeProductComment(props.productId, comment).then(() => {
      setComment('');

      if (props.getComments) {
        props.getComments();
      }
    });
  }

  if (props.isGuest) {
    return (
      <section
        aria-label='Questions and Answers'
        className='p-4 pt-6 bg-gray-100 md:p-6 mw-container md:rounded-lg'
      >
        <div className='empty-block'>
          <h5>Login to see your friends comments on this product.</h5>
        </div>
      </section>
    );
  } else {
    return (
      <section
        aria-label='Questions and Answers'
        className='p-4 pt-6 bg-gray-100 md:p-6 mw-container md:rounded-lg'
      >
        {props.hasFriends && props.numberOfFriends > 0 && (
          <section aria-label='write a review' className='mb-4'>
            <Textarea
              maxLength={250}
              style={{ minHeight: '46px', fontSize: '18px' }}
              placeholder='Type a comment to your friends here...'
              className='flex items-center w-full text-lg leading-snug rounded-md p-3'
              onChange={(v) => {
                setComment(v.target.value);
              }}
              value={comment}
            ></Textarea>

            <div className='flex items-center space-x-4 py-2 justify-end'>
              <IonButton
                size='small'
                disabled={!comment}
                onClick={submitComment}
              >
                Post Comment
              </IonButton>
            </div>
          </section>
        )}

        {props.hasFriends && props.numberOfFriends > 0 && props.comments.length === 0 && (
          <div className='empty-block'>
            <h5>Your friends have not yet commented on this product.</h5>
            <p>Be the first to comment and start a conversation with friends.</p>
          </div>
        )}

        {!props.hasFriends && !props.comments.length && (
          <div className='empty-block'>
            <p className='font-normal'>
              <button
                className='bg-none text-bold text-green-600 hover:text-green-500 mr-1'
                onClick={() => {
                  // If we are in a desktop view want to emit event that we will listen for in the desktop-aside. This event will tell us to change our state to friends
                  // This is necessary until we refactor the layout so that desktop aside and tab bar work together.
                  if (window.innerWidth >= 1024) {
                    const event = document.createEvent('Event');
                    event.initEvent('friendsEvent', true, true);
                    window.dispatchEvent(event);
                  } else {
                    props.router.push('/friends');
                  }

                  if (props.onDismiss) {
                    props.onDismiss();
                  }
                }}
              >
                Click Here
              </button>
              to learn how you can start shopping with friends, discussing products, and sharing your favorites.
            </p>
          </div>
        )}

        {props.comments.map((comment: CommentType, index: number) => {
          return (
            <div className='pb-4 mb-4 comment' key={index}>
              <label className='block mb-1 text-xs text-gray-600'>
                {dayjs(comment.created).format('ddd MMM D YYYY')}
              </label>
              <p
                className='block mb-2 text-base font-semibold leading-tight text-black'
                dangerouslySetInnerHTML={{
                  __html: comment.otherUser.firstName,
                }}
              ></p>
              <p
                className='p-4 -mx-1 text-base leading-snug text-gray-700 bg-white rounded-lg shadow-mdanswer'
                dangerouslySetInnerHTML={{
                  __html: comment.comment || '',
                }}
              ></p>
            </div>
          );
        })}
      </section>
    );
  }
};

export default ProductComments;
