import {Store} from 'pullstate';

import {getInbox, getInboxThread, getHeaders} from '../../Api/api.inbox';
import {
  InboxThreadDetailType,
  InboxThreadType,
  InboxHeaderType,
  InboxStoreProps,
} from './types';

const starterState: InboxStoreProps = {
  activeThread: undefined,
  threads: [],
  loadingInbox: false,
  loadingThread: false,
  headers: {
    'MW-CURRENT-PAGE': 1,
    'MW-ROWS-PER-PAGE': 5,
    'MW-TOTAL-RESULTS': 1,
  },
};

export const InboxStore = new Store(starterState);

const sync = (obj: any = {}) => {
  InboxStore.update((s: InboxStoreProps) => {
    return {...s, ...obj};
  });
};

export const closeThread = (): void => {
  sync({activeThread: undefined});
};

export const loadThread = async (id: any): Promise<void> => {
  const threadDetails: InboxThreadDetailType = await getInboxThread(id);
  sync({loadingThread: true});
  if (threadDetails) {
    sync({activeThread: threadDetails, loadingThread: false});
  } else {
    sync({loadingThread: false});
  }
};

export const loadInbox = async (
  currentPage: number,
  perPage: number
): Promise<void> => {
  sync({loadingInbox: true});
  try {
    const threads: Array<InboxThreadType> = await getInbox(
      currentPage,
      perPage
    );
    sync({threads: threads, loadingInbox: false});
  } catch (e) {
    sync({loadingInbox: false});
    console.error(e);
  }
};

export const fetchHeaders = async (
  currentPage: number,
  perPage: number
): Promise<void> => {
  try {
    const headers: InboxHeaderType = await getHeaders(
      currentPage,
      perPage
    );
    sync({headers: headers});
  } catch (e) {
    console.error(e);
  }
};
