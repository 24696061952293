import CartItem from '../Domains/Cart/CartItemClass';
import {CartStore} from '../Domains/Cart/CartStore';
import {CartItemType} from '../Domains/Cart/types';
import {GET, PUT, POST, DELETE} from './Api';
import DiscountItem from "../Domains/Cart/DiscountItemClass";
import CartBannerItem from "../Domains/Cart/CartBannerClass";

const adaptForDiscounts = (payload: any) => {
  let res = payload;
  if (!payload.hasOwnProperty("items")) {
    res = {items: payload, discounts: [], banners: []};
  }
  if (!res.hasOwnProperty("discounts")) {
    res = {...res, discounts: []}
  }
  if (!res.hasOwnProperty("banners")) {
    res = {...res, banners: []}
  }

  return res;
}

export const getCart = async (): Promise<{
  items: Array<CartItemType>,
  discounts: Array<DiscountItem>,
  banners: Array<CartBannerItem>
}> => {
  const cartCall = await GET('/cart');
  return adaptForDiscounts(cartCall.payload);
};

export const addToCartPost = async (
  productId: number,
  variantId: number,
  qty: number = 1
): Promise<{ items: Array<CartItemType>, discounts: Array<DiscountItem>, banners: Array<CartBannerItem> }> => {
  const cartCall = await PUT('/cart', [
    {
      productId,
      variantId,
      quantity: qty,
    },
  ]);
  return adaptForDiscounts(cartCall.payload);
};

export const bulkAddToCartPost = async (
  addProductList: { variantId: number; quantity: number, fromBundleId: number | null }[]
): Promise<{ items: Array<CartItemType>, discounts: Array<DiscountItem>, banners: Array<CartBannerItem> }> => {
  const cartCall = await PUT('/cart', addProductList);
  return adaptForDiscounts(cartCall.payload);
};

export const removeFromCartPost = async (
  productId: number,
  variantId: number,
  qty: number
): Promise<{ items: Array<CartItemType>, discounts: Array<DiscountItem>, banners: Array<CartBannerItem> }> => {
  const call = await PUT('/cart', [
    {
      productId: productId,
      variantId: variantId,
      quantity: qty,
    },
  ]);

  return adaptForDiscounts(call.payload);
};

export const subscribeToProduct = async (
  productId: any,
  variantId: any,
  qty: number = 1,
  subscription_interval: number = 1
): Promise<boolean> => {
  const cartCall = await POST('/subscriptions', {
    productId,
    variantId,
    qty,
    subscription_interval,
  });
  return cartCall.success;
};

export const unsubscribeFromProduct = async (
  productId: any,
  variantId: any
): Promise<boolean> => {
  const cartCall = await DELETE('/subscriptions', {
    productId,
    variantId,
  });
  return cartCall.success;
};

/**
 * Set Subscription Status
 * @returns
 */
export const getSubscriptionStatus = async () => {
  const call = await GET('/subscriptions/status');
  return call.payload;
};

export const getSubscribedProducts = async (): Promise<Array<CartItem>> => {
  const call = await GET('/subscriptions');
  return call.payload;
};

export const updateAutoTip = async (tipPercentage: number) => {
  const call = await PUT('/subscriptions/tip', {percentage: tipPercentage});
  return call.payload;
};

export const fetchAutoTipAmount = async () => {
  const call = await GET('/subscriptions/tip');
  return call.payload;
};

export const updateSubscriptionStatus = async (subScriptionState: number) => {
  try {
    await PUT('/subscriptions/status', {
      enabled: subScriptionState !== 0,
      status: subScriptionState
    });

    CartStore.update((s) => {
      s.subscriptionEnabled = subScriptionState !== 0;
      s.subscriptionState = subScriptionState;
    });

    CartStore.getRawState();

    return true;
  } catch (e: any) {
    console.error(e);
    return false;
  }
}
