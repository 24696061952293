export default class BannerModel {
  id: string;
  title: string;
  type?: string;
  action?: string;
  body: string;

  constructor(input: any) {
    this.id = input.id;
    this.title = input.title;
    this.type = input.type;
    this.action = input.action;
    this.body = input.body;
  }
}
