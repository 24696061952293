import {IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle} from "@ionic/react";
import {close} from 'ionicons/icons';

const BeyondLocalModal = (props: any) => {
  return (
    <section className='bg-white border-transparent h-full overflow-hidden md:h-auto md:rounded-lg'>
      <IonHeader className='sticky top-0 z-50'>
        <IonToolbar className='rounded-lg'>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>
          </IonButtons>

          <IonTitle className='flex-grow flex-shrink-0 w-full whitespace-nowrap'>
            Beyond Local
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <div className='px-8 py-4'>
        <h1 className='font-bold pb-4'>What is the "Beyond Local" Collection?</h1>

        <p className='pb-2'>Market Wagon's mission is to enable food producers to thrive in their local markets and
          connect you directly with them in your community. However, there are also local food producers who may not be
          near you.</p>
        <p className='pb-2'>We have food producers that sell on Market Wagon from other regions, producing food unique
          to their location. Food that cannot be otherwise sourced in your local area that we consider "Beyond
          Local."</p>
        <p className='pb-2'>Our commitment is that "Beyond Local" foods are still sourced directly, you know where it
          comes from, and it's offered only when there is no viable local supply for these items.</p>
      </div>
    </section>
  );
}

export default BeyondLocalModal;
