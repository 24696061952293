import {
  IonBadge,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonAlert,
  useIonRouter,
  useIonModal,
} from '@ionic/react';
import {UserStore, isUserKnown, logout} from '../User/UserStore';
import {
  mapOutline,
  personCircleOutline,
  mailOpenOutline,
  logOutOutline,
  idCardOutline,
  mailOutline,
  rocketOutline,
  helpBuoyOutline,
  readerOutline,
  fingerPrintOutline,
  checkmark,
  thumbsUpOutline,
  newspaperOutline,
  giftOutline,
  peopleCircleOutline,
} from 'ionicons/icons';
import OrdersList from '../Orders/OrdersList';
import {InboxStore} from '../Inbox/InboxStore';
import {useEffect, useState} from 'react';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import formatHubName from '../../Helpers/FormatHubName';
import {HubSelectModal} from '../Hubs/HubSelectionModal';
import SubscriptionModal from '../Subscriptions/SubscriptionsModal';
import OnBoard from '../Onboard/Onboard';
import ProfileModal from '../User/ProfileModal';
import InviteFriendModal from '../Friends/InviteFriendModal';

const MoreLinks = () => {
  const [presentAlert] = useIonAlert();
  const inboxState = InboxStore.useState((s) => s);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [showWagonPassModal, setShowWagonPassModal] = useState(false);
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const [showBecomeVendorModal, setShowBecomeVendorModal] = useState(false);
  const [showCommonQuestionsModal, setShowCommonQuestionsModal] =
    useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const router = useIonRouter();
  const [presentHubSelectModal, dismissHubSelectModal] = useIonModal(
    HubSelectModal,
    {
      onDismiss: () => {
        dismissHubSelectModal();
      },
      router,
    }
  );
  const [presentFriendsModal, dismissFriendsModal] = useIonModal(
    InviteFriendModal,
    {
      onDismiss: () => {
        dismissFriendsModal();
      },
    });
  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(
    SubscriptionModal,
    {
      onDismiss: () => {
        dismissSubscriptionModal();
      },
    }
  );
  const [presentOnboardModal, dismissOnboardModal] = useIonModal(OnBoard, {
    onDismiss: () => {
      dismissOnboardModal();
    },
  });
  const [presentProfileModal, dismissProfileModal] = useIonModal(ProfileModal, {
    onDismiss: () => {
      dismissProfileModal();
    },
  });

  useEffect(() => {
    setUnreadMessageCount(inboxState.threads.filter((t) => !t.isRead).length);
  }, [inboxState.threads]);

  // Get User State from the User STore
  const userState = UserStore.useState((s) => s);

  // Confirm on Logout
  const askForLogout = () => {
    presentAlert('Are you sure you want to logout?', [
      {
        text: 'Cancel',
      },
      {
        text: 'Yes, Logout',
        handler() {
          logout().then();
        },
      },
    ]).then();
  };

  return (
    <div>
      <IonList className='mb-4 bg-transparent'>
        {/* Login Register  */}
        {userState.isGuest && (
          <IonItem
            button
            lines='none'
            detail
            onClick={() => presentOnboardModal()}
          >
            <IonIcon
              className='text-green-700'
              icon={personCircleOutline}
              slot='start'
            />

            <IonLabel>
              <label className='block text-xs text-gray-500'>Hi Guest!</label>
              <strong>Login/Register</strong>
            </IonLabel>
          </IonItem>
        )}

        {/* If not a Guest but full logged in */}

        {!userState.isGuest && (
          <IonItem
            button
            lines='none'
            detail
            onClick={() => presentProfileModal()}
          >
            <IonIcon
              className='text-green-700'
              icon={personCircleOutline}
              slot='start'
            />

            <IonLabel>
              <label className='block text-xs text-gray-500'>My Profile</label>
              <strong>
                {userState.firstName} {userState.lastName}
              </strong>
            </IonLabel>
          </IonItem>
        )}

        <IonItem
          button
          lines='none'
          detail
          onClick={() => presentHubSelectModal()}
        >
          <IonIcon className='text-green-700' icon={mapOutline} slot='start'/>
          <IonLabel>
            <label className='block text-xs text-gray-500'>Hub</label>

            {userState.hubs && userState.hubs.length && (
              <strong>{formatHubName(userState.hubs[0]?.name)}</strong>
            )}

            {!userState.hubs?.length && <strong>Select Hub</strong>}
          </IonLabel>
        </IonItem>

        <IonItem button lines='none' detail routerLink='/vendors'>
          <IonIcon
            className='text-green-700'
            icon={rocketOutline}
            slot='start'
          />

          <strong>Browse All Vendors</strong>
        </IonItem>

        {!userState.isGuest && (
          <>
            <IonItem routerLink='/inbox' button lines='none' detail>
              <IonIcon
                className='text-green-700'
                icon={mailOpenOutline}
                slot='start'
              />
              <strong>Inbox</strong>

              {unreadMessageCount > 0 && (
                <IonBadge className='text-white bg-red-500' slot='end'>
                  {unreadMessageCount}
                </IonBadge>
              )}
            </IonItem>

            {/* Friends Item */}
            <IonItem
              button
              lines='none'
              detail
              onClick={() => {
                presentFriendsModal();
              }}
            >
              <IonIcon
                className='text-green-700'
                icon={peopleCircleOutline}
                slot='start'
              />

              <strong>Refer Friends</strong>
            </IonItem>

            {/* WagonPass Item */}
            <IonItem
              button
              lines='none'
              detail
              onClick={() => {
                setShowWagonPassModal(true);
              }}
            >
              <IonIcon
                className='text-green-700'
                icon={idCardOutline}
                slot='start'
              />

              <strong>Wagon Pass</strong>
            </IonItem>

            <IFrameModalController
              onDismiss={() => {
                setShowWagonPassModal(false);
              }}
              title='Wagon Pass'
              url={process.env.REACT_APP_WAGON_PASS_URL ?? 'https://marketwagon.com/wagon-pass'}
              open={showWagonPassModal}
            />

            {/* GiftCard Item */}
            <IonItem
              button
              lines='none'
              detail
              onClick={() => {
                setShowGiftCardModal(true);
              }}
            >
              <IonIcon
                className='text-green-700'
                icon={giftOutline}
                slot='start'
              />

              <strong>Gift Cards</strong>
            </IonItem>
            <IFrameModalController
              onDismiss={() => {
                setShowGiftCardModal(false);
              }}
              title='Gift Cards'
              url={process.env.REACT_APP_GIFT_CARD_URL ?? 'https://portal.marketwagon.com/shop/gift-cards'}
              open={showGiftCardModal}
            />

            {/* Subscription Item */}
            <IonItem
              button
              lines='none'
              detail
              onClick={() => {
                presentSubscriptionModal();
              }}
            >
              <IonIcon
                className='text-green-700'
                icon={checkmark}
                slot='start'
              />

              <strong>Subscriptions</strong>
            </IonItem>

            <IonItem button lines='none' detail routerLink='/help'>
              <IonIcon
                className='text-green-700'
                icon={mailOutline}
                slot='start'
              />
              <strong>Help Center</strong>
            </IonItem>
          </>
        )}
      </IonList>

      {/* My Orders */}
      {!userState.isGuest && <OrdersList/>}

      {isUserKnown(userState) && !userState.isGuest && (
        <IonList className='my-10'>
          <IonItem button lines='none' onClick={askForLogout}>
            <IonIcon
              className='text-green-700'
              icon={logOutOutline}
              slot='start'
            />
            Logout
          </IonItem>
        </IonList>
      )}

      <IonList className='parent mb-4 bg-transparent md:hidden'>
        <IonItem button lines='none' detail routerLink='/help'>
          <IonIcon className='text-green-700' icon={mailOutline} slot='start'/>
          <strong>Help Center</strong>
        </IonItem>

        <IonItem
          button
          lines='none'
          detail
          onClick={() => {
            window
              ?.open(
                'https://dairy.marketwagon.com/',
                '_blank',
                'noopener nofollow'
              )
              ?.focus();
          }}
        >
          <IonIcon
            className='text-green-700'
            icon={rocketOutline}
            slot='start'
          />

          <IonLabel>
            <strong>Become a Vendor</strong>
          </IonLabel>
        </IonItem>

        <IFrameModalController
          onDismiss={() => {
            setShowBecomeVendorModal(false);
          }}
          title='Become a Vendor'
          url='https://marketwagon.com/pages/apply-to-become-a-vendor.php'
          open={showBecomeVendorModal}
        />

        {/* Begin Questions */}
        <IonItem
          button
          lines='none'
          detail
          onClick={() => {
            setShowCommonQuestionsModal(true);
          }}
        >
          <IonIcon
            className='text-green-700'
            icon={helpBuoyOutline}
            slot='start'
          />

          <IonLabel>
            <strong>Common Questions</strong>
          </IonLabel>
        </IonItem>

        <IFrameModalController
          onDismiss={() => {
            setShowCommonQuestionsModal(false);
          }}
          title='Common Questions'
          url='https://marketwagon.com/pages/frequently-asked-questions.php'
          open={showCommonQuestionsModal}
        />

        {/* Begin Terms */}
        <IonItem
          button
          lines='none'
          detail
          onClick={() => {
            setShowTermsModal(true);
          }}
        >
          <IonIcon
            className='text-green-700'
            icon={readerOutline}
            slot='start'
          />
          <strong>Terms &amp; Conditions</strong>
        </IonItem>

        <IFrameModalController
          onDismiss={() => {
            setShowTermsModal(false);
          }}
          title='Terms &amp; Conditions'
          url='https://marketwagon.com/pages/conditions-of-use'
          open={showTermsModal}
        />

        {/* Begin Privacy */}
        <IonItem
          button
          lines='none'
          detail
          onClick={() => {
            setShowPrivacyModal(true);
          }}
        >
          <IonIcon
            className='text-green-700'
            icon={fingerPrintOutline}
            slot='start'
          />
          <strong>Privacy</strong>
        </IonItem>

        <IFrameModalController
          onDismiss={() => {
            setShowPrivacyModal(false);
          }}
          title='Privacy'
          url='https://marketwagon.com/terms-privacy.html'
          open={showPrivacyModal}
        />

        {/* Begin About */}
        <IonItem
          button
          lines='none'
          detail
          onClick={() => {
            router.push('/about');
          }}
        >
          <IonIcon
            className='text-green-700'
            slot='start'
            icon={thumbsUpOutline}
          />
          <strong>About Us</strong>
        </IonItem>

        {/* Begin Blog */}
        <IonItem
          button
          lines='none'
          detail
          onClick={() => {
            router.push('/blog');
          }}
        >
          <IonIcon
            className='text-green-700'
            slot='start'
            icon={newspaperOutline}
          />
          <strong>Blog</strong>
        </IonItem>
      </IonList>
    </div>
  );
};

export default MoreLinks;
