import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonSkeletonText,
  IonIcon,
  useIonLoading,
  useIonAlert,
  useIonModal
} from "@ionic/react";
import {reload, checkmark} from 'ionicons/icons';
import {useStoreState} from "pullstate";
import {useEffect, useState} from "react";
import MWButton from "../../../Components/MWButton/MWButton";
import PrivatePage from "../../../Components/Page/PrivatePage";
import {Helmet} from 'react-helmet';
import {bulkCartAdd} from "../../../Domains/Cart/CartStore";
import {UserStore} from "../../../Domains/User/UserStore";
import {ProductModal} from "../../../Domains/Product/ProductModal";
import {loadProduct} from "../../../Domains/Product/ProductStore";
import SubscriptionModal from "../../../Domains/Subscriptions/SubscriptionsModal";
import {subscribeToBundle, unsubscribeFromBundle, useGetBundle} from "../bundle.actions";

const BundlePage = (props: any) => {
  const bundleSlug = props.match?.params?.bundleId;

  const [bundle] = useGetBundle(bundleSlug);

  const isUserGuest: any = useStoreState(UserStore, s => s.isGuest);
  const [showLoading, stopLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });
  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(SubscriptionModal, {
    onDismiss: () => {
      dismissSubscriptionModal();
    }
  });

  useEffect(() => {
    if (bundle) {
      setIsSubscribed(bundle.subscriptionStatus ?? false);
    }
  }, [bundle]);

  const handleBundleAddToCart = async () => {
    try {
      const itemsToAdd: any = [];

      bundle?.products?.forEach((item: any) => {
        const tempItem = {
          variantId: item.variants[0].id,
          quantity: 1,
          fromBundleId: bundle?.id,
        };

        itemsToAdd.push(tempItem);
      });

      showLoading(`Adding ${itemsToAdd.length} items to your cart...`).then();

      await bulkCartAdd(itemsToAdd);
      stopLoading().then();

      return true;
    } catch (e: any) {
      console.error(e);
      stopLoading().then();
      presentAlert('There was an error while adding items to your cart. If the issue persists, ' +
        'please contact Customer Service').then();
      return false;
    }
  }

  const handleBundleSubscribe = async () => {
    showLoading(`Subscribing to ${bundle?.title}...`).then();

    if (bundle) {
      await subscribeToBundle(bundle.id).then((res) => {
        if (!res) {
          console.error(res);
          presentAlert(`There was an error while subscribing to ${bundle.title}. If the issue persists, please contact Customer Service`);
          stopLoading();
          return false;
        }

        setIsSubscribed(true);

        setTimeout(() => {
          stopLoading();
          presentSubscriptionModal();
        }, 500);
        return true;
      });
    }
  }

  const handleBundleUnsubscribe = async () => {
    showLoading(`Unsubscribing from ${bundle?.title}`).then();

    if (bundle) {
      await unsubscribeFromBundle(bundle.id).then((res) => {
        if (!res) {
          presentAlert(`There was an error while unsubscribing to ${bundle.title}. If the issue persists, please contact Customer Service`);
          stopLoading();
          return false;
        }

        setIsSubscribed(false);

        setTimeout(() => {
          stopLoading();
        }, 500);

        return true;
      });
    }
  }

  return (
    <PrivatePage>
      <Helmet>
        <title>{bundle && bundle.title ? bundle.title : 'Bundle'} Details | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com${props.match?.url}`}/>
      </Helmet>
      <IonHeader className='sticky top-0 z-50'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/'/>
          </IonButtons>

          <IonTitle className='flex-grow flex-shrink-0 w-full whitespace-nowrap'>
            {bundle ? bundle?.title :
              <IonSkeletonText className='mx-auto' animated style={{width: '86px', height: '20px'}}/>}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <section style={{backgroundColor: '#f1f2f6'}}>
        <div className='BundleCard flex flex-col mt-3 mx-auto w-full max-w-xs md:max-w-sm'>
          {bundle?.image ?
            <img className='rounded-tl-xl rounded-tr-xl object-cover w-full h-6.25rem' src={bundle?.image} alt=""/>
            : <IonSkeletonText className='rounded-tl-xl rounded-tr-xl my-0 w-full' animated style={{height: '100px'}}/>
          }

          <div className='bg-white pb-3.5 mb-3 rounded-bl-xl rounded-br-xl'>
            <div className='px-4'>
              <h2 className='font-black text-green-600 text-3xl text-center my-3.5'>
                {bundle?.title}
              </h2>

              {bundle?.description &&
                <p className='w-full mb-3' style={{color: '#757575'}}>{bundle?.description}</p>
              }

              {!bundle?.description &&
                <IonSkeletonText
                  animated
                  className=''
                  style={{width: '288px', height: '192px'}}
                />
              }

              {bundle?.products &&
                <div className="flex justify-between items-baseline">
                                    <span className='text-red-800 mb-4 block'>
                                        ${parseFloat(`${bundle?.total}`).toFixed(2)}
                                    </span>
                </div>
              }

              {!bundle?.products &&
                <IonSkeletonText
                  animated
                  className='my-3'
                  style={{width: '52px', height: '24px'}}
                />
              }

              <div className="flex justify-between items-baseline gap-x-4">
                {bundle?.products &&
                  <MWButton
                    title='Add to Cart'
                    onClick={handleBundleAddToCart}
                    bg-gray-200
                    className='justify-center w-full text-sm text-white bg-green-600 rounded-lg h-11 m-0'
                  >
                    Add to Cart
                  </MWButton>
                }

                {!bundle?.products &&
                  <>
                    <IonSkeletonText animated className='' style={{width: '136px', height: '44px'}}/>
                    <IonSkeletonText animated className='' style={{width: '136px', height: '44px'}}/>
                  </>
                }

                {isUserGuest !== true && bundle && !isSubscribed &&
                  <MWButton
                    title='Subscribe to this Product'
                    onClick={handleBundleSubscribe}
                    className='justify-center w-full text-sm font-bold text-green-800 bg-green-200 rounded-lg h-11 mx-0'
                  >
                    Subscribe <IonIcon className='ml-1' icon={reload}/>
                  </MWButton>
                }

                {isUserGuest !== true && bundle && isSubscribed &&
                  <MWButton
                    title='Change subscription status'
                    onClick={handleBundleUnsubscribe}
                    className='justify-center w-full px-4 text-sm font-bold text-black bg-gray-200 rounded-lg h-11'
                  >
                    Subscribed
                    <IonIcon icon={checkmark}/>
                  </MWButton>
                }
              </div>
            </div>
          </div>
        </div>

        <p className='text-sm text-center mb-2' style={{color: '#757575'}}>What's Included</p>

        <ul className='w-72 mx-auto flex flex-col gap-y-4 pb-8'>
          {bundle && bundle?.products && bundle?.products?.map((item: any, index: number) => {
            return (
              <li key={index} className='flex'>
                <button className='flex text-left' onClick={() => {
                  loadProduct(item).then();
                  presentProductModal();
                }}
                >
                  <img
                    alt={item.title}
                    className='w-3.125rem h-3.125rem rounded-lg object-cover'
                    src={item.images[0]}
                    onError={(e) => {
                      e.currentTarget.src = '/images/default-image.svg';
                    }}
                    width='50'
                    height='50'
                  />
                  <h2 className='ml-4 self-center font-semibold'>{item.title}</h2>
                </button>
              </li>
            );
          })}

          {!bundle?.products &&
            <>
              <li className='flex'>
                <IonSkeletonText className='rounded-lg' style={{width: '50px', height: '50px'}} animated/>
                <IonSkeletonText className='ml-4' style={{width: '238px', height: '50px', padding: '0 0 0 1rem'}}
                                 animated/>
              </li>

              <li className='flex'>
                <IonSkeletonText className='rounded-lg' style={{width: '50px', height: '50px'}} animated/>
                <IonSkeletonText className='ml-4' style={{width: '238px', height: '50px', padding: '0 0 0 1rem'}}
                                 animated/>
              </li>

              <li className='flex'>
                <IonSkeletonText className='rounded-lg' style={{width: '50px', height: '50px'}} animated/>
                <IonSkeletonText className='ml-4' style={{width: '238px', height: '50px', padding: '0 0 0 1rem'}}
                                 animated/>
              </li>
            </>
          }
        </ul>
      </section>
    </PrivatePage>
  );
};

export default BundlePage;
