import {
  IonContent,
  IonInput,
  IonItem,
  IonList,
  useIonRouter,
} from '@ionic/react';
import {useState, useEffect} from 'react';
import MWButton from '../../Components/MWButton/MWButton';
import {resetPassword} from '../../Api/api.account';
import MWLoader from '../../Components/MWLoader/MWLoader';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';
import PublicPage from '../../Components/Page/PublicPage';

const PasswordResetPage = () => {
  const router = useIonRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [resetForm, setResetForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [isDisabled, setDisabledStatus] = useState(true);
  const [submissionError, setSubmissionError] = useState('');
  const [resetSuccessful, setSuccessfulReset] = useState(false);
  const [userJWT, setJWT] = useState('');

  const doReset = () => {
    setDisabledStatus(true);
    setIsLoading(true);
    setPasswordError('');
    setConfirmPasswordError('');

    resetPassword(resetForm.password, userJWT).then((res: any) => {
      if (res === 'EXPIRED') {
        setSubmissionError('Password reset link has expired');
        setIsLoading(false);
        setDisabledStatus(false);
      }

      if (res === true) {
        setSubmissionError('');
        setResetForm({email: '', password: '', confirmPassword: ''});
        setSuccessfulReset(true);
        setIsLoading(false);
        setSuccessfulReset(true);

        const redirect = setTimeout(() => {
          clearInterval(redirect);
          router.push('/');
        }, 4000);
      }
    });
  };

  const verifyData = () => {
    // If we have an email, password, and confirm password value.
    // AND password and confirm password are equal. Enable the submit button.
    if (
      resetForm.password &&
      resetForm.confirmPassword &&
      resetForm.password === resetForm.confirmPassword
    ) {
      setDisabledStatus(false);
    }
  };

  useEffect(() => {
    // Grab token from URL
    // Hit endpoint to return proper data?
    // Add email to email field
    const jwt = window.location.href.split('password-reset/')[1];

    if (jwt) {
      setJWT(jwt);
    }
  }, [resetForm]);

  return (
    <PublicPage>
      <Helmet>
        <title>Reset Password | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com/password-reset`}/>
      </Helmet>

      <GeneralHeader title='Reset Password'/>

      <IonContent>
        <section slot='fixed' className='w-full'>
          <div
            className='w-72 mx-auto mt-5 mb-2 overflow-hidden border border-gray-200 rounded-lg shadow-lg bg-white-200 login-form'>
            <IonList>
              <IonItem>
                <label aria-label='newPassword' className='hidden'>
                  New Password
                </label>

                <IonInput
                  id='newPassword'
                  placeholder='New Password'
                  type='password'
                  value={resetForm.password}
                  required
                  onIonChange={(evt: any) => {
                    const password = evt?.target?.value;
                    setResetForm({...resetForm, ...{password}});
                  }}
                  onIonBlur={(evt: any) => {
                    if (!evt?.target?.value) {
                      setPasswordError('A password is required.');
                      return false;
                    }

                    setPasswordError('');
                    verifyData();
                    return;
                  }}
                />
              </IonItem>

              <IonItem lines='none'>
                <label aria-label='confirmPassword' className='hidden'>
                  Confirm Password
                </label>

                <IonInput
                  placeholder='Confirm Password'
                  type='password'
                  value={resetForm.confirmPassword}
                  required
                  onIonChange={(evt: any) => {
                    const confirmPassword = evt?.target?.value;
                    setResetForm({...resetForm, ...{confirmPassword}});
                  }}
                  onIonBlur={(evt: any) => {
                    const confirmPassword = evt?.target?.value;

                    if (
                      resetForm.password !== confirmPassword ||
                      !confirmPassword
                    ) {
                      setConfirmPasswordError('Passwords must match.');
                      return false;
                    }

                    setConfirmPasswordError('');
                    verifyData();
                    return;
                  }}
                />
              </IonItem>
            </IonList>
          </div>

          <div className='w-72 mx-auto mt-8 flex flex-col items-center'>
            {(passwordError || confirmPasswordError || submissionError) && (
              <div className='w-full p-2 mb-4 text-sm text-center text-red-800 bg-red-100 rounded-xl'>
                {passwordError && <p>{passwordError}</p>}

                {confirmPasswordError && <p>{confirmPasswordError}</p>}

                {submissionError && <p>{submissionError}</p>}
              </div>
            )}

            {resetSuccessful && (
              <div className='w-full p-2 mb-4 text-sm text-center text-white bg-green-500 rounded-xl'>
                <p>Password reset successful. Redirect in progress...</p>
              </div>
            )}

            <MWButton
              title='Reset Password'
              onClick={() => doReset()}
              disabled={isDisabled}
              className='mx-auto text-center'
            >
              Reset Password
            </MWButton>
          </div>
        </section>
      </IonContent>

      {isLoading && <MWLoader/>}
    </PublicPage>
  );
};

export default PasswordResetPage;
