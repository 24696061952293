import styles from './Qty.module.css';
import {trash, removeCircleOutline, addCircleOutline} from 'ionicons/icons';
import {IonButton, IonIcon} from '@ionic/react';
import {QtyProps} from './types';

const Qty = ({children, className, onMore, onLess, ...props}: QtyProps) => (
  <div
    {...props}
    className={`mw-qty ${styles.qty} bg-white shadow-md ${className}`}
  >
    <IonButton
      aria-label='Decrease Quantity'
      onClick={() => {
        if (onLess) {
          onLess();
        }
      }}
      style={{'--padding-end': '4px', '--padding-start': '5px'}}
      className='px-0'
      size='small'
      fill='clear'
    >
      {props.value === 1 ? (
        <IonIcon icon={trash} className='text-red-600'/>
      ) : (
        <IonIcon icon={removeCircleOutline}/>
      )}
    </IonButton>
    <span className={`value ${styles.qtyValue}`}>{props.value || 0}</span>
    <IonButton
      aria-label={`${props.max && props.value === props.max
        ? 'No more available'
        : 'Increase more'
      }`}
      disabled={!!(props.max && props.value === props.max)}
      onClick={() => {
        if (props.max && props.value === props.max) {
          // Ignore
        } else {
          onMore && onMore();
        }
      }}
      style={{'--padding-start': '4px', '--padding-end': '5px'}}
      className='px-0'
      size='small'
      fill='clear'
    >
      <IonIcon icon={addCircleOutline}/>
    </IonButton>
  </div>
);

export default Qty;
