// Our hook

import {logoFacebook, logoLinkedin, logoPinterest, logoTwitter} from "ionicons/icons"


export default function useShare() {

  const destinations = [
    {
      title: 'Facebook',
      icon: logoFacebook,
      generate(url: string, title?: string) {
        return `https://www.facebook.com/dialog/share?app_id=311393176226834&display=popup&href=${encodeURIComponent(url)}&title=${encodeURIComponent(title || "")}}`
      }
    },
    {
      title: 'Twitter',
      icon: logoTwitter,
      generate(url: string, title?: string) {
        return `https://twitter.com/intent/tweet?text=${encodeURIComponent(title || "")}&url=${encodeURIComponent(url)}&via=marketwagon`
      }
    },
    {
      title: 'LinkedIn',
      icon: logoLinkedin,
      generate(url: string, title?: string) {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title || "")}&source=marketwagon.com`
      }
    },
    {
      title: 'Pinterest',
      icon: logoPinterest,
      generate(url: string) {
        return `//pinterest.com/pin/create/link/?url=${encodeURIComponent(url)}&source=marketwagon.com`
      }
    }
  ]

  const canPlatformShare = (): Boolean => {
    return !!(navigator.share);
  }

  const platformShare = async (url: string, title?: string): Promise<any> => {
    return navigator.share({
      title: title,
      url: url,
    })
  }

  return {destinations, canPlatformShare, platformShare}
}
