/**
 * Component for Capturing Legacy
 * URL Params like:
 *
 * [x] ?q=[term]
 * [x] ?wagonId=[id]
 * [x] ?wagon=[name]
 * [] ?gl=[gooogle-location]
 */
import {useCallback, useEffect} from "react";
import {useHistory} from "react-router";
import {setUserStoreProperty} from "../Domains/User/UserStore";
import {createCookie} from "./CreateCookie";


const URLParamsRedirector = () => {
  const history = useHistory();

  const handleURLParams = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // Vendor Tracker ID
    if (params.trk) {
      // Save Vendor TRK code to localStorage
      // This will be send as the MW_TRK header param to API calls
      createCookie('vendorSource', params.trk, 30 * 24 * 60 * 60 * 1000);
      setUserStoreProperty('trk', params.trk.substring(0, 50));
    }

    // Capture Legacy Search Query Param
    // Redirect to new /search?q=[term]
    if (params.q && window.location.pathname !== '/search') {
      setTimeout(() => {
        history.push(`/search?q=${params.q}`)
      }, 500);
    }

    if (params.playerId) {
      setUserStoreProperty('playerId', params.playerId);
    }

    if (params.os) {
      setUserStoreProperty('os', params.os);
    }

    if (params.offer) {
      createCookie('mw_discount_code', params.offer, 24 * 60 * 60 * 1000);
    }
  }, [history])

  useEffect(() => {
    handleURLParams();
  }, [handleURLParams]);


  return (
    <></>
  );
};
export default URLParamsRedirector;
