import {useEffect} from "react";
import {useLocation} from "react-router";
import ContentFooter from "../../Domains/Layout/ContentFooter";
import DesktopAside from "../../Domains/Layout/DesktopAside";
import TabBar from "../../Domains/Layout/TabBar";

const PublicPage = ({children}: any) => {
  const location: any = useLocation();

  useEffect(() => {
    if (location.key) window.scrollTo(0, 0);
  }, [location.key]);

  return (
    <div className='lg:mr-80'>
      <DesktopAside/>

      <section className="app-main">
        <div className="min-h-inner-page-mobile bg-gray-100 pb-10">
          {children}
        </div>

        <ContentFooter className='hidden lg:block'/>
      </section>

      <TabBar className='lg:hidden'/>
    </div>
  );
}

export default PublicPage;
