import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonModal,
} from '@ionic/react';
import {isLoggedIn, switchHub} from '../../../Domains/User/UserStore';
import formatHubName from '../../../Helpers/FormatHubName';
import OnBoard from '../../../Domains/Onboard/Onboard';
import HubModel from "../hub.model";

const HubTile = ({...props}: { item: HubModel }) => {
  const item = props.item;
  const [showLoading, dismissLoading] = useIonLoading();
  const router = useIonRouter();
  const [showAlert] = useIonAlert();
  const [presentOnboardModal, dismissOnboardModal] = useIonModal(OnBoard, {
    onDismiss: () => {
      dismissOnboardModal();
    }
  });

  const handleHubChange = async (hub: HubModel) => {
    showLoading(`Changing market to ${hub.name}...`).then();
    const res = await switchHub(hub.id);

    if (res) {
      dismissLoading().then();
      router.push('/');
    }
  };

  return (
    <IonCard className='shadow-lg border border-gray-200 m-0 flex flex-col'>
      <IonCardHeader>
        <h2 className='font-bold text-xl text-black'>
          {formatHubName(item.name)}
        </h2>
      </IonCardHeader>

      <IonCardContent>
        <p className='text-sm leading-tight'>{item.description}</p>
      </IonCardContent>

      <div className='grow-1 h-full'/>

      <div className='bg-gray-100 px-4 justify-end flex items-center mt-2 py-2 w-full'>
        <IonButton
          size='small'
          className='w-full'
          onClick={() => {
            if (!isLoggedIn()) {
              showAlert('Shopping Market Wagon requires the creation of free account.',
                [
                  {
                    text: 'Register',
                    handler() {
                      presentOnboardModal();
                    }
                  },
                  {
                    text: 'Cancel',
                  }
                ]).then();
            } else {
              handleHubChange(item).then();
            }
          }}
        >
          Shop Here
        </IonButton>

        <IonButton
          size='small'
          fill='clear'
          className='w-full'
          routerLink={`/hubs/${item.id}`}
        >
          Details
        </IonButton>
      </div>
    </IonCard>
  );
};

export default HubTile;
