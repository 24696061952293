import {IonButton, IonSkeletonText} from "@ionic/react";
import MWAvatar from "../../../Components/MWAvatar/MWAvatar";
import UseToggle from "../../../Helpers/Hooks/UseToggle";

const VendorHero = ({...props}: any) => {
  const [isExpanded, toggleExpanded] = UseToggle(false);

  return (
    <div>
      {/* Loading state for hero image */}
      {!props.isLoading &&
        <IonSkeletonText
          animated
          className='w-full m-0 h-40 md:h-64'
          style={{height: '160px'}}
        />
      }

      {props.isLoading &&
        <div
          className={` bg-gray-800 bg-center bg-cover  ${props.compact ? 'h-20 md:h-28' : 'h-40 md:h-64'}`}
          style={{
            backgroundImage: `url('${props.backgroundImage}')`,
          }}
        />
      }

      <header className='max-w-screen-md mx-auto mb-4 px-4'>
        {/* Avatar loading states */}
        <div className='flex items-end justify-between'>
          {props.avatar && props.name &&
            <MWAvatar
              image={props.avatar}
              label={props.name}
              hideLabel={true}
              size='lg'
              className='-mt-10'
            />
          }

          {!props.avatar && !props.name &&
            <IonSkeletonText
              animated
              className='w-20 h-20 m-0 rounded-lg'
            />
          }
        </div>

        <div>
          <div className="flex items-center justify-between">
            {props.name &&
              <h1 className='text-xl font-bold leading-tight flex-grow flex-shrink w-full'>
                {props.name}
              </h1>
            }

            {!props.name &&
              <IonSkeletonText
                animated
                className='w-20 h-6 m-0'
              />
            }

            {props.handleViewProfile &&
              <IonButton size='small' onClick={props.handleViewProfile}>
                View Profile
              </IonButton>
            }

            {props.children}
          </div>

          <div className='sm:hidden max-w-screen-sm mt-2 text-sm text-gray-600'>
            {!props.isLoading &&
              <IonSkeletonText
                animated
                className='w-full h-16 m-0 rounded-lg'
              />
            }

            {/* Mobile description? */}
            {props.isLoading && (
              <p className={`${isExpanded ? '' : 'line-clamp-2'}`}>
                {props.description}
              </p>
            )}

            {props.isLoading && props.description && props.description.length > 80 && (
              <button
                className='text-xs text-green-600 hover:text-green-500 underline'
                onClick={() => toggleExpanded()}
              >
                {isExpanded ? 'View Less' : 'View More'}
              </button>
            )}
          </div>

          <div className={`hidden md:block max-w-screen-sm mt-2 text-sm text-gray-600`}>
            <p className={`${isExpanded ? '' : 'line-clamp-2'}`}>
              {props?.description}
            </p>

            {(props.description && props.description.length > 140) && (
              <button
                className='text-xs text-green-600 hover:text-green-500 underline'
                onClick={() => toggleExpanded()}
              >
                {isExpanded ? 'View Less' : 'View More'}
              </button>
            )}
          </div>
        </div>
      </header>
    </div>
  )
}

export default VendorHero;
