import DeliveryAddress from '../Domains/Delivery/DeliveryAddress';
import {HubType} from '../Domains/Hubs/types';
import {CoordinatesType} from '../Domains/Onboard/types';
import {AccountPayload, ProfileType} from '../Domains/User/types';
import {loadUserProfile, setUserAuth, UserStore} from '../Domains/User/UserStore';
import {GET, POST, PUT} from './Api';
import {getAuth, getBridgeData} from './api.auth';
import {getFeed} from './api.feed';

/**
 * Safely build our account results
 * Don't just take a payload
 * @param payload
 * @returns
 */
export const parseAccountPayload = (payload: AccountPayload) => {
  let isGuest = true;

  // If the payload has the id property and the payload.id does not contain a negative number, set isGuest to false
  if ((payload.hasOwnProperty('id')) && (payload.id && Math.sign(payload.id) === 1)) {
    isGuest = false;
  }

  return {
    avatar: payload.avatar,
    customerId: payload.customerId,
    dataSource: payload.dataSource ? payload.dataSource : null,
    deliveryAddress: payload.deliveryAddress ? new DeliveryAddress(payload.deliveryAddress) : undefined,
    deliveryDate: payload.hubs && payload.hubs.length ? new Date(payload.hubs[0].deliveryDay) : undefined,
    email: payload.email,
    firstName: payload.firstName,
    handle: payload.handle || `${payload.firstName || ''} ${payload.lastName || ''}`,
    hasPassword: payload.hasPassword,
    hubs: payload.hubs,
    hubId: payload.hubId,
    id: payload.id,
    image: payload.image,
    isGlutenFree: !!payload.isGlutenFree,
    isGuest: isGuest,
    isVegan: !!payload.isVegan,
    isWagonPassActive: payload.isWagonPassActive,
    lastName: payload.lastName,
    lastOrderDate: payload.lastOrderDate,
    paymentInfo: payload.paymentInfo,
    paymentType: payload.paymentType,
    playerId: payload.playerId,
    subscribedAllMessages: payload.subscribedAllMessages,
    trk: payload.trk,
    zipCode: payload.zipCode,
    utmSource: payload.utmSource,
    utmCampaign: payload.utmCampaign,
  };
};

/**
 * Upsert a Users Profile Details
 * if any property is not underined, then it should be set.
 * @param profileDetails
 * @returns
 */
export const saveProfile = async (
  profileDetails: ProfileType
): Promise<boolean> => {
  const results = await PUT('/account/profile', profileDetails);
  return results.success;
};

export const getProfile = async () => {
  try {
    const results = await GET('/account/profile');
    return results.payload ? results.payload : [];
  } catch (e) {
    console.error(e);
  }
};

/**
 * Login a Guest Users with Zipcode
 * @param zipCode
 * @param latLng
 * @param hubId
 * @param utmSource
 * @param utmCampaign
 * @param latLng
 * @param hubId
 * @param utmSource
 * @param utmCampaign
 * @param latLng
 * @param hubId
 * @param utmSource
 * @param utmCampaign
 * @param latLng
 * @param hubId
 * @param utmSource
 * @param utmCampaign
 * @returns
 */
export const loginGuest = async (
  zipCode: number | null = null,
  latLng: CoordinatesType | null = null,
  hubId: string | null = null,
  utmSource: string | null = null,
  utmCampaign: string | null = null
) => {
  let results: any;
  let utmCode = '';

  if (utmSource) {
    utmCode += '&utmSource=' + utmSource;
  }

  if (utmCampaign) {
    utmCode += '&utmCampaign=' + utmCampaign;
  }

  if (zipCode !== null) {
    results = await POST(`/auth/guest?zipCode=${zipCode}` + utmCode);
  } else if (
    latLng !== null &&
    latLng.hasOwnProperty('latitude') &&
    latLng.hasOwnProperty('longitude')
  ) {
    results = await POST(
      `/auth/guest?lat=${latLng.latitude}&lng=${latLng.longitude}` + utmCode
    );
  } else {
    results = await POST(`/auth/guest?hubId=${hubId}` + utmCode);
  }

  // Save auth for the user
  const hubs: Array<HubType> = results.payload || [];
  const authPayload: AccountPayload = {hubs: hubs, isGuest: true};

  return setUserAuth(authPayload);
};

export const loginWithEmail = async (
  email: string,
  password: string,
  playerId: string | null,
  os: string | null
): Promise<AccountPayload> => {
  // TODO: Make this more extensive of a check
  if (!email || !password) {
    throw new Error('Invalid email or password. Please check those values.');
  }

  // Call the email and password
  const results = await POST('/auth/login', {
    username: email,
    password,
    playerId: playerId,
    os: os,
  });

  // Save auth for the user
  const authPayload = parseAccountPayload(results.payload);
  authPayload.isGuest = false;
  authPayload.hasPassword = true;

  UserStore.update(() => authPayload);

  setUserAuth(authPayload).then();
  return authPayload;
};

export const registerAccount = async (
  firstName: string | null,
  lastName: string | null,
  email: string,
  password: string | null,
  zipCode: string | null,
  playerId: string | null,
  os: string | null,
  referredByCustomerId: string | null
): Promise<AccountPayload> => {
  if (!email) {
    throw new Error('Email is required. please try again.');
  }

  const results = await POST('/auth/register', {
    email,
    password,
    zipCode,
    firstName,
    lastName,
    playerId,
    os,
    referredByCustomerId
  });

  const authPayload = parseAccountPayload(results.payload);
  authPayload.isGuest = false;
  setUserAuth(authPayload).then();

  return authPayload;
};

export const resetPassword = async (password: string, jwt: string) => {
  if (!jwt || !password) {
    return '400';
  }

  await POST(`/auth/reset/${jwt}`, {
    jwt: jwt,
    password: password
  }).then((res) => {
    return res;
  });

  const res = await POST(`/auth/reset/${jwt}`, {
    password: password,
  });
  return res.success;
};

export const profileUpdatePassword = async (
  oldPassword: any,
  newPassword: any
) => {
  const results = await POST('/auth/update', {
    oldPassword,
    newPassword,
  });

  if (results.success) {
    return results;
  } else {
    throw new Error(results.message);
  }
};

/**
 *
 * When a user logs in during checkout we need to do a few things to log them into the app since we do not have their password.
 */
export const loadUserProfileAfterCheckoutLogin = async () => {
  const profileData = await getProfile();
  const authData = await getAuth();
  const bridgeData = await getBridgeData();

  // Frakenstein together something that mimics the account payload you would get from login from our various sources.
  // ID is coming from the the message check in iframe-modal.tsx. It's pulled from the server cookies after we receive the message
  const frankensteinsPayload = {
    ...profileData,
    ...authData,
    ...bridgeData
  };

  // Parse the frakensteined payload adding anything we are missing/and or needing.
  const authPayload: ProfileType = parseAccountPayload(frankensteinsPayload);

  // If the user id is negative, math.sign will return a -1, 1 if a positive number.
  authPayload.isGuest = Math.sign(Number(authPayload.id)) === -1;

  await loadUserProfile(authPayload);

  if (authPayload.hubId !== undefined) {
    await getFeed(String(authPayload.hubId));
  }

  setUserAuth(authPayload).then();
  return true;
};
