import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import {getSubscriptionRecommendations} from '../../Api/api.products';
import {subscriptionsAdd} from '../../Domains/Cart/CartStore';
import Product from '../../Domains/Product/ProductClass';
import MWButton from '../MWButton/MWButton';
import {ProductModal} from '../../Domains/Product/ProductModal';
import {loadProduct} from '../../Domains/Product/ProductStore';

const SubscribePostCheckout = ({...props}: { onDismiss: Function }) => {
  const [favoriteProducts, setfavoriteProducts] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [presentAlert] = useIonAlert();
  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });

  const loadFavoriteProducts = async () => {
    try {
      const currentProductPage = 1;
      const productsPerPage = 5;
      const products: Array<Product> = await getSubscriptionRecommendations(
        currentProductPage,
        productsPerPage
      );

      setfavoriteProducts(products);
    } catch (e) {
      console.error(e);
    }
  };

  const localSubscribe = async (
    button: any,
    prodId: number,
    variantId: number
  ) => {
    try {
      await subscriptionsAdd(prodId, variantId).then(() => {
        button.target.textContent = 'Added!';
        button.target.disabled = true;
      });
    } catch (e) {
      presentAlert('Error subscribing to product').then();
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      loadFavoriteProducts().then(() => {
        setIsLoaded(true);
      });
    }
  }, [isLoaded]);

  return (
    <div className='modal fade w-full outline-none overflow-x-hidden overflow-y-auto'>
      <div
        className='modal-content border-none shadow-lg relative flex flex-col  pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton
                aria-label='Close'
                id='close-button'
                fill='clear'
                slot='start'
                onClick={() => props.onDismiss()}
              >
                <IonIcon
                  aria-describedby='close-button'
                  icon={close}
                  slot='icon-only'
                />
              </IonButton>
            </IonButtons>
            <IonTitle className='w-full overflow-visible px-0'>
              Subscribe to Local Food
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className='modal-body p-4'>
          <h2 className='text-2xl font-normal leading-normal mt-0 mb-2'>
            Save time and never miss your favorite local items when you
            subscribe.
          </h2>
          <p>
            With an active subscription, your subscribed items will be
            automatically added to your cart and checked out using your saved
            payment and delivery preferences 36-hours before the order cut-off.
            Add as many local items as you wish to your weekly subscription.
            Pause any time you do not need a weekly delivery.
          </p>
          <p className='mt-4 font-bold'>Subscribe to your favorites now...</p>
          <p className='mb-6'>
            You have ordered these items most often. Add them to your
            subscription now to get them every week.
          </p>
          <div className=' justify-between'>
            <ul className='mx-auto flex flex-col gap-y-4 pb-8'>
              {!isLoaded &&
                [1, 2, 3, 4, 5].map((i) => {
                  return (
                    <li
                      key={i}
                      className='flex justify-between rounded-lg h-20'
                    >
                      <IonSkeletonText animated/>
                    </li>
                  );
                })}

              {favoriteProducts !== null &&
                isLoaded &&
                favoriteProducts.map((item: any, index: number) => {
                  return (
                    <li key={index} className='flex justify-between'>
                      <button
                        className='flex text-left '
                        onClick={() => {
                          loadProduct(item).then();
                          presentProductModal();
                        }}
                      >
                        <img
                          alt={item.title}
                          className='w-20 h-20 rounded-lg object-cover'
                          src={item.images[0]}
                          onError={(e) => {
                            e.currentTarget.src = '/images/default-image.svg';
                          }}
                          width='50'
                          height='50'
                        />

                        <h3 className='ml-4 self-center font-semibold'>
                          {item.title}
                        </h3>
                      </button>
                      <MWButton
                        title={'subscribe'}
                        className='bg-green-600 py-1 ml-3 text-sm rounded-md text-white mt-2'
                        onClick={(e: any) => {
                          localSubscribe(e, item.id, item.variants[0].id).then();
                        }}
                      >
                        Subscribe
                      </MWButton>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribePostCheckout;

