import dayjs from 'dayjs';
import {
  getProductReviews,
  writeProductReview,
} from '../../Api/api.products';
import MWRatingFull from '../../Components/MWRating/MWRatingFull';
import Textarea from 'react-expanding-textarea';
import {useState} from 'react';
import {IonButton, useIonLoading} from '@ionic/react';
import {ProductReviewsProps, ProductReviewType} from './types';

const ProductReviews = ({...props}: ProductReviewsProps) => {
  const [reviewText, setReviewText] = useState('');
  const [reviewScore, setRevewScore] = useState(5);
  const [showLoading, dismissLoading] = useIonLoading();

  const postReview = async () => {
    showLoading('Sending Review...').then();
    await writeProductReview(props.product?.id, reviewText, reviewScore).then();
    getProductReviews(props.product?.id).then((res) => {
      props.setReviews(res);
    });
    dismissLoading().then();
    setReviewText('');
  };

  return (
    <section
      aria-label='Questions and Answers'
      className='p-4 pt-6 bg-gray-100 md:p-6 mw-container md:rounded-lg'
    >
      {props.isGuest && (
        <section
          aria-label='Questions and Answers'
          className='p-4 pt-6 bg-gray-100 md:p-6 mw-container md:rounded-lg'
        >
          <div className='empty-block'>
            <h5>You must be logged in to leave a review.</h5>
          </div>
        </section>
      )}
      {!props.isGuest && (
        <section aria-label='write a review' className='mb-4'>
          <ul className='list-disc text-xs text-gray-900 p-4'>
            <li>Select the number of stars you choose.</li>
            <li>Please limit comments to the quality of the item received.</li>
            <li>Your review might not be immediately visible.</li>
          </ul>
          <Textarea
            value={reviewText}
            onChange={(v) => {
              setReviewText(v.target.value);
            }}
            maxLength={250}
            style={{minHeight: '46px', fontSize: '18px'}}
            placeholder='What did you like or dislike?'
            className='flex items-center w-full text-lg leading-snug rounded-md p-3'
          ></Textarea>
          <div className='flex items-center space-x-4 py-2 justify-between'>
            <MWRatingFull
              size='lg'
              rating={reviewScore}
              onChange={(score: number) => {
                setRevewScore(score);
              }}
            />
            <IonButton disabled={!reviewText} size='small' onClick={postReview}>
              Post Review
            </IonButton>
          </div>
        </section>
      )}
      {props.reviews.length === 0 && (
        <div className='empty-block'>
          <h5>No Reviews yet.</h5>
        </div>
      )}
      {props.reviews.map((review: ProductReviewType, index: number) => {
        return (
          <div key={index} className='pb-4 question'>
            <label className='block text-xs text-gray-600'>
              {dayjs(review.created).format('ddd MMM D YYYY')}
            </label>
            <div className='flex justify-between w-full mb-2 text-base font-semibold leading-tight text-black'>
              <p className='text-lg font-semibold'>{review.rater}</p>
              <MWRatingFull rating={review.rating}/>
            </div>
            {!!review.comment && (
              <p
                className='p-4 -mx-1 text-base leading-snug text-gray-700 bg-white rounded-lg shadow-mdanswer'
                dangerouslySetInnerHTML={{__html: review.comment}}
              ></p>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ProductReviews;
