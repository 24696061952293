import {useState} from "react";
import {CoverImageType} from "./types";

export const CoverImage = ({...props}: CoverImageType) => {
  const [showImage, setShowImage] = useState(true);

  return (
    <div
      aria-label={props.title || 'Image'}
      className={`${props.className} overflow-hidden relative`}
      style={{...(props.style || {})}}
    >
      {showImage && (
        <img
          src={props.image}
          alt={props.title}
          // Catch images that do no resolve and hide the image tag to prevent the broken image thumbnail
          onError={() => {
            setShowImage(false);
          }}
          loading="lazy"
          className=''
        />
      )}
    </div>
  );
};
