import {IonItem, IonLabel, IonList, IonListHeader, useIonAlert, useIonModal} from '@ionic/react';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {getOrderInformation} from '../../Api/api.orders';
import Order from './OrderClass';
import UseToggle from '../../Helpers/Hooks/UseToggle';
import OrderModal from './OrderModal';
import {setActiveOrder} from '../Cart/CartStore';

const OrdersList = () => {
  const [showCompletedOrders, setShowCompleted] = UseToggle(false);
  const [passedCutoff, setPassedCutoff] = useState(false);
  const [showDays, setShowDays] = useState(true);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<[] | null>(null);
  const [cutoffDate, setCutoffDate] = useState<string | null>(null);
  const [openOrders, setOpenOrders] = useState<[] | null>(null);
  const [closedOrders, setClosedOrders] = useState<[] | null>(null);
  const [futureOrders, setFutureOrders] = useState<[] | null>(null);
  const [showFutureOrders, setShowFutureOrders] = useState(false);
  const [presentAlert] = useIonAlert();
  const [presentOrderModal, dismissOrderModal] = useIonModal(OrderModal, {
    onDismiss: () => {
      dismissOrderModal();
    }
  })

  const openOrder = (order: any) => {
    setActiveOrder(order);
    presentOrderModal();
  };

  useEffect(() => {
    if (orders === null) {
      setIsLoading(true);

      getOrderInformation().then((res: any) => {
        if (res === false) {
          presentAlert('There was an error fetching orders. Please try again later.').then()
          setOrders(null);
        }

        const parsedOpenOrders = res.orders.filter((order: any) => order.status === 'open' || order.status === 'pending');
        const parsedClosedOrders = res.orders.filter((order: any) => order.status === 'closed');
        const futureOrders = res.orders.filter((order: any) => order.status === 'future');

        setOpenOrders(parsedOpenOrders)
        setClosedOrders(parsedClosedOrders);
        setFutureOrders(futureOrders);
        setOrders(res.orders);
        setCutoffDate(res.cutoffDate);
        // setDeliveryDate(res.deliveryDate);
        setIsLoading(false);
      });
    }
  }, [orders, presentAlert]);

  useEffect(() => {
    if (cutoffDate) {
      setIsLoading(true);

      if (dayjs(cutoffDate.split("T")[0]) < dayjs()) {
        setPassedCutoff(true);
      } else {
        let cutoffDiffDays = dayjs(cutoffDate.split("T")[0]).diff(dayjs(), 'day');
        let cutoffDiffHours = dayjs(cutoffDate.split("T")[0]).diff(dayjs(), 'hours') - (cutoffDiffDays * 24);

        if (cutoffDiffDays <= 0) {
          setShowDays(false);
          setHours(cutoffDiffHours);
        } else {
          setDays(cutoffDiffDays);
        }
      }

      setIsLoading(false);
    }
  }, [cutoffDate]);

  return (
    <IonList className='mb-4 bg-transparent'>
      <IonListHeader className='px-4'>
        <h1 className='flex items-center mt-3 mb-1 text-xl font-extrabold'>
          My Orders
        </h1>
      </IonListHeader>

      {!!orders !== null &&
        <>
          {openOrders && openOrders.map((order: Order, index: number) => {
            let addOnItemCount = 0;
            let addOnTotal = 0;

            if (order.addOns?.length > 0) {
              order.addOns.forEach((addOn: { items: any[]; orderTotal: number }) => {
                addOnItemCount += addOn.items.length;
                addOnTotal += addOn.orderTotal;
              })
            }

            return (
              !isLoading &&
              <IonItem
                button
                onClick={() => openOrder(order)}
                detail={false}
                key={index}
                lines='none'
                className='pb-1'
              >
                <IonLabel>
                  <label className='text-base font-semibold'>
                    {(!passedCutoff && order.status !== 'pending') ? `${showDays ? days + ' days' : hours + ' hours'}  left to add on to this order.` :
                      "Arrives " + dayjs(order.deliveryDate).format('ddd MMM D YYYY')
                    }

                    <p>{order.items.length + addOnItemCount} Items</p>
                  </label>
                </IonLabel>

                <span slot='end' className='text-sm font-semibold text-green-600'>
                    ${(order.orderTotal + addOnTotal).toFixed(2)}
                  </span>
              </IonItem>
            )
          })}

          {!openOrders?.length &&
            <IonItem detail lines='none'>
              <span slot='start' className='text-sm'>
                You have no open orders.
              </span>
            </IonItem>
          }
        </>
      }

      {orders !== null && futureOrders && futureOrders.length > 0 && !isLoading &&
        <IonItem button detail lines='none' onClick={() => setShowFutureOrders(!showFutureOrders)}>
          {!showFutureOrders ? (
            <span className='text-sm text-green-600'>See future orders</span>
          ) : (
            <span className='text-sm text-green-600'>Hide future orders</span>
          )}
        </IonItem>
      }

      {showFutureOrders && futureOrders !== null &&
        <>
          {futureOrders.map((order: Order, index: number) => {
            let addOnItemCount = 0;
            let addOnTotal = 0;

            if (order.addOns?.length > 0) {
              order.addOns.forEach((addOn: { items: any[]; orderTotal: number }) => {
                addOnItemCount += addOn.items.length;
                addOnTotal += addOn.orderTotal;
              })
            }

            return (
              !isLoading &&
              <IonItem
                button
                onClick={() => openOrder(order)}
                detail={false}
                key={index}
                lines='none'
                className="mb-4"
              >
                <IonLabel>
                  <label className='text-base font-semibold'>
                    {dayjs(order.deliveryDate).format('MMM D YYYY')}
                  </label>

                  <p>{order.items.length + addOnItemCount} Items</p>
                </IonLabel>

                <span slot='end' className='text-sm font-semibold text-green-600'>
                    ${(order.orderTotal + addOnTotal).toFixed(2)}
                  </span>
              </IonItem>
            )
          })}

          {orders && !closedOrders?.length &&
            <IonItem detail lines='none'>
              <span slot='start' className='text-sm'>
                You have no future orders.
              </span>
            </IonItem>
          }
        </>
      }

      {orders !== null && closedOrders && closedOrders.length > 0 && !isLoading &&
        <IonItem button detail lines='none' onClick={() => setShowCompleted()}>
          {!showCompletedOrders ? (
            <span className='text-sm text-green-600'>See completed orders</span>
          ) : (
            <span className='text-sm text-green-600'>Hide completed orders</span>
          )}
        </IonItem>
      }

      {showCompletedOrders && closedOrders !== null &&
        <>
          {closedOrders.map((order: Order, index: number) => {
            let addOnItemCount = 0;
            let addOnTotal = 0;

            if (order.addOns?.length > 0) {
              order.addOns.forEach((addOn: { items: any[]; orderTotal: number }) => {
                addOnItemCount += addOn.items.length;
                addOnTotal += addOn.orderTotal;
              })
            }

            return (
              !isLoading &&
              <IonItem
                button
                onClick={() => openOrder(order)}
                detail={false}
                key={index}
                lines='none'
              >
                <IonLabel>
                  <label className='text-base font-semibold'>
                    {dayjs(order.deliveryDate).format('MMM D YYYY')}
                  </label>

                  <p>{order.items.length + addOnItemCount} Items</p>
                </IonLabel>

                <span slot='end' className='text-sm font-semibold text-green-600'>
                    ${(order.orderTotal + addOnTotal).toFixed(2)}
                  </span>
              </IonItem>
            )
          })}

          {orders && !closedOrders?.length &&
            <IonItem detail lines='none'>
              <span slot='start' className='text-sm'>
                You have no completed orders.
              </span>
            </IonItem>
          }
        </>
      }
    </IonList>
  );
};

export default OrdersList;
