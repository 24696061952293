/**
 * Endorsements
 * Endorsements are a Vendor Rating that is assigned by those
 * who have phsically visited the vendors locations. If they have
 * not visited the location, they should not be allowed to leave
 * and endorsement, instead show them a list of products from the
 * vendor, and allow them to leave a review.
 *
 */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import {useCallback, useEffect, useState} from 'react';

import {close} from 'ionicons/icons';

import {VendorStore} from './VendorStore';
import Product from '../Product/ProductClass';
import MWAvatar from '../../Components/MWAvatar/MWAvatar';
import {loadProduct} from '../Product/ProductStore';
import UseToggle from '../../Helpers/Hooks/UseToggle';
import {endorseVendor, getVendorProducts} from '../../Api/api.vendor';
import {ProductResultsType} from '../Product/types';

const VendorEndorseModal = ({...props}: any) => {
  const vendorStore = VendorStore.useState((s) => s);
  const vendorProductsBase: Array<Product> = [];

  const [vendorProducts, setVendorProducts] = useState(vendorProductsBase);
  const [relation, setRelation] = useState(1);
  const [visited, setVisited] = useState(false);
  const [safetyScore, setSafetyScore] = useState(3);
  const [review, setReview] = useState('');
  const [saving, setSaving] = UseToggle(false);
  const [showAlert] = useIonAlert();

  // Load up the Vendor Products in case they haven't visited
  const loadVendorProducts = useCallback(async () => {
    const results: ProductResultsType = await getVendorProducts(
      vendorStore.vendor?.id,
      1
    );
    setVendorProducts(results.products);
  }, [vendorStore.vendor?.id]);

  useEffect(() => {
    loadVendorProducts().then();
  }, [loadVendorProducts]);

  const postEndorsement = async () => {
    setSaving(true);

    await endorseVendor(vendorStore.vendor?.id, {
      comment: review,
      score: safetyScore,
      relationship: relation,
    }).then(setSaving(false));
    showAlert('Endorsement Sent!').then();
    props.onDismiss();

    // TODO: Add endorsement to api.vendor.ts, and to the YAML file
  };

  const reviewProduct = (product: Product) => {
    if (product && product.id) {
      props.onDismiss();
      loadProduct(product, true).then();
    }
  };

  return (
    <IonPage className="h-full overflow-hidden md:rounded-lg md:h-37.5rem">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton onClick={() => props.onDismiss()}>
              <IonIcon icon={close}/>
            </IonButton>
          </IonButtons>
          <IonTitle>
            Endorse{' '}
            {vendorStore.vendor?.name ? vendorStore.vendor.name : 'Loading...'}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {vendorStore.vendor && (
        <IonContent fullscreen>
          <IonList>
            <div className='p-6 text-lg font-bold leading-snug bg-green-50'>
              Have feedback on this Vendor after visiting their location?
            </div>

            <IonItem>
              <IonLabel className='text-gray-300 ion-text-wrap'>
                Have you visited the location where this vendor grows and/or
                produces food?
              </IonLabel>
              <IonSelect
                className='focus:ring-2 group group-focus:ring-2'
                value={visited ? 'true' : 'false'}
                placeholder='State'
                onIonChange={(evt: any) => {
                  setVisited(evt.target.value === 'true');
                }}
              >
                <IonSelectOption value={'true'}>Yes</IonSelectOption>
                <IonSelectOption value={'false'}>No</IonSelectOption>
              </IonSelect>
            </IonItem>

            {visited && (
              <IonItem>
                <IonLabel className='text-gray-300 ion-text-wrap'>
                  How do you know the vendor?
                </IonLabel>
                <IonSelect
                  className='focus:ring-2 group group-focus:ring-2'
                  value={relation}
                  placeholder='State'
                  onIonChange={(evt: any) => {
                    setRelation(evt.target.value);
                  }}
                >
                  <IonSelectOption value='1'>Patron</IonSelectOption>
                  <IonSelectOption value='2'>Relative</IonSelectOption>
                  <IonSelectOption value='3'>Friend</IonSelectOption>
                  <IonSelectOption value='4'>Employee</IonSelectOption>
                </IonSelect>
              </IonItem>
            )}

            {visited && (
              <>
                <IonItem>
                  <IonLabel className='text-gray-300 ion-text-wrap'>
                    After visiting their location, I have confidence in the
                    safety and health of the food this producer offers.
                  </IonLabel>
                  <IonSelect
                    className='focus:ring-2 group group-focus:ring-2'
                    value={safetyScore}
                    placeholder='State'
                    onIonChange={(evt: any) => {
                      setSafetyScore(evt.target.value);
                    }}
                  >
                    <IonSelectOption value={1}>
                      Strongly Disagree
                    </IonSelectOption>
                    <IonSelectOption value={2}>Disagree</IonSelectOption>
                    <IonSelectOption value={3}>Neutral</IonSelectOption>
                    <IonSelectOption value={4}>Agree</IonSelectOption>
                    <IonSelectOption value={5}>Strongly Agree</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonTextarea
                    placeholder='Type your review of this vendor (optional)...'
                    value={review}
                    onIonChange={(e: any) => {
                      setReview(e.target.value);
                    }}
                  ></IonTextarea>
                </IonItem>
              </>
            )}

            {visited && (
              <div className='px-4 mt-4'>
                <IonButton
                  fill='solid'
                  disabled={saving}
                  expand='block'
                  onClick={postEndorsement}
                >
                  {saving && <IonSpinner className='mr-2'/>}
                  Send Endorsement
                </IonButton>
              </div>
            )}

            {!visited && (
              <div className='px-4 py-4'>
                <div className='p-2  text-green-700 font-semibold rounded-full py-1 px-4 text-xs text-center'>
                  Endorsements are only available to those who have visited the
                  vendors location. But if you have tried any of these products,
                  click to leave a product review.
                </div>
              </div>
            )}
          </IonList>

          {!visited && (
            <IonList>
              {(vendorProducts || []).map((product: Product) => {
                return (
                  <IonItem>
                    <div slot='start' className='mr-4'>
                      <MWAvatar image={product.images[0]} slot='start'/>
                    </div>
                    <div className='leading-tight text-base line-clamp-2'>
                      {product.title}
                    </div>
                    <div slot='end'>
                      <IonButton
                        size='small'
                        onClick={() => reviewProduct(product)}
                      >
                        Review
                      </IonButton>
                    </div>
                  </IonItem>
                );
              })}
            </IonList>
          )}

          <div className='page-buffer p-10'/>
        </IonContent>
      )}
    </IonPage>
  );
};
export default VendorEndorseModal;
