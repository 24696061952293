import {Store} from 'pullstate';

export type VendorType = {
  id: number;
  name: string;
  city?: string;
  state?: string;
  avatar?: string;
  isFollowed?: boolean;
  heroImage?: string;
  endorsementScore?: any;
  description?: string;
  followers: number;
  products?: number;
}

const starterStore: any = {
  vendor: null,
  products: {products: null, totalResults: null, currentPage: 1, rowsPerPage: null},
  posts: null,
};

export const VendorStore = new Store(starterStore);

/**
 * Set's the 'active vendor' for the user store.
 *
 * The 'active vendor' in this context is the vendor the user has chosen to route to or view in a modal.
 * @param vendor
 * @returns true/false
 */
export const setVendor = (vendor: VendorType) => {
  try {
    resetVendorStore();

    VendorStore.update((s) => {
      s.vendor = vendor;
    });

    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
}

/**
 * Set's a SIMPLE property on the VendorStore by the passed key.
 *
 * @param key Property to set on VendorStore
 * @param data Data to be set to 'key'
 * @returns true if successful, false otherwise.
 */
export const setVendorStoreProperty = (key: string, data: any) => {
  try {
    VendorStore.update((s) => {
      s[key] = data;
    });

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
 * Resets the vendor store to it's default state
 * @returns true if successful, false otherwise.
 */
const resetVendorStore = () => {
  try {
    VendorStore.update((s) => {
      s.vendor = undefined;
      s.products = {products: [], totalResults: 0, currentPage: 1, rowsPerPage: 0};
      s.posts = [];
    });

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
