import {useIonModal} from '@ionic/react';
import AddToCartButton from '../../../Components/AddToCartButton/AddToCartButton';
import MWAvatar from '../../../Components/MWAvatar/MWAvatar';
import Product from '../ProductClass';
import {ProductModal} from '../ProductModal';
import {loadProduct} from '../ProductStore';

const ProductListItem = ({...props}) => {
  const product: Product = props.product;
  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });

  const localOpenProduct = (product: Product) => {
    if (product && product.id) {
      loadProduct(product).then();
      presentProductModal();
    }
  }

  if (product) {
    return (
      <div
        aria-label={product.title}
        className='bg-white pb-3'
      >
        <div className='flex items-center justify-between px-4'>
          <button
            className='flex items-center justify-start text-ellipsis whitespace-pre-wrap max-w-75%'
            onClick={() => {
              localOpenProduct(product)
            }}
          >
            <MWAvatar className='mr-2' image={product.images[0]} slot='start'/>

            <div className='self-center text-left'>
              <p className='font-bold line-clamp-2 leading-tight break-all'>
                {product.title.trim()}
              </p>

              <p className='text-xs text-gray-500 line-clamp-1'>
                {product.vendor?.name}
              </p>
            </div>
          </button>

          <AddToCartButton
            buttonType={props.buttonType}
            additionalStyles='self-end self-center'
            productId={product.id}
            qty={1}
            variantId={product?.variants[0].id ?? null}
          />
        </div>

        <div className='block h-1px mx-auto w-5/6 mt-2' style={{backgroundColor: '#c8c6cc'}}></div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ProductListItem;
