import ProductModel from "../Product/product.model";

export default class BundleModel {
  id: string;
  title: string;
  slug?: string;
  description?: string;
  image?: string;
  subscriptionInterval?: number;
  subscriptionStatus?: false;
  total?: number;

  products?: Array<ProductModel>;

  constructor(input: any) {
    this.id = input.id;
    this.title = input.title;
    this.slug = input.slug;
    this.description = input.description;
    this.image = input.image;
    this.subscriptionInterval = input.subscriptionInterval;
    this.subscriptionStatus = input.subscriptionStatus;
    this.total = input.total;
    this.products = input.products ? input.products.map((i: any) => new ProductModel(i)) : undefined;
  }
}
