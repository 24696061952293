import {
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonBadge,
  IonSpinner,
  useIonModal, useIonRouter,
} from '@ionic/react';
import {
  cartOutline,
  cart,
  flashOutline,
  flash,
  home,
  homeOutline,
  gift,
  menu,
  menuOutline, giftOutline,
} from 'ionicons/icons';
import {useStoreState} from 'pullstate';
import {useEffect, useState} from 'react';
import CartItem from '../Cart/CartItemClass';
import {CartStore} from '../Cart/CartStore';
import {UserStore} from '../User/UserStore';
import CartModal from '../Cart/CartModal';
import IFrameModalController from "../../Components/IframeModal/IframeModalController";

const TabBar = ({...props}) => {
  const router = useIonRouter();
  const userState = useStoreState(UserStore);
  const cartState = CartStore.useState((s) => s, []);
  const [activeTab, setActiveTab] = useState('home');
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const [presentCartModal, dismissCartModal] = useIonModal(CartModal, {
    onDismiss: () => {
      dismissCartModal();
    },
    router: router
  });


  useEffect(() => {
    if (router.routeInfo.pathname === "/favorites") {
      setActiveTab("favorites")
    }
    if (router.routeInfo.pathname === "/more") {
      setActiveTab("more")
    }
  }, [router])

  return (
    <footer
      style={{
        paddingBottom: 'env(safe-area-inset-bottom, 0.5vh)',
        boxShadow: '0px -6px 10px rgba(0,0,0,0.09)',
      }}
      className={`sticky bottom-0 right-0 z-50 bg-green-600 transform-gpu transition-all ease-in-out duration-100  ${props.className || ''}`}
    >
      <IonTabBar
        color='light'
        className='text-white bg-green-600 '
        onIonTabsWillChange={(e) => {
          if (e.detail.tab === 'cart') {
            e.stopImmediatePropagation();
            presentCartModal();
          } else if (e.detail.tab === 'gift-cards') {
            e.stopImmediatePropagation();
            setShowGiftCardModal(true);
          } else {
            setActiveTab(e.detail.tab);
          }
        }}
      >
        <IonTabButton tab='home' href='/shop'>
          <IonIcon
            size='small'
            icon={activeTab === 'home' ? home : homeOutline}
          />

          <IonLabel>Shop</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab='favorites'
          href='/favorites'
          disabled={!userState.hasOwnProperty('isGuest') ? true : userState.isGuest}
        >
          <IonIcon
            size='small'
            icon={activeTab === 'favorites' ? flash : flashOutline}
          />

          <IonLabel>Quick Shop</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab='cart'
          disabled={!userState.hasOwnProperty('isGuest')}
        >
          <IonIcon
            size='small'
            icon={activeTab === 'cart' ? cart : cartOutline}
          />

          {/* {userState && userState.hubId && userState.hubs && userState.hubs.length && */}
          <IonLabel>
            {cartState.loading && userState && userState.hubId && userState.hubs && userState.hubs.length ?
              <IonSpinner/> : 'Cart'}
          </IonLabel>
          {/* } */}

          {!!cartState.items && !!cartState.items.length && (
            <IonBadge
              className={`text-white bg-red-500`}
            >
              {cartState.items.map((cartItem: CartItem) => cartItem.quantity || 0).reduce((a, b) => (a || 0) + b || 0)}
            </IonBadge>
          )}
        </IonTabButton>

        <IonTabButton
          tab='gift-cards'
        >
          <IonIcon
            size='small'
            icon={activeTab === 'gift-cards' ? gift : giftOutline}
          />

          <IonLabel>Gift Cards</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab='more'
          href='/more'
          disabled={!userState.hasOwnProperty('isGuest')}
        >
          <IonIcon
            size='small'
            icon={activeTab === 'more' ? menu : menuOutline}
          />

          <IonLabel>More</IonLabel>
        </IonTabButton>
      </IonTabBar>
      <IFrameModalController
        onDismiss={() => {
          setShowGiftCardModal(false);
        }}
        title='Gift Cards'
        url={process.env.REACT_APP_GIFT_CARD_URL ?? 'https://portal.marketwagon.com/shop/gift-cards'}
        open={showGiftCardModal}
      />
    </footer>
  );
};

export default TabBar;
