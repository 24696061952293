import Cookies from "js-cookie";

export const createCookie = (name: string, value: any, time: number) => {
  name = process.env.REACT_APP_ENV === 'staging' ? 'staging_' + name : name;

  if (!Cookies.get(name)) {
    const date = new Date();
    date.setTime(date.getTime() + time);

    const domain = process.env.REACT_APP_ENV === 'local' ? 'localhost' : 'marketwagon.com';

    Cookies.set(name, value, {
      expires: date,
      domain: domain,
      path: '/',
      secure: true,
      sameSite: 'lax',
    });
  }
};
