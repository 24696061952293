import {CreateAnimation} from '@ionic/react';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import {Link} from 'react-router-dom';
import {useState} from 'react';
import PublicPage from '../../Components/Page/PublicPage';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';

const Page404 = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <PublicPage>
      <Helmet>
        <title>404 | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/404"/>
      </Helmet>

      <GeneralHeader
        title='Page Not Found'
        backHref='/shop'
      />

      <section className='relative w-full min-h-screen overflow-hidden bg-gray-500'>
        <div className='bg-black error' style={{minHeight: '50vh'}}>
          <div
            className='flex items-end justify-center pt-10 road'
            style={{height: '30vh'}}
          >
            <CreateAnimation
              play={true}
              duration={10000}
              iterations={Infinity}
              fromTo={{
                property: 'transform',
                fromValue: 'translateX(65vw)',
                toValue: 'translateX(-65vw)',
              }}
            >
              <img
                src='/images/trail.png'
                alt='The Trail has ended - this page is missing'
                style={{height: '70px'}}
              />
            </CreateAnimation>
          </div>

          <div className='px-6 pt-20 pb-10 bg-green-500 grass'>
            <div className='max-w-lg p-1 mx-auto text-white bg-black rounded-md message'>
              <div
                className='py-2 font-mono text-2xl font-extrabold text-center rounded-lg upper ring-white ring-inset ring-8'>
                Page died of dysentery.
              </div>
            </div>
          </div>

          <Link
            to='/'
            className='flex underline justify-center pt-1 font-mono text-xl font-bold text-center text-white bg-black hover:bg-blue-400 center'
          >
            Click HERE to return the Homestead
          </Link>

          <IFrameModalController
            open={modalOpen}
            url="https://marketwagon.com/pages/contact-us.php"
            title="Contact Us"
            onDismiss={() => {
              setModalOpen(false);
            }}
          />

          <div className="flex items-center justify-center py-4">
            <button onClick={() => {
              setModalOpen(true);
            }} className="font-bold bg-green-500 font-mono py-1 px-3 rounded-full">
              Need help?
            </button>
          </div>
        </div>
      </section>
    </PublicPage>
  );
};

export default Page404;
