import dayjs from "dayjs";
import {personSharp, calendarSharp} from 'ionicons/icons';
import {IonIcon} from "@ionic/react";

const BlogPostCard = (props: any) => {
  const generateDescription = () => {
    let parsedBody = props.body.replace(/(<([^>]+)>)/gi, "");
    parsedBody = `${parsedBody.substring(0, 200)}...`;

    return parsedBody;
  }

  return (
    <article className="bg-white rounded-md shadow-lg mb-8">
      {props.image &&
        <img
          src={props.image}
          alt={props.title}
          className='w-full h-56 object-cover rounded-t-lg'
        />
      }
      <header className='px-4 pt-4'>
        <h3 className='font-medium text-lg mb-3 leading-5'>{props.title}</h3>

        {props.author &&
          <p className={`flex items-center text-sm`}>
            <IonIcon
              icon={personSharp}
            />
            &nbsp;
            <span className='font-semibold text-sm leading-tight'>
                            by {props.author}
                        </span>
          </p>
        }

        {props.postDate &&
          <p className='flex items-center mb-3 text-sm'>
            <IonIcon
              icon={calendarSharp}
            />
            &nbsp;
            <span className='text-xs leading-tight'>
                            {dayjs(props.postDate).format('MMM D, YYYY')}
                        </span>
          </p>
        }

        <p className='leading-5'>{generateDescription()}</p>
      </header>

      <p className='p-4 block text-green-500 hover:underline'>Read More</p>
    </article>
  );
};

export default BlogPostCard;
