import dayjs from 'dayjs';
import MWAvatar from '../MWAvatar/MWAvatar';
import {ChatBubbleProps} from './types';

const handleMessageData = (messageData: any) => {
  return (
    <p
      className='mt-1 text-base leading-snug'
      dangerouslySetInnerHTML={{__html: messageData}}
      style={{wordBreak: 'break-word'}}
    />
  )
}

const ChatBubble = ({...props}: ChatBubbleProps) => {
  return (
    <div
      className={`ChatBubble flex items-center ${
        props.direction === 'from' ? 'justify-start flex-row-reverse' : ''
      }`}
    >
      <div
        className={`flex items-start ${
          props.direction === 'from' ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <MWAvatar
          size='sm'
          hideLabel
          label={`${props.name}`}
          image={props.avatar}
        />
        <main
          className={`px-3 py-3 text-sm rounded-xl ${
            props.direction === 'from'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-100 flex-row'
          }`}
        >
          <p className='flex items-center text-xs font-bold leading-1'>
            {props.name}
            <span className='ml-1 font-normal opacity-50'>
              {dayjs(new Date(`${props.date}`)).format('ddd MMM D YYYY')}
            </span>
          </p>

          {props.message && (
            handleMessageData(props.message)
          )}
        </main>
      </div>
    </div>
  );
};

export default ChatBubble;
