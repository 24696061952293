import {useEffect, useState} from 'react';
import HubTile from './HubTile';
import {IonSkeletonText,} from '@ionic/react';
import PublicPage from '../../../Components/Page/PublicPage';
import {Helmet} from "react-helmet";
import AppHeader from '../../../Components/Header/AppHeader';
import {useListHubs} from "../hub.actions";
import HubModel from "../hub.model";
import {LoadingState} from "../../store";

const HubsPage = () => {
  const [hubs, loadingState] = useListHubs();
  const [filteredHubs, setFilteredHubs] = useState<HubModel[] | undefined>(undefined);

  useEffect(() => {
    setFilteredHubs(hubs);
  }, [hubs]);

  const filter = (zipCode: string | undefined) => {
    if (!zipCode) {
      setFilteredHubs(hubs);
    } else {
      const newHubs =  hubs?.filter((value) => {
        return (
          value.accessibleZipCodes?.find((zip) => zip.startsWith(zipCode.trim())) !==
          undefined
        );
      });
      setFilteredHubs(newHubs);
    }
  };

  return (
    <PublicPage>
      <Helmet>
        <title>Markets</title>
        <link rel="canonical" href="https://shop.marketwagon.com/hubs"/>
      </Helmet>

      <AppHeader/>

      <main className='relative bg-gray-100'>
        <div className='max-w-screen-lg mx-auto p-4 pb-10 min-h-screen'>
          {/* Content Header  */}
          <header className='px-2 mb-4 lg:my-10'>
            <h1 className='font-bold text-2xl lg:text-4xl mb-1'>
              Online Farmers Markets
            </h1>

            <p className='text-gray-600'>
              Our online farmers markets require no subscription, no minimum
              orders, and no cost to join. Just simple online shopping for local
              food.
            </p>
          </header>

          {loadingState === LoadingState.LOADED && (
            <div className='flex justify-center'>
              <input
                className='p-1 pl-4 pr-1 mx-auto overflow-hidden bg-white border border-gray-200 shadow-lg rounded-xl bg-white-200'
                placeholder='Filter by zip code'
                aria-label='ZipCode'
                type='text'
                onChange={(evt: any) => {
                  const zipCode = evt?.target?.value;
                  filter(zipCode);
                }}
              />
            </div>
          )}

          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 lg:grid-cols-3'>
            {loadingState !== LoadingState.LOADED &&
              [1, 2, 3, 4, 5, 6].map((i) => {
                return (
                  <div key={i} className='bg-white'>
                    <IonSkeletonText animated style={{height: '160px'}}/>
                    <div className='flex p-2 space-x-2'>
                      <IonSkeletonText style={{height: '40px'}}/>
                      <IonSkeletonText style={{height: '40px'}}/>
                    </div>
                  </div>
                );
              })}

            {loadingState === LoadingState.LOADED &&
              filteredHubs?.map((hub) => {
                return <HubTile item={hub} key={hub.id}/>;
              })}
          </div>
        </div>
      </main>
    </PublicPage>
  );
};
export default HubsPage;
