import {IonSegment, IonSegmentButton, IonSpinner, IonToolbar, useIonModal, useIonRouter} from '@ionic/react';
import {useCallback, useEffect, useState} from 'react';
import ProductItem from '../Product/ProductItem/ProductItem';
import Vendor from '../Vendor/VendorClass';
import {VendorItem} from '../Vendor/VendorItem';
import {
  FavoritesStore,
  loadFavoriteProducts,
  loadFavoriteVendors,
} from './FavoritesStore';
import VendorModal from '../Vendor/VendorModal';
import {setVendor} from '../Vendor/VendorStore'

const FavoritesList = ({...props}: any) => {
  const favoritesState = FavoritesStore.useState((s) => s, []);
  const [viewState, setViewState] = useState('products');
  const [loading, setLoading] = useState(false);
  const router = useIonRouter();
  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();
    },
    router: router,
  });

  /**
   * Open the Vendor Modal
   * @param vendor
   */
  const openVendor = async (vendor: Vendor) => {
    setVendor(vendor);
    presentVendorModal();
  }

  /**
   * Hook for ViewState and favorite length
   */
  const loadItems = useCallback(() => {
    if (viewState === 'vendors') {
      setLoading(true)
      loadFavoriteVendors().then(() => {
        setLoading(false)
      })
    } else if (viewState === 'products') {
      setLoading(true)
      loadFavoriteProducts().then(() => {
        setLoading(false)
      });
    }
  }, [viewState]);


  useEffect(() => {
    if (viewState) {
      loadItems();
    }
  }, [viewState, loadItems])

  return (
    <>
      <IonToolbar>
        <IonSegment value={viewState}>
          <IonSegmentButton
            value='products'
            onClick={() => setViewState('products')}
          >
            Products
          </IonSegmentButton>
          <IonSegmentButton
            value='vendors'
            onClick={() => setViewState('vendors')}
          >
            Vendors
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      {loading && <div className='pt-4 px-4 text-center flex items-center space-x-2 justify-center'>
        <IonSpinner/>
        <span className="font-medium text-gray-500">Loading...</span>
      </div>}

      {/* Vendor List  */}
      {viewState === 'vendors' && favoritesState.vendors.length > 0 && (
        <div className='max-w-screen-md py-4 mx-auto favorites'>
          {favoritesState.vendors.map((vendor: Vendor, index: number) => {
            return (
              <VendorItem
                key={index}
                vendor={vendor}
                onClick={() => {
                  openVendor(vendor).then();
                }}
              />
            );
          })}
        </div>
      )}

      {/* Product List  */}
      {viewState === 'products' && (
        <div className='max-w-screen-md py-4 pb-0 mx-auto products'>
          {favoritesState.products.map((product: any, index: number) => {
            return <ProductItem
              type='item'
              key={index}
              product={product}
              buttonType={props.buttonType}
            />;
          })}
        </div>
      )}


      {!loading && viewState === 'vendors' && favoritesState.vendors.length === 0 && (
        <div className='p-4'>
          <div className='empty-block'>
            <h5>No Favorite Vendors 😢</h5>
            <p className='items-center mb-4 text-sm text-gray-700 align-middle'>
              Looks like you haven't favorited any vendors yet. Tap the heart
              icon on your favorite vendor to have them saved here.
            </p>
          </div>
        </div>
      )}

      {!loading && viewState === 'products' && favoritesState.products.length === 0 && (
        <div className='p-4'>
          <div className='empty-block'>
            <h5>No Favorite Products 😢</h5>
            <p className='items-center mb-4 text-sm text-gray-700 align-middle'>
              Looks like you haven't favorited any products yet. Tap the heart
              icon on your favorite products to have them saved here.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default FavoritesList;
