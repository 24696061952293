import {useEffect, useState} from 'react';
import IFrameModalController from '../../../Components/IframeModal/IframeModalController';
import BannerModel from "../banner.model";
import {useListBanners} from "../banner.actions";

const BannersSection = () => {
  const [allBanners] = useListBanners();
  const [showGiftCardModal, setShowGiftCardModal] =
    useState<boolean>(false);
  const actOnClick = (action: string | undefined) => {
    if (!action) {
      return;
    }

    if (action === 'modal=gift-card') {
      setShowGiftCardModal(true);
    }
  };


  const [banners, setBanners] = useState<BannerModel[]>([]);

  useEffect(() => {
    if ((allBanners ?? []).length < 4) {
      setBanners((allBanners ?? []));
    } else {
      let bannerCount = 0;
      let tempBanners: any = [];
      let warning = (allBanners ?? []).filter((banner) => {
        return banner.type === 'warning';
      });
      let info = (allBanners ?? []).filter((banner) => {
        return banner.type === 'information';
      });
      let others = (allBanners ?? []).filter((banner) => {
        return banner.type !== 'information' && banner.type !== 'warning';
      });

      warning.forEach((banner) => {
        if (bannerCount < 3) {
          bannerCount++;
          tempBanners.push(banner);
        }
      });
      info.forEach((banner) => {
        if (bannerCount < 3) {
          bannerCount++;
          tempBanners.push(banner);
        }
      });
      others.forEach((banner) => {
        if (bannerCount < 3) {
          bannerCount++;
          tempBanners.push(banner);
        }
      });
      setBanners(tempBanners);
    }
  }, [allBanners]);

  return (
    <section className='bg-white space-y-2'>
      <IFrameModalController
        onDismiss={() => {
          setShowGiftCardModal(false);
        }}
        title='Gift Cards'
        url='https://portal.marketwagon.com/shop/gift-cards'
        open={showGiftCardModal}
      />
      {(allBanners ?? []) &&
        banners.map((banner, index: number) => {
          return (
            <div className='' key={index}>
              {banner.type && banner.type === 'information' && (
                <div className='bg-yellow-50 p-4 text-center shadow-md'>
                  <div className='flex justify-center '>
                    <div className='ml-3'>
                      <h3 className='font-medium text-yellow-800'>
                        {banner.title}
                      </h3>
                      <div
                        onClick={() => {
                          actOnClick(banner.action);
                        }}
                        className={
                          'mt-2 text-sm text-yellow-700 max-w-6xl ' +
                          (banner.action ? 'cursor-pointer' : '')
                        }
                        dangerouslySetInnerHTML={{__html: banner.body}}
                      />
                    </div>
                  </div>
                </div>
              )}
              {banner.type && banner.type === 'warning' && (
                <div className='bg-red-50 p-4 text-center shadow-md'>
                  <div className='flex justify-center'>
                    <div className='ml-3'>
                      <h3 className='font-medium text-red-800'>
                        {banner.title}
                      </h3>
                      <div
                        onClick={() => {
                          actOnClick(banner.action);
                        }}
                        className={
                          'mt-2 text-sm text-yellow-700 max-w-6xl ' +
                          (banner.action ? 'cursor-pointer' : '')
                        }
                        dangerouslySetInnerHTML={{__html: banner.body}}
                      />
                    </div>
                  </div>
                </div>
              )}
              {banner.type !== 'warning' && banner.type !== 'information' && (
                <div className='bg-blue-50 p-4 text-center shadow-md'>
                  <div className='flex justify-center'>
                    <div className='ml-3'>
                      <h3 className='font-medium text-blue-800'>
                        {banner.title}
                      </h3>
                      <div
                        onClick={() => {
                          actOnClick(banner.action);
                        }}
                        className={
                          'mt-2 text-sm text-blue-700 max-w-6xl ' +
                          (banner.action ? 'cursor-pointer' : '')
                        }
                        dangerouslySetInnerHTML={{__html: banner.body}}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </section>
  );
};
export default BannersSection;
