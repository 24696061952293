export default class HubModel {
  id: string;
  name: string;
  description?: string = undefined;
  deliveryDay?: Date = undefined;
  deliveryFee?: number = undefined;
  zipCode?: string = undefined;
  city?: string = undefined;
  state?: string = undefined;
  lat?: string = undefined;
  lng?: string = undefined;
  slug?: string = undefined;
  boundaries?: string = undefined;
  accessibleZipCodes?: Array<string> = undefined;

  constructor(input: any) {
    this.id = input.id;
    this.name = input.name;
    this.description = input.description;
    this.deliveryDay = input.deliveryDay;
    this.deliveryFee = input.deliveryFee;
    this.zipCode = input.zipCode;
    this.city = input.city;
    this.state = input.state;
    this.lat = input.lat;
    this.lng = input.lng;
    this.slug = input.slug;
    this.boundaries = input.boundaries;
    this.accessibleZipCodes = input.accessibleZipCodes;
  }

}
