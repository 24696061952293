import {useEffect, useRef} from "react";

const UseOutsideClick = (callback: any) => {
  const ref = useRef<any>(null);


  useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback]);

  return ref;
};

export default UseOutsideClick;
