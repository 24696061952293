import {PostCardType} from '../Domains/Post/types';
import Product from '../Domains/Product/ProductClass';
import {ProductResultsType} from '../Domains/Product/types';
import Vendor from '../Domains/Vendor/VendorClass';
import {DELETE, GET, POST} from './Api';
import {setVendorStoreProperty} from '../Domains/Vendor/VendorStore';

export const getVendor = async (id: number | string, hashed: boolean = false): Promise<Vendor | undefined> => {
  try {
    const results = await GET(`/vendors/${id}${hashed ? '?hashed=true' : ''}`);
    setVendorStoreProperty('vendor', results.payload);
    // Refactor: Remove class based constructor
    return new Vendor(results.payload);
  } catch (e) {
    console.error(e);
    // Refactor: Remove undefined, return null/false (likely false since we are committing to pullstate/stores)
    return undefined;
  }
};
export const getVendorPure = async (id: number | string): Promise<Vendor | null> => {
  try {
    const results = await GET(`/vendors/${id}`);
    return new Vendor(results.payload);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getFavoriteVendors = async (): Promise<Array<Vendor>> => {
  const results = await GET(`/favorites/vendors`);
  return results.payload.map((vendor: any) => new Vendor(vendor));
};

export const getAllFavoriteVendors = async (): Promise<Array<Vendor>> => {
  const results = await GET(`/favorites/all-vendors`);
  return results.payload.map((vendor: any) => new Vendor(vendor));
};

export const favoriteVendor = async (vendorId: number) => {
  return await POST(`/vendors/${vendorId}/favorite`);
};

export const unfavoriteVendor = async (vendorId: number) => {
  return await DELETE(`/vendors/${vendorId}/favorite`);
};

export const getVendorPosts = async (
  vendorId: number
): Promise<Array<PostCardType>> => {
  const results = await GET(`/vendors/${vendorId}/posts`, 1, 10);

  return results.payload.map((post: PostCardType) => {
    if (post.product) post.product = new Product(post.product);
    return post;
  });
};
export const getVendors = async (
  sort: string,
) => {
  const call = await GET(`/vendors?sort=${sort}`);

  return call.payload;
};

export const getVendorProducts = async (
  vendorId: any,
  requestedPage: number
): Promise<ProductResultsType> => {
  try {
    const page: number = requestedPage ? parseInt(`${requestedPage}`) : 1;
    const results = await GET(`/vendors/${vendorId}/products`, requestedPage, 30);

    const products = results.payload.map((product: any) => {
      return new Product(product);
    });

    const fullResults = {
      products,
      currentPage: page,
      totalResults: results.totalRows ?? 0,
      rowsPerPage: results.perPage ?? 0,
    };

    setVendorStoreProperty('products', fullResults);

    return fullResults;
  } catch (e) {
    console.error(e);
    return {
      products: [],
      currentPage: 0,
      totalResults: 0,
      rowsPerPage: 0,
    };
  }
};

export const getVendorProductsPure = async (
  vendorId: any,
  requestedPage: number
): Promise<ProductResultsType> => {
  try {
    const page: number = requestedPage ? parseInt(`${requestedPage}`) : 1;
    const results = await GET(`/vendors/${vendorId}/products`, requestedPage, 18);

    const products = results.payload.map((product: any) => new Product(product));

    return {
      products,
      currentPage: page,
      totalResults: results.totalRows ?? 0,
      rowsPerPage: results.perPage ?? 0,
    };
  } catch (e) {
    console.error(e);
    return {
      products: [],
      currentPage: 0,
      totalResults: 0,
      rowsPerPage: 0,
    };
  }
};

export const endorseVendor = async (vendorId: any, data: any) => {
  const results = await POST(`/vendors/${vendorId}/endorse`, data);
  return results.success;
};
