import CartItem from '../Cart/CartItemClass';
import {OrderType, AddressType, AddonType} from './types';

export default class Order implements OrderType {
  id?: string;
  created?: Date;
  items: Array<CartItem>;
  deliveryAddress?: AddressType;
  pickupAddress?: AddressType;
  addOns: AddonType[];

  status: 'open' | 'closed' | 'pending';
  type: 'delivery' | 'pickup';
  tax: number;
  tip: number;
  processingFee: number;
  orderTotal: number;
  deliveryDate: string | null;

  constructor(starter: OrderType) {
    this.id = starter.id || this.id;
    this.created = starter.created || new Date();
    this.items = (starter.items || []).map((i: any) => new CartItem(i));
    this.orderTotal = starter.orderTotal || 0;
    this.deliveryAddress = starter.deliveryAddress;
    this.pickupAddress = starter.pickupAddress;
    this.addOns = starter.addOns || [];
    this.status = starter.status || 'open';
    this.type = starter.type || 'delivery';
    this.tax = starter.tax || 0;
    this.tip = starter.tip || 0;
    this.processingFee = starter.processingFee || 0;
    this.deliveryDate = starter.deliveryDate || null;
  }

  get calculateTotal(): string {
    let subtotal = this.items
      .map((item: CartItem) => {
        return (item.product.variant.price || 0) * (item.quantity || 0);
      })
      .reduce((a, b) => a + b, 0);

    return (subtotal + this.tip + this.processingFee + this.tax).toFixed(2);
  }
}
