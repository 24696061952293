import {FAQQuestion} from './types';
import {IonRouterLink} from "@ionic/react";
import IFrameModalController from "../../Components/IframeModal/IframeModalController";
import {useState} from "react";

const OrderComponent =
  () => {
    const [showWagonPassModal, setShowWagonPassModal] = useState(false);
    return <>
      <>Visit <IonRouterLink routerLink='/shop'>MarketWagon.com</IonRouterLink> and input your zip code to shop from
        farmers and artisans local to your community. Browse the store, select your goods, and checkout before the order
        deadline to receive your weekly order. Your weekly delivery date is located at the top of the site and on your
        order confirmation email.
        You can get delivery in one of three ways:
        1) Delivery to your home for a flat fee, 2) Delivery to a market host near you for free, or 3) Help us get your
        employer set up as a workplace wellness host and receive free delivery straight to your place of work.
        <br/>
        You can also join our <button onClick={() => setShowWagonPassModal(true)}
                                      className='whitespace-nowrap text-green-600'>Wagon Pass</button> program, for
        weekly free delivery.
      </>
      <IFrameModalController
        onDismiss={() => {
          setShowWagonPassModal(false);
        }}
        title='Wagon Pass'
        url={process.env.REACT_APP_WAGON_PASS_URL ?? 'https://marketwagon.com/wagon-pass'}
        open={showWagonPassModal}
      />
    </>
  };

const WagonPassComponent =
  () => {
    const [showWagonPassModal, setShowWagonPassModal] = useState(false);
    return <><>Save BIG on local food delivered weekly with a fixed monthly rate of $13.95 or a yearly pass of $139.95
      with <button onClick={() => setShowWagonPassModal(true)} className='whitespace-nowrap text-green-600'>Wagon
        Pass</button>. If you order weekly, you can save more than $250 in a year. Plus, you'll have access to
      members-only offers and Wagon Day Perks!
    </>
      ,
      <IFrameModalController
        onDismiss={() => {
          setShowWagonPassModal(false);
        }}
        title='Wagon Pass'
        url={process.env.REACT_APP_WAGON_PASS_URL ?? 'https://marketwagon.com/wagon-pass'}
        open={showWagonPassModal}
      />
    </>
  }

const FAQQuestions: FAQQuestion[] = [
  {
    id: 1,
    question: 'How do I place an order?',
    answer: OrderComponent,
    phrases: [],
  },
  {
    id: 2,
    question: 'Does Market Wagon deliver in my area?',
    answer:
      <>We have multiple delivery areas serving millions of households in several states. To know if we serve your area,
        you can enter your zip code on our <IonRouterLink routerLink='/'>home page</IonRouterLink> or look at the list
        of <IonRouterLink routerLink='/hubs'>delivery networks</IonRouterLink>.</>,
    phrases: [
      'deliver',
      'area',
      'to me',
      'to my',
      'city',
      'where',
      'zip code',
      'address',
    ],
  },
  {
    id: 3,
    question: 'When will my order arrive?',
    answer:
      <>The day of week is displayed at the top of the website and in your confirmation email. Typically, we only
        deliver one or two days out of the week. Not every day. Typically, your order will arrive in mid-to-late
        afternoon. <b>We are not able to provide precise arrival times.</b> However, you will receive an email and text
        message as soon as the order arrives. </>,
    phrases: ['when', 'time', 'estimate', 'arrive', 'delivery'],
  },
  {
    id: 4,
    question: "Can I add items to a delivery after I've ordered?",
    answer:
      <>Yes. After you have placed an order, if something new catches your eye, you can add that item to your existing
        order without incurring additional delivery fees. First, be sure you're logged in before checkout. Next, make
        sure that you're shopping for the same delivery day as your open order. Then, just add to your cart only the new
        items you wish to add. Proceed to check out and your delivery option will be defaulted to "Add Items to my
        existing order." with no delivery fees. </>,
    phrases: ['addon', 'add on', 'add-on', 'additional', 'more', 'to my order'],
  },
  {
    id: 5,
    question: 'What do I do with my empty tote(s)?',
    answer:
      <>Our totes and ice packs are reusable. Simply set out your empty bag(s) and the ice packs on your next order and
        our delivery drivers will pick them us. This help us to keeps our costs down, and reduces waste.</>,
    phrases: ['tote', 'return', 'reuse', 'exchange'],
  },
  {
    id: 6,
    question: 'Is there a contract? Subscription? Minimum order?',
    answer:
      <>There is no contract, no subscription, and no minimum order to receive delivery. Buy what you want, when you
        want it.</>,
    phrases: ['subscribe', 'automatic', 'contract', 'minimum']
  },
  {
    id: 7,
    question: 'How does my food stay cold and safe?',
    answer: <>Market Wagon will use necessary means, including but not limited to insulated packaging, cold packs (“ice
      packs”), pre-chilled containers, and packing temperature-compatible items in the same tote, to ensure your
      products arrive at the address that you specified for your order at safe temperatures for the food contained in
      your order at the time of delivery. Meats may be delivered frozen or partially thawed at or below 41 degrees
      Fahrenheit. Meats that have been thawed under refrigeration and kept cold can be safely re-frozen. Or, store the
      thawed meats in your refrigerator and cook within 7 days.</>,
    phrases: ['defrost', 'frozen', 'cold', 'thaw', 'refreeze', 'freeze'],
  },
  {
    id: 8,
    question: "How does Market Wagon vet farmers/artisans?",
    answer:
      <>Market Wagon’s mission is to support local farmers and artisans through an online marketplace. We connect
        farmers and artisans to our customers and allow the vendor to describe the process they use to raise or prepare
        their products. If you have any questions, there is a chat feature in the store that allows you to ask anything
        of the farmer. We want to you to understand where your food comes from and allow you to make that decision based
        on your preferences.</>,
    phrases: ['farm', 'vendor'],
  },
  {
    id: 9,
    question: "What does Market Wagon do in the winter?",
    answer:
      <>Market Wagon is a year-round service that sources a variety of farmer and artisan products. While produce will
        come and go with the seasons, we expect more and more of our farmers to have fruits and vegetables into the
        winter time as they construct new greenhouses and increase the amount they’re planting. Otherwise, our customers
        can shop for a variety of goods like eggs, meats, dairy, grocery items, easy ready made meals, household goods,
        and pet foods regardless of the season.</>,
    phrases: []
  },
  {
    id: 10,
    question: "What if I experience a quality issue or missing product?",
    answer:
      <>If you do not receive a product or are not 100% satisfied with the quality of the product you’ve received,
        please let us know at <IonRouterLink routerLink='/support'>support</IonRouterLink>.</>,
    phrases: ["problem", "damage", "broken", "poor", "missing", "receive", "recieve", "was not", "checked"]
  },
  {
    id: 11,
    question: "Does Market Wagon save my information for future orders?",
    answer: <>Upon logging in, Market Wagon will provide you with information about your previous orders, including a
      favorites section that will automatically suggest products to you based on your past selections. After checking
      out, it will also remember your previous address or location selected, so you can streamline your shopping
      experience.</>,
    phrases: []
  },

  {
    id: 12,
    question: "Do I need to tip my driver?",
    answer: <>Market Wagon drivers do not rely on tips. But you may choose to give an optional gratuity to your delivery
      driver in cash. Or, you can add a tip for the Market Wagon delivery team with your credit card during the checkout
      process.</>,
    phrases: ["tip", "tipping"]
  },
  {
    id: 13,
    question: "Do you accept SNAP/EBT as payment?",
    answer: <>Unfortunately, the USDA rules on SNAP/EBT card payments do not allow for online vendors like Market Wagon
      to accept the cards as payment. We are eagerly awaiting a change in that policy, but until then, we cannot accept
      SNAP, EBT, or "food stamp" payments online.</>,
    phrases: ['ebt', 'snap', 'food stamps']
  },
  {
    id: 14,
    question: "How can I apply to be a driver?",
    answer: <>We would love to have your drive for us! follow this <a
      className='text-green-600'
      href='https://portal.marketwagon.com/delivery/drivers/apply' rel='noreferrer' target='_blank'>link</a> to fill out
      the application.</>,
    phrases: ['drive', 'apply to drive']
  },
  {
    id: 15,
    question: "How can I share Market Wagon with my Friends?",
    answer: <><IonRouterLink routerLink='/friends'>Shop Local with Friends. Give $20 + Get $20.</IonRouterLink><br/>Each
      time you invite a friend to be a customer on Market Wagon, your friend will receive $20 off their first order, and
      you'll get a $20 Market Wagon gift card when they place their first order. Use your referral link to start sharing
      the gift of local food with all of your friends.</>,
    phrases: ['share', 'friend', 'friends', 'sharing', 'referral', 'refer']
  },
  {
    id: 16,
    question: "How can I become a Wagon Pass Member and get Free Delivery?",
    answer: WagonPassComponent,
    phrases: ['wagon pass', 'free', 'free delivery', 'pass', 'save']
  }
];


export default FAQQuestions;
