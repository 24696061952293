import {
  IonSpinner,
  useIonAlert,
} from '@ionic/react';
import {useEffect, useState} from 'react';
import {UserStore} from '../User/UserStore';
import {getOrders} from '../../Api/api.orders';
import {useStoreState} from 'pullstate';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import Order from '../Orders/OrderClass';
import dayjs from 'dayjs';
import PrivatePage from '../../Components/Page/PrivatePage';
import {Helmet} from 'react-helmet';
import GeneralHeader from '../../Components/Header/GeneralHeader';
import UseIsGuestError from "../../Helpers/Hooks/UseIsGuestError";

const SupportPage = () => {
  const userState = useStoreState(UserStore);
  const ordersBaseState: Array<Order> = [];
  const [orders, setOrders] = useState(ordersBaseState);
  const [openModal, setOpenModal] = useState(false);
  const [problemUrl, setProblemUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [presentAlert] = useIonAlert();

  UseIsGuestError();

  useEffect(() => {
    if (userState.id && !userState.isGuest && loading) {
      getOrders()
        .then((res: Array<Order>) => {
          setOrders(res);
          setLoading(false);
        })
        .catch((e) => {
          presentAlert(e).then();
          setLoading(false);
        });
    }
  }, [userState, setOrders, loading, presentAlert]);

  const openSubmitModal = (orderId: string | undefined) => {
    if (orderId) {
      setProblemUrl(
        `https://admin.marketwagon.com/problems/?uEmail=${userState.email}&orderId=${orderId}`
      );
      setOpenModal(true);
    }
  };
  return (
    <PrivatePage>
      <Helmet>
        <title>Support | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/support"/>
      </Helmet>

      <GeneralHeader
        title='Customer Support'
        backHref='/shop'
      />

      <section>
        <div className='py-0 md:py-12 bg-gray-100'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='lg:text-center'>
              <p className='my-4 max-w-2xl text-xl text-gray-500 lg:mx-auto'>
                Select the order you have an issue with...
              </p>
            </div>

            <div className='bg-white shadow overflow-hidden sm:rounded-md divide-y'>
              {loading && (
                <div className='p-4 text-center flex items-center space-x-2 justify-center'>
                  <IonSpinner/>
                  <span className='font-medium text-gray-500'>
                    Loading...
                  </span>
                </div>
              )}

              {!loading &&
                orders.map((order) => {
                  let addOnItemCount = 0;
                  let addOnTotal = 0;
                  if (order.addOns?.length > 0) {
                    order.addOns.forEach(
                      (addOn: { items: any[]; orderTotal: number }) => {
                        addOnItemCount += addOn.items.length;
                        addOnTotal += addOn.orderTotal;
                      }
                    );
                  }
                  return (
                    <button
                      key={order.id}
                      className='flex items-center w-full cursor-pointer px-4 py-4 sm:px-6'
                      onClick={() => {
                        openSubmitModal(order.id);
                      }}
                    >
                      <div className='min-w-0 flex-1 px-4 sm:grid sm:grid-cols-2 sm:gap-4'>
                        <div className='flex w-full'>
                          <div className='md:w-1/2 w-full justify-start'>
                            <span className='text-sm font-medium text-indigo-600 truncate'>
                              {dayjs(order.deliveryDate).format('MMM D YYYY')}
                            </span>
                          </div>
                          <div className='md:w-1/2 w-full justify-start'>
                            {
                              order.status === 'open' ? (
                                <span
                                  className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
                                Open
                              </span>
                              ) : order.status === 'closed' ? (
                                <span
                                  className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                                Delivered
                              </span>
                              ) : (
                                <span
                                  className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
                                Pending
                              </span>
                              )}
                          </div>
                        </div>

                        <div>
                          <p className='mt-2 sm:mt-0 flex items-center text-sm text-gray-500 truncate'>
                            {order.items.length + addOnItemCount}{' '}
                            {order.items.length + addOnItemCount > 1
                              ? 'items'
                              : 'item'}{' '}
                            for ${(order.orderTotal + addOnTotal).toFixed(2)}
                          </p>
                        </div>
                      </div>

                      <div>
                        <svg
                          className='h-5 w-5 text-gray-400'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          aria-hidden='true'
                        >
                          <path
                            fillRule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </div>
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      <IFrameModalController
        open={openModal}
        url={problemUrl}
        title='Submit a Problem'
        onDismiss={() => {
          setOpenModal(false);
        }}
      />
    </PrivatePage>
  );
};

export default SupportPage;
