import {
  IonButton,
  IonCard,
  IonSkeletonText,
  useIonAlert,
  useIonLoading,
  useIonRouter,
  useIonModal,
} from '@ionic/react';
import dayjs from 'dayjs';
import GoogleMapReact from 'google-map-react';
import formatHubName from '../../../Helpers/FormatHubName';
import {isLoggedIn, switchHub} from '../../../Domains/User/UserStore';
import OnBoard from '../../../Domains/Onboard/Onboard';
import {Helmet} from 'react-helmet';
import AppHeader from '../../../Components/Header/AppHeader';
import PublicPage from '../../../Components/Page/PublicPage';
import {useGetHubDetails} from "../hub.actions";

const HubDetailsPage = ({match}: any) => {
  const id = match.params.id;
  const [showLoading, dismissLoading] = useIonLoading();
  const router = useIonRouter();
  const [showAlert] = useIonAlert();
  const [presentOnboardModal, dismissOnboardModal] = useIonModal(OnBoard, {
    onDismiss: () => {
      dismissOnboardModal();
    }
  });

  const [hub] = useGetHubDetails(id);


  const handleHubChange = async (id: string, name: string) => {
    showLoading(`Changing market to ${name}...`).then();
    const res = await switchHub(id);

    if (res) {
      dismissLoading().then();
      router.push('/');
    }
  };

  const handleApiLoaded = (map: any, maps: any) => {
    if (hub) {
      let boundaries = JSON.parse(hub?.boundaries ?? "");

      for (let i = 0; i < boundaries.length; i++) {
        const boundary = boundaries[i];
        let polygon = '';
        let coordSet = null;
        let finalCoords = [];

        if (boundary.length > 0) {
          if (boundary.includes('POLYGON')) {
            const tempBoundary = boundary
              .replaceAll('POLYGON ((', '')
              .replaceAll('))', '')
              .split(',')
              .map((item: string) => item.trim().replaceAll(' ', ','))
              .join(' ')
            polygon += `${tempBoundary} `;
          } else {
            polygon += `${boundary} `;
          }
        }

        coordSet = polygon.split(' ');

        for (let i = 0; i < coordSet.length; i++) {
          const coords = coordSet[i].split(',');

          if (coords[0] && coords[1]) {
            const tempCoords = {
              lng: Number(coords[0]),
              lat: Number(coords[1]),
            };
            finalCoords.push(tempCoords);
          }
        }

        const countyBoundary = new maps.Polygon({
          paths: finalCoords,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
        });

        countyBoundary.setMap(map);
      }

      map.setCenter({lat: Number(hub?.lat), lng: Number(hub?.lng)});
    }
  };

  return (
    <PublicPage>
      <Helmet>
        <title>{hub && hub.name ? hub.name : 'Hub'} Details | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com${match?.url}`}/>
      </Helmet>

      <AppHeader/>

      <div className='md:p-4 lg:px-0 container max-w-screen-md mx-auto'>
        <IonCard className='p-4 space-y-4 pb-0'>
          {!hub && (
            <div className='bg-white space-y-5'>
              <IonSkeletonText animated style={{height: '40px'}}/>
              <div>
                <IonSkeletonText
                  animated
                  style={{height: '20px', width: '40%'}}
                />
                <IonSkeletonText
                  animated
                  style={{height: '20px', width: '40%'}}
                />
              </div>
              <div>
                <IonSkeletonText
                  animated
                  style={{height: '20px', width: '80%'}}
                />
                <IonSkeletonText
                  animated
                  style={{height: '20px', width: '80%'}}
                />
                <IonSkeletonText
                  animated
                  style={{height: '20px', width: '30%'}}
                />
              </div>
              <IonSkeletonText animated style={{height: '500px'}}/>
            </div>
          )}

          {hub && (
            <>
              <div className='flex space-x-2'>
                <div className='w-full'>
                  <h1 className='text-2xl font-bold text-black mb-2'>
                    {formatHubName(hub.name)}
                  </h1>
                </div>
                <div className='grow-0'>
                  <IonButton
                    size='small'
                    className='w-full'
                    onClick={() => {
                      if (!isLoggedIn()) {
                        showAlert('Shopping Market Wagon request the creation of free account.',
                          [
                            {
                              text: 'Register',
                              handler() {
                                presentOnboardModal();
                              }
                            },
                            {
                              text: 'Cancel',
                            }
                          ]).then();
                      } else {
                        handleHubChange(hub.id, hub?.name).then();
                      }
                    }}
                  >
                    Shop Here
                  </IonButton>
                </div>
              </div>

              <p className='text-lg font-semibold leading-tight'>
                Arrives{' '}
                <strong className='font-bold text-black'>
                  Every {dayjs(hub.deliveryDay).format('dddd')}
                </strong>
                <br/>
                <span className='text-sm text-gray-500'>
                  *Order by{' '}
                  {dayjs(hub.deliveryDay).subtract(1, 'day').format('dddd')} 1AM
                </span>
              </p>

              <p className='text-sm text-black leading-tight'>
                Home Delivery for a flat ${hub.deliveryFee?.toFixed(2) ?? '8.95'} fee to
                any address in the highlighted regions.{' '}
                <strong>No subscription required.</strong>
              </p>

              <div className='h-96 -ml-4 -mr-4 -mb-4'>
                <GoogleMapReact
                  bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_KEY}}
                  defaultCenter={{lat: 0, lng: 0}}
                  defaultZoom={7}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({map, maps}: { map: any; maps: any }) =>
                    handleApiLoaded(map, maps)
                  }
                ></GoogleMapReact>
              </div>
            </>
          )}
        </IonCard>
      </div>
    </PublicPage>
  );
};
export default HubDetailsPage;
