import {IonIcon, useIonModal, useIonRouter} from '@ionic/react';
import {chevronDown} from 'ionicons/icons';
import {UserStore} from '../User/UserStore';
import dayjs from 'dayjs';
import {HubSelectModal} from '../Hubs/HubSelectionModal';

const DeliveryButton = () => {
  const userState = UserStore.useState((s) => s);
  const router = useIonRouter();
  const [presentHubSelectModal, dismissHubSelectModal] = useIonModal(HubSelectModal, {
    onDismiss: () => {
      dismissHubSelectModal();
    },
    router: router,
  });

  return (
    <button
      onClick={() => presentHubSelectModal()}
      aria-label='Delivery Settings'
      title='Delivery Settings'
      className='flex flex-row items-center flex-shrink-0 space-x-1 text-xs font-semibold leading-tight transition-all duration-150 rounded-md focus:outline-none focus:ring-2 py-1-x-2 hover:ring-2'
    >
      {userState.hubs && userState.hubs[0]?.id ? (
        <div
          className='flex items-center justify-center px-2 py-1 mt-1 space-x-1 text-xs font-bold text-gray-500 bg-gray-200 rounded-full md:text-xs'>
          <span>Delivery</span>
          <span className='text-black'>
            {dayjs(userState.hubs[0]?.deliveryDay).format('ddd MMM D')}{' '}
            <IonIcon icon={chevronDown} className='text-gray-500 '/>{' '}
          </span>
        </div>
      ) : (
        <span className='flex items-center px-2 py-1 text-white bg-black rounded-full'>
          Select Hub
        </span>
      )}{' '}
    </button>
  );
};

export default DeliveryButton;
