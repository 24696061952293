import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/react"
import FavoriteButton from "../../Components/FavoriteButton/FavoriteButton";
import ShareButton from "../../Components/ShareButton/ShareButton";
import ProductDetails from "./ProductDetail"
import {close} from 'ionicons/icons';
import {ProductStore} from "./ProductStore";
import {ProductModalProps} from "./types";
import {useEffect} from "react";
import {Helmet} from 'react-helmet';

export const ProductModal = ({...props}: ProductModalProps) => {
  const productState = ProductStore.useState(s => s);
  const product = productState.product;
  const pathName = window.location.pathname || null;
  const searchParams = window.location.search || null;

  useEffect(() => {
    if (props.product) {
      let gtagItems = {
        id: props.product?.id,
        name: props.product?.title,
        brand: props.product?.vendor?.name,
        list_position: 1,
        quantity: 1,
        price:
          props.product?.variants?.[0]?.salePrice ||
          props.product?.variants?.[0]?.price,
      };

      window.gtag('event', 'view_item_list', {
        "items": gtagItems
      });
    }
  }, [props.product]);

  return <>
    <div className="h-full overflow-hidden pb-10 md:rounded-lg">
      <Helmet>
        <title>{product?.title ? product.title : "Product"} Details | Market Wagon</title>
        <link rel="canonical"
              href={`https://shop.marketwagon.com${pathName !== null ? pathName : ''}${searchParams !== null ? searchParams : ''}`}/>
      </Helmet>

      <IonHeader className='sticky top-0 z-50'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>
          </IonButtons>

          <IonTitle className='flex-grow flex-shrink-0 w-full whitespace-nowrap'>
            {product?.title || 'loading'}
          </IonTitle>

          <IonButtons slot='end' className='pr-3 space-x-3'>
            {!!product && <>
              <FavoriteButton productId={product.id} iconClass='text-2xl text-gray-400' liked={product.isFavorite}/>

              <ShareButton
                iconClass='text-xl'
                url={`https://www.marketwagon.com/products?id=${product?.id}&share=social`}
                title={`${product.title} on MarketWagon.com`}
              />
            </>}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ProductDetails product={product} loadingDetail={productState.loading} router={props.router}
                        onDismiss={props.onDismiss}/>
      </IonContent>
    </div>
  </>
}
