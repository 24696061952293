import Pagination from '../../Components/Pagination/Pagination';
import {ProductType} from './ProductClass';
import ProductCardWide from './ProductItem/ProductCardWide';

const ProductList = ({...props}: any) => {
  return (
    <>
      <div
        className='grid max-w-screen-xl grid-cols-1 gap-4 px-2 py-4 mx-auto product-list-grid md:grid-cols-2 lg:grid-cols-3 '>
        {!props.isLoading && props.products && true && props.products.map((product: ProductType) => {
          return <ProductCardWide key={product.id} product={product}/>
        })}
      </div>

      {props.totalPages > 1 &&
        <Pagination
          current={props.currentPage}
          totalPages={props.totalPages}
          link={props.link}
          onPageChange={props.onPageChange}
        />
      }
    </>
  );
};
export default ProductList;
