import VendorModel from "../Vendor/vendor.model";
import VariantModel from "./variant.model";

export default class ProductModel {
  id: string;
  title?: string;
  badge?: string;
  ribbonText?: string;
  ratingCount?: number;
  avgRating?: number;
  created?: Date;
  tags?: string;
  isPreOrder: boolean = false;
  originCountry?: string;
  originState?: string;
  originCounty?: string;
  ingredients?: string;
  description?: string;
  processor?: string;
  images?: string[];
  previousOrderCount?: number;
  previousVendorOrderCount?: number;
  isFavorite: boolean = false;

  vendor?: VendorModel;
  variants?: VariantModel[];

  constructor(input: any) {
    this.id = input.id;
    this.title = input.title;
    this.badge = input.badge;
    this.ribbonText = input.ribbonText;
    this.ratingCount = input.ratingCount;
    this.avgRating = input.avgRating;
    this.created = input.created ? new Date(input.created) : undefined;
    this.tags = input.tags;
    this.isPreOrder = !!input.isPreOrder;
    this.originCountry = input.originCountry;
    this.originState = input.originState;
    this.originCounty = input.originCounty;
    this.ingredients = input.ingredients;
    this.description = input.description ?? input.newDescription;
    this.processor = input.processor;
    this.images = input.images;
    this.previousOrderCount = input.previousOrderCount;
    this.previousVendorOrderCount = input.previousVendorOrderCount;
    this.isFavorite = !!input.isFavorite;

    this.vendor = input.vendor ? new VendorModel(input.vendor) : undefined;
    this.variants = input.variants ? input.variants.map((i: any) => new VariantModel(i)) : undefined;
  }
}
