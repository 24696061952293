import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import MoreLinks from './MoreView';
import {logout, UserStore} from '../User/UserStore';
import PrivatePage from '../../Components/Page/PrivatePage';
import {ProfileType} from "../User/types";
import {useStoreState} from "pullstate";

const MorePage = () => {
  const userState: ProfileType = useStoreState(UserStore);
  const [presentAlert] = useIonAlert();

  // Confirm on Logout
  const askForLogout = () => {
    presentAlert('Are you sure you want to logout?', [
      {
        text: 'Cancel',
      },
      {
        text: 'Yes, Logout',
        handler() {
          logout().then();
        },
      },
    ]).then();
  };

  return (
    <PrivatePage>
      <IonHeader className='sticky top-0'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/'/>
          </IonButtons>

          <IonTitle className='flex-grow flex-shrink-0 w-full whitespace-nowrap'>
            My Market Wagon
          </IonTitle>

          <IonButtons slot='end'>
            {userState.hubs && userState.hubs.length > 0 && !userState.isGuest &&
              <IonButton onClick={askForLogout}>Logout</IonButton>
            }
          </IonButtons>

        </IonToolbar>
      </IonHeader>
      <MoreLinks/>
    </PrivatePage>
  );
};

export default MorePage;
