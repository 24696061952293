import {IonItem} from '@ionic/react';
import MWAvatar from '../../Components/MWAvatar/MWAvatar';
import Vendor from './VendorClass';

// Removing this type causes an issue with the IonItem within the return. Unsure why, so going to keep this type in the file for the mean time.
interface VendorItemType {
  vendor: Vendor;
  className?: string;
  onClick?: any;
}

export const VendorItem = ({...props}: VendorItemType) => {
  return (
    <IonItem button {...props} detail={false} aria-label={props.vendor.name} className={props.className}>
      <div slot='start' className='mr-4'>
        <MWAvatar
          image={props.vendor.avatar}
          label={props.vendor.name}
          hideLabel={true}
          slot='start'
        />
      </div>
      <main className='capitalize'>
        <div className='font-bold line-clamp-2'>{props.vendor.name}</div>
        <p className='text-xs text-gray-500 line-clamp-1'>
          {props.vendor?.city}, {props.vendor.state}
        </p>
      </main>
    </IonItem>
  );
};
