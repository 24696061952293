import {IonItem} from '@ionic/react';
import DiscountItem from "./DiscountItemClass";

const DiscountListItem = (props: { item: DiscountItem, itemClass?: string }) => {
  const item: DiscountItem = props.item;

  return (
    <IonItem
      lines='none'
      color='transparent'
      className={`${props.itemClass || ''} py-3`}
    >
      <div className='w-full ion-text-wrap p-1'>
        <div className='text-sm font-semibold text-red-500 w-full flex items-center justify-between'>
          <div>
            {item.reason}
          </div>

          <div className='text-base font-semibold text-red-500 whitespace-nowrap pr-4'>
            -${item.amount?.toFixed(2)}
          </div>
        </div>
        <div className='text-xs text-black font-semibold leading-tight break-words line-clamp-2'>
        </div>
      </div>
    </IonItem>
  );
};

export default DiscountListItem;
