import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useState} from 'react';
import {submitSurvey} from '../../Api/api.orders';

const NPSModal = ({...props}: { onDismiss: Function; orderId: number }) => {
  const [NPSScore, setNPSScore] = useState(-1);
  const radioOptions = Array.from(Array(9).keys()).map((x) => (x + 1));

  const localSubmitSurvey = () => {
    submitSurvey(props.orderId, 'NPS', NPSScore).then();
    props.onDismiss();
  };
  return (
    <div className='modal fade w-full outline-none overflow-x-hidden overflow-y-auto h-full md:h-auto'>
      <div className='modal-dialog modal-dialog-centered relative w-auto h-full pointer-events-none'>
        <div
          className='modal-content border-none shadow-lg relative flex flex-col w-full h-full pointer-events-auto
           bg-white bg-clip-padding rounded-md outline-none text-current'>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonButton
                  aria-label='Close'
                  id='close-button'
                  fill='clear'
                  slot='start'
                  onClick={() => props.onDismiss()}
                >
                  <IonIcon
                    aria-describedby='close-button'
                    icon={close}
                    slot='icon-only'
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className='w-full overflow-visible px-0'>
                Survey
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <div className='modal-body relative p-4'>
            <div className='flex justify-center'>
              <h2 className='text-2xl font-normal leading-normal mt-0 mb-2'>
                How likely are you to recommend shopping on Market Wagon to a
                friend?
              </h2>
            </div>

            <div className='flex justify-center'>
              <div key={0} className='form-check form-check-inline'>
                <input
                  className='form-check-input md:h-4 md:w-4 border border-gray-300 bg-white checked:bg-blue-600 mt-1
                  align-top mx-2 cursor-pointer pull-right'
                  type='radio'
                  name='NPSRadioOptions'
                  id='NPSradio1'
                  value='0'
                  onClick={() => setNPSScore(0)}
                />
              </div>

              {radioOptions.map((i) => {
                return (
                  <div key={i} className='form-check form-check-inline'>
                    <input
                      className='form-check-input form-check-input md:h-4 md:w-4 border border-gray-300 bg-white
                      checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mx-2 cursor-pointer'
                      type='radio'
                      name='NPSRadioOptions'
                      value={i}
                      onClick={() => {
                        setNPSScore(i);
                      }}
                    />
                  </div>
                );
              })}

              <div key={10} className='form-check form-check-inline'>
                <input
                  className='form-check-input form-check-input md:h-4 md:w-4 border border-gray-300 bg-white
                  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1
                  align-top bg-no-repeat bg-center bg-contain float-left mx-2 cursor-pointer'
                  type='radio'
                  name='NPSRadioOptions'
                  value='10'
                  id='NPSradio10'
                  onClick={() => {
                    setNPSScore(10);
                  }}
                />
              </div>
            </div>
            <div className='md:flex md:justify-center'>
              <div className='md:w-2/3 flex justify-between'>
                <label
                  className='form-check-label inline-block text-gray-800'
                  htmlFor='NPSradio1'
                >
                  Very Unlikely
                </label>
                <label
                  className='form-check-label inline-block text-gray-800'
                  htmlFor='NPSradio10'
                >
                  Very Likely
                </label>
              </div>
            </div>
          </div>

          <div
            className='modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200
            rounded-b-md'>
            <button
              className={`inline-block px-6 py-2.5 bg-green-600 text-white text-base rounded shadow-md
              hover:bg-green-700 hover:shadow-lg ${
                NPSScore === -1 ? 'opacity-50' : ''
              }`}
              type='button'
              value='Submit'
              disabled={NPSScore === -1}
              onClick={() => localSubmitSurvey()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NPSModal;
