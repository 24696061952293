import {useIonAlert, useIonModal, useIonRouter} from '@ionic/react';
import {useStoreState} from 'pullstate';
import {useCallback, useEffect, useState} from 'react';
import {UserStore} from '../../Domains/User/UserStore';
import OnBoard from '../../Domains/Onboard/Onboard';

const UseIsGuestError = () => {
  const userState = useStoreState(UserStore);
  const [location, setLocation] = useState('');
  const [hasChecked, setHasChecked] = useState(false);
  const router = useIonRouter();
  const [showAlert, dismissAlert] = useIonAlert();
  const [presentOnboardModal, dismissOnboardModal] = useIonModal(OnBoard, {
    onDismiss: () => {
      dismissOnboardModal();
    }
  });

  const showUnAuthedAlert = useCallback(() => {
    showAlert({
      backdropDismiss: false,
      message: `This page requires the creation of a free account.`,
      buttons: [
        {
          text: 'Registration',
          handler: () => {
            presentOnboardModal();
          }
        },
        {text: 'Go Back', handler: () => router.push('/', 'forward')}
      ]
    }).then();
  }, [presentOnboardModal, router, showAlert]);

  useEffect(() => {
    if (userState?.isGuest && !hasChecked) {
      setLocation(window.location.pathname.split('/')[1]);
      setHasChecked(true);
      showUnAuthedAlert();
    } else if (!userState.isGuest && hasChecked) {
      dismissAlert().then();
    }
  }, [hasChecked, showUnAuthedAlert, location, userState?.isGuest, dismissAlert]);

  return showAlert;
}

export default UseIsGuestError;
