import {IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle} from "@ionic/react";
import {close} from 'ionicons/icons';

const GeneralStoreModal = (props: any) => {
  return (
    <section className='bg-white border-transparent h-full overflow-hidden md:h-auto md:rounded-lg'>
      <IonHeader className='sticky top-0 z-50'>
        <IonToolbar className='rounded-lg'>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>
          </IonButtons>

          <IonTitle className='flex-grow flex-shrink-0 w-full whitespace-nowrap'>
            What is the "General Store" Collection?
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <div className='px-8 py-4'>
        <h1 className='font-bold pb-4'>What is the "General Store" Collection?</h1>

        <p className='pb-2'>Market Wagon's mission is to enable food producers to thrive in their local markets and
          connect you directly with them in your community. However, there are some foods that our customers enjoy that
          cannot be sourced from a local or artisanal provider.</p>
        <p className='pb-2'>In order to save our customers time and delivery costs, we have partnered with grocery
          suppliers to get an assortment of grocery staples that we can deliver together in one trip with your local
          food choices. This saves you time and money by combining multiple deliveries into one.</p>
        <p className='pb-2'>Our commitment is that "General Store" foods are offered only when there is no viable local
          supply for these items.</p>
      </div>
    </section>
  );
}

export default GeneralStoreModal;
