import BundleModel from "./bundle.model";
import {DELETE, GET, POST} from "../../Api/Api";

export class BundleApi {
  static async getBundles() {
    const call = await GET('/bundles');
    return call.payload.map((i: any) => new BundleModel(i));
  }

  static async getSubscribedBundles() {
    const call = await GET('/bundles/subscribed');
    return call.payload.map((i: any) => new BundleModel(i));
  }

  static async getBundle(id: string) {
    const call = await GET(`/bundles/${id}`);
    return new BundleModel(call.payload);
  }

  static async subscribeToBundle(
    id: string,
    subscription_interval: number
  ) {
    const call = await POST(`/bundles/${id}`, {
      subscription_interval
    });
    return call.success;
  }

  static async unsubscribeFromBundle(id: string) {
    const call = await DELETE(`/bundles/${id}`);
    return call.success;
  }
}


