import {MapType} from "./types"

const Map = ({...props}: MapType) => {
  return (
    <div
      className={`rounded-md shadow-md overflow-hidden mw-map flex items-stretch ${
        props.className || ''
      }`}
    >
      <iframe
        width="100%"
        height="100%"
        title="map"
        className={`${props.mapClass || ''}`}
        id="gmap_canvas"
        src={`https://maps.google.com/maps?q=${encodeURIComponent(
          `${props.address}`,
        )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
        scrolling="no"
      ></iframe>
    </div>
  )
}

export default Map
