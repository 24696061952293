import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import {loadUserProfileAfterCheckoutLogin} from '../../Api/api.account';
import {queryAuth} from '../../Api/api.auth';
import {validSenders} from '../../Helpers/ValidSenders';
import MWLoader from '../MWLoader/MWLoader';

const IFrameModal = ({...props}) => {
  const dismiss = props.onDismiss || function () {
  };
  const [isLoading, setLoading] = useState(props.componentProps.showLoading);
  const [presentAlert] = useIonAlert();
  const [hasReceivedMessage, setHasReceivedMessage] = useState(false);

  useEffect(() => {
    window.addEventListener('message', async (message) => {
      // Only listen for messages in the valid senders array.
      if (validSenders().includes(message.origin) && !hasReceivedMessage) {
        // If the message is a string that reads 'close-without-refresh', simply hide the checkout modal
        if (typeof message.data === 'string') {
          if (message.data === 'close-without-refresh') {
            props.componentProps.showCheckout(false);
            props.onDismiss();
          }

          if (message.data === 'close-with-refresh') {
            props.componentProps.showCheckout(false);
            props.onDismiss();
          }

          // Since users can be a guest in shopping, but login a real account during checkout, we need to
          // handle a message upon their login and load their profile data into the app based on the cookies
          // set by the api.
          if (message.data === 'customer-logged-in') {
            // After we receive the message query the api for server set auth cookies;
            // If we get a number back, it's a customer ID, we can assume the user was successfully logged in.
            await queryAuth().then((res) => {
              if (res === false) {
                presentAlert('There was error after logging in. Please try again later. If the issue persists please contact customer service.');
                return false;
              }

              loadUserProfileAfterCheckoutLogin().then(() => {
                if (props.componentProps.router) {
                  props.componentProps.router.push('/shop');
                }
              });

              return true;
            });
          }
        }

        // If the message is a number, we can assume checkout completed successfully.
        // Re-init the cart store, so we have a fresh, empty cart as it exists in the backend/api land
        // Hide the checkout iframe and dismiss the cart modal.
        // Route the user to order complete
        if (typeof message.data === 'number') {
          if (props.componentProps.showCheckout) {
            props.componentProps.showCheckout(false);
          }

          window.removeEventListener('message', () => {
          }, true);
          props.onDismiss();
          props.handleCheckoutComplete(message.data);
          return;
        }

        setHasReceivedMessage(true);
      }
    });

    return () => window.removeEventListener('message', () => {
      return
    });
  }, [presentAlert, props, hasReceivedMessage]);

  return (
    <IonPage>
      {!props.componentProps.hideHeader && (
        <IonHeader className='shadow-md'>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton
                fill='clear'
                slot='start'
                onClick={() => {
                  dismiss();
                }}
              >
                <IonIcon icon={close} slot='icon-only'/>
              </IonButton>
            </IonButtons>

            <IonTitle>{props.componentProps.title || ''}</IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent
        className={`absolute left-0 right-0 bottom-0 h-auto ${
          props.componentProps.hideHeader ? 'top-0' : 'top-11'
        }`}
      >
        {isLoading && <MWLoader/>}
        <iframe
          title={props.componentProps.title || 'iFrame'}
          src={props.componentProps.url}
          className='w-full flex-grow flex-shrink'
          style={{height: '100%'}}
          onLoad={() => setLoading(false)}
        />
      </IonContent>
    </IonPage>
  );
};
export default IFrameModal;
