import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import {checkmarkCircle} from 'ionicons/icons';
import dayjs from 'dayjs';
import {switchHub, UserStore} from '../User/UserStore';
import formatHubName from '../../Helpers/FormatHubName';
import {useStoreState} from 'pullstate';
import {HubItemType} from './types';

export const HubSelectModal = ({...props}) => {
  const userState = useStoreState(UserStore);
  const [showLoading, dismissLoading] = useIonLoading();

  const handleDateChange = async (hub: HubItemType) => {
    showLoading(`Changing Market to ${hub.name}...`).then();

    await switchHub(hub.id).then(() => {
      dismissLoading();

      if (props.onDismiss) {
        props.onDismiss();
      }
    });
  }

  const goToChangeLocation = () => {
    props.router.push('/hubs');

    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const hasMoreThan1DeliveryDate = () => {
    return userState.hubs && userState.hubs.length > 1;
  };

  return (
    <IonPage className="h-full overflow-hidden md:rounded-lg md:h-37.5rem">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close Delivery Modal'
              fill='clear'
              onClick={() => props.onDismiss()}
            >
              Done
            </IonButton>
          </IonButtons>

          <IonTitle>Market Selection</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className='flex flex-col h-full'>
          <div className='py-3 px-3 bg-white'>
            {userState.hubs && (
              <div className='flex flex-col items-center space-y-3 my-2'>
                <div>
                  <strong>{formatHubName(userState?.hubs[0]?.name)}</strong>
                </div>
                {hasMoreThan1DeliveryDate() && (
                  <>
                    <div className='mb-4 text-sm leading-snug text-gray-600'>
                      Your market supports multiple delivery days! Note:
                      Although most farmers and artisans sell on both days, some
                      may be available on only one or the other day.
                    </div>
                    <div className='text-sm leading-snug text-gray-600 font-bold'>
                      Select which day you'd like your items delivered:
                    </div>
                    <IonSegment mode='ios' value={userState.hubs[0]?.id}>
                      {userState.hubs.length &&
                        userState.hubs.map((hub: any, index: number) => {
                          let selected = false;

                          if (
                            userState &&
                            userState.hubs?.length &&
                            userState.hubs[0].id
                          ) {
                            if (hub.id === userState.hubs[0].id) {
                              selected = true;
                            }
                          }

                          return (
                            <IonSegmentButton
                              key={index}
                              value={hub.id}
                              onClick={() => !selected && handleDateChange(hub)}
                            >
                              <div className='flex items-center'>
                                {selected && (
                                  <IonIcon
                                    icon={checkmarkCircle}
                                    className='mr-2 text-green-600'
                                    size='small'
                                  />
                                )}
                                {dayjs(hub.deliveryDay).format('ddd MMM D')}
                              </div>
                            </IonSegmentButton>
                          );
                        })}
                    </IonSegment>
                  </>
                )}
                {!hasMoreThan1DeliveryDate() && (
                  <div className='mb-4 text-sm leading-snug text-gray-600'>
                    {userState.hubs[0].description}
                  </div>
                )}
              </div>
            )}

            {!userState.hubs?.length && (
              <IonLabel className='ion-text-wrap'>No Hub Selected</IonLabel>
            )}

            <IonButton
              aria-label='Select a Different Location'
              fill='clear'
              size='small'
              className='w-full'
              onClick={() => goToChangeLocation()}
            >
              Select a Different Location
            </IonButton>
          </div>

          <div className='text-sm text-gray-900 bg-gray-100 flex-grow h-full py-6 px-5 space-y-4'>
            <p>
              <strong>💡 Did you know:</strong> Supporting your local farmer and
              artisans through Market Wagon conserves fuel that would be used to
              ship produce from faraway places?
            </p>
            <p>
              Purchasing from local business aids in conserving lands that may
              otherwise be used for development. This means that not only is the
              food being bought likely better quality, but it’s better for the
              environment too.
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
