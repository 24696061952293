import styles from './MWButton.module.css';
import {IonButton} from "@ionic/react";
import {ButtonProps} from './types';

const MWButton = ({...props}: ButtonProps) => {
  const classes = [styles.MWButton, props.className || ''];
  if (props.xs) classes.push(styles.MWButtonXs);
  if (props.sm) classes.push(styles.MWButtonSm);
  if (props.md) classes.push(styles.MWButtonMd);
  if (props.lg) classes.push(styles.MWButtonLg);
  if (props.disabled) classes.push(styles.MWButtonDisabled);
  if (props.rounded) classes.push(styles.MWButtonRounded);
  if (props.hilight) classes.push(styles.MWButtonHilight);
  return (
    <IonButton
      fill={props.fill || 'clear'}
      aria-label={props.title}
      className={`${classes.join(' ')} ${props.className}`}
      color={props.color}
      disabled={props.disabled}
      onClick={(evt) => (props.onClick ? props.onClick(evt) : {})}
    >
      {props.children}
    </IonButton>
  );
};

export default MWButton;
