import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage, IonRadio, IonRadioGroup, IonSkeletonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useEffect, useRef, useState} from 'react';

import CartItem from '../Cart/CartItemClass';
import MWButton from '../../Components/MWButton/MWButton';
import {
  addAutoTip,
  CartStore, CartStoreInit,

  getAutoTipAmount,
  getSubscriptions,
  subscriptionRemove,
  subscriptionsAdd,
} from '../Cart/CartStore';
import BundleListItem from '../../NewDomains/Bundle/Components/BundleSubscriptionRow';
import {loadProduct} from '../Product/ProductStore';
import Product from '../Product/ProductClass';
import {useStoreState} from 'pullstate';
import {updateSubscriptionStatus} from '../../Api/api.cart';
import {ProductModal} from '../Product/ProductModal';
import Qty from "../../Components/Qty/Qty";
import {
  subscribeToBundle,
  unsubscribeFromBundle,
  useListSubscribedBundles
} from "../../NewDomains/Bundle/bundle.actions";
import BundleModel from "../../NewDomains/Bundle/bundle.model";

const SubscriptionModal = ({...props}: any) => {
  const subscriptionsBase: Array<CartItem> = [];
  const [subscriptions, setSubscriptions] = useState(subscriptionsBase);
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false);
  const [autoTipAmount, setAutoTipAmount] = useState(-1);
  const [autoTipSelected, setAutoTipSelected] = useState(false);
  const router = useIonRouter();
  const subscriptionState = useStoreState(CartStore, s => s.subscriptionState);
  const showSubscriptionModal = useStoreState(CartStore, s => s.showSubscriptionModal);
  const [presentAlert] = useIonAlert();
  const [subscribedBundles] = useListSubscribedBundles();
  const [bundles, setBundles] =
    useState<BundleModel[] | undefined>(undefined);
  const offOption: any = useRef();
  const cartOption: any = useRef();
  const subOption: any = useRef();
  const [hasMounted, setHasMounted] = useState(false);
  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });

  const localChangeQty = async (item: CartItem, qty: number) => {
    item.quantity += qty;
    if (item.quantity === 0) {
      await localDelete(item);
    } else {
      await subscriptionsAdd(item.product.id, item.product.variant.id, qty);
    }
    await CartStoreInit();

  };

  const localDelete = async (cartItem: CartItem) => {
    setSubscriptions(
      subscriptions.filter((item: CartItem) => {
        return item.id !== cartItem.id;
      })
    );

    await subscriptionRemove(cartItem.product.id, cartItem.product.variant.id);
  };

  const removeBundle = async (id: any) => {
    setBundles(
      bundles?.filter((item) => {
        return item.id !== id;
      })
    );
    await unsubscribeFromBundle(id)
  }

  const handleBundleRoute = (id: string) => {
    props.onDismiss();
    router.push(`/bundles/${id}`);
  }

  const changeBundleInterval = async (id: any, interval: any) => {
    await subscribeToBundle(
      id,
      interval
    );
  };

  const changeInterval = async (cartItem: any, interval: any) => {
    await subscriptionsAdd(
      cartItem.product.id,
      cartItem.product.variant.id,
      0,
      interval
    );
  };

  const handleSubscriptionUpdate = async (e: any) => {
    const newSubState: number = e.target.value;

    offOption.current.disabled = true;
    cartOption.current.disabled = true;
    subOption.current.disabled = true;

    await updateSubscriptionStatus(newSubState).then(() => {
      offOption.current.disabled = false;
      cartOption.current.disabled = false;
      subOption.current.disabled = false;

      return true;
    }).catch(() => {
      presentAlert('There was an error updating your subscription preferences. If the error persists, please contact customer service.');
      offOption.current.disabled = false;
      cartOption.current.disabled = false;
      subOption.current.disabled = false;

      return false;
    });
  }

  useEffect(() => {
    setBundles(subscribedBundles);
  }, [subscribedBundles]);

  useEffect(() => {
    if (!subscriptionsLoaded && !hasMounted) {
      getSubscriptions().then((items) => {
        setSubscriptions(items);
        setSubscriptionsLoaded(true);
      });

      getAutoTipAmount().then((e: any) => {
        setAutoTipAmount(e.percentage);
      });

      setHasMounted(true);
    }
  }, [subscriptionsLoaded, subscriptions, showSubscriptionModal, hasMounted]);

  useEffect(() => {
    if (autoTipAmount > -1 && autoTipSelected) {
      addAutoTip(autoTipAmount).then();
    }
  }, [autoTipAmount, autoTipSelected]);

  return (
    <IonPage className='h-full overflow-hidden md:rounded-lg md:h-37.5rem'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon icon={close} slot='icon-only'/>
            </IonButton>
          </IonButtons>
          <IonTitle>My Subscriptions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='w-full h-full'>
        <div className='px-5 pt-2'>
          <h2 className='text-lg font-bold'>Automatic Checkout</h2>

          <IonList className='pb-2'>
            <IonRadioGroup
              value={subscriptionState !== null ? subscriptionState : 0}
            >
              <IonItem>
                <IonLabel>
                  Off
                </IonLabel>

                <IonRadio
                  value={0}
                  mode='md'
                  ref={offOption}
                  onClick={handleSubscriptionUpdate}
                  disabled={!hasMounted}
                />
              </IonItem>

              <IonItem>
                <IonLabel>
                  Full Cart
                </IonLabel>

                <IonRadio
                  value={1}
                  mode='md'
                  ref={cartOption}
                  onClick={handleSubscriptionUpdate}
                  disabled={!hasMounted}
                />
              </IonItem>

              <IonItem>
                <IonLabel>
                  Only Subscribed Items
                </IonLabel>

                <IonRadio
                  value={2}
                  mode='md'
                  ref={subOption}
                  onClick={handleSubscriptionUpdate}
                  disabled={!hasMounted}
                />
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </div>

        <div className='px-5 pt-2 mb-2 text-sm leading-snug text-gray-900'>
          <h2 className='mb-2 font-bold'>Never Miss a Market Deadline for Order Again!</h2>
          <p>Your subscribed items will be added to your cart automatically on the selected interval. From there, based
            on your selection above we will automatically check out your entire cart or only the items from your
            subscription list using your saved payment and delivery preferences 36 hours before the order cut-off. Add
            as many local items as you wish to your subscription and pick how often you would like to receive your local
            item (i.e weekly, every 2 weeks, etc.). Pause any time you don't want to receive these items and reactivate
            when you want to start receiving them again.</p>
        </div>

        <IonItemDivider/>

        <IonList>
          <IonItem lines='full'>
            <IonLabel className='ion-text-wrap'>
              <h2
                className='flex items-center leading-snug text-gray-900 text-sm'
                style={{fontWeight: 'bold'}}
              >
                My Subscribed Products
              </h2>
              <p
                className='py-2 text-sm text-gray-900'
                style={{color: 'rgb(17, 24, 39)'}}
              >
                You're subscribed to the following items. Each week, these will
                automatically be added to your cart. When your subscription is
                active, these items will be ordered on-schedule. If paused, you
                may simply checkout when you are ready.
              </p>
            </IonLabel>
          </IonItem>
          {!subscriptionsLoaded && (
            <div className='flex px-6 mb-6 space-x-4'>
              <IonSkeletonText
                style={{height: '50px', width: '600px'}}
                className='rounded-lg'
                animated={true}
              />
            </div>
          )}
          {subscriptions.map((item: CartItem, index: number) => {
            return (
              <div key={index}>
                {/*<CartListItem*/}
                {/*  onOpenProduct={() => {*/}
                {/*    const partialProduct = item.product as unknown;*/}
                {/*    const unknownProduct = partialProduct as Product;*/}
                {/*    loadProduct(unknownProduct);*/}
                {/*    presentProductModal();*/}
                {/*  }}*/}
                {/*  item={item}*/}
                {/*  key={index}*/}
                {/*  onChangeQty={(qty: any) => {*/}
                {/*    localChangeQty(item, qty);*/}
                {/*  }}*/}
                {/*  onRemove={() => {*/}
                {/*    localDelete(item);*/}
                {/*  }}*/}
                {/*/>*/}

                <IonItem
                  lines={index + 1 === subscriptions.length ? 'none' : 'inset'}
                >
                  <button
                    aria-label='View Details'
                    onClick={() => {
                      const partialProduct = item.product as unknown;
                      const unknownProduct = partialProduct as Product;
                      loadProduct(unknownProduct).then();
                      presentProductModal();
                    }}
                    className='w-12 h-12 mr-2 bg-gray-200 bg-center bg-cover rounded-md shadow-md flex-fixed imageholder'
                    style={{
                      backgroundImage: `url(${
                        item?.product?.images
                          ? item.product.images[0]
                          : '/images/default-image.svg'
                      })`,
                    }}
                  />
                  <IonLabel className='ion-text-wrap'>
                    <div
                      onClick={() => {
                        const partialProduct = item.product as unknown;
                        const unknownProduct = partialProduct as Product;
                        loadProduct(unknownProduct).then();
                        presentProductModal();
                      }}
                      className='mb-1 text-lg font-semibold leading-tight text-green-600 line-clamp-2 cursor-pointer'>
                      {item.product.title}
                    </div>
                    <p
                      onClick={() => {
                        // setVendor(item?.vendor as Vendor);
                        // presentVendorModal();
                      }}
                      className='mt-1 text-gray-600'>
                      {item.vendor}
                    </p>
                  </IonLabel>

                  {/* Price and QTY */}
                  <div
                    slot='end'
                    aria-label='price'
                    className='text-sm text-right'
                  >
                    {/* Qty Controls  */}

                    <span className='text-gray-500'>
                    <Qty
                      onLess={() => {
                        localChangeQty(item, -1).then();
                      }}
                      onMore={() => {
                        localChangeQty(item, +1).then();
                      }}
                      value={item.quantity}
                    />
                  </span>


                    {/* Price  */}
                    <div className='text-base font-semibold text-gray-700'>
                      ${(item.product.variant.price * item.quantity).toFixed(2)}
                    </div>
                  </div>
                </IonItem>
                <IonItem>
                  <label
                    htmlFor='subscription_interval'
                    className='py-2 text-sm text-gray-600'
                  >
                    How frequently would you like this item delivered?
                  </label>
                  <select
                    name='subscription_interval'
                    className='form-select px-3 py-1.5 ml-5 text-gray-700 bg-white border border-solid border-gray-300 rounded focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                    onChange={(e) => {
                      changeInterval(item, e.target.value).then();
                    }}
                    defaultValue={item.subscription_interval}
                  >
                    <option value='1'>Once a week</option>
                    <option value='2'>Once every 2 weeks</option>
                    <option value='3'>Once every 3 weeks</option>
                    <option value='4'>Once every 4 weeks</option>
                  </select>
                </IonItem>
              </div>
            );
          })}
          {subscriptionsLoaded && subscriptions.length <= 0 && (
            <IonItem lines='none'>
              <IonLabel className='ion-text-wrap'>
                <h2 className='flex items-center'>
                  <strong>Your subscription list is empty</strong>
                </h2>
                <p className='py-2 text-sm text-gray-600'>Start shopping</p>
              </IonLabel>
            </IonItem>
          )}
          <IonList>
            <IonItem lines='full'>
              <IonLabel className='ion-text-wrap'>
                <h2
                  className='flex items-center leading-snug text-gray-900 text-sm'
                  style={{fontWeight: 'bold'}}
                >
                  My Subscribed Bundles
                </h2>
                <p
                  className='py-2 text-sm text-gray-900'
                  style={{color: 'rgb(17, 24, 39)'}}
                >
                  You're subscribed to the following bundles. You can choose how often you receive these items,
                  they will automatically be added to your cart.

                </p>
              </IonLabel>
            </IonItem>
            {!subscriptionsLoaded && (
              <div className='flex px-6 mb-6 space-x-4'>
                <IonSkeletonText
                  style={{height: '50px', width: '600px'}}
                  className='rounded-lg'
                  animated={true}
                />
              </div>
            )}
            {subscriptionsLoaded && (bundles?.length ?? 0) <= 0 && (
              <IonItem lines='none'>
                <IonLabel className='ion-text-wrap items-center'>
                  <h2 className='flex items-center'>
                    <strong>Currently no subscribed bundles</strong>
                  </h2>
                  <p className='py-2 text-sm text-gray-600'>Start shopping</p>
                </IonLabel>
              </IonItem>
            )}
            {bundles && bundles.length > 0 && bundles.map((bundle, index) => {
              return (
                <div key={index}>
                  <BundleListItem
                    item={bundle}
                    key={index}
                    onRemove={() => {
                      removeBundle(bundle.id).then();
                    }}
                    onGoToBundle={() => {
                      handleBundleRoute(bundle.id);
                    }}
                  />
                  <IonItem>
                    <label
                      htmlFor='subscription_interval'
                      className='py-2 text-sm text-gray-600'
                    >
                      How frequently would you like this item delivered?
                    </label>
                    <select
                      name='subscription_interval'
                      className='form-select px-3 py-1.5 ml-5 text-gray-700 bg-white border border-solid border-gray-300 rounded focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                      onChange={(e) => {
                        changeBundleInterval(bundle.id, e.target.value).then();
                      }}
                      defaultValue={bundle.subscriptionInterval}
                    >
                      <option value='1'>Once a week</option>
                      <option value='2'>Once every 2 weeks</option>
                      <option value='3'>Once every 3 weeks</option>
                      <option value='4'>Once every 4 weeks</option>
                    </select>
                  </IonItem>
                </div>
              );
            })}
          </IonList>
          <IonItemDivider/>
          <IonItem lines='none'>
            <IonLabel className='ion-text-wrap'>
              <h2 className='flex items-center'>
                <strong>Default Driver Tip</strong>
              </h2>
              <p className='py-2 text-sm text-gray-600'>
                Select a tip amount for your automatic orders.
              </p>
            </IonLabel>
          </IonItem>
          <br/>
          <div className='flex justify-around'>
            <MWButton
              onClick={() => {
                setAutoTipAmount(0);
                setAutoTipSelected(true);
              }}
              hilight={autoTipAmount <= 0}
              className='w-1/5 text-white bg-green-600 rounded-lg h-11 text-sm md:text-base'
              title={'No Tip'}
            >
              No Tip
            </MWButton>
            <MWButton
              onClick={() => {
                setAutoTipAmount(5);
                setAutoTipSelected(true);
              }}
              hilight={autoTipAmount === 5}
              className='w-1/5 text-white bg-green-600 rounded-lg h-11 text-sm md:text-base'
              title={'5'}
            >
              5%
            </MWButton>
            <MWButton
              onClick={() => {
                setAutoTipAmount(10);
                setAutoTipSelected(true);
              }}
              hilight={autoTipAmount === 10}
              className='w-1/5 MWButtonHilight text-white bg-green-600 rounded-lg h-11 text-sm md:text-base'
              title={'10%'}
            >
              10%
            </MWButton>
            <MWButton
              onClick={() => {
                setAutoTipAmount(20);
                setAutoTipSelected(true);
              }}
              hilight={autoTipAmount === 20}
              className='w-1/5 text-white  bg-green-600 rounded-lg h-11 text-sm md:text-base'
              title={'20%'}
            >
              20%
            </MWButton>
          </div>
        </IonList>
        <div className='p-10 m-10'></div>
      </IonContent>
    </IonPage>
  );
};
export default SubscriptionModal;
