import {IonIcon, IonSkeletonText} from "@ionic/react";
import {useEffect, useRef, useState} from "react";
import {getBlogPost} from "../../Api/api.blog";
import {BlogEntryType} from "./types";
import {personSharp, calendarSharp} from 'ionicons/icons';
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import PublicPage from "../../Components/Page/PublicPage";
import {Helmet} from 'react-helmet';
import GeneralHeader from "../../Components/Header/GeneralHeader";

const BlogEntry = ({match}: any) => {
  const idOrSlug = match.params.idOrSlug;
  const [postData, setPostData] = useState<BlogEntryType | null>(null);
  const blogBody = useRef<HTMLElement | null>(null);

  const handleBlogMarkup = () => {
    const markup: any = postData?.body;

    return (
      // Setting a ref on the section so we can target it once we have generated the data.
      <section
        className='Blog__body px-4 pb-4'
        dangerouslySetInnerHTML={{__html: markup}}
        style={{marginTop: '-1px'}}
        ref={blogBody}
      />
    );
  }

  useEffect(() => {
    if (idOrSlug) {
      getBlogPost(idOrSlug).then((res: any) => {
        setPostData(res);
      });
    }
  }, [idOrSlug]);

  useEffect(() => {
    // If we have the blog body ref let's parse all the anchors to open 3rd party websites in a new tab.
    if (blogBody !== null) {
      const allAnchors = blogBody?.current?.querySelectorAll('a');

      allAnchors?.forEach((item: any) => {
        if (!item.href.includes('https://www.marketwagon.com')) {
          item.setAttribute('target', '_blank');
          item.setAttribute('rel', 'noopener nofollow');
        }
      });
    }
  });

  return (
    <PublicPage>
      <Helmet>
        <title>{postData?.title ? postData.title : 'Blog'} | Market Wagon</title>
        <link rel="canonical" href={`https://shop.marketwagon.com${match.url}`}/>
      </Helmet>

      <GeneralHeader
        title={postData?.title ? postData.title : null}
        backHref='/shop'
        slug={postData?.slug ? postData.slug : null}
        showSearch={true}
      />

      <article
        className='my-4 mx-auto max-w-21.25rem w-full shadow-lg bg-white rounded-t-md sm:max-w-md md:max-w-lg lg:max-w-xl 2xl:max-w-3xl'>
        {postData?.image &&
          <img
            src={postData?.image}
            alt={postData?.title}
            className='object-cover h-60 w-full rounded-t-md'
          />
        }

        {postData === null &&
          <IonSkeletonText className='object-cover h-60 w-full rounded-t-md' animated/>
        }

        <header className='px-4 pb-4 pt-7'>
          <>
            {postData?.title &&
              <h1 className='text-2xl font-medium leading-6'>{postData?.title}</h1>
            }

            {postData === null &&
              <IonSkeletonText className='text-2xl font-medium leading-6' animated style={{height: '24px'}}/>
            }
          </>

          <>
            {postData?.author &&
              <p className={`flex items-center text-sm mt-2 mb-1`}>
                <IonIcon
                  icon={personSharp}
                />
                &nbsp;
                <span className='leading-tight'>
                    by {postData?.author}
                </span>
              </p>
            }

            {postData === null &&
              <div className={`flex items-center text-sm mt-2 mb-1`}>
                <IonIcon
                  icon={personSharp}
                />
                &nbsp;
                <IonSkeletonText animated style={{width: '80px', height: '17.5px'}}/>
              </div>
            }
          </>

          <>
            {postData?.publish_date &&
              <p className='flex items-center text-sm'>
                <IonIcon
                  icon={calendarSharp}
                />

                &nbsp;

                <span className='leading-tight'>
                  {dayjs(postData?.publish_date).format('MMM D, YYYY')}
                </span>
              </p>
            }

            {postData === null &&
              <div className={`flex items-center text-sm`}>
                <IonIcon
                  icon={calendarSharp}
                />

                &nbsp;

                <IonSkeletonText animated style={{width: '80px', height: '17.5px'}}/>
              </div>
            }
          </>
        </header>

        {postData?.vendor &&
          <nav className='px-4 pb-4'>
            <Link
              to={`/collections/vendor/${postData?.vendor.id}`}
              className='flex justify-center px-3 py-2 bg-green-600 text-white rounded-md hover:bg-green-500'
            >
              Shop this Vendor
            </Link>
          </nav>
        }

        {/*
            Because the markup returned from the API has html in it we want to run a function to handle the setting of this.
          */}
        <>
          {postData?.body &&
            handleBlogMarkup()
          }

          {postData === null &&
            <>
              <IonSkeletonText className='rounded-none mx-auto pb-2' animated style={{width: '90%', height: '144px'}}/>
              <br/>
            </>
          }
        </>
      </article>
    </PublicPage>
  );
}

export default BlogEntry;
