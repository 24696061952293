import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import CollectionsGrid from './CollectionGrid';
import {setActiveCollection} from './CollectionStore';
import {CollectionType} from './types';

const CollectionsModal = ({...props}) => {
  const handleOnCollectionSelect = async (collection: CollectionType) => {
    setActiveCollection(collection).then();

    if (props.router) {
      props.router.push(`/collections/${collection.slug}`);
    }
  }

  return (
    <IonPage className='h-full md:rounded-lg md:overflow-hidden md:h-37.5rem' aria-label='Shopping Cart Modal'
             aria-haspopup='true'>
      <IonHeader>
        <IonToolbar style={{'--border-color': 'transparent'}}>
          <IonButtons slot='start'>
            <IonButton
              aria-label='Close Shopping Cart Window'
              id='close-button'
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon
                aria-describedby='close-button'
                icon={close}
                slot='icon-only'
              />
            </IonButton>
          </IonButtons>
          <IonTitle>Browse All Collections</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='flex-grow'>
        <CollectionsGrid
          type='list'
          onSelect={(collection: CollectionType) => {
            handleOnCollectionSelect(collection).then();
            props.onDismiss();
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default CollectionsModal;
