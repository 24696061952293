import Order from '../Domains/Orders/OrderClass';
import {UserStore} from '../Domains/User/UserStore';
import {GET, POST} from './Api';

export const getOrders = async (): Promise<Array<Order>> => {
  try {
    const ordersCall = await GET('/orders');
    return ordersCall.payload.orders.map((order: any) => new Order(order));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getOrderInformation = async () => {
  try {
    const results = await GET('/orders');
    return results.payload;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const getOrder = async (orderId: number) => {
  const orderCall = await GET(`/orders/${orderId}`);

  return new Order(orderCall.payload);
};

export const editOrder = async (order: any) => {
  const orderCall = await POST(`/orders/edit-order`, {order});
  let editedOrder = new Order(orderCall.payload);
  UserStore.update((s) => {
    s.editOrderTotal = editedOrder.calculateTotal;
  })

  return editedOrder;
};

export const getSurveyType = async (orderId: number) => {
  const orderCall = await GET(`/orders/${orderId}/survey/`);

  return orderCall.payload;
};

export const submitSurvey = async (
  orderId: number,
  type: string,
  response: string | number,
  questionId?: number | null,
  vendorId?: number | null,
) => {
  const orderCall = await POST(`/orders/${orderId}/survey`, {
    type: type,
    response: response,
    questionId: questionId,
    vendorId: vendorId,
  });
  return orderCall.payload;
};
