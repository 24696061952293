import {IonIcon, IonSkeletonText} from "@ionic/react";
import {heart, peopleSharp, powerSharp} from 'ionicons/icons';
import {useState, useEffect} from "react";
import {getBlogPosts} from "../../Api/api.blog";
import BlogPostCard from "../Blog/BlogPostCard";
import Pagination from "../../Components/Pagination/Pagination";
import {UseQuery} from "../../Helpers/Hooks/UseQuery";
import PublicPage from "../../Components/Page/PublicPage";
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';
import AppHeader from "../../Components/Header/AppHeader";

const AboutPage = () => {
  let urlQuery = UseQuery();
  const [blogPosts, setBlogPosts] = useState<Array<[]> | null>(null);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const page: any = urlQuery.get('page') || 1;

    setPaginationPage(page);
    setIsLoading(true);
  }, [urlQuery]);

  useEffect(() => {
    if (isLoading && paginationPage) {
      getBlogPosts(paginationPage, 12, 'news').then((res: any) => {
        setBlogPosts(res.posts);

        if (paginationPage === 1) {
          setTotalPages(Math.ceil(res.totalRows / 12));
        }

        setIsLoading(false);
      });
    }
  }, [isLoading, paginationPage]);

  return (
    <PublicPage>
      <Helmet>
        <title>About Market Wagon | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/about"/>
      </Helmet>

      <AppHeader/>

      <section className='text-center text-black px-4 py-8 max-w-3xl w-full mx-auto'>
        <h1 className='text-4xl flex justify-center items-center mb-4'>
          We&nbsp;
          <IonIcon
            icon={heart}
            className='text-green-600 text-6xl'
          />
          &nbsp;Local Food.
        </h1>

        <p className='text-3xl font-light'>
          More than a market, we're on a mission to enable food producers to thrive in their local markets.
        </p>

        <div className='flex justify-center my-8'>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
            <path fill="#007a3e"
                  d="M35 44V28h-5.75V12.75q0-3.95 2.4-6.35Q34.05 4 38 4v40Zm-20.75 0V25.6q-2.6-.55-4.425-2.625Q8 20.9 8 18V4h3v14h3.25V4h3v14h3.25V4h3v14q0 2.9-1.825 4.975Q19.85 25.05 17.25 25.6V44Z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
            <path fill="#007a3e"
                  d="m8.25 42-2.1-2.1 21-21q-1.1-2.4-.5-4.975.6-2.575 2.85-4.825 2.25-2.2 5.45-2.825T40.2 7.7q2.1 2.1 1.425 5.25T38.6 18.5q-2.05 2.1-4.575 2.75-2.525.65-4.725-.3l-3.35 3.35 15.6 15.6-2.1 2.1-15.6-15.6Zm6.25-17.55L8.55 18.5Q6 15.95 5.9 12.45q-.1-3.5 2.3-6.2L20.45 18.5Z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
            <path fill="#007a3e"
                  d="M35 44V28h-5.75V12.75q0-3.95 2.4-6.35Q34.05 4 38 4v40Zm-20.75 0V25.6q-2.6-.55-4.425-2.625Q8 20.9 8 18V4h3v14h3.25V4h3v14h3.25V4h3v14q0 2.9-1.825 4.975Q19.85 25.05 17.25 25.6V44Z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
            <path fill="#007a3e"
                  d="m8.25 42-2.1-2.1 21-21q-1.1-2.4-.5-4.975.6-2.575 2.85-4.825 2.25-2.2 5.45-2.825T40.2 7.7q2.1 2.1 1.425 5.25T38.6 18.5q-2.05 2.1-4.575 2.75-2.525.65-4.725-.3l-3.35 3.35 15.6 15.6-2.1 2.1-15.6-15.6Zm6.25-17.55L8.55 18.5Q6 15.95 5.9 12.45q-.1-3.5 2.3-6.2L20.45 18.5Z"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
            <path fill="#007a3e"
                  d="M35 44V28h-5.75V12.75q0-3.95 2.4-6.35Q34.05 4 38 4v40Zm-20.75 0V25.6q-2.6-.55-4.425-2.625Q8 20.9 8 18V4h3v14h3.25V4h3v14h3.25V4h3v14q0 2.9-1.825 4.975Q19.85 25.05 17.25 25.6V44Z"/>
          </svg>
        </div>

        <p className="text-gray-600 text-left">
          We are a start-up team carrying out this mission using cutting-edge shopping and supply chain technology,
          proprietary fulfillment processes, and innovative logistics approaches. Started in Indianapolis in 2016,
          Market Wagon now operates local food delivery hubs across the Midwest.
        </p>
      </section>

      <section className='bg-white text-center text-black px-4 py-8'>
        <div className='max-w-3xl w-full mx-auto' style={{maxWidth: '600px'}}>
          <h2 className='text-4xl mb-4'>Local Food. Delivered.</h2>
          <h3 className='mb-4 font-light text-2xl mb-8'>We bring a farmers market to the doorstep.</h3>
          <p className='mb-8 text-left'>We create online farmers markets with delivery to give consumers more access to
            local food. Our technology and logistics innovations allow farmers and artisans to list and sell their
            products online with the convenience of e-commerce and fulfill orders with our managed last-mile
            network.</p>

          <img
            className='rounded-md'
            src='https://marketwagon.com/img/totes-shelf-cover-sm.png'
            alt='Row of Black cooler totes with the Market Wagon logo lining a shelf.'
          />
        </div>
      </section>

      <section className='p-4 py-8 text-black text-center max-w-3xl w-full mx-auto'>
        <h2 className='text-4xl mb-4'>Our Vision</h2>
        <h3 className='mb-4 font-light text-2xl mb-8'>We are leading the producer-direct food channel (AKA "Local Food")
          in the fastest-growing segment of grocery sales, e-commerce.</h3>
        <p className='text-left'>Market Wagon is committed to serving local farms and food artisans by growing the
          market for their products with consumer-friendly shopping and delivery services.</p>

        <div
          className="flex flex-col items-center justify-center mt-20 gap-y-16 md:flex-row md:gap-x-10 md:items-start">
          <div className='flex flex-col items-center text-center max-w-xs w-full'>
            <div className='bg-white shadow-lg rounded-full w-20 h-20 flex justify-center items-center mb-4'>
              <IonIcon
                icon={peopleSharp}
                className='text-green-600 text-6xl'
              />
            </div>

            <h4 className='text-xl font-medium mb-2'>People</h4>
            <p>Restoring a connection between consumers and the farmers and artisans who produce their food is central
              to our mission.</p>
          </div>

          <div className='flex flex-col items-center text-center max-w-xs w-full'>
            <div className='bg-white shadow-lg rounded-full w-20 h-20 flex justify-center items-center mb-4'>
              <IonIcon
                icon={powerSharp}
                className='text-green-600 text-6xl'
              />
            </div>

            <h4 className='text-xl font-medium mb-2'>Technology</h4>
            <p>Every function of our business, from shopping to fulfillment, is tech-enabled using proprietary software
              and processes developed by our industry-leading team.</p>
          </div>

          <div className='flex flex-col items-center text-center max-w-xs w-full'>
            <div className='bg-white shadow-lg rounded-full w-20 h-20 flex justify-center items-center mb-4'>
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path fill="#007a3e"
                      d="M11.2 39.95q-2.45 0-4.175-1.725Q5.3 36.5 5.3 34.05H2V11q0-1.2.9-2.1Q3.8 8 5 8h28.95v8.35h5.25L46 25.4v8.65h-3.55q0 2.45-1.725 4.175Q39 39.95 36.55 39.95q-2.45 0-4.175-1.725Q30.65 36.5 30.65 34.05H17.1q0 2.45-1.725 4.175Q13.65 39.95 11.2 39.95Zm0-3q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85ZM5 31.05h1.1q.85-1.35 2.15-2.15 1.3-.8 2.9-.8 1.6 0 2.925.825 1.325.825 2.175 2.125h14.7V11H5Zm31.55 5.9q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85Zm-2.6-10.2h9.3l-5.55-7.4h-3.75ZM18 21.55Z"/>
              </svg>
            </div>

            <h4 className='text-xl font-medium mb-2'>Logistics</h4>
            <p className='text-base'>Our innovative fulfillment and logistics approaches enable us to operate e-commerce
              grocery using local suppliers at a scale never before imagined.</p>
          </div>
        </div>
      </section>

      <section className='p-4 py-8 text-black max-w-3xl w-full mx-auto xl:max-w-5xl'>
        <h2 className='text-center text-4xl mb-8'>Market Wagon in the News</h2>

        {!isLoading && blogPosts?.length === 0 &&
          <p className='text-center'>No news posts found.</p>
        }

        <ul className='md:flex md:flex-wrap md:justify-around'>
          {blogPosts && blogPosts.map((post: any, index: number) => {
            return (
              <li
                key={index}
                className='md:max-w-xs md:w-full xl:max-w-18.75rem'
              >
                <Link
                  to={`/blog/${post.slug}`}
                >
                  <BlogPostCard
                    title={post.title}
                    image={post.image}
                    body={post.body}
                    slug={post.slug}
                    author={post.author}
                    postDate={post.publish_date}
                    category={post.category}
                  />
                </Link>
              </li>
            );
          })}

          {blogPosts === null &&
            Array(25).fill(0).map((_, i: number) => {
              return (
                <li
                  key={i}
                  className='md:max-w-xs md:w-full xl:max-w-18.75rem'
                >
                  <article key={i} className="bg-white rounded-md shadow-lg mb-8">
                    <IonSkeletonText className='w-full h-56 rounded-t-lg' animated/>

                    <header className='px-4 pt-4'>
                      <IonSkeletonText className='w-full mb-3' style={{height: '20px'}} animated/>

                      <IonSkeletonText className='w-full mb-1' style={{height: '20px', width: '100px'}} animated/>
                      <IonSkeletonText className='w-full mb-3' style={{height: '20px', width: '100px'}} animated/>

                      <IonSkeletonText className='px-4 w-full' style={{height: '100px'}} animated/>
                    </header>

                    <nav className='p-4'>
                      <IonSkeletonText className='w-full mb-1' style={{height: '20px', width: '100px'}} animated/>
                    </nav>
                  </article>
                </li>
              )
            })
          }
        </ul>

        {blogPosts?.length !== 0 && totalPages &&
          <Pagination
            current={paginationPage}
            totalPages={totalPages}
            link='/about?page={page}'
            onPageChange={(page) => {
              setPaginationPage(page);
            }}
          />
        }
      </section>

      <section className='p-4 py-8 text-white relative'>
        <div className='w-full h-full absolute left-0 top-0 overflow-hidden z-0 bg-black'>
          <img className='z-0 opacity-30 w-full h-full object-cover'
               src='https://marketwagon.com/img/action-shot-sm.png' alt=''/>
        </div>

        <div className='z-10 relative w-full mx-auto text-center'>
          <h2 className='text-4xl mb-4'>Our Team</h2>
          <h3 className='font-light text-2xl mb-8'>We have both the talent and passion to succeed.</h3>

          <div className='xl:flex'>
            <div className='flex flex-col items-center text-center max-w-xs w-full mx-auto mb-20'>
              <div className='shadow-lg rounded-full flex justify-center items-center mb-4'>
                <img
                  className='rounded-full w-28 h-28'
                  src='https://marketwagon.com/img/team_nick.jpg'
                  alt='Co-founder and board member, Nick Carter.'
                />
              </div>

              <h4 className='text-xl font-medium mb-1'>Nick Carter</h4>
              <h5 className='mb-4'>Co-Founder, Board Member (and Farmer)</h5>
              <p className='mb-4'>When Nick was 18, the consequences that consolidation and commoditization ("industrial
                food") had
                wrought on his family's farm forced Nick to look for a career elsewhere. He left his family's farm over
                a decade ago and built a career in tech startups. He launched several successful businesses. But, as
                they say, you can take the boy out of the country, but nothing took the country out of Nick.</p>
              <p>At Market Wagon, his path in business led him right back to the farm, but in a different capacity.
                Nick's innovations have created deeper market penetration and distribution channels to the benefit of
                both small farmers (his dad included) and the local-food consumers they serve. His tech-startup skills
                brought Market Wagon to life, and today the company is constantly using technology to re-invent the
                local food supply chain.</p>
            </div>

            <div className='flex flex-col items-center text-center max-w-xs w-full mx-auto'>
              <div className='shadow-lg rounded-full flex justify-center items-center mb-4'>
                <img
                  className='rounded-full w-28 h-28'
                  src='https://marketwagon.com/img/team_dan.png'
                  alt='Co-founder and CEO, Dan Brunner.'
                />
              </div>

              <h4 className='text-xl font-medium mb-2'>Dan Brunner</h4>
              <h5 className='mb-4'>Co-Founder, CEO</h5>
              <p className='mb-4'>Dan is an experienced entrepreneur with a background in software, logistics, and order
                fulfillment. Dan
                has long believed in the online future of grocery shopping and is on a mission to bring best-in-class
                experiences to food growers and makers as well as the consumers they serve. Dan has worked in
                engineering consulting, software product management, marketing, and the design and improvement of order
                fulfillment systems. He served as VP of Grocery Solutions Design at Kiva Systems, acquired by Amazon in
                2012. In 2016, Dan met Nick.</p>
              <p>Dan has worn many hats at Market Wagon, starting as COO/CFO and along the way also overseeing vendor
                recruitment/relations, operations engineering, marketing, and the product side of Market Wagon’s
                software platform. Today Dan leads the entire team a</p>
            </div>
          </div>
        </div>
      </section>
    </PublicPage>
  );
}

export default AboutPage;
