import InboxList from './InboxList';
import UseIsGuestError from '../../Helpers/Hooks/UseIsGuestError';
import PrivatePage from '../../Components/Page/PrivatePage';
import {Helmet} from "react-helmet";
import GeneralHeader from '../../Components/Header/GeneralHeader';

const InboxPage = () => {
  // Fire on page load to determine if the user is a guest or not
  // & Redirect if needed.
  UseIsGuestError();

  return (
    <PrivatePage>
      <Helmet>
        <title>Inbox</title>
        <link rel="canonical" href="https://shop.marketwagon.com/inbox"/>
      </Helmet>

      <GeneralHeader title='Inbox' backHref='/shop'/>

      <div className='md:p-4'>
        <InboxList className='md:mx-auto md:max-w-screen-md min-h-screen'/>
      </div>
    </PrivatePage>
  );
};

export default InboxPage;
