import {IonIcon, useIonAlert, useIonModal} from '@ionic/react';
import {heartOutline, heart} from 'ionicons/icons';
import {useStoreState} from 'pullstate';
import {useCallback, useEffect, useState} from 'react';
import {favoriteProduct, unfavoriteProduct} from '../../Api/api.products';
import {favoriteVendor, unfavoriteVendor} from '../../Api/api.vendor';
import {UserStore} from '../../Domains/User/UserStore';
import {LikeProps} from './types';
import OnBoard from '../../Domains/Onboard/Onboard';

const FavoriteButton = ({...props}: LikeProps) => {
  const userState = useStoreState(UserStore);
  const liked: boolean = props.liked;
  const [isLiked, setIsLiked] = useState(liked);
  const [showAlert] = useIonAlert();
  const [presentOnboardModal, dismissOnboardModal] = useIonModal(OnBoard, {
    onDismiss: () => {
      dismissOnboardModal();
    }
  });

  const showUnAuthedAlert = useCallback(() => {
    showAlert({
      message: `Favorites requires the creation of an account.`,
      buttons: [
        {
          text: 'Sign Up',
          handler: () => {
            presentOnboardModal();
          },
        },
        {text: 'Close'},
      ],
    }).then();
  }, [presentOnboardModal, showAlert]);

  const favoriteIt = () => {
    if (userState?.isGuest === false) {
      if (props.productId) {
        favoriteProduct(props.productId).then();
      } else if (props.vendorId) {
        favoriteVendor(props.vendorId).then();
      }
      if (props.onFavorite) props.onFavorite();
      setIsLiked(true);
    } else {
      showUnAuthedAlert();
    }
  };

  const unfavoriteIt = () => {
    if (userState?.isGuest === false) {
      if (props.productId) {
        unfavoriteProduct(props.productId).then();
      } else if (props.vendorId) {
        unfavoriteVendor(props.vendorId).then();
      }
      setIsLiked(false);

      if (props.onUnfavorite) props.onUnfavorite();
    } else {
      showUnAuthedAlert();
    }
  };

  useEffect(() => {
    setIsLiked(props.liked);
  }, [props.liked]);

  return (
    <button
      title={`${isLiked ? 'Unlike this' : 'Like this'}`}
      className={`inline-flex like ${props.className || ''} h-6 w-6`}
      onClick={(evt: any) => {
        if (evt.preventDefault) {
          evt.preventDefault();
          evt.stopPropagation();
        }
        if (isLiked) {
          unfavoriteIt();
        } else {
          favoriteIt();
        }
      }}
    >
      <div className='relative w-full h-full wrapper'>
        {isLiked ? (
          <IonIcon
            icon={heart}
            className={`absolute top-0 left-0 text-red-500 transition-all duration-200 ease-in-out transform
            focus:scale-125 focus:opacity-70 ${
              props.iconClass || ''
            }`}
          />
        ) : (
          <IonIcon
            icon={heart}
            className={`absolute top-0 left-0 text-gray-500 text-opacity-60 ${
              props.iconClass || ''
            }`}
          />
        )}
        <IonIcon
          icon={heartOutline}
          className={`absolute top-0 left-0 text-white text-opacity-40 drop-shadow-sm ${
            props.iconClass || ''
          }`}
        />
      </div>
      {props.label && (
        <span className={`${props.labelClass || ''}`}>{props.label}</span>
      )}
    </button>
  );
};

export default FavoriteButton;
