import {IonButton, useIonAlert, useIonLoading} from '@ionic/react';
import dayjs from 'dayjs';
import {writeQuestion} from '../../Api/api.products';
import Textarea from 'react-expanding-textarea';
import {useState} from 'react';
import {ProductQuestionProps, ProductQuestionType} from './types';

const ProductQuestions = ({...props}: ProductQuestionProps) => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [questionSent, setQuestionSent] = useState(false);
  const [showLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const sendQuestion = async () => {
    showLoading('Sending question...').then();

    setTimeout(async () => {
      await writeQuestion(props.product?.id, questionText).then((res: any) => {
        if (res) {
          dismissLoading();
          setQuestionText('');
          setQuestionSent(true);
        }
      }).catch((error: any) => {
        dismissLoading();
        console.error(error);
        presentAlert('There was an error submitting your question, please try again laster.');
      });
    }, 1000)
  };

  return (
    <section
      aria-label='Questions and Answers'
      className='p-4 pt-6 bg-gray-100 md:p-6 mw-container md:rounded-lg'
    >
      {props.questions.length === 0 && (
        <div className='empty-block'>
          <h5>No Questions Yet!</h5>
        </div>
      )}
      {props.isGuest && (
        <section
          aria-label='Questions and Answers'
          className='p-4 pt-6 bg-gray-100 md:p-6 mw-container md:rounded-lg'
        >
          <div className='empty-block'>
            <h5>You must be logged in to ask a question.</h5>
          </div>
        </section>
      )}
      {!questionSent && !props.isGuest && (
        <IonButton
          class='mw-button_MWButton__3DYQn justify-center px-4 mb-4 text-white bg-green-600 rounded-lg h-11 ios button button-clear ion-activatable ion-focusable hydrated'
          onClick={() => setShowQuestion(!showQuestion)}
        >
          Ask a Question
        </IonButton>
      )}
      {questionSent && (
        <div>
          <p>
            Your question has been sent! Once the vendor provides an answer you will be alerted via email, and it will
            appear below.
          </p>
        </div>
      )}
      {showQuestion && !questionSent && (
        <section aria-label='write a question for the vendor' className='mb-4'>
          <Textarea
            value={questionText}
            onChange={(v) => {
              setQuestionText(v.target.value);
            }}
            maxLength={400}
            style={{minHeight: '46px', fontSize: '18px'}}
            placeholder='What question do you have for the vendor?'
            className='flex items-center w-full text-lg leading-snug rounded-md p-3'
          ></Textarea>
          <div className='flex items-center space-x-4 py-2 justify-between'>
            <IonButton size='small' onClick={sendQuestion}>
              Submit Question
            </IonButton>
          </div>
        </section>
      )}
      {props.questions.map((question: ProductQuestionType, index: number) => {
        return (
          <div className='pb-4 mb-4 question' key={index}>
            <label className='block mb-1 text-xs text-gray-600'>
              {dayjs(question.created).format('ddd MMM D YYYY')}
            </label>
            <p
              className='block mb-2 text-base font-semibold leading-tight text-black'
              dangerouslySetInnerHTML={{__html: question.question}}
            ></p>
            {question.answer && question.answer?.length > 0 && (
              <p
                className='p-4 -mx-1 text-base leading-snug text-gray-700 bg-white rounded-lg shadow-mdanswer'
                dangerouslySetInnerHTML={{
                  __html: question.answer?.replace(/[&#39;]+/g, '') || '',
                }}
              ></p>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ProductQuestions;
