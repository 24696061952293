import {IonIcon} from '@ionic/react';
import styles from './MWChip.module.css';
import {MWChipProps} from './types';

const MWChip = ({...props}: MWChipProps) => {
  const active = props.active && props.active.toString() === 'true';
  return (
    <button
      aria-label={props.label}
      onClick={(evt) => {
        if (props.onClick) props.onClick(evt)
      }}
      className={`${styles.MWChip
      } border border-gray-200 flex-shrink-0 flex-grow-0 cursor-pointer ${props.className
      }  ${active ? styles.MWChipActive : ''} `}
    >
      {props['icon-left'] ? (
        <IonIcon className='mr-1' icon={props['icon-left']}></IonIcon>
      ) : null}
      <label className='cursor-pointer whitespace-nowrap lineclamp-1 '>
        {props.label} {props.children}
      </label>
      {props['icon-right'] ? (
        <IonIcon className='ml-1' icon={props['icon-right']}></IonIcon>
      ) : null}
    </button>
  );
};

export default MWChip;
