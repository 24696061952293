import {IonHeader, IonToolbar, useIonRouter} from "@ionic/react";
import {useStoreState} from "pullstate";
import {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import CollectionNavigation from "../../Domains/Collection/CollectionNavigation";
import {
  clearActiveCollection,
  CollectionStore,
  loadCollections,
  setActiveChild,
  setActiveCollection
} from "../../Domains/Collection/CollectionStore";
import {CollectionSubType, CollectionType} from "../../Domains/Collection/types";
import DeliveryButton from "../../Domains/Delivery/DeliveryButton";
import SearchBar from "../../Domains/Search/SearchBar";
import {UserStore} from "../../Domains/User/UserStore";
import UseBranding from "../../Helpers/Hooks/UseBranding";

/**
 * Header for "shopping" pages. Includes Logo, delivery date/hub switcher, search and collections.
 * @returns
 */
const AppHeader = ({...props}: any) => {
  // Create substates for our state variables. Substates allow us to only react when a substate changes vs
  // all the collections store changing.
  const collections =
    useStoreState(CollectionStore, s => s.collections);
  const activeCollection = useStoreState(CollectionStore, s => s.activeCollection);
  const activeChildCollection = useStoreState(CollectionStore, s => s.activeChild);
  const userHubs = useStoreState(UserStore, s => s.hubs);
  const activeHub = userHubs && userHubs[0] ? userHubs[0] : null;
  const [hasMounted, setHasMounted] = useState(false);
  const router = useIonRouter();

  const awaitGetCollections = useCallback(async () => {
    if (activeHub) {
      await loadCollections();
    }
  }, [activeHub]);

  /**
   * Set's the active collection and scrolls the page
   * @param collection
   */
  const handleCollectionSelect = (collection: CollectionType) => {
    setActiveCollection(collection).then();
    setActiveChild(null).then();

    if (props.pageContainer) {
      props.pageContainer.current.scrollTo(0, 0);
    }
  }

  const handleChildCollectionSelect = (subCollection: CollectionSubType) => {
    setActiveChild(subCollection).then();

    if (props.pageContainer) {
      props.pageContainer.current.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    if (!hasMounted) {
      awaitGetCollections().then();
      setHasMounted(true);
    }
  }, [awaitGetCollections, hasMounted]);

  return (
    <>
      <IonHeader
        style={{'--border-width': '0'}}
        mode='ios'
        translucent
        className='sticky top-0 z-50'
      >
        <IonToolbar
          style={{'--border-width': '0', height: '65px'}}
          className='relative flex flex-row px-4'
        >
          <div className='flex flex-row relative self-center gap-x-8' slot='start'>
            <Link
              slot="start"
              to='/shop'
              className='block w-auto lg:w-auto'
              aria-label='Market Wagon home'
              onClick={(e: any) => {
                e.preventDefault();
                router.push('/shop');
                clearActiveCollection();
              }}
            >
              <img
                src={UseBranding('general-header')}
                className='h-8'
                alt='Market Wagon Logo'
              />
            </Link>

            {activeHub !== null &&
              <DeliveryButton/>
            }
          </div>
        </IonToolbar>

        {activeHub &&
          <SearchBar/>
        }

        <CollectionNavigation
          collections={collections ?? null}
          activeCollection={activeCollection ?? null}
          activeChildCollection={activeChildCollection ?? null}
          onCollectionSelect={handleCollectionSelect}
          onChildCollectionSelect={handleChildCollectionSelect}
          specialTab={props.specialTab ?? null}
          isLoading={hasMounted}
          showFreshFeedButton={!!activeHub?.id}
          showCollectionsButton={false}
          router={router}
        />
      </IonHeader>
    </>
  );
}

export default AppHeader;
