import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {arrowBack, navigateCircleOutline} from 'ionicons/icons';
import {userRegister} from '../User/UserStore';
import {silentlySetGuestCookie} from '../../Api/api.auth';

const Register = ({...props}: { changeView: Function, onDismiss: Function }) => {
  // Setup for Base State
  const formBase: {
    email?: string;
    password?: string;
    password2?: string;
    firstName?: string;
    lastName?: string;
    zipCode?: string;
  } = {
    email: undefined,
    password2: undefined,
    firstName: undefined,
    lastName: undefined,
    zipCode: undefined,
  };
  const [formState, setFormState] = useState(formBase);
  const [hasGuestCookie, setHasGuestCookie] = useState(false);
  // Set up General Form Error State
  const errorBase: string | undefined = undefined;
  const [errorMessage, setErrorMessage] = useState(errorBase);
  const [presentLoading, dismissLoading] = useIonLoading();
  // Multiple Form Errors
  const [formErrors, setFormErrors] = useState([{field: 'none'}]);
  const [geoZip, setGeoZip] = useState();
  const submitBtn: any = useRef();
  const registerInput: any = useRef();
  const updateUserState = (name: string, value: any) => {
    let user: any = formState;
    user[name] = value;
    setFormState(user);
    validateForm();
  };

  const doRegister = useCallback(async () => {
    presentLoading('Registering... ').then();

    try {
      await userRegister(
        `${formState.firstName}`,
        `${formState.lastName}`,
        `${formState.email}`,
        `${formState.password}`,
        `${formState.zipCode}`
      ).catch((e) => {
        throw e;
      });
      window.gtag('event', 'conversion', {'send_to': process.env.REACT_APP_API_GOOGLE_ADS + '/z5iUCJq356QBEMDYofwD'});

      props.onDismiss();
    } catch (e) {
      const error: any = e;
      setErrorMessage(error.message || error);
    }

    dismissLoading().then();
  }, [dismissLoading, formState.email, formState.firstName, formState.lastName, formState.password, formState.zipCode, presentLoading, props]);

  const zipCodeChange = (evt: any) => {
    console.log(evt.target?.value);
  }

  const handleBrowserLocationRequest = async () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async (pos) => {
        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_KEY}`)
          .then(data => data.json())
          .then(data => {
            const addressComponents = data.results[0].address_components;
            const zipCode = addressComponents.filter((comp: any) => {
              if (comp.types[0] === 'postal_code') {
                return comp;
              }

              return false;
            });

            setGeoZip(zipCode[0].long_name);
          });
      });
    } else {
      // showMessage('Sorry, your browser does not support Geolocation.');
    }
  }

  const validateForm = () => {
    setFormErrors([]);
    const errors = [];

    if (
      (formState.email || '').length < 5 ||
      (formState.email || '').search('@') === -1
    ) {
      errors.push({field: 'email', message: 'Email is requried'});
    }

    if ((formState.firstName || '').length < 1) {
      errors.push({
        field: 'firstName',
        message: 'Your first name is requried',
      });
    }

    if ((formState.firstName || '').length < 1) {
      errors.push({
        field: 'lastName',
        message: 'Your last name is requried',
      });
    }

    if ((formState.password || '').length < 5) {
      errors.push({field: 'password', message: 'Password is too short'});
    }

    if ((formState.zipCode || '').length < 5) {
      errors.push({field: 'zipCode', message: 'Zip Code is required'});
    } else if (!hasGuestCookie && (formState.zipCode || '').length === 5) {
      silentlySetGuestCookie(parseInt(String(formState.zipCode))).then((res) => {
        if (res) {
          setHasGuestCookie(true);
        } else {
          setHasGuestCookie(false);
        }
      });
    }

    if (formState.password2 !== formState.password) {
      errors.push({field: 'password', message: 'Passwords do not match'});
    }

    setFormErrors(errors);
  };

  const handleRegisterKeyPress = useCallback((e: any) => {
    if (e.key === 'Enter' && !submitBtn.current.disabled) {
      doRegister().then();
    }
  }, [doRegister])

  useEffect(() => {
    const inputListener = registerInput.current;
    inputListener.addEventListener('keyup', handleRegisterKeyPress);
    return () => {
      inputListener.removeEventListener('keyup', handleRegisterKeyPress);
    };

  }, [handleRegisterKeyPress]);
  return (
    <div className="h-full"
         ref={registerInput}>
      <IonHeader className='sticky top-0'>
        <IonToolbar>

          <IonButtons slot='start'>
            <IonBackButton/>

            <IonButton onClick={() => {
              props.changeView('welcome')
            }}
            >
              <IonIcon icon={arrowBack}/>
            </IonButton>
          </IonButtons>

          <IonTitle>Register</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonItemDivider className='mb-4 bg-green-100'>
            <p className='py-4 text-sm leading-tight text-green-600 '>
              Create your <strong>MarketWagon</strong> account to save your
              cart, share with friends, and interact with your favorite farmers.
            </p>
          </IonItemDivider>

          <IonItem>
            <IonLabel position='fixed' className='text-gray-300'>
              First Name
            </IonLabel>

            <IonInput
              autofocus
              value={formState.firstName}
              onIonChange={(evt: any) => {
                updateUserState('firstName', evt.detail.value);
              }}
              placeholder='First Name'
              type='text'
              autoCapitalize='true'
              required
            />
          </IonItem>

          <IonItem>
            <IonLabel position='fixed' className='text-gray-300'>
              Last Name
            </IonLabel>

            <IonInput
              value={formState.lastName}
              onIonChange={(evt: any) => {
                updateUserState('lastName', evt.detail.value);
              }}
              placeholder='Last Name'
              type='text'
              autoCapitalize='true'
              required
            />
          </IonItem>

          <IonItem>
            <IonLabel position='fixed' className='text-gray-300'>
              Email
            </IonLabel>

            <IonInput
              value={formState.email}
              onIonChange={(evt) => {
                setErrorMessage(undefined);
                updateUserState('email', evt.detail.value);
              }}
              placeholder='Email Address'
              pattern='email'
              type='email'
              required
            />
          </IonItem>

          <IonItem>
            <IonLabel position='fixed' onInput={zipCodeChange} className='text-gray-300'>
              Zip Code
            </IonLabel>

            <IonInput
              value={formState.zipCode || geoZip}
              onIonChange={(evt) => {
                setErrorMessage(undefined);
                updateUserState('zipCode', evt.detail.value);
              }}
              placeholder='Zip Code'
              pattern='tel'
              type='tel'
              required
            />

            <div slot='end'>
              <IonButton
                fill='clear'
                onClick={handleBrowserLocationRequest}
              >
                <IonIcon
                  slot='icon-only'
                  className='text-lg'
                  icon={navigateCircleOutline}
                />
              </IonButton>
            </div>
          </IonItem>

          <div className='h-3'/>

          <IonItemGroup>
            <IonItem>
              <IonLabel position='fixed' className='text-gray-300'>
                Password
              </IonLabel>

              <IonInput
                placeholder='Password'
                pattern='password'
                type='password'
                required
                onIonChange={(evt) => {
                  setErrorMessage(undefined);
                  updateUserState('password', evt.detail.value);
                }}
              />
            </IonItem>

            <IonItem>
              <IonLabel position='fixed' className='text-gray-300'>
                Confirm
              </IonLabel>

              <IonInput
                placeholder='Password'
                pattern='password'
                type='password'
                required
                onIonChange={(evt) => {
                  setErrorMessage(undefined);
                  updateUserState('password2', evt.detail.value);
                }}
              />
            </IonItem>
          </IonItemGroup>
        </IonList>

        <div className='px-4 mt-10'>
          <IonButton
            expand='block'
            onClick={() => doRegister()}
            disabled={formErrors.length > 0 || !hasGuestCookie}
            ref={submitBtn}
          >
            Create My Account →
          </IonButton>
          {errorMessage && (
            <div className='p-2 my-6 mb-4 text-sm text-center text-red-800 bg-red-100 rounded-xl'>
              {errorMessage}
            </div>
          )}

          {formErrors && formErrors.length > 0 ? (
            <ul className='flex flex-wrap items-center justify-start mt-6 rounded-lg errors'>
              {formErrors.map((error: any, index: number) => {
                if (error.field !== 'none') {
                  return (
                    <li
                      className='p-2 m-0 mb-1 mr-1 text-xs text-yellow-700 bg-yellow-100 rounded-md'
                      key={index}
                    >
                      {error?.message}
                    </li>
                  );
                }

                return true;
              })}
            </ul>
          ) : null
          }
          <div className="m-40"/>
        </div>
      </IonContent>

    </div>
  );
};

export default Register;
