import {IonButton, IonIcon, IonItem} from "@ionic/react";
import {trash} from 'ionicons/icons';
import BundleModel from "../bundle.model";

const BundleListItem = (props: {
  itemClass?: string,
  item: BundleModel,
  onGoToBundle: (id: string) => void,
  onRemove: (id: string) => void
}) => {
  const item: any = props.item;

  return (
    <IonItem
      lines='none'
      color='transparent'
      className={`${props.itemClass || ''} pt-2 pb-3`}
    >
      <button
        aria-label='View Details'
        onClick={() => {
          if (props.onGoToBundle) {
            props.onGoToBundle(item?.id);
          }
        }}
        className='w-12 h-12 mr-2 bg-gray-200 bg-center bg-cover rounded-md shadow-md flex-fixed imageholder'
        style={{
          backgroundImage: `url(${
            item?.image
              ? item.image
              : '/images/default-image.svg'
          })`,
        }}
      />
      <div className='w-full py-3 pr-2 ion-text-wrap'>
        <div className='mb-1 text-base text-black font-semibold leading-tight'>
          {item?.title}
        </div>
      </div>
      <IonButton
        aria-label='Remove Bundle'
        onClick={() => {
          if (props.onRemove) {
            props.onRemove(item?.id);
          }
        }}
        style={{'--padding-end': '4px', '--padding-start': '5px'}}
        className='px-0'
        size='large'
        fill='clear'
      >
        <IonIcon icon={trash} className='text-red-600'/>

      </IonButton>
    </IonItem>
  );
};
export default BundleListItem;
