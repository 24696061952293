import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {close} from 'ionicons/icons';
import {useStoreState} from 'pullstate';
import UseToggle from '../../Helpers/Hooks/UseToggle';
import OrderView from './OrderView';
import {OrderModalProps} from './types';
import {CartStore} from '../Cart/CartStore';

const OrderModal = ({watch, ...props}: OrderModalProps) => {
  const [editMode, toggleEditMode] = UseToggle(false);
  const order = useStoreState(CartStore, s => s.activeOrder);

  const dismissModal = () => {
    props.onDismiss();
  };

  return (
    <IonPage className="h-full overflow-hidden md:rounded-lg md:h-37.5rem">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton fill='clear' onClick={() => props.onDismiss()}>
              <IonIcon icon={close} slot='icon-only'/>
            </IonButton>
          </IonButtons>
          <IonTitle>Order Details</IonTitle>
          {order.status === 'open' && (
            <IonButtons slot='end'>
              {!editMode && (
                <IonButton fill='clear' onClick={toggleEditMode}>
                  Edit Order
                </IonButton>
              )}

              {editMode && (
                <IonButton
                  fill='clear'
                  color='danger'
                  onClick={toggleEditMode}
                  className='text-red-500'
                >
                  Cancel
                </IonButton>
              )}
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className='w-full pb-10'>
        {/* View Order / Edit Order  */}
        <OrderView
          onToggleEditMode={toggleEditMode}
          order={order}
          editMode={editMode}
          dismissModal={dismissModal}
        />

        {/* Scroll Buffer */}
        <div className='h-40'/>
      </IonContent>
    </IonPage>
  );
};
export default OrderModal;
