import {CommentType} from '../Domains/Comments/types';
import Product from '../Domains/Product/ProductClass';
import {ProductReviewType, ProductQuestionType} from '../Domains/Product/types';
import {DELETE, GET, POST} from './Api';

export const getProduct = async (
  id: any,
  messageId: any = null
): Promise<Product | number | undefined> => {
  try {
    const call = await GET(`/products/${id}?messageId=${messageId}`);
    return new Product(call.payload);
  } catch (error) {
    console.error(error);
    return 404;
  }
};

export const getProductComments = async (
  productId: any
): Promise<Array<CommentType>> => {
  const results = await GET(`/products/${productId}/comments`);
  return results.payload;
};

export const writeProductComment = async (
  productId: any,
  comment: string
): Promise<Boolean> => {
  const results = await POST(`/products/${productId}/comments`, {
    comment: comment,
  });
  return results.payload;
};

export const getProductReviews = async (
  productId: any
): Promise<Array<ProductReviewType>> => {
  const results = await GET(`/products/${productId}/reviews`);
  return results.payload;
};

export const writeProductReview = async (
  productId: any,
  comment: string,
  rating: number
): Promise<Boolean> => {
  const results = await POST(`/products/${productId}/reviews`, {
    comment: comment,
    rating: rating,
  });
  return results.payload;
};

export const getProductQuestions = async (
  productId: any
): Promise<Array<ProductQuestionType>> => {
  const results = await GET(`/products/${productId}/questions`);
  return results.payload;
};

export const writeQuestion = async (
  productId: any,
  question: string
): Promise<Boolean> => {
  const results = await POST(`/products/${productId}/questions`, {
    question: question,
  });
  return results.payload;
};

export const getFavoriteProducts = async (
  page: number = 1,
  perPage: number = 20
): Promise<Array<Product>> => {
  const results = await GET(`/favorites/products`, page, perPage);
  return results.payload.map((product: any) => new Product(product));
};

export const getSubscriptionRecommendations = async (
  page: number = 1,
  perPage: number = 5
): Promise<Array<Product>> => {
  const result = await GET(`/subscriptions/recommendations`, page, perPage);
  return result.payload.map((product: any) => new Product(product));
};

export const favoriteProduct = async (productId: number) => {
  return await POST(`/products/${productId}/favorite`);
};

export const unfavoriteProduct = async (productId: number) => {
  return await DELETE(`/products/${productId}/favorite`);
};
