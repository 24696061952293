import {StatBlockProps} from "./types";

const StatBlock = ({...props}: StatBlockProps) => {
  return (
    <div
      className={`stat-block flex flex-col justify-center items-center text-center ${
        props.className || ''
      }`}
    >
      <div className='text-2xl font-bold'>{props.value}</div>
      <div className='text-xs text-gray-600'>{props.label}</div>
    </div>
  );
};

export default StatBlock;
