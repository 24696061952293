import {LoadingState} from '../store';
import {useEffect, useState} from "react";
import HubModel from "./hub.model";
import {HubAPI} from "./hub.api";

export const useGetHubDetails = (id: string): [HubModel | undefined, LoadingState] => {
  const [loadingState, setLoadingState] = useState(LoadingState.UNINITIALIZED);
  const [hub, setHub] =
    useState<HubModel | undefined>(undefined);

  useEffect(() => {
    let ignore = false;

    async function startFetching() {
      try {
        setLoadingState(LoadingState.LOADING);
        const hub = await HubAPI.getHubDetails(id);
        setLoadingState(LoadingState.LOADED);
        if (!ignore) {
          setHub(hub);
        }
      } catch (_e) {
        setLoadingState(LoadingState.FAILED);
      }
    }

    startFetching().then();
    return () => {
      ignore = true;
    };
  }, [id]);

  return [hub, loadingState];
};

export const useListHubs = (): [HubModel[] | undefined, LoadingState] => {
  const [loadingState, setLoadingState] = useState(LoadingState.UNINITIALIZED);
  const [hubs, setHubs] =
    useState<HubModel[] | undefined>(undefined);

  useEffect(() => {
    let ignore = false;

    async function startFetching() {
      try {
        setLoadingState(LoadingState.LOADING);
        const hubs = await HubAPI.getHubs();
        setLoadingState(LoadingState.LOADED);
        if (!ignore) {
          setHubs(hubs);
        }
      } catch (_e) {
        setLoadingState(LoadingState.FAILED);
      }
    }

    startFetching().then();
    return () => {
      ignore = true;
    };
  }, []);

  return [hubs, loadingState];
};
