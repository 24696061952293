import {IonSkeletonText} from "@ionic/react";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getBlogPosts} from "../../Api/api.blog";
import AppHeader from "../../Components/Header/AppHeader";
import PublicPage from "../../Components/Page/PublicPage";
import Pagination from "../../Components/Pagination/Pagination";
import {UseQuery} from "../../Helpers/Hooks/UseQuery";
import BlogPostCard from "./BlogPostCard";
import {Helmet} from 'react-helmet';

const BlogPage = () => {
  let urlQuery = UseQuery();
  const [blogPosts, setBlogPosts] = useState<Array<[]> | null>(null)
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const page: any = urlQuery.get('page') || 1;

    setPaginationPage(page);
    setIsLoading(true);
  }, [urlQuery]);

  useEffect(() => {
    if (isLoading && paginationPage) {
      getBlogPosts(paginationPage, 12, 'blog').then((res: any) => {
        setBlogPosts(res.posts);

        if (paginationPage === 1) {
          setTotalPages(Math.ceil(res.totalRows / 12));
        }

        setIsLoading(false);
      });
    }
  }, [isLoading, paginationPage]);

  return (
    <PublicPage>
      <Helmet>
        <title>Blogs | Market Wagon</title>
        <link rel="canonical" href="https://shop.marketwagon.com/blog"/>
      </Helmet>

      <AppHeader/>

      <section className='p-4 py-8 text-black max-w-3xl w-full mx-auto xl:max-w-5xl'>
        <h1 className='text-4xl flex justify-center items-center mb-8'>Market Wagon Blog</h1>

        {!isLoading && blogPosts?.length === 0 &&
          <p className='text-center'>No blog posts found.</p>
        }

        <ul className='md:flex md:flex-wrap md:justify-around'>
          {blogPosts && blogPosts.map((post: any, index: number) => {
            return (
              <li
                key={index}
                className='md:max-w-xs md:w-full xl:max-w-18.75rem'
              >
                <Link
                  to={`/blog/${post.slug}`}
                >
                  <BlogPostCard
                    title={post.title}
                    image={post.image}
                    body={post.body}
                    slug={post.slug}
                    author={post.author}
                    postDate={post.publish_date}
                    category={post.category}
                  />
                </Link>
              </li>
            );
          })}

          {blogPosts === null &&
            Array(25).fill(0).map((_, i: number) => {
              return (
                <li
                  key={i}
                  className='md:max-w-xs md:w-full xl:max-w-18.75rem'
                >
                  <article key={i} className="bg-white rounded-md shadow-lg mb-8">
                    <IonSkeletonText className='w-full h-56 rounded-t-lg' animated/>

                    <header className='px-4 pt-4'>
                      <IonSkeletonText className='w-full mb-3' style={{height: '20px'}} animated/>

                      <IonSkeletonText className='w-full mb-1' style={{height: '20px', width: '100px'}} animated/>
                      <IonSkeletonText className='w-full mb-3' style={{height: '20px', width: '100px'}} animated/>

                      <IonSkeletonText className='px-4 w-full' style={{height: '100px'}} animated/>
                    </header>

                    <nav className='p-4'>
                      <IonSkeletonText className='w-full mb-1' style={{height: '20px', width: '100px'}} animated/>
                    </nav>
                  </article>
                </li>
              )
            })
          }
        </ul>

        {blogPosts?.length !== 0 && totalPages &&
          <Pagination
            current={paginationPage}
            totalPages={totalPages}
            link='/blog?page={page}'
            onPageChange={(page) => {
              setBlogPosts(null);
              setPaginationPage(page);
            }}
          />
        }
      </section>
    </PublicPage>
  )
}

export default BlogPage;
