import {setUserAuth} from '../Domains/User/UserStore';
import {PUT, POST, GET} from './Api';
import {ProfileType} from '../Domains/User/types';

export const serverLogout = async (): Promise<boolean> => {
  try {
    await PUT('/auth/logout').catch((e) => {
      throw e;
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const requestPasswordResetEmail = async (email: string) => {
  await POST('/auth/forgot', {email: email});
  // TODO: should do something with the result
};

/**
 * Queiries the auth of existing values.
 * @returns Customer ID on success, false on failure
 */
export const queryAuth = async () => {
  const call = await GET('/auth');

  if (call.status === 200 && call.payload.customerId && call.payload.hubId) {
    return call.payload.customerId;
  } else {
    return false;
  }
};

export const getAuth = async () => {
  try {
    const call = await GET('/auth');

    return call.payload;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const getBridgeData = async () => {
  try {
    const call = await GET('/auth/bridge');
    return call.payload;
  } catch (error) {
    return false;
  }
}

export const handleBridgeData = async (profileData: ProfileType) => {
  const profile: any = setUserAuth(profileData);

  if (profile) {
    // @ts-ignore
    const _learnq: any = window._learnq || [];

    _learnq.push(['identify', {
      '$email': profile.email,
      '$first_name': profile.firstName,
      '$last_name': profile.lastName
    }]);
  }

  return true;
}

export const handleGuestBridge = async (bridgeData: any) => {
  try {
    setUserAuth(bridgeData).then();

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
 * Silently sets the guest cookie for registration.
 * "Silently" means this function returns true or false and does not modify userstate in any way.
 * @param zipCode
 * @returns
 */
export const silentlySetGuestCookie = async (
  zipCode: number | null = null,
) => {
  try {
    await POST(`/auth/guest?zipCode=${zipCode}`);

    return true;
  } catch (error) {
    return false;
  }
}
