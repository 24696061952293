import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage, IonSkeletonText,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from '@ionic/react';
import {cardOutline, close} from 'ionicons/icons';
import {useEffect, useState} from 'react';
import UseToggle from '../../Helpers/Hooks/UseToggle';
import DeliveryAddressForm from '../Delivery/DeliveryAddressForm';
import {profileUpdatePassword, saveProfile} from "../../Api/api.account";
import {loadUserProfile, UserStore} from './UserStore';
import {DeliveryAddressType} from '../Delivery/DeliveryAddress';
import {hashObject} from '../../Helpers/HashObject';
import IFrameModalController from "../../Components/IframeModal/IframeModalController";
import Vendor from "../Vendor/VendorClass";
import {getAllFavoriteVendors} from "../../Api/api.vendor";
import {VendorCommunications} from "../Vendor/VendorCommunications";
import {ProfileType} from './types';

const ProfileModal = ({...props}: any) => {
  // Get User State
  const userState = UserStore.useState(s => s);
  const [hasChanges, setHasChanges] = useState(false);
  const [changedPassword, setChangedPassword] = useState(false);

  // Set hooks for handle
  const [handle, setHandle] = useState(`${userState.handle || ""}`);

  const [isLoading, setIsLoading] = useState(true);
  const [favoriteVendors, setFavoriteVendors] = useState([]);
  useEffect(() => {
    if (isLoading) {
      getAllFavoriteVendors().then((vendors: any) => {
        setFavoriteVendors(vendors);
        setIsLoading(false);
      });
    }
  }, [isLoading]);


  // Set hooks for password
  const [passwords, setPasswords] = useState({current: "", new: "", confirm: ""});

  // Set hooks for user
  const [user, setUser] = useState({
    firstName: `${userState.firstName || ""}`,
    lastName: `${userState.lastName || ""}`,
    email: `${userState.email || ""}`
  });

  // Set hooks for toggling editAddress form
  const [editAddress, toggleEditAddress] = UseToggle(false);


  // Setup hooks for Address
  const addressOrUndefined: DeliveryAddressType | undefined = undefined;
  const [deliveryAddress, setDeliveryAddress] = useState(userState.deliveryAddress || addressOrUndefined);

  // Setup hooks for Gluten and Vegan
  const [isGlutenFree, toggleGlutenFree] = UseToggle(userState.isGlutenFree);
  const [isVegan, toggleVegan] = UseToggle(userState.isVegan);
  const [subscribedAllMessages, toggleSubscribedAllMessages] = UseToggle(userState.subscribedAllMessages);

  const [showAlert] = useIonAlert();
  const [showToast] = useIonToast();

  // //error catching states
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [handleError, setHandleError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [hasError, setHasError] = useState(false);


  const [showDropinIframe, setShowDropinIframe] = useState(false);
  useEffect(() => {
    loadUserProfile().then();
  }, []);


  useEffect(() => {
    if (user.firstName.length > 0) {
      setFirstNameError(false);
    } else {
      setFirstNameError(true);
    }
    if (user.lastName.length > 0) {
      setLastNameError(false);
    } else {
      setLastNameError(true);
    }
    if (user.email.length > 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    if (handle.length > 0) {
      setHandleError(false);
    } else {
      setHandleError(true);
    }
    if (passwords.new === passwords.confirm) {
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }
    if (firstNameError || lastNameError || handleError || emailError || confirmPasswordError) {
      setHasError(false);
    } else {
      setHasError(true);
    }

  }, [user, handle, passwords, confirmPasswordError, emailError, firstNameError, handleError, lastNameError])

  useEffect(() => {
    if (hashObject(deliveryAddress) !== hashObject(userState.deliveryAddress)) {
      setHasChanges(true);
    } else if (handle !== userState.handle) {
      setHasChanges(true);
    } else if (isVegan !== userState.isVegan) {
      setHasChanges(true);
    } else if (isGlutenFree !== userState.isGlutenFree) {
      setHasChanges(true);
    } else if (subscribedAllMessages !== userState.subscribedAllMessages) {
      setHasChanges(true);
    } else if (user.firstName !== userState.firstName) {
      setHasChanges(true);
    } else if (user.lastName !== userState.lastName) {
      setHasChanges(true);
    } else if (user.email !== userState.email) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
    if (passwords.current.length && passwords.new.length && passwords.new === passwords.confirm) {
      setChangedPassword(true);
    }

  }, [passwords, handle, isGlutenFree, isVegan, deliveryAddress, setHasChanges, userState, subscribedAllMessages, user, setChangedPassword])

  const onchangePassword = async () => {
    if (passwords.new === passwords.confirm) {
      try {

        await profileUpdatePassword(passwords.current, passwords.new).catch(e => {
          throw e
        })
        setChangedPassword(false);
        setPasswords({current: passwords.new, new: "", confirm: ""});
        showToast('Password successfully updated!', 1000).then();
      } catch (e: any) {
        showAlert(e.message).then();
      }
    }
  }

  const onSaveProfile = async () => {
    const payload: ProfileType = {
      isGlutenFree: isGlutenFree,
      isVegan: isVegan,
      subscribedAllMessages: subscribedAllMessages,
    }
    payload.firstName = user.firstName;
    payload.lastName = user.lastName;
    payload.email = user.email;
    payload.handle = handle;

    if (passwords.current.length && passwords.new === passwords.confirm) {
      payload.currentPassword = passwords.current;
      payload.newPassword = passwords.new;
      payload.confirmPassword = passwords.confirm;
    }
    if (hashObject(userState.deliveryAddress) !== hashObject(deliveryAddress)) {
      payload.deliveryAddress = deliveryAddress;
    } else {
      payload.deliveryAddress = userState.deliveryAddress;
    }


    try {
      // Save it
      await saveProfile(payload).catch(e => {
        throw e
      })
      // Now have UserStore load the profile
      await loadUserProfile().catch(e => {
        throw e
      })
      setHasChanges(false);
      showToast("Profile updated", 1000).then();
    } catch (e: any) {
      showAlert(e).then();
    }
  }


  return (
    <IonPage className="h-full overflow-hidden md:rounded-lg md:h-47.25rem">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton
              fill='clear'
              slot='start'
              onClick={() => props.onDismiss()}
            >
              <IonIcon icon={close} slot='icon-only'/>
            </IonButton>
          </IonButtons>
          <IonTitle>My Details</IonTitle>
        </IonToolbar>

        {/* Animation the Transition so it's not so harsh */}
        {(hasChanges && hasError) &&
          <IonToolbar
            className='px-10 lg:px-20 h-16 opacity-100 transform transition-all duration-100'>
            <IonButton onClick={onSaveProfile} fill="solid" expand="block">Save My Changes</IonButton>
          </IonToolbar>
        }

      </IonHeader>
      <main className='w-full overflow-y-auto h-full bg-gray-100 pt-4'>

        <IonListHeader className="flex items-center">
          <div className="whitespace-nowrap">Edit Profile</div>
        </IonListHeader>
        <IonList className="mb-4">


          <IonItem>
            <IonLabel position='fixed' className='text-gray-300'>
              Username
            </IonLabel>
            <IonInput
              placeholder='MyUsername'
              type='text'
              value={handle}
              required
              onIonChange={(evt: any) => {
                const _handle = evt?.target?.value;
                setHandle(_handle);
              }}
            >
            </IonInput>
            {handleError &&
              <IonText
                className="text-red-600"
              >
                Username required
              </IonText>
            }
          </IonItem>

        </IonList>

        {/* -----------------
                Dietary Settings
                ----------------- */}
        <IonListHeader>
          Dietary Preferences
        </IonListHeader>
        <IonList className="mb-4">

          <IonItem lines='none'>
            <p className='py-2 text-sm text-gray-600'>
              Have special dietary restrictions? Select them below, and we will
              highlight products that might meet your requirements
            </p>
          </IonItem>
          <IonItem>
            <IonLabel>Vegan</IonLabel>
            <IonToggle slot='end' checked={isVegan} onIonChange={() => {
              toggleVegan()
            }}/>
          </IonItem>
          <IonItem lines='none'>
            <IonLabel>Gluten Free</IonLabel>

            <IonToggle slot='end' checked={isGlutenFree} onIonChange={() => {
              toggleGlutenFree();
            }}/>
          </IonItem>
        </IonList>

        <IonListHeader>
          Add/Change Default Payment
        </IonListHeader>
        <IonList className="mb-4">
          <IonItem
            button
            lines='none'
            detail
            onClick={() => {
              setShowDropinIframe(true);
            }}
          >
            <IonIcon className='text-green-700' icon={cardOutline} slot='start'/>
            <strong>Payment Method</strong>
          </IonItem>
          <IFrameModalController
            onDismiss={() => {
              setShowDropinIframe(false);
            }}
            title='Default Payment Method'
            url={`${process.env.REACT_APP_SHOP_PROFILE_URL}`}
            open={showDropinIframe}
          />


        </IonList>

        {/* -----------------
                Delivery Settings
                ----------------- */}
        <IonListHeader>
          Delivery Address
        </IonListHeader>
        <IonList className="mb-4">

          {!editAddress && <IonItem lines='none'>
            <IonLabel>
              <h2>{deliveryAddress?.address}</h2>
              <p>{deliveryAddress?.city}, {deliveryAddress?.state} {deliveryAddress?.zipCode}</p>
            </IonLabel>
            <IonButton
              onClick={() => toggleEditAddress()}
              size="default"
              fill="clear"
              slot="end">
              Edit
            </IonButton>
          </IonItem>}
          {editAddress &&
            <div>
              <DeliveryAddressForm
                address={deliveryAddress}
                onCancel={() => toggleEditAddress()}
                onSave={(address: any) => {
                  setDeliveryAddress(address);
                  toggleEditAddress()
                }}/>
            </div>}
        </IonList>

        {/* -----------------
                Change Account Settings
                ----------------- */}
        <IonListHeader className="flex items-bottom justify-between">
          <div className="flex-grow">Update Account</div>
        </IonListHeader>
        <IonList className="mb-4">

          <IonItem>
            <IonLabel position='fixed' className='text-gray-300'>
              First Name
            </IonLabel>
            <IonInput
              placeholder=' First'
              type='text'
              value={user.firstName}
              required
              onIonChange={(evt: any) => {
                setUser({
                  firstName: `${evt?.target?.value || ""}`,
                  lastName: `${user.lastName || ""}`,
                  email: `${user.email || ""}`
                });
              }}
            >
            </IonInput>
            {firstNameError &&
              <IonText
                className="text-red-600"
              >
                First Name required
              </IonText>
            }
          </IonItem>
          <IonItem>
            <IonLabel position='fixed' className='text-gray-300'>
              Last Name
            </IonLabel>
            <IonInput
              placeholder='Last'
              type='text'
              value={user.lastName}
              required
              onIonChange={(evt: any) => {
                setUser({
                  lastName: `${evt?.target?.value || ""}`,
                  firstName: `${user.firstName || ""}`,
                  email: `${user.email || ""}`
                });
              }}
            >
            </IonInput>
            {lastNameError &&
              <IonText
                className="text-red-600"
              >
                Last Name required
              </IonText>
            }
          </IonItem>
          <IonItem lines="none">
            <IonLabel position='fixed' className='text-gray-300'>
              Email
            </IonLabel>
            <IonInput
              placeholder='My Email Address'
              type='text'
              value={user.email}
              required
              onIonChange={(evt: any) => {
                setUser({
                  email: `${evt?.target?.value || ""}`,
                  firstName: `${user.firstName || ""}`,
                  lastName: `${user.lastName || ""}`,
                });
              }}
            >
            </IonInput>
            {emailError &&
              <IonText
                className="text-red-600"
              >
                Email required
              </IonText>
            }
          </IonItem>

        </IonList>


        {/* -----------------
                Change Password
                ----------------- */}
        <IonListHeader>
          Change Password
        </IonListHeader>
        <IonList className="mb-4">
          <IonItem>
            <IonLabel
              position='fixed'
              className='text-gray-300'>
              Current
            </IonLabel>
            <IonInput
              placeholder='Current Password'
              type='password'
              value={passwords.current}
              required
              onIonChange={(evt: any) => {
                setPasswords({
                  current: evt?.target?.value,
                  new: passwords.new,
                  confirm: passwords.confirm
                });
              }}
            >
            </IonInput>
          </IonItem>
          <IonItem>
            <IonLabel
              position='fixed'
              className='text-gray-300'>
              New Pass
            </IonLabel>
            <IonInput
              placeholder='New Password'
              type='password'
              value={passwords.new}
              required
              onIonChange={(evt: any) => {
                setPasswords({
                  new: evt?.target?.value,
                  current: passwords.current,
                  confirm: passwords.confirm
                });
              }}
            >
            </IonInput>

          </IonItem>
          <IonItem lines="none">
            <IonLabel
              position='fixed'
              className='text-gray-300'>
              Confirm
            </IonLabel>
            <IonInput
              placeholder='Confirm New Password'
              type='password'
              value={passwords.confirm}
              required
              onIonChange={(evt: any) => {
                setPasswords({
                  confirm: evt?.target?.value,
                  current: passwords.current,
                  new: passwords.new
                });
              }}
            >
            </IonInput>
            {confirmPasswordError &&
              <IonText
                className="text-red-600"
              >
                Confirm password must match
              </IonText>
            }
          </IonItem>
          {changedPassword &&
            <IonItem lines='none'>
              <IonButton
                onClick={() => onchangePassword()}
                size="default"
                fill="clear"
                slot="end">
                Save Password
              </IonButton>
            </IonItem>}
        </IonList>

        {/* -----------------
                Email Settings
                ----------------- */}
        <IonListHeader>
          Email Settings
        </IonListHeader>
        <IonList className="mb-4">
          <IonItem lines="none">
            <IonLabel className="ion-text-wrap">Subscribed to all Vendor Messages</IonLabel>
            <IonToggle
              slot='end'
              checked={subscribedAllMessages}
              onIonChange={() => {
                toggleSubscribedAllMessages()
              }}/>
          </IonItem>

        </IonList>
        <IonListHeader>
          Vendor Communications
        </IonListHeader>
        <IonItem lines='none'>
          <p className='py-2 text-sm text-gray-600'>
            Unfollow vendors to stop receiving messages
          </p>
        </IonItem>

        {favoriteVendors.length > 0 && !isLoading &&
          <IonList className='max-w-screen-md py-4 mx-auto favorites'>
            {favoriteVendors.map((vendor: Vendor, index: number) => {
              return (
                <VendorCommunications
                  key={index}
                  vendor={vendor}
                  isFollowed={vendor.isFollowed}
                  handleSetLoading={() => {
                    setIsLoading(true);
                  }}
                  name={vendor.name}
                  id={vendor.id}
                />

              );
            })}
          </IonList>
        }
        {isLoading &&
          <IonItem className="mb-4">
            <IonSkeletonText
              animated

            />
          </IonItem>
        }
        {favoriteVendors.length === 0 && !isLoading &&
          <div className='p-4'>
            <div className='empty-block'>
              <h5>No Followed Vendors 😢</h5>
              <p className='items-center mb-4 text-sm text-gray-700 align-middle'>
                Looks like you haven't followed any vendors yet. Tap the heart
                icon on your favorite vendor to have them saved here.
              </p>
            </div>
          </div>
        }

        {/* buffer to ensure we scroll on mobile */}
        <div className="m-40"/>
      </main>

    </IonPage>
  );
};
export default ProfileModal;
