import {IonItem} from '@ionic/react';
import MWAvatar from '../../Components/MWAvatar/MWAvatar';
import Vendor from './VendorClass';
import {favoriteVendor, unfavoriteVendor} from "../../Api/api.vendor";
import {VendorItemType} from './types';

const unFollowVendor = async (vendor: Vendor, handleSetLoading: any) => {
  await unfavoriteVendor(vendor.id);
  handleSetLoading(true);

}

const followVendor = async (vendor: Vendor, handleSetLoading: any) => {
  await favoriteVendor(vendor.id);
  handleSetLoading(true);

}

export const VendorCommunications = ({...props}: VendorItemType) => {
  return (
    <IonItem detail={false} aria-label={props.vendor.name}
             lines="none">
      <div slot='start'
           className='mr-4'>
        <MWAvatar
          image={props.vendor.avatar}
          label={props.vendor.name}
          hideLabel={true}
          slot='start'

        />
      </div>

      <div className='font-bold line-clamp-2'>{props.vendor.name}</div>
      <div slot='end'>
        {props.vendor.isFollowed &&
          <button className='text-xs text-gray-500 line-clamp-1 '
                  onClick={() => {
                    unFollowVendor(props.vendor, props.handleSetLoading).then();
                  }}>
            Unfollow
          </button>
        }
        {!props.vendor.isFollowed &&
          <button className='text-xs text-gray-500 line-clamp-1'
                  onClick={() => {
                    followVendor(props.vendor, props.handleSetLoading).then();
                  }}>
            Follow
          </button>
        }
      </div>

    </IonItem>
  );
};
