const CALL_EVERY_MS: number = 1000 * 60 * 3; // every 3 minutes

let latestVersion: string;

/**
 * Check Version
 *
 * Gets a version from /version.json
 * if no latest is set, it sets it,
 * if there is a latest, and it's not the same
 * it alerts the user a new update is ready
 *
 * This will be called every 3 minutes
 */
const checkVersion = async () => {
  try {
    const versionCall = await fetch('/version.json');
    const versionPayload = await versionCall.json();

    if (!latestVersion) {
      // If no latest Version - set it to the payload version
      latestVersion = `${versionPayload.version || '0.0.0'}`;
    } else if (latestVersion !== versionPayload.version) {
      window.location.reload();
    }
  } catch (e) {
  }
};

export const observeVersionChange = () => {
  checkVersion().then();
  setInterval(() => {
    checkVersion().then();
  }, CALL_EVERY_MS);
};
