import {useIonRouter} from "@ionic/react";
import {useEffect, useState} from "react";
import CSATModal from '../CSATModal/CSATModal';
import MarketingSourceModal from '../MarketingSourceModal/MarketingSourceModal';
import NPSModal from '../NPSModal/NPSModal';
import ShareModal from '../ShareModal/ShareModal';
import SubscribePostCheckout from '../SubscribePostCheckout/SubscribePostCheckout';

const Modal = (props: any) => {
  const [modalType, setModalType] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const router = useIonRouter();

  const handleModalClose = () => {
    setIsOpen(false);

    // If the modal was triggered by a url we need to navigete them back to the mounted component.
    if (router.routeInfo.pathname.includes('/products/') ||
      router.routeInfo.pathname.includes('/vendors/') ||
      router.routeInfo.pathname.includes('/subscription') ||
      router.routeInfo.pathname.includes('/profile') ||
      router.routeInfo.pathname.includes('/account')
    ) {
      router.push('/shop');
    }

    setTimeout(() => {
      props.onModalClose();
    }, 500);

    return props.data;
  };

  useEffect(() => {
    if (modalType === null) {
      setModalType(props.data.name);
    }
  }, [modalType, props.data.name]);

  useEffect(() => {
    if (modalType !== null) {
      setIsOpen(true);
    }
  }, [modalType]);

  return (
    <>
      <div className='Modal w-full h-screen' style={{zIndex: 100}}>
        <div
          className={`ModalBackground bg-black w-full h-screen fixed top-0 right-0 ${
            isOpen ? 'opacity-50' : 'opacity-0'
          }`}
          style={{transition: 'opacity 0.5s', zIndex: 100}}
        />

        <div
          className={`ion-modal flex justify-center fixed right-0 w-full items-center h-full ${
            isOpen ? 'top-0' : 'top-full'
          }`}
          style={{transition: 'top 0.5s', zIndex: 100}}
        >
          <div
            className='h-full flex flex-col justify-start overflow-y-auto md:max-h-33.75rem md:justify-center'
            style={{
              width: '600px',
              zIndex: 100,
            }}
          >
            {props.data.name === 'CSAT' && (
              <CSATModal
                onDismiss={handleModalClose}
                orderId={props.data.data.orderId}
                question={props.data.data.question}
                questionId={props.data.data.questionId}
              />
            )}

            {props.data.name === 'MarketingSourceModal' && (
              <MarketingSourceModal
                onDismiss={handleModalClose}
                orderId={props.data.data.orderId}
              />
            )}

            {props.data.name === 'NPS' && (
              <NPSModal
                onDismiss={handleModalClose}
                orderId={props.data.data.orderId}
              />
            )}

            {props.data.name === 'Share' && (
              <ShareModal onDismiss={handleModalClose}/>
            )}

            {props.data.name === 'Subscribe' && (
              <SubscribePostCheckout onDismiss={handleModalClose}/>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
