import {scrollerType} from "./types";

const HorizontalScroller = ({...props}: scrollerType) => {
  return (
    <div
      className={`horizontal-scroller relative flex items-center overflow-x-auto ${
        props.className || ''
      }`}
    >
      <div className={`flex flex-nowrap ${props.wrapperClass || ''}`}>
        {props.children}
      </div>
    </div>
  )
}

export default HorizontalScroller;
