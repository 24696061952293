import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar, useIonToast,
} from '@ionic/react';
import {close, clipboardOutline} from 'ionicons/icons';
import ShareButton from '../../Components/ShareButton/ShareButton';
import {UserStore} from "../User/UserStore";
import {isPlatform} from "@ionic/core";
import Button from '../../Components/MWButton/MWButton'

interface InviteFriendModalProps {
  onDismiss: Function;
}

function ClipboardCopy({copyText}: { copyText: string }) {

  const [showToast] = useIonToast();

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = async () => {
    await copyTextToClipboard(copyText);
    showToast(`Link Copied`, 1500).then();
  }

  return (
    <Button
      title='Copy Link'
      onClick={handleCopyClick} className='text-white bg-green-600 rounded-md'>
      <IonIcon
        icon={clipboardOutline}
      />
      &nbsp;
      Copy Link
    </Button>
  );
}

const InviteFriendModal = ({...props}: InviteFriendModalProps) => {
  const userState = UserStore.useState(s => s);
  const inviteUrl = `${window.location.origin}/shop?referredByCustomerId=${userState.id}`;

  return (
    <IonPage className="h-full overflow-hidden md:rounded-lg md:h-37.5rem">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton fill='clear' onClick={() => props.onDismiss()}>
              <IonIcon icon={close} slot='icon-only'/>
            </IonButton>
          </IonButtons>
          <IonTitle>Refer Friends</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='w-full'>
        <div className='p-4'>
          <h1 className='mb-4 text-2xl font-extrabold leading-snug text-center'>
            Help others discover Market Wagon. Earn rewards!
          </h1>
          <p className='mb-4 text-base leading-snug text-gray-700'>
            When you invite friends, relatives, co-workers, or neighbors to shop local on Market Wagon, you will receive
            a $20 credit after each time one of them places their first order. And they get $20 off that order right off
            the bat!
          </p>
          <p className='mb-4 text-base leading-snug text-gray-700'>
            More new Market Wagon customers means more rewards for you. Just use your unique referral link below in
            texts, e-mails, or social media posts to start spreading the news about local food. And earning!
          </p>

          <input
            className='w-full p-2 mb-4 bg-gray-100 rounded-md'
            type='text'
            value={inviteUrl}
          ></input>

          <div className='flex items-center space-x-2 justify-around'>
            <ClipboardCopy copyText={inviteUrl}/>
            {
              (!isPlatform('desktop') &&
                <ShareButton
                  label='Share'
                  className='text-white bg-green-600 rounded-md'
                  title='Shop with me on Market Wagon'
                  url={inviteUrl}
                />
              )
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default InviteFriendModal;
