import {VendorDetailType} from "./types";

export default class Vendor {
  id: number;
  name: string;
  city?: string;
  state?: string;
  avatar?: string;
  isFollowed?: boolean;
  heroImage?: string;
  endorsementScore: any;
  description?: string;
  followers: number;
  products?: number;

  constructor(starter: VendorDetailType) {
    this.id = starter.id;
    this.name = starter.name;
    this.city = starter.city;
    this.state = starter.state;
    this.avatar = starter.avatar;
    this.heroImage = starter.heroImage;
    this.description = starter.description;
    this.followers = starter.followers || 0;
    this.endorsementScore = starter.endorsementScore || 0;
    this.isFollowed = starter.isFollowed;
    this.products = starter.products || 0;
  }
}
