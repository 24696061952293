import {
  IonContent,
  IonPage,
  useIonAlert,
} from '@ionic/react';
import useEventListener from '@use-it/event-listener';
import {useState} from 'react';
import {loginGuest} from '../../Api/api.account';
import IFrameModalController from '../../Components/IframeModal/IframeModalController';
import UseBranding from '../../Helpers/Hooks/UseBranding';
import MWButton from "../../Components/MWButton/MWButton";
import {userRegister} from "../User/UserStore";

type ViewType = 'zipCode' | 'register';
const CustomerReferralLanding = ({...props}: {
  changeView: Function;
  onDismiss: Function;
}) => {
  const [view, setView] = useState<ViewType>("zipCode");
  const [localZipCode, setLocalZipcode]: any = useState(null);
  const [localEmail, setLocalEmail]: any = useState(null);
  const [localPassword, setLocalPassword]: any = useState(null);
  const [localPasswordConfirm, setLocalPasswordConfirm]: any = useState(null);
  const [showMessage] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const urlQuery = new URLSearchParams(new URL(window.location.href).search);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const utmSource = urlQuery.get('utm_source');
  const utmCampaign = urlQuery.get('utm_campaign');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const guestRegister = async () => {
    setLoading(true);

    if (localZipCode && localZipCode.length === 5) {
      try {
        const res = await loginGuest(localZipCode, null, null, utmSource, utmCampaign);
        if (!res) {
          showMessage('Sorry, we do not currently support your location.').then();
        } else {
          setView('register');
        }
      } catch (e) {
        console.error('error setting user', e);
        showMessage('Sorry, we do not currently support your location.').then();
      }
    }
    setLoading(false);
  };

  const register = async () => {
    try {
      setLoading(true);
      await userRegister(null, null, localEmail, null, localZipCode, params.referredByCustomerId);
      props.onDismiss();
      setLoading(false);
    } catch (e) {
      const error: any = e;
      console.error('error setting user', error);
      showMessage(error.message).then();
    }
    setLoading(false);

  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && localZipCode) {
      if (view === 'zipCode') {
        guestRegister().then();
      } else {

      }
    }
  };

  useEventListener('keydown', handleKeyPress);

  return (
    <IonPage className='h-full'>
      <IonContent>
        <main className='bg-white py-4 px-4'>
          <div className='flex flex-col items-center justify-center bg-white'>
            <img
              className='max-w-none'
              src={UseBranding()}
              alt='Market Wagon Logo'
              width='80px'
              height='80px'
            />
            <h1 className='text-base font-bold text-center leading-tight mt-4 md:text-2xl'>
              Welcome to Market Wagon,<br/>
              the online farmer's market that delivers.
            </h1>

            {(view === 'zipCode' &&
              <>
                <p className='text-gray-800 text-center px-4 md:px-6 mt-4 text-sm'>
                  You’ll be excited by what you find here! It’s all locally sourced. Produce, proteins, baked goods and
                  more.
                  <br/>
                  <br/>
                  Please enter your Zip Code to make sure we can deliver to you.
                </p>
                <div
                  className='mt-4 w-full max-w-sm'>
                  <input
                    tabIndex={3}
                    type='tel'
                    placeholder='Zip Code'
                    className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg'
                    required
                    value={localZipCode || ''}
                    onChange={(evt: any) => {
                      setLocalZipcode(evt.target.value);
                    }}
                  />

                </div>
                <div className='mt-4 w-full max-w-sm'>
                  <MWButton
                    title='Next'
                    className='justify-center w-full text-white bg-green-600 rounded-lg'
                    onClick={() => {
                      guestRegister().then();
                    }}
                    disabled={(localZipCode || '').length !== 5 || loading}
                  >
                    {!loading ? (
                      <div>Next</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </MWButton>
                </div>
              </>
            )}
            {(view === 'register' &&
              <>
                <p className='text-gray-800 text-center px-4 md:px-6 mt-4 text-sm'>
                  We are thrilled that you are here!
                  <br/>
                  <br/>
                  Please enter your e-mail address to claim your $20 off credit. It will be a pre-loaded discount at
                  checkout. And by creating a password, you’ll be able to manage your shopping any time, any where.
                  <br/>
                  <br/>
                  After you order, the person who referred you will also get a $20 credit. That’s our way of thanking
                  them on your behalf for introducing you to Market Wagon!
                </p>
                <div
                  className='mt-4 w-full max-w-sm'>
                  <input
                    tabIndex={3}
                    type='email'
                    placeholder='Email Address'
                    style={{fontSize: '18px'}}
                    className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg'
                    value={localEmail || ''}
                    onChange={(evt: any) => {
                      setLocalEmail(evt.target.value);
                    }}
                  />
                </div>
                <div
                  className='mt-4 w-full max-w-sm'>
                  <input
                    tabIndex={4}
                    type='password'
                    placeholder='Password'
                    style={{fontSize: '18px'}}
                    className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg'
                    value={localPassword || ''}
                    onChange={(evt: any) => {
                      setLocalPassword(evt.target.value);
                    }}
                  />
                </div>
                <div
                  className='mt-4 w-full max-w-sm'>
                  <input
                    tabIndex={5}
                    type='password'
                    placeholder='Confirm Password'
                    style={{fontSize: '18px'}}
                    className='w-full text-xl py-1 px-4 border border-gray-200 shadow-sm rounded-lg'
                    value={localPasswordConfirm || ''}
                    onChange={(evt: any) => {
                      setLocalPasswordConfirm(evt.target.value);
                    }}
                  />
                </div>
                <div className='mt-4 w-full max-w-sm'>
                  <MWButton
                    title='Shop Market Wagon'
                    className='justify-center w-full text-white bg-green-600 rounded-lg'
                    onClick={() => {
                      register().then();
                    }}
                  >
                    {!loading ? (
                      <div>Shop Market Wagon</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </MWButton>
                </div>
              </>
            )}

          </div>
        </main>
        <div className='flex justify-center px-4 mt-4 mb-2 space-x-3 text-xs flex-fixed legal'>
          <button
            className='underline'
            title='View Terms of Service'
            onClick={() => {
              setShowTermsModal(true);
            }}
          >
            Terms of Service
          </button>

          <button
            className='underline'
            title='View Privacy Policy'
            onClick={() => {
              setShowPrivacyModal(true);
            }}
          >
            Privacy Policy
          </button>
        </div>
      </IonContent>

      <IFrameModalController
        onDismiss={() => {
          setShowTermsModal(false)
        }}
        title="Terms &amp; Conditions"
        url="https://marketwagon.com/pages/conditions-of-use"
        open={showTermsModal}
      />

      <IFrameModalController
        onDismiss={() => {
          setShowPrivacyModal(false)
        }}
        title="Privacy"
        url="https://marketwagon.com/privacy.php"
        open={showPrivacyModal}
      />
    </IonPage>
  );
};
export default CustomerReferralLanding;
