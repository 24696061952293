export default class DiscountItem {
  quantity?: number;
  code?: string;
  amount: number;
  reason?: string;
  forCartItemId?: number;

  constructor(starter: any) {
    this.quantity = starter.quantity;
    this.code = starter.code;
    this.amount = starter.amount;
    this.forCartItemId = starter.forCartItemId;
    this.reason = starter.reason;
  }
}
