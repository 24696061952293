import {IonIcon, useIonModal} from "@ionic/react";
import {informationCircle} from 'ionicons/icons';
import BeyondLocalModal from "../Modal/Modals/BeyondLocalModal";

const BeyondLocalBadge = (props: any) => {
  const [presentBeyondLocalModal, dismissBeyondLocalModal] = useIonModal(BeyondLocalModal, {
    onDismiss: () => {
      dismissBeyondLocalModal();
    }
  });

  return (
    <button
      className={`absolute z-20 h-auto shadow-xl ${props.classes}`}
      onClick={() => {
        presentBeyondLocalModal();
      }}
      style={{width: `${props.type !== 'compact' ? '67px' : '37px'}`}}
    >
      <img
        className='w-full h-full'
        src='/images/badges/beyond-local.svg'
        alt='Click to learn about our Beyond Local Program'
      />

      <IonIcon
        className={`text-white absolute -bottom-0.438rem -left-0.438rem
        ${props.type !== 'compact' ? '-right-0.438rem' : 'left'}`}
        icon={informationCircle} size="medium"/>
    </button>
  )
}

export default BeyondLocalBadge;
